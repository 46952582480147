import React, { Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ReduxState } from '../../redux/types';
import {
  selectDeviceStateByIdSelector,
  selectFirmwareVersionRecentByIdSelector,
  selectDevicePersonByDeviceIdSelector,
  getDeviceIsLoadingSelector,
  getDeviceErrorSelector,
  getDeviceSuccessSelector,
  selectDeviceConfigByIdSelector,
  selectDeviceByIdSelector,
  selectLocationByIdSelector,
  selectDeviceRemoteCommandByIdSelector,
  getDeviceSuccessMessage,
  getHwRevisionFirmwareMappingsSelector as getHwRevisionFirmwareMappingsSelector
} from '../../redux/device/deviceSelectors';
import {
  DeviceStateEntry,
  FirmwareVersion,
  DevicePerson,
  Device,
  DeviceConfig,
  DeviceLockStatus,
  DeviceDataCollection,
  DeviceRemoteCommand,
  Location,
  DeviceVersionUpdateStatus,
  deviceHealthStatusList,
  deviceConnectivityStatusList,
  deviceVersionUpdateStatusList,
  deviceLockStatusList,
  DeviceComponentStates,
  DeviceConnectivityStatus,
  DeviceSleeperType,
  HwRevisionFirmware,
  FirmwareType,
  HwRevision
} from '../../redux/device/deviceTypes';
import { DeviceGroup, Person } from '../../redux/person/personTypes';
import {
  getDeviceStateOverviewThunk,
  getDevicePersonOverviewThunk,
  getDeviceFirmwareVersionsRecentThunk,
  cancelUpdateDeviceThunk,
  checkDeviceStatusThunk,
  rebootDeviceThunk,
  restartAppThunk,
  updateDeviceThunk,
  retireDeviceThunk,
  updateDeviceConfigThunk,
  resetDevice,
  saveDeviceDataCollectionThunk,
  putAutoUpdateThunk,
  putConnectivityNotificationThunk,
  postUnlinkDeviceThunk,
  getHwRevisionsAndFirmwareMappingsThunk as getHwRevisionsAndFirmwareMappingsThunk
} from '../../redux/device/deviceThunks';
import { uuid16StringToBase64 } from '../../../shared/src/util/uuid';
import style from './DeviceOverview.scss';
import { formatTimestamp } from '../../utils/time';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { DeviceEditor } from '../DeviceEditor/DeviceEditor';
import { CircleIndicator } from '../../components/CircleIndicator/CircleIndicator';
import { isEqual } from 'lodash';
import { getFirmwareTypeForHwRevision, getKokoMapUrl, goTo } from '../../utils/util';
import Link from '@material-ui/core/Link';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';
import { BreadCrumbData, Error, Flag, flagOptions, SelectOption } from '../../redux/common/types';
import { getClearDeviceResponseStatus } from '../../redux/device/deviceActions';
import { putEsIdThunk } from '../../redux/person/personThunks';
import { clearPersonResponseStatusAction } from '../../redux/person/personActions';
import { getPersonByIdSelector, getPersonErrorStateSelector, getPersonLoadingStateSelector, getPersonSuccessStateSelector } from '../../redux/person/personSelector';
import { getActiveRecord, hasActiveRecord } from '../../utils/createFrontEndError';
import { UnlinkButton } from '../../components/UnlinkButton/UnlinkButton';
import {
  DataGrid,
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridSelectionModel,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import { ConfirmationDialog } from '../../components/ConfirmationDialog/ConfirmationDialog';
import { Box, Button, Container, IconButton, MenuItem, MuiThemeProvider, TextField } from '@material-ui/core';
import { GridToolbar } from '../../components/GridToolbar/GridToolbar';
import { defaultTheme } from '../../utils/styles';
import { defaultDeviceBreadCrumb, defaultDeviceBreadCrumbs } from '../../utils/breadcrumbs';
import { getBreadcrumbsSelector } from '../../redux/oauth/oauthSelectors';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { Header } from '../../components/Header/Header';
import ClearIcon from '@material-ui/icons/Clear';

interface Props {
  deviceStateById: Record<string, DeviceStateEntry>;
  firmwareVersionById: Record<string, FirmwareVersion>;
  devicePersonByDeviceId: Record<string, DevicePerson>;
  deviceById: Record<string, Device>;
  personById: Record<string, Person>;
  locationById: Record<string, Location>;
  deviceRemoteCommandById: Record<string, DeviceRemoteCommand>;
  deviceConfigById: Record<string, DeviceConfig>;
  isLoading: Record<string, boolean>;
  isLoadingPerson: Record<string, boolean>;
  error: Record<string, Error>;
  errorPerson: Record<string, Error>;
  success: Record<string, boolean>;
  successPerson: Record<string, boolean>;
  successMessage?: Nullable<string>;
  breadCrumbs: BreadCrumbData[];
  hwRevisionFirmwareMappings: HwRevisionFirmware[];
  getHwRevisionFirmwareMappings: () => void;
  getDeviceStates: () => void;
  getDevicePersonOverview: () => void;
  getDeviceFirmwareVersionsRecent: () => void;
  requestCancelUpdate: (deviceId: string, osTreeRefSpec: string) => void;
  requestCheckStatus: (deviceId: string) => void;
  requestReboot: (deviceId: string) => void;
  requestRestartApp: (deviceId: string) => void;
  requestRetire: (deviceId: string) => void;
  requestUpdate: (deviceId: string, osTreeRefSpec: string, isAutoUpdateDisabled: boolean) => void;
  requestSaveDeviceDataCollection: (deviceDataCollection: DeviceDataCollection) => Promise<any>;
  requestUpdateEsId: (personId: string, deviceId: string, esId?: Nullable<string>) => Promise<any>;
  requestUpdateDeviceConfig: (deviceConfig: DeviceConfig) => Promise<any>;
  requestFactoryReset: (deviceId: string) => void;
  putAutoUpdate: (deviceId: string, autoUpdateDisabled: boolean) => void;
  putConnectivityNotification: (deviceId: string, connectivityNotification: boolean) => void;
  postUnlinkDevice: (deviceId: string, personId: string, sensorId: string) => void;
  clearDeviceResponseStatus: () => void;
  clearPersonResponseStatus: () => void;
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
}

export interface DeviceOverviewRow {
  id: string;
  personId?: Nullable<string>;
  owner?: Nullable<string>;
  infoPopupContent: string;
  email?: Nullable<string>;
  serialNumber: string;
  deviceNumber?: Nullable<string>;
  connectivityStatus: string;
  connectivityStatusTimestamp: string;
  healthStatus: string;
  healthStatusTimestamp: string;
  softwareVersion: string;
  swVersionStartDateTs: string;
  firmwareVersion: string;
  firmwareVersionUpdate: string;
  firmwareVersionUpdateStatus: DeviceVersionUpdateStatus;
  hwRevision: HwRevision;
  firmwareType: FirmwareType;
  osLockStatus: DeviceLockStatus;
  autoUpdateDisabled: boolean;
  deviceId64: string;
  deviceRemoteCommandPort: number;
  endDate: string;
  ledsEnabled: boolean;
  micEnabled: boolean;
  fitbitId?: Nullable<string>;
  kobeId?: Nullable<string>;
  fitbitAuthToken?: Nullable<string>;
  museId?: Nullable<string>;
  hexoId?: Nullable<string>;
  deviceSleeperType?: Nullable<DeviceSleeperType>;
  esId?: Nullable<string>;
  connectivityNotification?: Nullable<boolean>;
  latestFactoryResetTs: string;
  timeZoneId?: Nullable<string>;
  devicePersonDeviceId?: Nullable<string>;
  devicePersonPersonId?: Nullable<string>;
  devicePersonSensorId?: Nullable<string>;
  deviceComponentStates: DeviceComponentStates;
  deviceGroup?: Nullable<DeviceGroup>;
  cohortId?: Nullable<number>;
}

const invalidFilterItem = (filter: GridFilterItem) => !filter?.value && filter?.operatorValue !== 'isEmpty' && filter?.operatorValue !== 'isNotEmpty';

const DeviceOverview: React.FC<Props> = ({
  deviceStateById,
  firmwareVersionById,
  devicePersonByDeviceId,
  deviceById,
  personById,
  locationById,
  deviceRemoteCommandById,
  deviceConfigById,
  isLoading,
  isLoadingPerson,
  error,
  errorPerson,
  success,
  successPerson,
  successMessage,
  breadCrumbs,
  hwRevisionFirmwareMappings,
  getHwRevisionFirmwareMappings,
  getDeviceStates,
  getDevicePersonOverview,
  getDeviceFirmwareVersionsRecent,
  requestCancelUpdate,
  requestCheckStatus,
  requestReboot,
  requestRestartApp,
  requestRetire,
  requestUpdate,
  requestSaveDeviceDataCollection,
  requestUpdateEsId,
  requestUpdateDeviceConfig,
  requestFactoryReset,
  putAutoUpdate,
  putConnectivityNotification,
  postUnlinkDevice,
  clearDeviceResponseStatus,
  clearPersonResponseStatus,
  appendBreadCrumbs
}) => {
  const makeQueryUrl = (deviceId?: Nullable<string>): string => '/devices' + (deviceId ? `/${deviceId}` : '');

  const [quickSelectSelected, setQuickSelectSelected] = useState<any>(1);
  const [expandedPersonInfoDeviceId, setExpandedPersonInfoDeviceId] = useState<string>(''); // deviceId of the row on which person's info is expanded
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [{ columnField: 'endDate', value: undefined, operatorValue: 'isEmpty' }] });
  // Local copy of filter model containing all active filters for reference since free grid version only allows one active filter to be used internally
  const [localFilterModel, setLocalFilterModel] = useState<GridFilterModel>({ items: [{ columnField: 'endDate', value: undefined, operatorValue: 'isEmpty' }] });

  const navigate = useNavigate();
  const location: any = useLocation();
  const params: any = useParams();

  const firmwareVersions = Object.values(firmwareVersionById).sort((a, b) => b.versionName.localeCompare(a.versionName)) || [];
  const latestFirmwareVersionName =
    firmwareVersions && firmwareVersions.length ? firmwareVersions?.reduce((a, b) => (a.created.localeCompare(b.created) <= 0 ? b : a))?.versionName : '';

  const getQuickSelectOptionMapping = (): Map<string, SelectOption> =>
    new Map<string, SelectOption>([
      [JSON.stringify([{ columnField: 'endDate', value: undefined, operatorValue: 'isEmpty' }]), { value: 1, label: 'DEFAULT VIEW: Device Status = "Non-Retired"' }],
      [
        JSON.stringify([
          { columnField: 'endDate', value: undefined, operatorValue: 'isEmpty' },
          { columnField: 'firmwareVersion', value: latestFirmwareVersionName, operatorValue: 'is' }
        ]),
        { value: 2, label: 'Device Status = "Non-Retired" AND Firmware = "Latest"' }
      ],
      [JSON.stringify([{ columnField: 'endDate', value: undefined, operatorValue: 'isNotEmpty' }]), { value: 3, label: 'Device Status = "Retired"' }],
      [JSON.stringify([{ columnField: 'firmwareVersion', value: latestFirmwareVersionName, operatorValue: 'is' }]), { value: 4, label: 'Firmware = "Latest"' }],
      [JSON.stringify([{ columnField: 'firmwareVersion', value: latestFirmwareVersionName, operatorValue: 'not' }]), { value: 5, label: 'Firmware Version != "Latest"' }],
      [JSON.stringify([{ columnField: 'connectivityStatus', value: DeviceConnectivityStatus.CONNECTED, operatorValue: 'is' }]), { value: 6, label: 'Online Status = "Connected"' }],
      [
        JSON.stringify([{ columnField: 'connectivityStatus', value: DeviceConnectivityStatus.DISCONNECTED, operatorValue: 'is' }]),
        { value: 7, label: 'Online Status = "Disconnected"' }
      ]
    ]);
  useEffect(() => {
    getHwRevisionFirmwareMappings();
    getDeviceStates();
    getDevicePersonOverview();
    getDeviceFirmwareVersionsRecent();
  }, []);

  useEffect(() => {
    setRows(() => getRows());
    setSelectedDeviceId(() => params['deviceId']);
  }, [devicePersonByDeviceId, deviceStateById, deviceById, params, hwRevisionFirmwareMappings]);

  useEffect(() => {
    const matchedFilterItemLists: GridFilterItem[][] = [];
    for (let i = 0; i < localFilterModel?.items?.length; i++) {
      const filterItem: GridFilterItem = localFilterModel.items[i];
      const filterItemWithoutMetaData: GridFilterItem[] = [
        {
          columnField: filterItem.columnField,
          value: filterItem.value,
          operatorValue: filterItem.operatorValue
        }
      ];
      for (let j = 0; j < matchedFilterItemLists.length; j++) {
        const concatItem: GridFilterItem[] = matchedFilterItemLists[j].concat(filterItemWithoutMetaData);
        const matchedQuickSelect: Nullable<SelectOption> = getQuickSelectOptionMapping().get(JSON.stringify(concatItem));
        if (matchedQuickSelect) {
          matchedFilterItemLists.push(concatItem);
        }
      }
      const matchedQuickSelect: Nullable<SelectOption> = getQuickSelectOptionMapping().get(JSON.stringify(filterItemWithoutMetaData));
      if (matchedQuickSelect) {
        matchedFilterItemLists.push(filterItemWithoutMetaData);
      }
    }
    if (matchedFilterItemLists.length) {
      const max = matchedFilterItemLists?.reduce((a, b) => (a.length > b.length ? a : b));
      setQuickSelectSelected(() => getQuickSelectOptionMapping()?.get(JSON.stringify(max))?.value);
    } else {
      setQuickSelectSelected(() => '');
    }
  }, [localFilterModel]);

  const getRows = (): DeviceOverviewRow[] => {
    return Object.values(deviceStateById).map((deviceState) => {
      const deviceId: string = deviceState.id;
      const devicePerson: Nullable<DevicePerson> = deviceId ? devicePersonByDeviceId?.[deviceId] : undefined;
      const person: Nullable<Person> = devicePerson?.personId ? personById?.[devicePerson?.personId] : undefined;
      const device: Device = deviceById?.[deviceId];
      const location: Nullable<Location> = devicePerson?.locationId ? locationById?.[devicePerson?.locationId] : undefined;
      const deviceRemoteCommand: DeviceRemoteCommand = deviceRemoteCommandById?.[deviceId];
      const deviceConfig: DeviceConfig = deviceConfigById?.[deviceId];
      const firmwareType = getFirmwareTypeForHwRevision(deviceState.hwRevision, hwRevisionFirmwareMappings);
      const row: DeviceOverviewRow = {
        id: deviceId,
        personId: person?.id,
        owner: person?.fullName,
        infoPopupContent: `deviceId: ${deviceId}\nperson: ${JSON.stringify(person, null, 2)}`,
        email: person?.email,
        serialNumber: device?.serialNumber,
        deviceNumber: device?.deviceNumber !== undefined ? `D${device?.deviceNumber}` : undefined,
        connectivityStatus: deviceState?.connectivityStatus,
        connectivityStatusTimestamp: formatTimestamp(deviceState?.connectivityStatusTimestamp),
        healthStatus: deviceState?.healthStatus,
        healthStatusTimestamp: formatTimestamp(deviceState?.healthStatusTimestamp),
        softwareVersion: deviceState?.softwareVersion,
        swVersionStartDateTs: formatTimestamp(deviceState?.swVersionStartDateTs),
        firmwareVersion: deviceState?.firmwareVersion,
        firmwareVersionUpdate: deviceState?.firmwareVersionUpdate,
        firmwareVersionUpdateStatus: deviceState?.firmwareVersionUpdateStatus,
        hwRevision: deviceState?.hwRevision || HwRevision.UNKNOWN,
        firmwareType: firmwareType,
        osLockStatus: deviceState?.osLockStatus,
        autoUpdateDisabled: deviceState?.autoUpdateDisabled,
        deviceId64: uuid16StringToBase64(deviceId),
        deviceRemoteCommandPort: deviceRemoteCommand?.port,
        endDate: device?.endDate,
        ledsEnabled: deviceConfig?.ledsEnabled,
        micEnabled: deviceConfig?.micEnabled,
        fitbitId: deviceState.fitbitId,
        fitbitAuthToken: deviceState?.fitbitAuthToken,
        kobeId: deviceState?.kobeId,
        museId: deviceState?.museId,
        hexoId: deviceState?.hexoId,
        deviceSleeperType: deviceState?.deviceSleeperType,
        esId: person?.esId,
        connectivityNotification: device?.connectivityNotification,
        latestFactoryResetTs: device?.latestFactoryResetTs,
        timeZoneId: location?.timeZoneId,
        devicePersonDeviceId: devicePerson?.deviceId,
        devicePersonPersonId: devicePerson?.personId,
        devicePersonSensorId: devicePerson?.sensorId,
        deviceComponentStates: deviceState?.deviceComponentStates,
        deviceGroup: person?.personSettings?.deviceGroup,
        cohortId: person?.personSettings?.cohortId
      };
      return row;
    });
  };

  const [rows, setRows] = useState<GridRowsProp>(() => getRows());

  const rowById = {};
  for (const row of rows) {
    rowById[row.id] = row;
  }

  const setExpandedPerson: Function = (deviceId: string) => {
    return (e): void => {
      e.stopPropagation();
      setExpandedPersonInfoDeviceId(deviceId == expandedPersonInfoDeviceId ? '' : deviceId);
    };
  };

  const requestCancelUpdateWrapper = (id: string): void => {
    const row = rowById[id];
    if (!row) {
      alert(`Could not find device\n${id}`);
      return;
    }
    if (row.firmwareVersion != row.firmwareVersionUpdate) {
      requestCancelUpdate(row.id, row.firmwareVersionUpdate);
    } else {
      alert(`Target version and current version need to be different. Aborting. Device\n${id}\nTry fetching the latest data`);
    }
  };

  // Takes an action callback (deviceId: string, ...args) => void and args for that action
  const applyToFilteredDevices: Function = (func: (deviceId: string, ...args) => void, ...args) => {
    const getMessage = (key: any, ...args): string => {
      switch (key) {
        case requestCheckStatus:
          return 'Request status of the following devices';
        case requestReboot:
          return 'The following devices will be affected';
        case requestRestartApp:
          return 'The following devices will be affected';
        case requestUpdate:
          return `Request firmware update (${args[0]}) of the following devices`;
        case requestCancelUpdateWrapper:
          return `Request update cancellation for the following devices`;
        case requestRetire:
          return `Retire the following devices`;
        case requestFactoryReset:
          return `Request factory reset of the following devices`;
        default:
          return 'The following devices will be affected';
      }
    };

    return (): void => {
      // Ask for user confirmation
      if (!confirm(getMessage(func, ...args) + ':\n' + selectionModel.join('\n'))) return;
      // Apply the func on each device
      selectionModel.forEach((id) => {
        if (func == requestUpdate) {
          // FIXME
          const disableAutoUpdates = confirm(`Disable auto-updates for device ${id}`);
          args.push(disableAutoUpdates);
        }
        func(id as string, ...args);
      });

      if (func == requestUpdate) {
        setTimeout(getDeviceStates, 1000);
      }
    };
  };

  const updateDeviceDataCollection = async (deviceDataCollection: DeviceDataCollection): Promise<any> => {
    try {
      await requestSaveDeviceDataCollection(deviceDataCollection);
    } finally {
      togglePopupState();
    }
  };

  const updateEsId = async (personId: Nullable<string>, deviceId: string, esId?: Nullable<string>): Promise<any> => {
    try {
      await requestUpdateEsId(personId || '', deviceId, esId || '');
    } finally {
      togglePopupState();
    }
  };

  const updateAutoUpdateDisabled = async (deviceId: string, autoUpdateDisabled: boolean): Promise<any> => {
    try {
      await putAutoUpdate(deviceId, autoUpdateDisabled);
    } finally {
      togglePopupState();
    }
  };

  const updateConnectivityNotification = async (deviceId: string, connectivityNotification: boolean): Promise<any> => {
    try {
      await putConnectivityNotification(deviceId, connectivityNotification);
    } finally {
      togglePopupState();
    }
  };

  const updateDeviceConfig = async (deviceId: string, ledFlag: boolean, micFlag: boolean): Promise<any> => {
    const deviceConfig: DeviceConfig = deviceConfigById?.[deviceId];
    const updatedConfig: DeviceConfig = {
      id: deviceId,
      ledsEnabled: ledFlag,
      micEnabled: micFlag,
      created: deviceConfig?.created,
      modifiedDate: deviceConfig?.modifiedDate
    };
    try {
      await requestUpdateDeviceConfig(updatedConfig);
    } finally {
      togglePopupState();
    }
  };

  const editorPopup = (deviceId: string): JSX.Element => (
    <DeviceEditor
      updateDeviceConfig={updateDeviceConfig}
      updateDeviceDataCollection={updateDeviceDataCollection}
      updateEsId={updateEsId}
      updateAutoUpdate={updateAutoUpdateDisabled}
      updateConnectivityNotification={updateConnectivityNotification}
      row={rowById[deviceId]}
      handleClose={() => togglePopupState()}
    />
  );

  const togglePopupState = (deviceId?: Nullable<string>): void => {
    const newDeviceId: string = deviceId || '';
    goTo(makeQueryUrl(newDeviceId), navigate).call(this);
  };

  const clearResponseStatuses = async () => {
    await clearDeviceResponseStatus();
    await clearPersonResponseStatus();
  };

  const factoryReset = async (e: SyntheticEvent, deviceId: string): Promise<any> => {
    e.stopPropagation();
    await requestFactoryReset(deviceId);
  };

  const columns: GridColDef[] = [
    { field: 'esId', headerName: 'ES ID', width: 150 },
    {
      field: 'owner',
      headerName: 'Owner',
      width: 350,
      renderCell: (params: GridRenderCellParams) => {
        const rowData: GridRowModel = params.row;
        const personId: string = rowData?.['personId'];
        const deviceId: string = rowData?.['id'];
        const infoPopupContent: string = rowData?.['infoPopupContent'];
        return (
          <React.Fragment>
            <Link target="_blank" hidden={!personId || !params.value} href={`/user/${personId}`} onClick={(e) => e.stopPropagation()}>
              {/* @ts-expect-error: TODO add description */}
              {params.value || ''}
            </Link>
            {!!params.value && (
              <div className={style.infoIcon} onClick={setExpandedPerson(deviceId)}>
                ⓘ
              </div>
            )}
            <ConfirmationDialog
              open={expandedPersonInfoDeviceId === deviceId}
              dialogText={infoPopupContent}
              closeEvent={() => setExpandedPersonInfoDeviceId(() => '')}
              closeEventText="Close"
            />
          </React.Fragment>
        );
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 380,
      renderCell: (params: GridRenderCellParams) => {
        const personId: string = params.row?.['personId'];
        return (
          <Link target="_blank" hidden={!personId} href={`/user/${personId}`} onClick={(e) => e.stopPropagation()}>
            {/* @ts-expect-error: TODO add description */}
            {params.value || ''}
          </Link>
        );
      }
    },
    { field: 'id', headerName: 'Device UUID', width: 350 },
    { field: 'deviceId64', headerName: 'Device ID 64', width: 250 },
    { field: 'serialNumber', headerName: 'Serial #', width: 200 },
    { field: 'deviceNumber', headerName: 'Device #', width: 140 },
    {
      field: 'timeZoneId',
      headerName: 'Koko Map',
      width: 120,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => {
        const personId: string = params.row?.['personId'];
        const timeZoneId: string = params.value as string;
        const kokoMapLink = personId ? getKokoMapUrl(personId, timeZoneId, true) : null;
        return (
          <Link target="_blank" hidden={!personId} href={kokoMapLink || ''} onClick={(e) => e.stopPropagation()}>
            Koko Map
          </Link>
        );
      }
    },
    {
      field: 'devicePersonDeviceId',
      headerName: 'Unlink',
      width: 120,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => {
        const rowData: GridRowModel = params.row;
        const devicePersonDeviceId: string = params.value as string;
        const devicePersonPersonId: string = rowData?.['devicePersonPersonId'];
        const devicePersonSensorId: string = rowData?.['devicePersonSensorId'];
        const owner: string = rowData?.['owner'];
        const deviceNumber: string = rowData?.['deviceNumber'];
        const id: string = params.id as string;
        const personId: string = rowData?.['personId'];
        return (
          <UnlinkButton
            unlinkDevice={() => postUnlinkDevice(devicePersonDeviceId, devicePersonPersonId, devicePersonSensorId)}
            personName={owner}
            deviceNumber={deviceNumber}
            disabled={!devicePersonDeviceId || devicePersonDeviceId !== id || !devicePersonSensorId || !devicePersonPersonId || devicePersonPersonId !== personId}
          />
        );
      }
    },
    {
      field: 'devicePersonSensorId',
      headerName: 'Factory Reset',
      width: 140,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => (
        <Button variant="contained" onClick={(e) => factoryReset(e, params.id as string)}>
          Factory Reset
        </Button>
      )
    },
    {
      field: 'connectivityStatus',
      headerName: 'Online Status',
      width: 310,
      type: 'singleSelect',
      valueOptions: deviceConnectivityStatusList,
      renderCell: (params: GridRenderCellParams) => (
        <React.Fragment>
          <CircleIndicator status={params.value as string} />
          {params.value} ({params.row?.['connectivityStatusTimestamp']})
        </React.Fragment>
      )
    },
    {
      field: 'healthStatus',
      headerName: 'Device Health',
      width: 300,
      type: 'singleSelect',
      valueOptions: deviceHealthStatusList,
      renderCell: (params: GridRenderCellParams) => (
        <React.Fragment>
          <CircleIndicator status={params.value as string} />
          {params.value} ({params.row?.['healthStatusTimestamp']})
        </React.Fragment>
      )
    },
    {
      field: 'ledsEnabled',
      headerName: 'Feature Flags',
      width: 250,
      sortable: false,
      filterable: false,
      valueGetter: (params: GridValueGetterParams) => `LEDS ${params.row?.['ledsEnabled'] ? 'Enabled' : 'Disabled'} / Mic ${params.row?.['micEnabled'] ? 'Enabled' : 'Disabled'}`
    },
    { field: 'kobeId', headerName: 'Kobe ID', width: 140 },
    { field: 'fitbitId', headerName: 'Fitbit ID', width: 140 },
    { field: 'museId', headerName: 'Muse ID', width: 140 },
    { field: 'hexoId', headerName: 'Hexo ID', width: 140 },
    { field: 'deviceSleeperType', headerName: 'Sleeper Type', width: 140 },
    {
      field: 'deviceRemoteCommandPort',
      headerName: 'Remote Command Port',
      type: 'number',
      width: 230,
      valueFormatter: (params: GridValueFormatterParams) => (!params.value ? '' : ('' + params.value).replace(',', ''))
    },
    {
      field: 'softwareVersion',
      headerName: 'SW Version',
      width: 670,
      valueFormatter: (params: GridValueFormatterParams) => `${params.value} (${params.row?.['swVersionStartDateTs']})`
    },
    { field: 'firmwareVersion', headerName: 'FW Version', width: 300, type: 'singleSelect', valueOptions: firmwareVersions.map((f) => f.versionName) },
    { field: 'firmwareVersionUpdate', headerName: 'FW Version Updating', width: 300 },
    { field: 'firmwareVersionUpdateStatus', headerName: 'Update Status', width: 300, type: 'singleSelect', valueOptions: deviceVersionUpdateStatusList },
    { field: 'osLockStatus', headerName: 'Lock Status', width: 300, type: 'singleSelect', valueOptions: deviceLockStatusList },
    {
      field: 'autoUpdateDisabled',
      headerName: 'Auto-Update',
      width: 170,
      type: 'singleSelect',
      valueOptions: flagOptions,
      valueGetter: (params: GridValueGetterParams) => (params.value ? Flag.DISABLED : Flag.ENABLED)
    },
    { field: 'hwRevision', headerName: 'HW Revision', width: 300 },
    { field: 'firmwareType', headerName: 'FW Type', width: 150 },
    {
      field: 'connectivityNotification',
      headerName: 'Connectivity Notification',
      width: 240,
      type: 'singleSelect',
      valueOptions: flagOptions,
      valueGetter: (params: GridValueGetterParams) => (params.value ? Flag.ENABLED : Flag.DISABLED)
    },
    {
      field: 'latestFactoryResetTs',
      headerName: 'Latest Factory Reset',
      width: 210,
      type: 'dateTime',
      valueGetter: (params: GridValueGetterParams) => (params.value ? moment('' + params.value).format('YYYY-MM-DD HH:mm') : undefined)
    },
    {
      field: 'endDate',
      headerName: 'Retired',
      width: 150,
      type: 'dateTime',
      valueGetter: (params: GridValueGetterParams) => (params.value ? moment('' + params.value).format('YYYY-MM-DD HH:mm') : undefined)
    }
  ];

  // Free version of grid only supports single filter at a time so overriding.
  const multiFilterAppliedRows = (filterModel: GridFilterModel): GridRowsProp => {
    if (filterModel.items.length > 1) {
      return rows.filter((row) => {
        let matchesAllConditions: boolean = true;
        for (let i: number = 0; i < filterModel.items.length; i++) {
          const filterItem: GridFilterItem = filterModel.items[i];
          const rowValue: any = filterItem.columnField ? row[filterItem.columnField] : undefined;
          if (filterItem.operatorValue === 'is' || filterItem.operatorValue === 'equals' || filterItem.operatorValue === '=') {
            matchesAllConditions = matchesAllConditions && (rowValue as string)?.toLowerCase() === (filterItem.value as string)?.toLowerCase();
          } else if (filterItem.operatorValue === 'not' || filterItem.operatorValue === '!=') {
            matchesAllConditions = matchesAllConditions && (rowValue as string)?.toLowerCase() !== (filterItem.value as string)?.toLowerCase();
          } else if (filterItem.operatorValue === 'contains') {
            matchesAllConditions = matchesAllConditions && (rowValue as string)?.toLowerCase().includes((filterItem.value as string)?.toLowerCase());
          } else if (filterItem.operatorValue === 'startsWith') {
            matchesAllConditions = matchesAllConditions && (rowValue as string)?.toLowerCase().startsWith((filterItem.value as string)?.toLowerCase());
          } else if (filterItem.operatorValue === 'endsWith') {
            matchesAllConditions = matchesAllConditions && (rowValue as string)?.toLowerCase().endsWith((filterItem.value as string)?.toLowerCase());
          } else if (filterItem.operatorValue === '<') {
            matchesAllConditions = matchesAllConditions && (rowValue as number) < (filterItem.value as number);
          } else if (filterItem.operatorValue === '>') {
            matchesAllConditions = matchesAllConditions && (rowValue as number) > (filterItem.value as number);
          } else if (filterItem.operatorValue === '<=') {
            matchesAllConditions = matchesAllConditions && (rowValue as number) <= (filterItem.value as number);
          } else if (filterItem.operatorValue === '>=') {
            matchesAllConditions = matchesAllConditions && (rowValue as number) >= (filterItem.value as number);
          } else if (filterItem.operatorValue === 'isEmpty') {
            matchesAllConditions = matchesAllConditions && !rowValue;
          } else if (filterItem.operatorValue === 'isNotEmpty') {
            matchesAllConditions = matchesAllConditions && !!rowValue;
          }
        }
        return matchesAllConditions;
      });
    } else {
      return rows;
    }
  };

  const handleFilterModelUpdate = (
    newFilterMode: GridFilterModel,
    setLocalFilter: Dispatch<SetStateAction<GridFilterModel>>,
    setInternalFilter: Dispatch<SetStateAction<GridFilterModel>>,
    location: any
  ): void => {
    setLocalFilter(() => newFilterMode);
    setInternalFilter(() => {
      const standardFilters: GridFilterItem[] = newFilterMode?.items?.filter((item) => item.columnField !== 'search' && !invalidFilterItem(item));
      return { items: standardFilters?.[0] ? [standardFilters?.[0]] : [] };
    });
  };

  const handleInternalLocalFilterModalChange = (newFilterModel: GridFilterModel) => {
    setLocalFilterModel(() => newFilterModel);
  };

  const commonFilterClick = (gridFilterItems: Nullable<GridFilterItem[]>) => {
    const filterModelFromUrl: GridFilterModel = { items: [] };
    if (gridFilterItems) {
      filterModelFromUrl.items.push(...gridFilterItems);
    }
    handleFilterModelUpdate(filterModelFromUrl, setLocalFilterModel, setFilterModel, location);
    handleInternalLocalFilterModalChange(filterModelFromUrl); // Simulate the data-grid pass-thru of the internal filter
  };

  const selectChange = (value: any): void => {
    const entry: Nullable<[string, SelectOption]> = Array.from(getQuickSelectOptionMapping()).find((entry) => entry[1].value === value);
    if (entry) {
      commonFilterClick(JSON.parse(entry[0]));
    } else {
      commonFilterClick(undefined);
    }
  };

  const unsupportedFwTypeMessage = `Can't update devices with multiple FW types`;

  const triggerUpdate = (version: string): void => {
    if (version.includes(unsupportedFwTypeMessage)) {
      return;
    }
    applyToFilteredDevices(requestUpdate, version)();
  };

  // If there are any selected devices, only show firmware versions that are supported by all selected devices. And show a warning if different type FW types are selected.
  const supportedFwVersions: FirmwareVersion[] = selectionModel.length > 0 ? [] : firmwareVersions;
  if (supportedFwVersions.length === 0) {
    const requestedFwTypes: FirmwareType[] = [];
    selectionModel.forEach((id) => {
      const device = deviceStateById?.[id as string];
      const hwRevisionFirmware = hwRevisionFirmwareMappings.find((hwRevisionFirmware) => hwRevisionFirmware.hwRevision === device?.hwRevision);
      const fwType = hwRevisionFirmware?.firmwareType || FirmwareType.UNKNOWN;
      if (requestedFwTypes.indexOf(fwType) === -1) {
        requestedFwTypes.push(fwType);
      }
    });
    if (requestedFwTypes.length !== 1) {
      supportedFwVersions.push({
        id: '1',
        versionName: unsupportedFwTypeMessage,
        firmwareType: FirmwareType.UNKNOWN,
        stopGap: false,
        groupName: '',
        created: '',
        modifiedDate: ''
      });
    } else {
      firmwareVersions.forEach((firmwareVersion) => {
        if (firmwareVersion.firmwareType === requestedFwTypes[0]) {
          supportedFwVersions.push(firmwareVersion);
        }
      });
    }
  }

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth={false}>
          <Header
            breadCrumbs={breadCrumbs}
            title={'Devices'}
            appendBreadCrumbs={appendBreadCrumbs}
            disableTitleGutters
            defaultCurrentCrumb={defaultDeviceBreadCrumb}
            defaultCrumbHistory={defaultDeviceBreadCrumbs}
            overrideUrl={location?.pathname}
          />
          <Box display="flex" justifyContent="space-between" className={style.filterContainer}>
            <Box>
              <TextField
                select
                label="Select common filter"
                className={style.filterItem}
                value={quickSelectSelected}
                onChange={(e) => selectChange(e.target.value)}
                inputProps={{ IconComponent: () => null }}
                InputProps={{
                  endAdornment: quickSelectSelected ? (
                    <IconButton size={'small'} onClick={() => selectChange('')}>
                      <ClearIcon />
                    </IconButton>
                  ) : undefined
                }}
                variant="outlined"
                size="small"
              >
                {Array.from(getQuickSelectOptionMapping()).map(([key, value]) => (
                  <MenuItem key={value.value} value={value.value}>
                    {value.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box>
              <Button variant="contained" color="primary" className={style.button} disabled={!selectionModel?.length} onClick={applyToFilteredDevices(requestCheckStatus)}>
                Check Status
              </Button>
              <Button variant="contained" color="primary" className={style.button} disabled={!selectionModel?.length} onClick={applyToFilteredDevices(requestReboot)}>
                Reboot
              </Button>
              <Button variant="contained" color="primary" className={style.button} disabled={!selectionModel?.length} onClick={applyToFilteredDevices(requestRestartApp)}>
                Restart App
              </Button>
              <TextField
                select
                defaultValue="Update Device Version"
                InputProps={{ style: { height: '36.5px' } }}
                className={style.firmwareItem}
                onChange={(event) => triggerUpdate(event.target.value)}
                variant="outlined"
                size="small"
              >
                <MenuItem value="Update Device Version" disabled>
                  Update Device Version
                </MenuItem>
                {supportedFwVersions.map((version) => (
                  <MenuItem key={version.id} value={version.versionName} title={JSON.stringify(version, null, 2)}>
                    {version.versionName}
                  </MenuItem>
                ))}
              </TextField>
              <Button variant="contained" color="primary" className={style.button} disabled={!selectionModel?.length} onClick={applyToFilteredDevices(requestCancelUpdateWrapper)}>
                Cancel Update
              </Button>
              <Button variant="contained" color="primary" className={style.button} disabled={!selectionModel?.length} onClick={applyToFilteredDevices(requestRetire)}>
                Retire
              </Button>
            </Box>
          </Box>
          <div className={style.dataGrid}>
            <DataGrid
              rows={multiFilterAppliedRows(localFilterModel)}
              columns={columns}
              selectionModel={selectionModel}
              pagination
              checkboxSelection
              disableSelectionOnClick
              disableVirtualization
              onSelectionModelChange={(newSelectionModel: GridSelectionModel) => setSelectionModel(newSelectionModel)}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => {
                if (!isEqual(filterModel, newFilterModel)) {
                  setFilterModel(() => newFilterModel);
                  const newF: GridFilterItem = newFilterModel?.items?.[0];
                  const existingF: Nullable<GridFilterItem> = localFilterModel?.items?.find((filter) => filter.columnField === newF?.columnField);
                  if (
                    (existingF && (existingF?.value !== newF?.value || existingF?.operatorValue !== newF?.operatorValue)) ||
                    (!existingF && !invalidFilterItem(newF)) ||
                    (!newF && localFilterModel?.items?.length)
                  ) {
                    handleInternalLocalFilterModalChange(newFilterModel);
                  }
                }
              }}
              onRowClick={(params: GridRowParams) => togglePopupState(params.id as string)}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{ toolbar: { clientQuickSearchProps: { rows: getRows(), setRows, excludedColumns: ['personId', 'infoPopupContent'] } } }}
            />
          </div>
          {!!selectedDeviceId && !!rowById[selectedDeviceId] && editorPopup(selectedDeviceId)}
          <StatusMessage
            saveSuccessful={
              !hasActiveRecord(isLoading) &&
              !hasActiveRecord(error) &&
              !hasActiveRecord(isLoadingPerson) &&
              !hasActiveRecord(errorPerson) &&
              (hasActiveRecord(success) || hasActiveRecord(successPerson))
            }
            saveUnsuccessful={!hasActiveRecord(isLoading) && !hasActiveRecord(isLoadingPerson) && (hasActiveRecord(error) || hasActiveRecord(errorPerson))}
            clearResponseStatus={clearResponseStatuses}
            saveMessage={successMessage}
            errorMessage={getActiveRecord(error)?.message || getActiveRecord(errorPerson)?.message}
          />
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      deviceStateById: selectDeviceStateByIdSelector(state),
      devicePersonByDeviceId: selectDevicePersonByDeviceIdSelector(state),
      deviceById: selectDeviceByIdSelector(state),
      personById: getPersonByIdSelector(state),
      locationById: selectLocationByIdSelector(state),
      deviceRemoteCommandById: selectDeviceRemoteCommandByIdSelector(state),
      deviceConfigById: selectDeviceConfigByIdSelector(state),
      firmwareVersionById: selectFirmwareVersionRecentByIdSelector(state),
      isLoading: getDeviceIsLoadingSelector(state),
      isLoadingPerson: getPersonLoadingStateSelector(state),
      error: getDeviceErrorSelector(state),
      errorPerson: getPersonErrorStateSelector(state),
      success: getDeviceSuccessSelector(state),
      successPerson: getPersonSuccessStateSelector(state),
      successMessage: getDeviceSuccessMessage(state),
      breadCrumbs: getBreadcrumbsSelector(state),
      hwRevisionFirmwareMappings: getHwRevisionFirmwareMappingsSelector(state)
    };
  },
  (dispatch: Function) => ({
    getDeviceStates: () => {
      dispatch(getDeviceStateOverviewThunk());
    },
    getDevicePersonOverview: () => {
      dispatch(getDevicePersonOverviewThunk());
    },
    getDeviceFirmwareVersionsRecent: () => {
      dispatch(getDeviceFirmwareVersionsRecentThunk());
    },
    requestCheckStatus: (deviceId: string) => {
      dispatch(checkDeviceStatusThunk(deviceId));
    },
    requestReboot: (deviceId: string) => {
      dispatch(rebootDeviceThunk(deviceId));
    },
    requestRestartApp: (deviceId: string) => {
      dispatch(restartAppThunk(deviceId));
    },
    requestUpdate: (deviceId: string, osTreeRefSpec: string, isAutoUpdateDisabled: boolean) => {
      dispatch(updateDeviceThunk(deviceId, osTreeRefSpec, isAutoUpdateDisabled));
    },
    requestCancelUpdate: (deviceId: string, osTreeRefSpec: string) => {
      dispatch(cancelUpdateDeviceThunk(deviceId, osTreeRefSpec));
    },
    requestRetire: (deviceId: string) => {
      dispatch(retireDeviceThunk(deviceId));
    },
    requestSaveDeviceDataCollection: (deviceDataCollection: DeviceDataCollection) => {
      return dispatch(saveDeviceDataCollectionThunk(deviceDataCollection));
    },
    requestUpdateEsId: (personId: string, deviceId: string, esId?: Nullable<string>) => {
      return dispatch(putEsIdThunk(personId, deviceId, esId));
    },
    requestUpdateDeviceConfig: (deviceConfig: DeviceConfig) => {
      return dispatch(updateDeviceConfigThunk(deviceConfig));
    },
    requestFactoryReset: (deviceId: string) => {
      dispatch(resetDevice(deviceId));
    },
    putAutoUpdate: (deviceId: string, autoUpdateDisabled: boolean) => {
      dispatch(putAutoUpdateThunk(deviceId, autoUpdateDisabled));
    },
    putConnectivityNotification: (deviceId: string, connectivityNotification: boolean) => {
      dispatch(putConnectivityNotificationThunk(deviceId, connectivityNotification));
    },
    postUnlinkDevice: (deviceId: string, personId: string, sensorId: string) => {
      dispatch(postUnlinkDeviceThunk(deviceId, personId, sensorId));
    },
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    clearDeviceResponseStatus: () => {
      dispatch(getClearDeviceResponseStatus());
    },
    clearPersonResponseStatus: () => {
      dispatch(clearPersonResponseStatusAction());
    },
    getHwRevisionFirmwareMappings: () => {
      dispatch(getHwRevisionsAndFirmwareMappingsThunk());
    }
  })
);

export default compose(connectRedux)(DeviceOverview) as React.ComponentType;
