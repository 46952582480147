import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import style from './Login.scss';
import { ReduxState } from '../../redux/types';
import { loginThunk } from '../../redux/oauth/oauthThunks';
import { LoginPayload } from '../../redux/oauth/oauthTypes';
import { Navigate, useNavigate } from 'react-router-dom';
import { RouteNames } from '../../utils/navigationUtils';

interface Props {
  isLoggedIn: boolean;
  login: Function;
  errorMessage: any;
  startLogin: Function;
}

const Login: React.FC<Props> = ({ isLoggedIn, startLogin }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const submitLogin = () => {
    startLogin({ username: email, password });
  };

  return (
    <div className={style.container}>
      <input className={style.email} type="text" placeholder="Email" value={email} onChange={onChangeEmail} />
      <input className={style.password} type="password" placeholder="Password" value={password} onChange={onChangePassword} />
      <button className={style.link_button} onClick={() => navigate('/forgot-password')}>
        Forgot Password
      </button>
      <button className={style.action_button} onClick={submitLogin}>
        Login
      </button>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {};
  },
  (dispatch: Function) => ({
    startLogin: (loginPayload: LoginPayload) => {
      dispatch(loginThunk(loginPayload));
    }
  })
);

export default compose(connectRedux)(Login);
