import React from "react";
import { AccessTime as AccessTimeIcon, ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { Text } from "../../../../components/Typography";
import style from './TableCell.scss';

export type CellProp = {
  value?: number | string;
  timeSince?: string;
  showRedAlert?: boolean;
}

export type TableCellProps = {
  cellProp?: CellProp;
  name?: string;
  isGreyed?: boolean
}

const TableCell: React.FC<TableCellProps> = ({ cellProp, name, isGreyed }) => {
  const { value, timeSince, showRedAlert } = cellProp || {};
  let cellTextClassName = style.cellText;
  if (isGreyed) {
    cellTextClassName = style.cellTextGreyed;
  } else if (showRedAlert && isGreyed) {
    cellTextClassName = style.cellTextAlertGreyed;
  } else if (showRedAlert) {
    cellTextClassName = style.cellTextAlert;
  }

  return (
    <td className={style.tableCell}>
      <div className={style.cellBox}>
        <Text variant="sm" className={cellTextClassName}>
          {name || value}
        </Text>
        {!name && (
          <span className={style.errIconContainer}>
            {showRedAlert && (
              <ErrorOutlineIcon className={isGreyed ? style.errOutlineIconGreyed : style.errOutlineIcon} />
            )}
          </span>
        )}
        {timeSince && (
          <div className={style.rightIconContainer}>
            <AccessTimeIcon className={isGreyed ? style.timeIconGreyed : style.timeIcon} />
            <Text variant="xs" className={isGreyed ? style.timeTextGreyed : style.timeText}>{timeSince}</Text>
          </div>
        )}
      </div>
    </td>
  )
}

export default TableCell;
