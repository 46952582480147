import React from 'react';
import { Button as MaterialUIButton, ButtonProps } from '@mui/material';
import style from './Button.scss';
import classNames from 'classnames';

export const Button: React.FC<ButtonProps> = React.memo((props) => {
  let customStyle = '';
  if (props.variant === 'contained') {
    customStyle = style.contained;
  } else if (props.variant === 'outlined') {
    customStyle = style.outlined;
  } else if (props.variant === 'text') {
    customStyle = style.text;
  }
  return <MaterialUIButton {...props} className={classNames(customStyle, props.className)} />;
});
