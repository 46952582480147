import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ReduxState } from '../../redux/types';
import style from './Dashboard.scss';
import { BreadCrumbData } from '../../redux/common/types';
import NavSideBarContentWrapper from '../../components/NavSideBarContentWrapper/NavSideBarContentWrapper';

interface Props {
  breadCrumbs: BreadCrumbData[];
}

const Dashboard: React.FC<Props> = () => {
  return (
    <NavSideBarContentWrapper headerText="Dashboard">
      <div className={style.mainContainer}></div>
    </NavSideBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({}),
  (dispatch: Function) => ({})
);

export default compose(connectRedux)(Dashboard);
