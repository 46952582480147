import { ErrorPayload } from 'redux/common/types';
import { ThunkParams } from 'redux/types';
import { addNewOrganizationAction, getAllOrganizationsAction, getOrganizationByIdAction, updateOrganizationAction } from './organizationActions';
import { Organization } from './organizationTypes';

function processAddNewOrganizationSuccess(payload: Organization) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(addNewOrganizationAction.success(payload));
  };
}

function processAddNewOrganizationFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(addNewOrganizationAction.fail(error));
  };
}

export function addNewOrganizationThunk(newOrganizationRequest: any) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(addNewOrganizationAction.start());
    return apiClient.post('v1/organization', {
      auth: true,
      data: newOrganizationRequest,
      successAction: processAddNewOrganizationSuccess,
      failAction: processAddNewOrganizationFail
    });
  };
}

function processUpdateOrganizationSuccess(payload: Organization) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(updateOrganizationAction.success(payload));
  };
}

function processUpdateOrganizationFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(updateOrganizationAction.fail(error));
  };
}

export function updateOrganizationThunk(updatedOrganization: Organization) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(updateOrganizationAction.start());
    return apiClient.put(`v1/organization`, {
      auth: true,
      data: updatedOrganization,
      successAction: processUpdateOrganizationSuccess,
      failAction: processUpdateOrganizationFail
    });
  };
}

function processGetOrganizationByIdSuccess(payload: Organization) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getOrganizationByIdAction.success(payload));
  };
}

function processGetOrganizationByIdFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getOrganizationByIdAction.fail(error));
  };
}

export function getOrganizationByIdThunk(organizationId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getOrganizationByIdAction.start());
    return apiClient.get(`v1/organization/${organizationId}`, {
      auth: true,
      successAction: processGetOrganizationByIdSuccess,
      failAction: processGetOrganizationByIdFail
    });
  };
}

function processGetAllOrganizationsSuccess(payload: Organization[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getAllOrganizationsAction.success(payload));
  };
}

function processGetAllOrganizationsFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getAllOrganizationsAction.fail(error));
  };
}

export function getAllOrganizationsThunk() {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getAllOrganizationsAction.start());
    return apiClient.get(`v1/organization/all`, {
      auth: true,
      successAction: processGetAllOrganizationsSuccess,
      failAction: processGetAllOrganizationsFail
    });
  };
}
