import { createReduxActionCreator } from '../types';
import {
  ChatMessage,
  ErrorPayloadAndPersonId,
  ChatGptMessagesResponsePayload,
  ChangeSleepCoachMessageReadSuccessPayload,
  PostChatSleepCoachMessageResponsePayload
} from './chatTypes';

export const CHAT_ACTION_PREFIX = 'chat-redux/';

export const getChatGPTMobileMessagesActions = createReduxActionCreator<ChatGptMessagesResponsePayload, string, ErrorPayloadAndPersonId>(
  `${CHAT_ACTION_PREFIX}get-chat-gpt-mobile-messages`
);

export const getChatGPTDeviceMessagesActions = createReduxActionCreator<ChatGptMessagesResponsePayload, string, ErrorPayloadAndPersonId>(
  `${CHAT_ACTION_PREFIX}get-chat-gpt-device-messages`
);

export const getChatSleepCoachMessagesActions = createReduxActionCreator<ChatGptMessagesResponsePayload, string, ErrorPayloadAndPersonId>(
  `${CHAT_ACTION_PREFIX}get-chat-sleep-coach-messages`
);

export const getLatestUnreadSleepCoachMessagesActions = createReduxActionCreator<ChatMessage[]>(`${CHAT_ACTION_PREFIX}get-latest-unread-sleep-coach-messages`);

export const changeSleepCoachMessageReadActions = createReduxActionCreator<ChangeSleepCoachMessageReadSuccessPayload>(`${CHAT_ACTION_PREFIX}change-sleep-coach-message-read`);

export const postChatSleepCoachMessageActions = createReduxActionCreator<PostChatSleepCoachMessageResponsePayload, string, ErrorPayloadAndPersonId>(
  `${CHAT_ACTION_PREFIX}post-chat-sleep-coach-message`
);
