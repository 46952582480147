import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import NavSideBarContentWrapper from '../../components/NavSideBarContentWrapper/NavSideBarContentWrapper';
import { getSleepLogByCarePlanDateUTCMsSelector } from '../../redux/activity/activitySelectors';
import { getSleepLogsThunk } from '../../redux/activity/activityThunks';
import {
  getGrouppedMeasurementsSelector,
  getMetricUnitSelector,
  getPersonWithCarePlansByIdSelector,
  getSurveyRangeByMetricIdSelector
} from '../../redux/carePlan/carePlanSelectors';
import { enableDemoModeThunk, fetchCarePlanMeasurementsThunk, fetchPatientsAndCarePlansThunk, postCarePlanMessageThunk } from '../../redux/carePlan/carePlanThunks';
import { Measurement, MetricIDs, UnitIDs } from '../../redux/carePlan/carePlanTypes';
import { getDeviceTimeZoneIdSelector } from '../../redux/device/deviceSelectors';
import { getDevicePersonOverviewThunk } from '../../redux/device/deviceThunks';
import { getIsMdRevUserSelector } from '../../redux/person/personSelector';
import { ReduxState } from '../../redux/types';
import {
  carePlanTableDateFormat,
  getLatestBoolValueByMetricId,
  getLatestNumberValueByMetricId,
  getLatestStringValueByMetricId,
  getNumberValueFromMeasurement,
  isChartMetric
} from '../../utils/carePlanUtils';
import { normalizeBooleanValue } from '../../utils/content';
import { getStringFromUTCMs, tsAsTimeStr } from '../../utils/time';
import style from './PatientProfileOld.scss';
import Chart from './components/Chart';
import CollapsibleTable, { PatientProfileTableRow } from './components/CollapsibleTable/CollapsibleTable';
import SendMessageDialog from './components/SendMessageDialog/SendMessageDialog';

const FETCH_MEASUREMENTS_INTERVAL = 10000; // 10 sec

type ReduxProps = ConnectedProps<typeof connectRedux>;

type Props = ReduxProps & {};

const PatientProfileOld: React.FC<Props> = ({
  getDeviceTimeZoneId,
  grouppedMeasurements,
  metricUnitById,
  personWithCarePlansById,
  getSurveyRangeByMetricId,
  getSleepLogByCarePlanDateUTCMs,
  enableDemoMode,
  fetchCarePlanMeasurements,
  fetchDevicePersonOverview,
  fetchPatientsAndCarePlans,
  fetchUserSleepLogs,
  postCarePlanMessage,
  isMdRevUser
}) => {
  const [openCarePlanMessageDialog, setOpenCarePlanMessageDialog] = useState(false);
  const [carePlanMessage, setCarePlanMessage] = useState('');

  const params = useParams();
  const navigate = useNavigate();
  const { userId } = params || {};

  const sleepLogByCarePlanDateUTCMs = userId && getSleepLogByCarePlanDateUTCMs(userId);
  const deviceTimeZoneId = userId && getDeviceTimeZoneId(userId);

  useEffect(() => {
    const fetchMeasurements = () => {
      fetchDevicePersonOverview(); // fetching to get time zone
      fetchCarePlanMeasurements();
      fetchPatientsAndCarePlans();
    };
    fetchMeasurements(); // initial fetch
    const intervalId = setInterval(() => {
      fetchMeasurements();
    }, FETCH_MEASUREMENTS_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (userId) {
      fetchUserSleepLogs(userId);
    }
  }, [userId]);

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleDemoButtonClick = async () => {
    if (userId) {
      try {
        await enableDemoMode(userId);
      } catch (err) {
        console.log('enableDemoMode err:', err);
        alert(`enable demo mode error: ${err}`);
      }
    }
  };

  const sendCarePlanMessage = async () => {
    if (userId) {
      try {
        await postCarePlanMessage(userId, carePlanMessage);
        setCarePlanMessage('');
      } catch (err) {
        console.log('post care plan message error:', err);
        alert(`post care plan message error: ${err}`);
      }
      setOpenCarePlanMessageDialog(false);
    }
  };

  const handleSendMessageButtonClick = async () => {
    setOpenCarePlanMessageDialog(true);
  };

  const handleUserDetailsButtonClick = async () => {
    navigate(`/user/${userId}`);
  };

  const tableRowData: PatientProfileTableRow[] | undefined = React.useMemo(() => {
    if (!grouppedMeasurements) {
      return undefined;
    }
    const measurementsByDateMs: Record<string, Record<MetricIDs, Measurement[]>> = (grouppedMeasurements && userId && grouppedMeasurements[userId]) || {};
    const rowData: PatientProfileTableRow[] = [];
    for (let dateUTCMs in measurementsByDateMs) {
      const measurementsByMetricId = measurementsByDateMs[dateUTCMs];
      const metricIds = Object.keys(measurementsByMetricId);
      const id = measurementsByMetricId[metricIds[0]][0]?.id || null;
      const history: Record<string, { id: string; value: string; created: string }[]> = {};
      for (let metricId in measurementsByMetricId) {
        if (measurementsByMetricId[metricId]?.length && measurementsByMetricId[metricId].length > 1) {
          const rowHistoryData: { id: string; value: string; created: string }[] = measurementsByMetricId[metricId].map((measurement) => {
            const value = isChartMetric(metricId as MetricIDs)
              ? getNumberValueFromMeasurement(metricId as MetricIDs, measurement)
              : measurement.stringValue || normalizeBooleanValue(measurement.boolValue);
            const created = tsAsTimeStr(measurement?.created, deviceTimeZoneId);
            return { id: measurement.id, value, created };
          });
          history[metricId] = rowHistoryData;
        }
      }
      const createdLabel = getStringFromUTCMs(Number(dateUTCMs), carePlanTableDateFormat);
      const sleepTime =
        sleepLogByCarePlanDateUTCMs &&
        sleepLogByCarePlanDateUTCMs[dateUTCMs] &&
        tsAsTimeStr(sleepLogByCarePlanDateUTCMs[dateUTCMs].sleepTime, sleepLogByCarePlanDateUTCMs[dateUTCMs].timeZone);
      const wakeTime =
        sleepLogByCarePlanDateUTCMs &&
        sleepLogByCarePlanDateUTCMs[dateUTCMs] &&
        tsAsTimeStr(sleepLogByCarePlanDateUTCMs[dateUTCMs].wakeTime, sleepLogByCarePlanDateUTCMs[dateUTCMs].timeZone);
      const row: PatientProfileTableRow = {
        id,
        dateLabel: createdLabel,
        walkActivity: getLatestBoolValueByMetricId(MetricIDs.WALK_ACTIVITY, measurementsByMetricId),
        medicationReminder: getLatestBoolValueByMetricId(MetricIDs.MEDICATION_REMINDER, measurementsByMetricId),
        sleepTime,
        wakeTime,
        CHFBreathingLyingDown: getLatestBoolValueByMetricId(MetricIDs.CHF_BREATHING_LYING_DOWN, measurementsByMetricId),
        CHFChestPain: getLatestBoolValueByMetricId(MetricIDs.CHF_CHEST_PAIN, measurementsByMetricId),
        CHFLightHeaded: getLatestBoolValueByMetricId(MetricIDs.CHF_LIGHT_HEADED, measurementsByMetricId),
        CHFShortOfBreath: getLatestBoolValueByMetricId(MetricIDs.CHF_SHORT_OF_BREATH, measurementsByMetricId),
        CHFNauseous: getLatestBoolValueByMetricId(MetricIDs.CHF_NAUSEOUS, measurementsByMetricId),
        CHFSwellingFeet: getLatestBoolValueByMetricId(MetricIDs.CHF_SWELLING_FEET, measurementsByMetricId),
        CHFSwellingLegs: getLatestBoolValueByMetricId(MetricIDs.CHF_SWELLING_LEGS, measurementsByMetricId),
        heartRate: getLatestNumberValueByMetricId(MetricIDs.HEART_RATE, measurementsByMetricId),
        CHFWeightEducationOpenQuestion: getLatestStringValueByMetricId(MetricIDs.WEIGHT_EDUCATION_OPEN_QUESTION, measurementsByMetricId),
        medicationReminderMdr: getLatestBoolValueByMetricId(MetricIDs.MEDICATION_SET_REMINDER_QUESTION, measurementsByMetricId),
        coachCall: getLatestBoolValueByMetricId(MetricIDs.COACHING_CALL, measurementsByMetricId),
        stressLevel: getLatestNumberValueByMetricId(MetricIDs.STRESS_LEVEL, measurementsByMetricId),
        healthCoachConcerns: getLatestStringValueByMetricId(MetricIDs.HEALTH_COACH_CONCERNS, measurementsByMetricId),
        history
      };
      rowData.push(row);
    }

    return rowData ? rowData : undefined;
  }, [grouppedMeasurements, sleepLogByCarePlanDateUTCMs, deviceTimeZoneId]);

  type ChartValue = { [date: string]: number | undefined };
  type ChartData = Record<string, ChartValue[]> | undefined;

  // { [MetricIDs.HEART_RATE]: [{ 'March 02': 120 }, { 'March 01': 110 }]
  //   [MetricIDs.GLUCOSE_LEVEL]: [{ 'March 02': 90, 'March 01': 100 }]
  const chartData: ChartData = React.useMemo(() => {
    const data: ChartData = {};
    const measurementsByDateUTCMs = (grouppedMeasurements && userId && grouppedMeasurements[userId]) || {};
    for (let dateUTCMs in measurementsByDateUTCMs) {
      const measurements = measurementsByDateUTCMs[dateUTCMs];
      for (let metricId in measurements) {
        const latestMeasurement: Measurement = measurements[metricId][0];
        if (isChartMetric(metricId as MetricIDs)) {
          const createdDate = getStringFromUTCMs(Number(dateUTCMs), carePlanTableDateFormat)?.toUpperCase();
          const value = getNumberValueFromMeasurement(metricId as MetricIDs, latestMeasurement);
          const chartValue = { [createdDate]: value };
          if (!data[metricId]) {
            data[metricId] = [chartValue];
          } else {
            data[metricId]?.unshift(chartValue);
          }
        }
      }
    }

    return data ? data : undefined;
  }, [grouppedMeasurements]);

  const chartXLabelsByMetricId: Record<string, string[]> | undefined = React.useMemo(() => {
    const labelsByMetricId = {};
    for (let metricId in chartData) {
      labelsByMetricId[metricId] = chartData && chartData[metricId]?.map((chartValue) => Object.keys(chartValue)[0].toUpperCase());
    }

    return labelsByMetricId;
  }, [chartData]);

  const chartValueDataByMetricId: Record<string, number[]> | undefined = React.useMemo(() => {
    const chartDataByMetricId = {};
    for (let metricId in chartData) {
      chartDataByMetricId[metricId] = chartData && chartData[metricId]?.map((chartValue) => Object.values(chartValue)[0]);
    }

    return chartDataByMetricId;
  }, [chartData]);

  const weightLabel = metricUnitById[UnitIDs.LBS]?.name;
  const heartRateLabel = metricUnitById[UnitIDs.BPM]?.name;
  const glucoseLevelLabel = metricUnitById[UnitIDs.MG_DL]?.name;
  const stressLevelLabel = 'Stress Level';

  const weightRange = userId ? getSurveyRangeByMetricId(userId, MetricIDs.WEIGHT) : undefined;
  const heartRateRange = userId ? getSurveyRangeByMetricId(userId, MetricIDs.HEART_RATE) : undefined;
  const glucoseRange = userId ? getSurveyRangeByMetricId(userId, MetricIDs.GLUCOSE_LEVEL) : undefined;

  return (
    <NavSideBarContentWrapper headerText={() => {
        return (
        <div className={style.headerBox}>
          <IconButton onClick={handleBackButton} className={style.iconBackButton}>
            <ArrowBackIosIcon fontSize="medium" />
          </IconButton>
          <Typography variant="h4">{(userId && personWithCarePlansById[userId]?.fullName) || ''}</Typography>
          <div>
            <Button className={style.headerButton} variant="outlined" onClick={handleDemoButtonClick} disabled={!userId}>
              Enable Demo
            </Button>
            <Button className={style.headerButton} variant="outlined" onClick={handleSendMessageButtonClick} disabled={!userId}>
              Send Message
            </Button>
            <Button className={style.headerButton} variant="outlined" onClick={handleUserDetailsButtonClick} disabled={!userId}>
              Patient Details
            </Button>
          </div>
        </div>
      )
    }}>
      <SendMessageDialog
        open={openCarePlanMessageDialog}
        onClose={() => setOpenCarePlanMessageDialog(false)}
        onSend={sendCarePlanMessage}
        text={carePlanMessage}
        setText={setCarePlanMessage}
      />
      <Box className={style.container}>
        <Divider sx={{ marginBottom: '30px' }} />
        {weightLabel && chartValueDataByMetricId && chartValueDataByMetricId[MetricIDs.WEIGHT] && chartXLabelsByMetricId && chartXLabelsByMetricId[MetricIDs.WEIGHT] && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              WEIGHT
            </Typography>
            <Box className={style.chartBox}>
              <Chart
                data={chartValueDataByMetricId[MetricIDs.WEIGHT]}
                yAxisLabel={weightLabel}
                xLabels={chartXLabelsByMetricId[MetricIDs.WEIGHT]}
                valueFormatter={(value) => `${value} ${weightLabel}`}
                minRange={weightRange?.minFloatValue}
                maxRange={weightRange?.maxFloatValue}
              />
            </Box>
          </>
        )}
        {chartValueDataByMetricId[MetricIDs.HEART_RATE] && heartRateLabel && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              HEART RATE
            </Typography>
            <Box className={style.chartBox}>
              <Chart
                data={chartValueDataByMetricId[MetricIDs.HEART_RATE]}
                yAxisLabel={heartRateLabel}
                xLabels={chartXLabelsByMetricId[MetricIDs.HEART_RATE]}
                valueFormatter={(value) => `${value} ${heartRateLabel}`}
                minRange={heartRateRange?.minFloatValue}
                maxRange={heartRateRange?.maxFloatValue}
              />
            </Box>
          </>
        )}
        {chartValueDataByMetricId[MetricIDs.GLUCOSE_LEVEL] && glucoseLevelLabel && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              GLUCOSE LEVEL
            </Typography>
            <Box className={style.chartBox}>
              <Chart
                data={chartValueDataByMetricId[MetricIDs.GLUCOSE_LEVEL]}
                yAxisLabel={glucoseLevelLabel}
                xLabels={chartXLabelsByMetricId[MetricIDs.GLUCOSE_LEVEL]}
                valueFormatter={(value) => `${value} ${glucoseLevelLabel}`}
                minRange={glucoseRange?.minFloatValue}
                maxRange={glucoseRange?.maxFloatValue}
              />
            </Box>
          </>
        )}
        {chartValueDataByMetricId[MetricIDs.STRESS_LEVEL] && glucoseLevelLabel && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              STRESS LEVEL
            </Typography>
            <Box className={style.chartBox}>
              <Chart
                data={chartValueDataByMetricId[MetricIDs.STRESS_LEVEL]}
                yAxisLabel={stressLevelLabel}
                xLabels={chartXLabelsByMetricId[MetricIDs.STRESS_LEVEL]}
                valueFormatter={(value) => `${value} ${stressLevelLabel}`}
                minYValue={1}
                maxYValue={5}
              />
            </Box>
          </>
        )}
        {!!tableRowData?.length && (
          <Box className={style.tableBox}>
            <CollapsibleTable rowsData={tableRowData} isMdrView={isMdRevUser} />
          </Box>
        )}
      </Box>
    </NavSideBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    getDeviceTimeZoneId: (personId: string) => getDeviceTimeZoneIdSelector(personId, state),
    grouppedMeasurements: getGrouppedMeasurementsSelector(state),
    metricUnitById: getMetricUnitSelector(state),
    personWithCarePlansById: getPersonWithCarePlansByIdSelector(state),
    getSurveyRangeByMetricId: (userId: string, metricId: MetricIDs) => getSurveyRangeByMetricIdSelector(userId, metricId, state),
    getSleepLogByCarePlanDateUTCMs: (userId: string) => getSleepLogByCarePlanDateUTCMsSelector(userId, state),
    isMdRevUser: getIsMdRevUserSelector(state)
  }),
  {
    enableDemoMode: enableDemoModeThunk,
    fetchCarePlanMeasurements: fetchCarePlanMeasurementsThunk,
    fetchDevicePersonOverview: getDevicePersonOverviewThunk,
    fetchPatientsAndCarePlans: fetchPatientsAndCarePlansThunk,
    fetchUserSleepLogs: (userId: string) => getSleepLogsThunk(userId),
    postCarePlanMessage: postCarePlanMessageThunk
  }
);

export default compose(connectRedux)(PatientProfileOld);
