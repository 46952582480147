import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { ICommand } from '@uiw/react-md-editor/src/commands';
import style from './MarkdownEditor.scss';
import './MarkdownEditor.scss';
import assign from 'lodash/assign';

interface Props {
  textDispatcher: [string, Dispatch<SetStateAction<string>>];
}

const header: ICommand = {
  keyCommand: commands.title1.keyCommand,
  buttonProps: commands.title1.buttonProps,
  icon: <div style={{ fontSize: 18, textAlign: 'left' }}>Header</div>,
  execute: commands.title1.execute
};

const subtitle: ICommand = {
  keyCommand: commands.title2.keyCommand,
  buttonProps: commands.title2.buttonProps,
  icon: <div style={{ fontSize: 18, textAlign: 'left' }}>Subtitle</div>,
  execute: commands.title2.execute
};

const getElementContents = (props, children) =>
  (Array.isArray(children) &&
    children.reduce((children, child) => {
      const lastIndex: number = children.length - 1;
      if (typeof child === 'string' && typeof children[lastIndex] === 'string') {
        children[lastIndex] += child;
      } else {
        children.push(child);
      }
      return children;
    }, [])) ||
  children;

const getCoreProps = (props: PropsWithChildren<any>) => {
  const propKeys: string[] = Object.keys(props);
  const dataPropKeys: string[] = propKeys.filter((propKey) => propKey.match(/data-.*/g));
  const base = {
    key: props.nodeKey,
    className: props.className
  };
  const dataAttributes = dataPropKeys.reduce((prev, dataPropKey) => {
    const attributes = {};
    attributes[dataPropKey] = props[dataPropKey];
    return assign(attributes, prev);
  }, {});
  return assign(dataAttributes, base);
};

const BREAK_TOKEN: string = 'LLLBREAK777';
const HEADER_PATTERN: RegExp = /^#+\s/i;
const LINE_BREAK_PATTERN: RegExp = /(?:\r\n|\r|\n)/g;

export const MarkdownEditor: React.FC<Props> = ({ textDispatcher }): JSX.Element => {
  /*
   * Tokenize line breaks so that they are not converted to markdown by processor. This is to override the default
   * behavior so triple breaks will create new paragraphs instead of double breaks
   */
  const transformLineBreaks = (text: string): string => {
    let newStr: string = '';
    const spl: string[] = text.split(LINE_BREAK_PATTERN);
    for (let i = 0; i < spl.length; i++) {
      newStr = newStr + spl[i] + (HEADER_PATTERN.test(spl[i]) ? '\n' : BREAK_TOKEN);
    }
    return newStr;
  };

  return (
    <React.Fragment>
      <MDEditor
        className={style.editor}
        value={textDispatcher[0]}
        onChange={(x) => textDispatcher[1](x || '')}
        previewOptions={{ style: { display: 'none' } }}
        visiableDragbar={false}
        height={300}
        fullscreen={false}
        commands={[
          commands.group([header, subtitle], {
            name: 'title',
            groupName: 'title',
            buttonProps: {}
          }),
          commands.divider,
          commands.bold,
          commands.divider,
          commands.image,
          commands.divider,
          commands.link
        ]}
      />
      <MDEditor.Markdown
        className={style.renderer}
        source={transformLineBreaks(textDispatcher[0])}
        disallowedTypes={[
          'paragraph',
          'blockquote',
          'code',
          'definition',
          'delete',
          'emphasis',
          'html',
          'inlineCode',
          'list',
          'listItem',
          'table',
          'tableCell',
          'tableRow',
          'thematicBreak'
        ]}
        unwrapDisallowed={true}
        renderers={{
          strong: (props) => <span className={style.highlighted}>{getElementContents(getCoreProps(props), props.children)}</span>,
          text: (props) => (
            <React.Fragment>
              {getElementContents(getCoreProps(props), props.children)
                .split(BREAK_TOKEN + BREAK_TOKEN + BREAK_TOKEN)
                .map((par, parIndex) => {
                  return (
                    <React.Fragment key={parIndex}>
                      {parIndex > 0 && (
                        <React.Fragment>
                          <br />
                          <br />
                        </React.Fragment>
                      )}
                      {par.split(BREAK_TOKEN + BREAK_TOKEN).map((ln, lnIndex) => {
                        return (
                          <React.Fragment key={lnIndex}>
                            {lnIndex > 0 && <br />}
                            {ln.split(BREAK_TOKEN).map((sp, spIndex) => {
                              return (
                                <React.Fragment key={spIndex}>
                                  {spIndex > 0 && ' '}
                                  {sp}
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
            </React.Fragment>
          )
        }}
      />
    </React.Fragment>
  );
};
