import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getEmailParam, getFirstFunnelPageUrl, getPriceParam } from '../../utils/navigationUtils';

const Account = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const emailParam = getEmailParam(location);
  const priceParam = getPriceParam(location);

  useEffect(() => {
    const url = getFirstFunnelPageUrl(emailParam, priceParam);
    navigate(url);
  }, [emailParam, priceParam, navigate]);

  return null;
};

const connectRedux = connect(
  () => {
    return {};
  },
  () => ({})
);

export default compose(connectRedux)(Account);
