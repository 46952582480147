import { Container, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { ContentTile } from '../../components/ContentTile/ContentTile';
import { Header } from '../../components/Header/Header';
import { BreadCrumbData } from '../../redux/common/types';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '../../redux/oauth/oauthSelectors';
import { ReduxState } from '../../redux/types';
import { RouteNames } from '../../utils/navigationUtils';
import { defaultTheme } from '../../utils/styles';
import { defaultUsersBreadCrumb, defaultUsersBreadCrumbs } from '../../utils/breadcrumbs';
import { goTo } from '../../utils/util';
import style from './Users.scss';

interface Props {
  breadCrumbs: BreadCrumbData[];
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
}

const Users: React.FC<Props> = ({ breadCrumbs, appendBreadCrumbs }) => {
  const navigate = useNavigate();

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            breadCrumbs={breadCrumbs}
            title="Users"
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultUsersBreadCrumb}
            defaultCrumbHistory={defaultUsersBreadCrumbs}
          />
          <ContentTile title="All Users" subTitles={[{ text: 'View all users and their associated flags.' }]} onClick={goTo('/allUsers', navigate)} />
          <ContentTile title="All Flags" subTitles={[{ text: 'View all of the flags and the users assigned to them.' }]} onClick={goTo('/allFlags', navigate)} />
          <ContentTile title="New Sleep Coach Chats" onClick={goTo('/newSleepCoachChats', navigate)} />
          <ContentTile title="Sleep Schedules" onClick={goTo(RouteNames.SLEEP_SCHEDULE_REVIEW, navigate)} />
          <ContentTile title="Demo Access Codes" onClick={goTo('/accessCodes', navigate)} />
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      breadCrumbs: getBreadcrumbsSelector(state)
    };
  },
  (dispatch: Function) => ({
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    }
  })
);

export default compose(connectRedux)(Users) as React.ComponentType;
