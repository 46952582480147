import { ErrorPayload } from 'redux/common/types';
import { Person } from 'redux/person/personTypes';

export interface ChatState {
  readonly errorChangeSleepCoachMessageRead?: Nullable<Error>;
  readonly errorGetChatGPTDeviceMessages: Record<string, Nullable<Error>>;
  readonly errorGetChatGPTMobileMessages: Record<string, Nullable<Error>>;
  readonly errorGetChatSleepCoachMessages: Record<string, Nullable<Error>>;
  readonly errorGetLatestUnreadSleepCoachMessages?: Nullable<Error>;
  readonly errorPostChatSleepCoachMessage: Record<string, Nullable<Error>>;
  readonly isChangingSleepCoachMessageRead: boolean;
  readonly isLoadingChatGPTDeviceMessages: Record<string, boolean>;
  readonly isLoadingChatGPTMobileMessages: Record<string, boolean>;
  readonly isLoadingChatSleepCoachMessages: Record<string, boolean>;
  readonly isLoadingLatestUnreadSleepCoachMessages: boolean;
  readonly isPostingChatSleepCoachMessage: Record<string, boolean>;
  readonly chatGPTDeviceMessages: Record<string, ChatMessage[]>;
  readonly chatGPTMobileMessages: Record<string, ChatMessage[]>;
  readonly chatSleepCoachMessages: Record<string, ChatMessage[]>;
  readonly latestUnreadSleepCoachMessages: ChatMessage[];
}

export interface ChatMessage {
  id: string;
  organizationId: string;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
  personId: string;
  senderId?: Nullable<string>;
  recipientId?: Nullable<string>;
  message: string;
  messageReadTs?: Nullable<string>;
  promptContext?: Nullable<string>;
  promptUsed?: Nullable<string>;
  prevMessageId?: Nullable<string>;
  chatChannelType: ChatChannelType;
  startTs?: Nullable<string>;
  endTs?: Nullable<string>;
  summaryLevelType?: Nullable<ChatSummaryLevelType>;
  summaryCategoryType?: Nullable<ChatSummaryCategoryType>;
  person?: Nullable<Person>; // only for latestUnreadSleepCoachMessages
}

export enum ChatChannelType {
  UNKNOWN = 'UNKNOWN',
  SLEEP_COACH = 'SLEEP_COACH',
  CHAT_BOT_MOBILE = 'CHAT_BOT_MOBILE',
  CHAT_BOT_DEVICE = 'CHAT_BOT_DEVICE'
}

export enum ChatSummaryLevelType {
  UNKNOWN = 'UNKNOWN',
  LAST_10_MIN = 'LAST_10_MIN',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export enum ChatSummaryCategoryType {
  UNKNOWN = 'UNKNOWN',
  GENERIC = 'GENERIC',
  INTERESTS = 'INTERESTS',
  SLEEP = 'SLEEP',
  PLANS = 'PLANS',
  ABUSE_AND_HEALTH_ISSUES = 'ABUSE_AND_HEALTH_ISSUES'
}

export interface ChatGptMessagesResponsePayload {
  personId: string;
  messages: ChatMessage[];
}
export interface PostChatSleepCoachMessageResponsePayload {
  personId: string;
  message: ChatMessage;
}

export interface ErrorPayloadAndPersonId {
  error: ErrorPayload;
  personId: string;
}

export interface ChangeSleepCoachMessageReadSuccessPayload {
  personId: string;
  messageId: string;
  readStatus: boolean;
}
