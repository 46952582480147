import React from 'react';
import { useNavigate } from 'react-router-dom';
import { goTo } from '../../utils/util';
import { StyledBreadcrumb } from '../../utils/styles';

interface Props {
  label: string;
  url: string;
  state?: Nullable<any>;
}

export const SimpleCrumb: React.FC<Props> = ({ label, url, state }): JSX.Element => <StyledBreadcrumb label={label} onClick={goTo(url, useNavigate(), state)} />;
