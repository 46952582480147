import React from 'react';
import style from './CheckIcon.scss';

export const CheckIcon: React.FC = () => (
  <svg width="49" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <g transform="translate(24,24)">
      <g className={style.CheckSuccessCheckGroup} opacity="0">
        <path
          className={style.CheckSuccessCheck}
          fill="none"
          d="M-10 1.5c0 0 6.5 6 6.5 6c0 0 13.5-13 13.5-13"
          stroke="#24B47E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="28 28"
          strokeDashoffset="28"
        />
      </g>
    </g>
    <path
      className={style.CheckSuccessCircle}
      fill="none"
      stroke="#24B47E"
      strokeWidth="2"
      d="M23 0c0 12.7-10.3 23-23 23c-12.7 0-23-10.3-23-23c0-12.7 10.3-23 23-23c12.7 0 23 10.3 23 23"
      strokeLinecap="round"
      strokeDashoffset="145"
      strokeDasharray="145 145"
      strokeLinejoin="round"
      strokeMiterlimit="1"
      transform="translate(24,24) rotate(-35)"
    />
  </svg>
);
