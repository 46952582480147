import { ReduxState } from '../types';
import {
  Device,
  DeviceConfig,
  DeviceConnectivity,
  DeviceLoadingTags,
  DevicePerson,
  DeviceRemoteCommand,
  DeviceVersion,
  HwRevision,
  HwRevisionFirmware,
  SensorDevice
} from './deviceTypes';
import { DeviceState, DeviceStateEntry, DeviceProvisioning, FirmwareVersion, Location } from './deviceTypes';
import { Error } from '../common/types';
import { DateTime } from 'luxon';
import { getSelectedPersonDetailsSelector } from '../person/personSelector';

const getDeviceState = (state: ReduxState): DeviceState => {
  return state.device;
};

export const selectDeviceStateByIdSelector = (state: ReduxState): Record<string, DeviceStateEntry> => {
  const device: DeviceState = getDeviceState(state);
  return device.deviceStateById;
};

export const selectDeviceByIdSelector = (state: ReduxState): Record<string, Device> => {
  const device: DeviceState = getDeviceState(state);
  return device.deviceById;
};

export const selectSensorDeviceByIdSelector = (state: ReduxState): Record<string, SensorDevice> => {
  const device: DeviceState = getDeviceState(state);
  return device.sensorDeviceById;
};

export const selectLocationByIdSelector = (state: ReduxState): Record<string, Location> => {
  const device: DeviceState = getDeviceState(state);
  return device.locationById;
};

export const selectDeviceRemoteCommandByIdSelector = (state: ReduxState): Record<string, DeviceRemoteCommand> => {
  const device: DeviceState = getDeviceState(state);
  return device.deviceRemoteCommandById;
};

export const selectDeviceConfigByIdSelector = (state: ReduxState): Record<string, DeviceConfig> => {
  const device: DeviceState = getDeviceState(state);
  return device.deviceConfigById;
};

export const selectDeviceProvisioningByIdSelector = (state: ReduxState): Record<string, DeviceProvisioning> => {
  const device: DeviceState = getDeviceState(state);
  return device.deviceProvisioningById;
};

export const selectFirmwareVersionByIdSelector = (state: ReduxState): Record<string, FirmwareVersion> => {
  const device: DeviceState = getDeviceState(state);
  return device.firmwareVersionById;
};

export const selectFirmwareVersionRecentByIdSelector = (state: ReduxState): Record<string, FirmwareVersion> => {
  const device: DeviceState = getDeviceState(state);
  return device.firmwareVersionRecentById;
};

export const selectDeviceConnectivityByIdSelector = (state: ReduxState): Record<string, DeviceConnectivity> => {
  const device: DeviceState = getDeviceState(state);
  return device.deviceConnectivityById;
};

export const selectDevicePersonByDeviceIdSelector = (state: ReduxState): Record<string, DevicePerson> => {
  const device: DeviceState = getDeviceState(state);
  return device.devicePerson.byDeviceId;
};

export const selectSelectedDevicePersonSelector = (state: ReduxState): DevicePerson | undefined => {
  const device: DeviceState = getDeviceState(state);
  return device.selectedDevicePerson;
};

export const getDeviceIsLoadingSelector = (state: ReduxState): Record<string, boolean> => {
  const device: DeviceState = getDeviceState(state);
  return device.isLoading;
};

export const getDeviceErrorSelector = (state: ReduxState): Record<string, Error> => {
  const device: DeviceState = getDeviceState(state);
  return device.error;
};

export const getDeviceSuccessSelector = (state: ReduxState): Record<string, boolean> => {
  const device: DeviceState = getDeviceState(state);
  return device.success;
};

export const getDeviceSuccessMessage = (state: ReduxState): Nullable<string> => {
  const device: DeviceState = getDeviceState(state);
  return device.successMessage;
};

export const getDeviceTimeZoneIdSelector = (personId: string, state: ReduxState): Nullable<string> => {
  const device: DeviceState = getDeviceState(state);
  const devicePerson = device.devicePerson?.byPersonId && device.devicePerson?.byPersonId[personId];
  return devicePerson?.locationId ? device.locationById?.[devicePerson?.locationId]?.timeZoneId : undefined;
};

export const selectTimeZoneIdSelector = (state: ReduxState): Nullable<string> => {
  const device: DeviceState = getDeviceState(state);
  const selectedDevicePerson: Nullable<DevicePerson> = selectSelectedDevicePersonSelector(state);
  return selectedDevicePerson?.locationId ? device.locationById?.[selectedDevicePerson?.locationId]?.timeZoneId : undefined;
};

export const getSensorDeviceByDeviceIdRangeOverlap = (sensorDevices: SensorDevice[], deviceId: string, timestamp: DateTime): Nullable<SensorDevice> => {
  return Object.values(sensorDevices).find((sensorDevice) => {
    return (
      sensorDevice.id === deviceId &&
      DateTime.fromISO(sensorDevice.startDate) <= timestamp &&
      sensorDevice.endDate &&
      (sensorDevice.endDate === null || DateTime.fromISO(sensorDevice.endDate) >= timestamp)
    );
  });
};

export const selectSensorDeviceFromSelectedPersonDetailsByRangeOverlap = (state: ReduxState, timestamp: DateTime): Nullable<SensorDevice> => {
  const selectedPerson = getSelectedPersonDetailsSelector(state);
  const sensorDeviceById = selectSensorDeviceByIdSelector(state);
  const sensorDeviceId = selectedPerson?.sensorDeviceIds?.find((sensorDeviceId: string) => {
    const sensorDevice = sensorDeviceById[sensorDeviceId];
    return sensorDevice && DateTime.fromISO(sensorDevice.startDate) <= timestamp && (sensorDevice.endDate == undefined || DateTime.fromISO(sensorDevice.endDate) >= timestamp);
  });
  return sensorDeviceId ? sensorDeviceById[sensorDeviceId] : undefined;
};

export const getKokoMapDeviceConnectivityValuesSelector = (state: ReduxState): DeviceConnectivity[] => {
  const device: DeviceState = getDeviceState(state);
  const deviceConnectivityById = selectDeviceConnectivityByIdSelector(state);
  const deviceConnectivities: DeviceConnectivity[] = [];
  device.kokoMapDeviceConnectivityIds?.forEach((id: string) => {
    deviceConnectivities.push(deviceConnectivityById[id]);
  });
  return deviceConnectivities;
};

export const isLoadingDeviceVersionHistory = (state: ReduxState): boolean => {
  const device: DeviceState = getDeviceState(state);
  return device.isLoading[DeviceLoadingTags.GET_DEVICE_VERSION_HISTORY];
};

export const getDeviceVersionHistoryBydeviceId = (state: ReduxState, deviceId?: Nullable<string>): DeviceVersion[] => {
  const device: DeviceState = getDeviceState(state);
  const selectedPerson = getSelectedPersonDetailsSelector(state);
  return (selectedPerson?.deviceId && device.deviceVersionsByDeviceId && device.deviceVersionsByDeviceId[selectedPerson?.deviceId]) || [];
};

export const getDeviceStateByDeviceId = (state: ReduxState, deviceId?: Nullable<string>): Nullable<DeviceStateEntry> => {
  const deviceStateById = selectDeviceStateByIdSelector(state);
  return deviceId ? deviceStateById[deviceId] : null;
};

// selector for HwRevision to Firmwaretype mappings using the device state hwRevisionFirmwareByHwRevision
export const getHwRevisionFirmwareMappingsSelector = (state: ReduxState): HwRevisionFirmware[] => {
  const device: DeviceState = getDeviceState(state);
  return device.hwRevisionFirmwareMappings;
};
