import React, { Dispatch, SetStateAction } from 'react';
import style from './QuizOption.scss';
import { Radio, TextField, Typography, Box, withStyles, RadioProps, IconButton } from '@material-ui/core';
import { defaultPrimaryColor } from '../../utils/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { SurveyOption } from '../../redux/content/contentTypes';
import { getDefaultOption } from '../../containers/PageDetails/PageDetails';

interface Props {
  label: string;
  index: number;
  indexState: [number, Dispatch<SetStateAction<number>>];
  optionState: [SurveyOption[], Dispatch<SetStateAction<SurveyOption[]>>];
  selectable: boolean;
}

const OptionSelector = withStyles({
  root: {
    color: defaultPrimaryColor
  },
  checked: {}
})((props: RadioProps) => <Radio color="default" {...props} />);

export const QuizOption: React.FC<Props> = ({ label, index, indexState, optionState, selectable }) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const updatedArray: SurveyOption[] = [...optionState[0]];
    const updatedItem: SurveyOption = { ...updatedArray[index] };
    updatedItem.title = e.target.value;
    updatedArray[index] = updatedItem;
    optionState[1](updatedArray);
  };

  const addOption = (): void => updateOptionsState((x) => x.push(getDefaultOption()));
  const deleteOption = (): void => updateOptionsState((x) => x.splice(index, 1));

  const updateOptionsState = (update: (arr: SurveyOption[]) => void): void => {
    const newArr: SurveyOption[] = [...optionState[0]];
    update(newArr);
    optionState[1](newArr);
  };

  return (
    <Box className={style.quizOption}>
      {selectable && (
        <Box component="div" className={style.adjustForLabel}>
          <OptionSelector checked={indexState[0] === index} onChange={() => indexState[1](index)} value={index} />
        </Box>
      )}
      <Box component="span">
        <Typography variant="subtitle1" component="div">
          {label}
        </Typography>
        <TextField className={style.quizOptionInput} variant="outlined" value={optionState[0][index].title} onChange={handleChange} />
      </Box>
      {index === optionState[0].length - 1 && (
        <Box component="div" className={style.adjustForLabel}>
          <IconButton className={style.tileIcon} component="span" onClick={deleteOption} disabled={optionState[0].length === 1 || (selectable && indexState[0] === index)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
          <IconButton className={style.tileIcon} component="span" onClick={addOption}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
