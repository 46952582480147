import {
  Backdrop,
  Button,
  CardHeader,
  CircularProgress,
  ClickAwayListener,
  Container,
  Dialog,
  Divider,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  MuiThemeProvider,
  Paper,
  Popper,
  PropTypes,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { cloneDeep } from 'lodash';
import { Moment } from 'moment';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { ConnectedProps, connect, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { SelectButton } from '../../components/SelectButton/SelectButton';
import { TabbedPage } from '../../components/TabbedPage/TabbedPage';
import { extractAnswer, normalizeEnumName } from '../../utils/content';
import { chipTheme, defaultTheme } from '../../utils/styles';
import { goTo, goToKokoMap } from '../../utils/util';
import { AdHocTab } from './containers/AdHoc/AdHocTab';
import { ContentTab } from './containers/Content/ContentTab';
import { SleepScheduleTab } from './containers/SleepSchedule/SleepScheduleTab';

import style from './UserDetails.scss';

import { FlagDetails } from '../FlagDetails/FlagDetails';
import Chat from './containers/Chat/Chat';
import { DevicesShippingUserTab } from './containers/DevicesShippingUser/DevicesShippingUserTab';
import { HistoryTab } from './containers/History/HistoryTab';
import { IsiScoreTab } from './containers/IsiScores/IsiScoresTab';

import { NavBar } from '../../components/NavBar/NavBar';
import { FlagCards } from '../../components/Workflow/FlagCards/FlagCards';
import { UserFlags } from './components/UserFlags/UserFlags';

import { timestampAndRandomCombinedUUIDString } from '../../../shared/src/util/uuid';
import { clarifyErrorMessage, getActiveRecord, hasActiveRecord } from '../../utils/createFrontEndError';
import { isUserDetailSleepCoachChatRoute } from '../../utils/navigationUtils';
import { createScheduleLogMapping, createScheduleSurveyMapping } from '../../utils/sleepSchedule';
import { currentDateTime, tsAsDateTimeStr } from '../../utils/time';
import {
  defaultDialogTransition,
  defaultUserDetailsCrumb,
  defaultUserDetailsCrumbs,
  defaultUserDetailsSleepCoachChatCrumb,
  defaultUserDetailsSleepCoachChatCrumbs
} from '../../utils/breadcrumbs';

import { BreadCrumbData } from 'redux/common/types';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';
import { sleepLogsSelector } from '../../redux/activity/activitySelectors';
import { getSleepLogsThunk } from '../../redux/activity/activityThunks';
import { DailySleepLog } from '../../redux/activity/activityTypes';
import { isLatestPersonSleepCoachMessageUnreadSelector } from '../../redux/chat/chatSelectors';
import { ChatChannelType } from '../../redux/chat/chatTypes';
import { getClientDevicesByIdSelector, getPushNotificationsByIdSelector } from '../../redux/clientDevice/clientDeviceSelectors';
import { getPushNotificationsThunk } from '../../redux/clientDevice/clientDeviceThunks';
import { getClearResponseStatusAction } from '../../redux/content/contentActions';
import {
  getContentErrorSelector,
  getContentIsLoadingSelector,
  getContentSuccessSelector,
  getDeviceContentByTypeSelector,
  getFeedItemsSelector,
  getFlagByIdSelector,
  getPersonWorkflowsSelector,
  getSaveSuccessfulSelector,
  getSuccessMessageSelector,
  getSurveyResponsesSelector
} from '../../redux/content/contentSelectors';
import {
  getAllFlagContentThunk,
  getDeviceContentThunk,
  getFeedItemsThunk,
  getPersonFlagContentThunk,
  getSurveyResponsesByTypeThunk,
  saveFlagContentThunk,
  savePersonTaskStatusThunk,
  saveWorkflowCommentThunk
} from '../../redux/content/contentThunks';
import {
  AdHocContentRequest,
  DeviceContentType,
  PersonWorkflow,
  PersonWorkflowComment,
  PersonWorkflowStatus,
  PersonWorkflowTaskStatus,
  SurveyScoreResponse,
  WorkflowTemplate,
  WorkflowType,
  accountStatusList,
  deviceGroupList
} from '../../redux/content/contentTypes';
import { getDeviceStateByDeviceId, selectDeviceByIdSelector, selectLocationByIdSelector } from '../../redux/device/deviceSelectors';
import { DeviceConnectivityStatus, DeviceHealthStatus, DeviceShipment } from '../../redux/device/deviceTypes';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { getAuthPersonIdSelector, getBreadcrumbsSelector } from '../../redux/oauth/oauthSelectors';
import { clearPersonResponseStatusAction, clearSleepCalculationAction } from '../../redux/person/personActions';
import {
  getAdHocErrorSelector,
  getBatchLoadingSelector,
  getBatchSuccessSelector,
  getClientDeviceById,
  getCurrentSleepScheduleCalculationSelector,
  getHistoricSleepSchedulesSelector,
  getPersonByIdSelector,
  getPersonErrorStateSelector,
  getPersonIsiScoreByIdSelector,
  getPersonLoadingEventChangeStateSelector,
  getPersonLoadingStateSelector,
  getPersonOrderByIdSelector,
  getPersonSettingByIdSelector,
  getPersonSleepProgramByIdSelector,
  getPersonSuccessStateSelector,
  getPersonWarningMessageStateSelector,
  getSelectedPersonAccountStatuses,
  getSelectedPersonDetailsSelector,
  getSelectedPersonDeviceShipments,
  getSelectedPersonDeviceTrainings,
  getSelectedPersonShipmentInfo,
  getSnsErrorSelector
} from '../../redux/person/personSelector';
import {
  calculateSleepScheduleThunk,
  getCreateAdHocBatchContentThunk,
  getPersonByIdThunk,
  getPersonDetailsThunk,
  putAccountStatusThunk,
  putDeviceGroupThunk,
  putEnableChatBotThunk,
  putEnableDeviceTrainingThunk,
  putEnableLunaSleepLogSummariesThunk,
  putEnableLunaSleepScheduleSummariesThunk,
  putEnableLunaWeeklySummariesThunk,
  putEnableMobileCustomSleepStoriesThunk,
  putEnableSleepLogAutoSubmissionThunk,
  putEnableSleepScheduleThunk,
  putEsIdThunk,
  putShowSleepFeaturesThunk,
  putSleepLogDeviceTimesEnabledThunk,
  saveIsiScoresThunk,
  saveOrderThunk,
  saveShipmentThunk,
  saveSleepScheduleAndCalculationThunk,
  sendPushNotificationsThunk
} from '../../redux/person/personThunks';
import {
  AccountStatus,
  DataUsageAgreementType,
  DeviceGroup,
  Person,
  PersonAccountStatus,
  PersonIsiScore,
  PersonOrder,
  SleepCalculationRequest,
  SleepScheduleCalculationRequest
} from '../../redux/person/personTypes';
import { getActionStringWithoutState } from '../../redux/reduxUtils';
import { createNewSleepScheduleActions, getClearResponseStatusSleepScheduleAction, updateSleepScheduleLunaSummaryActions } from '../../redux/sleepschedule/sleepScheduleActions';
import {
  getDraftScheduleByIdSelector,
  getErrorSleepScheduleSelector,
  getIsLoadingSleepScheduleSelector,
  getSleepScheduleSuccessMessageSelector,
  getSleepSchedulesByIdSelector,
  getSuccessSleepScheduleSelector
} from '../../redux/sleepschedule/sleepScheduleSelector';
import { getSleepSchedulesThunk } from '../../redux/sleepschedule/sleepScheduleThunks';
import { ReduxState } from '../../redux/types';

const generateSurveyTable = (scheduleSurveys?: Nullable<SurveyScoreResponse[]>): JSX.Element => (
  <TableContainer component={Paper} className={style.surveyScoreTable} hidden={!scheduleSurveys?.length}>
    <Table size="small" style={{ whiteSpace: 'nowrap' }}>
      <TableHead>
        <TableRow>
          <TableCell className={style.surveyHeaderCell}>DATE</TableCell>
          <TableCell className={style.surveyHeaderCell}>SCORE</TableCell>
          {scheduleSurveys?.[0]?.surveyQuestions?.map((surveyQuestion, index) => (
            <TableCell key={index} className={style.surveyHeaderCell}>
              {surveyQuestion.questionText}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {scheduleSurveys?.map((row, index) => (
          <TableRow key={index}>
            <TableCell className={style.rowCell}>{row.surveyDate}</TableCell>
            <TableCell className={style.rowCell}>{row.surveyScore}</TableCell>
            {row.surveyQuestions?.map((surveyQuestion, sIndex) => (
              <TableCell key={sIndex} className={style.rowCell}>
                {extractAnswer(surveyQuestion)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

type PropsFromRedux = ConnectedProps<typeof connectRedux>;

export const UserDetails: React.FC<PropsFromRedux> = ({
  appendBreadCrumbs,
  calculateSleepSchedule,
  clearContentResponseStatus,
  clearPersonResponseStatus,
  clearResponseStatusSleepScheduleAction,
  clearSleepCalculation,
  fetchPerson,
  getAllFlagContent,
  getDeviceContent,
  getFeedItems,
  getPersonDetails,
  getPersonFlagContent,
  getPushNotifications,
  getSleepSchedules,
  getSurveyResponses,
  getUserSleepLogs,
  saveFlag,
  saveFlagComment,
  saveFlagTaskStatus,
  saveIsiScores,
  saveOrder,
  saveShipment,
  saveSleepScheduleAndCalculation,
  sendAdHocContent,
  sendPushNotifications,
  updateAccountStatus,
  updateChatBotEnabled,
  updateDeviceGroup,
  updateEsId,
  updateLunaSleepLogSummariesEnabled,
  updateLunaSleepScheduleSummariesEnabled,
  updateLunaWeeklySummariesEnabled,
  updateMobileCustomSleepStoriesEnabled,
  updateEnableDeviceTraining,
  updateShowSleepFeatures,
  updateSleepScheduleEnabled,
  updateSleepLogDeviceTimesEnabled,
  updateSleepLogAutoSubmissionEnabled,
  adHocError,
  authenticatedPersonId,
  batchLoading,
  batchSuccess,
  breadCrumbs,
  clientDeviceById,
  clientDevicesById,
  currentSleepScheduleCalculation,
  deviceById,
  deviceContentByType,
  deviceShipments,
  deviceState,
  deviceTrainings,
  error,
  errorContent,
  feedItems,
  flagById,
  flagSuccessMessage,
  scheduleSuccessMessage,
  historicSleepLogs,
  historicSleepSchedules,
  historicSurveyResponses,
  isLoading,
  isLoadingContent,
  loadingEventChange,
  locationById,
  personAccountStatuses,
  personById,
  personIsiScoreById,
  personOrderById,
  personSettingById,
  personSleepProgramById,
  personWorkflows,
  pushNotificationsById,
  saveSuccessful,
  selectedPersonDetails,
  shipmentInfo,
  sleepScheduleById,
  draftScheduleById,
  snsError,
  scheduleError,
  success,
  successContent,
  successSchedule,
  warningMessage,
  isSleepScheduleLoading
}) => {
  let scheduleLogMapping: Record<string, DailySleepLog[]>;
  const latestSleepLog = React.useMemo(() => {
    if (historicSleepSchedules?.length) {
      for (let i = 0; i < historicSleepSchedules.length; i++) {
        const sleepScheduleId: Nullable<string> = historicSleepSchedules[i].id;
        if (sleepScheduleId) {
          const sleepLogs: Nullable<DailySleepLog[]> = scheduleLogMapping?.[sleepScheduleId];
          if (sleepLogs && sleepLogs.length) {
            return sleepLogs[0];
          }
        }
      }
    }
    return undefined;
  }, [selectedPersonDetails?.personId, historicSleepSchedules?.length]);

  const latestTimeZone = React.useMemo(
    () => (selectedPersonDetails?.locationId ? locationById?.[selectedPersonDetails.locationId]?.timeZoneId : latestSleepLog?.timeZone || 'UTC'),
    [selectedPersonDetails?.personId, selectedPersonDetails?.locationId, latestSleepLog]
  );

  const displayCreateSleepScheduleState: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const displayCalculationState: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openManualTask, setOpenManualTask] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const params = useParams();
  const { userId } = params || {};
  const navigate = useNavigate();
  const location: any = useLocation();

  const openSleepCoachChatTab = isUserDetailSleepCoachChatRoute(location.pathname);

  const person: Nullable<Person> = React.useMemo(() => (selectedPersonDetails ? personById?.[selectedPersonDetails?.personId] : null), [selectedPersonDetails?.personId]);

  const isLatestPersonSleepCoachMessageUnread = !!userId && useSelector((state: ReduxState) => isLatestPersonSleepCoachMessageUnreadSelector(userId, state));

  const personSleepProgram = React.useMemo(
    () => (selectedPersonDetails?.personSleepProgramId ? personSleepProgramById?.[selectedPersonDetails?.personSleepProgramId] : null),
    [selectedPersonDetails?.personSleepProgramId]
  );
  const personTrack = personSleepProgram?.programSubType ? normalizeEnumName(personSleepProgram.programSubType) : undefined;
  const titleText = `${person?.givenNames || ''} ${person?.familyName || ''}${personTrack ? ' [ Track: ' + personTrack + ']' : ''}`;

  const prettifyDate = (ts?: Nullable<string>): string => (ts ? `(${tsAsDateTimeStr(ts, latestTimeZone)})` : '');
  const deviceGroup =
    selectedPersonDetails?.personId && personSettingById[selectedPersonDetails?.personId] ? personSettingById[selectedPersonDetails?.personId]?.deviceGroup : undefined;
  const deviceNumber = selectedPersonDetails?.deviceId ? deviceById[selectedPersonDetails?.deviceId]?.deviceNumber : undefined;

  const deviceGroupListNormalizeAndMemo = React.useMemo(() => deviceGroupList.map((deviceGroup) => normalizeEnumName(deviceGroup)), []);
  const accountStatusListNormalizeAndMemo = React.useMemo(() => accountStatusList.map((accountStatus) => normalizeEnumName(accountStatus)), []);

  const sleepLogDeviceTimesEnabledTs = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.sleepLogDeviceTimesEnabledTs : '';
  const sleepLogDeviceTimesEnabledOptionsMemo = React.useMemo(
    () => ['Disabled', `Enabled ${prettifyDate(sleepLogDeviceTimesEnabledTs)}`],
    [selectedPersonDetails?.personId, sleepLogDeviceTimesEnabledTs]
  );

  const sleepLogAutoSubmissionEnabledTs = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.sleepLogAutoSubmissionEnabledTs : '';
  const sleepLogAutoSubmissionEnabledptionsMemo = React.useMemo(
    () => ['Disabled', `Enabled ${prettifyDate(sleepLogAutoSubmissionEnabledTs)}`],
    [selectedPersonDetails?.personId, sleepLogAutoSubmissionEnabledTs]
  );

  const lunaSleepLogSummariesEnabledTs = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.lunaSleepLogSummariesEnabledTs : '';
  const lunaSleepLogSummariesEnabledOptionsMemo = React.useMemo(
    () => ['Disabled', `Enabled ${prettifyDate(lunaSleepLogSummariesEnabledTs)}`],
    [selectedPersonDetails?.personId, lunaSleepLogSummariesEnabledTs]
  );

  const lunaSleepScheduleSummariesEnabledTs = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.lunaSleepScheduleSummariesEnabledTs : '';
  const lunaSleepScheduleSummariesEnabledOptionsMemo = React.useMemo(
    () => ['Disabled', `Enabled ${prettifyDate(lunaSleepScheduleSummariesEnabledTs)}`],
    [selectedPersonDetails?.personId, lunaSleepScheduleSummariesEnabledTs]
  );

  const lunaWeeklySummariesEnabledTs = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.lunaWeeklySummariesEnabledTs : '';
  const lunaWeeklySummariesEnabledOptionsMemo = React.useMemo(
    () => ['Disabled', `Enabled ${prettifyDate(lunaWeeklySummariesEnabledTs)}`],
    [selectedPersonDetails?.personId, lunaWeeklySummariesEnabledTs]
  );

  const mobileCustomSleepStoriesEnabledTs = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.mobileCustomSleepStoriesEnabledTs : '';
  const mobileCustomSleepStoriesEnabledOptionsMemo = React.useMemo(
    () => ['Disabled', `Enabled ${prettifyDate(mobileCustomSleepStoriesEnabledTs)}`],
    [selectedPersonDetails?.personId, mobileCustomSleepStoriesEnabledTs]
  );

  const enableDeviceTraining = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.enableDeviceTraining : '';
  const enableDeviceTrainingOptionsMemo = React.useMemo(() => ['Disabled', `Enabled`], [selectedPersonDetails?.personId, enableDeviceTraining]);

  const showSleepFeatures = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.showSleepFeatures : '';
  const showSleepFeaturesOptionsMemo = React.useMemo(() => ['Disabled', `Enabled`], [selectedPersonDetails?.personId, showSleepFeatures]);

  const mobileSleepCoachEnabledTs = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.mobileSleepCoachEnabledTs : '';
  const mobileSleepCoachEnabledOptionsMemo = React.useMemo(
    () => ['Disabled', `Enabled ${prettifyDate(mobileSleepCoachEnabledTs)}`],
    [selectedPersonDetails?.personId, mobileSleepCoachEnabledTs]
  );

  const mobileChatBotEnabledTs = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.mobileChatBotEnabledTs : '';
  const mobileChatBotEnabledOptionsMemo = React.useMemo(
    () => ['Disabled', `Enabled ${prettifyDate(mobileChatBotEnabledTs)}`],
    [selectedPersonDetails?.personId, mobileChatBotEnabledTs]
  );

  const deviceChatBotEnabledTs = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.deviceChatBotEnabledTs : '';
  const deviceChatBotEnabledOptionsMemo = React.useMemo(
    () => ['Disabled', `Enabled ${prettifyDate(deviceChatBotEnabledTs)}`],
    [selectedPersonDetails?.personId, deviceChatBotEnabledTs]
  );

  const sleepScheduleEnabledTs = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId]?.sleepScheduleEnabledTs : '';
  const sleepScheduleEnabledOptionsMemo = React.useMemo(
    () => ['Disabled', `Enabled ${prettifyDate(sleepScheduleEnabledTs)}`],
    [selectedPersonDetails?.personId, sleepScheduleEnabledTs]
  );

  useEffect(() => {
    if (userId) {
      getPersonDetails(userId);
      getSleepSchedules(userId);
      getSurveyResponses(userId);
      getUserSleepLogs(userId);
      getFeedItems(userId);
      getDeviceContent();
      getPersonFlagContent(userId);
      getAllFlagContent(WorkflowType.FLAG_MANUAL);
      getPushNotifications(userId);
    }
  }, [userId]);

  useEffect(() => {
    setOpenDialog(false);
    const processedPersonIds: string[] = [];
    personWorkflows?.forEach((personWorkflow) => {
      const personWorkflowComments: Nullable<PersonWorkflowComment[]> = personWorkflow?.personWorkflowComments;
      personWorkflowComments?.forEach((personWorkflowComment) => {
        const authorPersonId: Nullable<string> = personWorkflowComment?.authorPersonId;
        if (authorPersonId && !personById?.[authorPersonId] && !processedPersonIds.includes(authorPersonId)) {
          processedPersonIds.push(authorPersonId);
          fetchPerson(authorPersonId);
        }
      });
      const personWorkflowStatuses: Nullable<PersonWorkflowStatus[]> = personWorkflow?.personWorkflowStatuses;
      personWorkflowStatuses?.forEach((personWorkflowStatus) => {
        const submitterPersonId: Nullable<string> = personWorkflowStatus?.submitterPersonId;
        if (submitterPersonId && !personById?.[submitterPersonId] && !processedPersonIds.includes(submitterPersonId)) {
          processedPersonIds.push(submitterPersonId);
          fetchPerson(submitterPersonId);
        }
      });
    });
  }, [personWorkflows]);

  const saveAccountStatusCallback = React.useCallback(
    async (selectedIndex: number) => {
      const selectedStatus: AccountStatus = AccountStatus?.[accountStatusList[selectedIndex]];
      if (selectedPersonDetails?.personId) {
        const personAccountStatus: PersonAccountStatus = {
          id: timestampAndRandomCombinedUUIDString(),
          personId: selectedPersonDetails?.personId,
          accountStatus: selectedStatus,
          startDate: currentDateTime(latestTimeZone).format()
        };
        await updateAccountStatus(personAccountStatus);
      }
    },
    [selectedPersonDetails?.personId, accountStatusList]
  );

  const saveDeviceGroupCallback = React.useCallback(
    async (selectedIndex: number) => {
      const selectedGroup: DeviceGroup = DeviceGroup[deviceGroupList[selectedIndex]];
      await updateDeviceGroup(selectedGroup, selectedPersonDetails?.personId || '');
    },
    [selectedPersonDetails?.personId, deviceGroupList]
  );

  const saveSleepLogDeviceTimesEnabledCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateSleepLogDeviceTimesEnabled(!!selectedIndex, person?.id);
      }
    },
    [person?.id]
  );

  const saveSleepLogAutoSubmissionCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateSleepLogAutoSubmissionEnabled(!!selectedIndex, person?.id);
      }
    },
    [person?.id]
  );

  const saveLunaSleepLogSummariesEnabledCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateLunaSleepLogSummariesEnabled(!!selectedIndex, person?.id);
      }
    },
    [person?.id]
  );

  const saveLunaSleepScheduleSummariesEnabledCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateLunaSleepScheduleSummariesEnabled(!!selectedIndex, person?.id);
      }
    },
    [person?.id]
  );

  const saveLunaWeeklySummariesEnabledCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateLunaWeeklySummariesEnabled(!!selectedIndex, person?.id);
      }
    },
    [person?.id]
  );

  const saveChatBotDeviceEnabledCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateChatBotEnabled(!!selectedIndex, person?.id, ChatChannelType.CHAT_BOT_DEVICE);
      }
    },
    [person?.id]
  );

  const saveChatBotMobileEnabledCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateChatBotEnabled(!!selectedIndex, person?.id, ChatChannelType.CHAT_BOT_MOBILE);
      }
    },
    [person?.id]
  );

  const saveSleepCoachChatEnabledCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateChatBotEnabled(!!selectedIndex, person?.id, ChatChannelType.SLEEP_COACH);
      }
    },
    [person?.id]
  );

  const saveMobileCustomSleepStoriesEnabledCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateMobileCustomSleepStoriesEnabled(!!selectedIndex, person?.id);
      }
    },
    [person?.id]
  );

  const saveEnableDeviceTrainingCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateEnableDeviceTraining(!!selectedIndex, person?.id);
      }
    },
    [person?.id]
  );

  const saveShowSleepFeaturesCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateShowSleepFeatures(!!selectedIndex, person?.id);
      }
    },
    [person?.id]
  );

  const saveSleepScheduleEnabledCallback = React.useCallback(
    async (selectedIndex: number) => {
      if (person?.id) {
        await updateSleepScheduleEnabled(!!selectedIndex, person?.id);
      }
    },
    [person?.id]
  );

  const determineColor = (status?: Nullable<DeviceConnectivityStatus | DeviceHealthStatus>): Exclude<PropTypes.Color, 'inherit'> => {
    switch (status) {
      case DeviceConnectivityStatus.CONNECTED:
      case DeviceHealthStatus.HEALTHY: {
        return 'primary';
      }
      case DeviceConnectivityStatus.DISCONNECTED:
      case DeviceHealthStatus.UNHEALTHY: {
        return 'secondary';
      }
      default: {
        return 'default';
      }
    }
  };

  const openKokoMap = () => {
    goToKokoMap(selectedPersonDetails?.personId || '', latestTimeZone, navigate, false);
  };

  const openMeasurements = () => {
    navigate(`/patients/${userId}`);
  };

  scheduleLogMapping = createScheduleLogMapping(historicSleepLogs, latestTimeZone, historicSleepSchedules);
  const scheduleSurveyMapping: Record<string, SurveyScoreResponse[]> = createScheduleSurveyMapping(historicSurveyResponses, latestTimeZone, historicSleepSchedules);

  const clearResponseStatus = (): void => {
    clearPersonResponseStatus();
    clearContentResponseStatus();
    clearResponseStatusSleepScheduleAction();
  };

  const handleCloseManualTask = (event: React.MouseEvent<Document, MouseEvent>): void => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenManualTask(() => false);
  };

  const handleCreateMenuItemClick = (): void => {
    setOpenManualTask(() => false);
    setOpenDialog(() => !openDialog);
  };

  const handleMenuItemClick = (flag: WorkflowTemplate): void => {
    const updatedFlag: WorkflowTemplate = cloneDeep(flag);
    setOpenManualTask(() => false);
    const personWorkflow: PersonWorkflow = {
      id: timestampAndRandomCombinedUUIDString(),
      personId: selectedPersonDetails?.personId || '',
      workflowId: updatedFlag.id,
      workflowType: updatedFlag.workflowType,
      openedTs: new Date().toISOString(),
      personWorkflowTasks: []
    };
    updatedFlag.personWorkflows = [personWorkflow];
    saveFlag(updatedFlag, selectedPersonDetails?.personId);
  };

  const personSetting = selectedPersonDetails?.personId ? personSettingById?.[selectedPersonDetails?.personId] : undefined;
  const irbAgreed = !!(
    personSetting?.dataUsageAgreementType === DataUsageAgreementType.YES ||
    (personSetting?.dataUsageAgreementType === DataUsageAgreementType.MAYBE && personSetting?.irbConsentTs && personSetting?.irbHipaaConsentTs)
  );
  const Irb: string = personSetting?.dataUsageAgreementType || 'N/A';
  const IrbConsentTimestamp: string = personSetting?.irbConsentTs || 'N/A';

  const errorMessage = clarifyErrorMessage(getActiveRecord(error)) || clarifyErrorMessage(getActiveRecord(errorContent)) || clarifyErrorMessage(getActiveRecord(scheduleError));

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Backdrop
            open={
              !!loadingEventChange ||
              !!isSleepScheduleLoading[getActionStringWithoutState(createNewSleepScheduleActions.start.toString())] ||
              !!isSleepScheduleLoading[getActionStringWithoutState(updateSleepScheduleLunaSummaryActions.start.toString())]
            }
            style={{ zIndex: 2147483647 }}
          >
            <CircularProgress />
          </Backdrop>
          <NavBar
            breadCrumbs={breadCrumbs}
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={openSleepCoachChatTab ? defaultUserDetailsSleepCoachChatCrumb : defaultUserDetailsCrumb}
            defaultCrumbHistory={openSleepCoachChatTab ? defaultUserDetailsSleepCoachChatCrumbs : defaultUserDetailsCrumbs}
            overrideUrl={location?.pathname}
          />
          <Grid container spacing={2} className={style.gridBody}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" spacing={1}>
                {/* left column: user info */}
                <Grid item xs={'auto'}>
                  {/* title */}
                  <Typography variant="h3" component="div" className={style.titleText}>
                    {titleText}
                  </Typography>
                  {/* person info */}
                  <Typography component="div">
                    {`Email: ${person?.email ? person.email : ''}`}
                    <br />
                    {`ES ID: ${person?.esId ? person.esId : ''}`}
                    <br />
                    {`Device Number: ${deviceNumber ? 'D' + deviceNumber : ''}`}
                    <br />
                    {`Content Day: ${personSleepProgram?.programStep}`}
                    <br />
                    {`IRB Agreed: ${irbAgreed}`}
                    <br />
                    {`IRB Response: ${personSetting?.dataUsageAgreementType || 'N/A'}`}
                    <br />
                    {`IRB Consent Timestamp: ${personSetting?.irbConsentTs ? tsAsDateTimeStr(personSetting.irbConsentTs, latestTimeZone) : 'N/A'}`}
                    <br />
                    {`IRB HIPAA Consent Timestamp: ${personSetting?.irbHipaaConsentTs ? tsAsDateTimeStr(personSetting.irbHipaaConsentTs, latestTimeZone) : 'N/A'}`}
                    <br />
                  </Typography>
                  {/* chip buttons */}
                  <ThemeProvider theme={chipTheme}>
                    <Grid container spacing={1} className={style.gridChipButtons}>
                      <Grid item xs={'auto'}>
                        <Button
                          className={style.chip}
                          disabled={!selectedPersonDetails?.deviceId}
                          color={determineColor(deviceState?.connectivityStatus)}
                          variant="contained"
                          onClick={goTo(`/devices/${selectedPersonDetails?.deviceId}`, navigate)}
                        >
                          Device {deviceState?.connectivityStatus}
                        </Button>
                      </Grid>
                      <Grid item xs={'auto'}>
                        <Button
                          className={style.chip}
                          disabled={!selectedPersonDetails?.deviceId}
                          color={determineColor(deviceState?.healthStatus)}
                          variant="contained"
                          onClick={goTo(`/devices/${selectedPersonDetails?.deviceId}`, navigate)}
                        >
                          Device {deviceState?.healthStatus}
                        </Button>
                      </Grid>
                      <Grid item xs={'auto'}>
                        <Button className={style.chip} disabled={!selectedPersonDetails?.deviceId} color="default" variant="contained" onClick={openKokoMap}>
                          Koko Map
                        </Button>
                      </Grid>
                      <Grid item xs={'auto'}>
                        <Button className={style.chip} color="default" variant="contained" onClick={openMeasurements}>
                          Measurements
                        </Button>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </Grid>
                {/* right column: select buttons */}
                <Grid item xs={'auto'}>
                  {/* select buttons 1st row */}
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={deviceGroupListNormalizeAndMemo}
                        activeIndex={deviceGroup ? deviceGroupList.indexOf(deviceGroup) : 0}
                        onClick={saveDeviceGroupCallback}
                        label={'Device Group'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={accountStatusListNormalizeAndMemo}
                        activeIndex={!person?.accountStatus ? 0 : accountStatusList.indexOf(person?.accountStatus)}
                        onClick={saveAccountStatusCallback}
                        label={'Status'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                  </Grid>
                  {/* select buttons 2nd row */}
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={sleepLogDeviceTimesEnabledOptionsMemo}
                        activeIndex={!personSettingById?.[selectedPersonDetails?.personId || '']?.sleepLogDeviceTimesEnabledTs ? 0 : 1}
                        onClick={saveSleepLogDeviceTimesEnabledCallback}
                        label={'Sleep Log Device Times'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={sleepLogAutoSubmissionEnabledptionsMemo}
                        activeIndex={!personSettingById?.[selectedPersonDetails?.personId || '']?.sleepLogAutoSubmissionEnabledTs ? 0 : 1}
                        onClick={saveSleepLogAutoSubmissionCallback}
                        label={'Sleep Log Auto Submission'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={lunaSleepLogSummariesEnabledOptionsMemo}
                        activeIndex={!personSettingById?.[selectedPersonDetails?.personId || '']?.lunaSleepLogSummariesEnabledTs ? 0 : 1}
                        onClick={saveLunaSleepLogSummariesEnabledCallback}
                        label={'Luna Sleep Log Summaries'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                  </Grid>
                  {/* select buttons 3rd row */}
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={mobileCustomSleepStoriesEnabledOptionsMemo}
                        activeIndex={!selectedPersonDetails?.personId || !personSettingById?.[selectedPersonDetails?.personId]?.mobileCustomSleepStoriesEnabledTs ? 0 : 1}
                        onClick={saveMobileCustomSleepStoriesEnabledCallback}
                        label={'Mobile Custom sleep stories'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={mobileSleepCoachEnabledOptionsMemo}
                        activeIndex={!selectedPersonDetails?.personId || !personSettingById?.[selectedPersonDetails?.personId]?.mobileSleepCoachEnabledTs ? 0 : 1}
                        onClick={saveSleepCoachChatEnabledCallback}
                        label={'Sleep Coach Access'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={mobileChatBotEnabledOptionsMemo}
                        activeIndex={!selectedPersonDetails?.personId || !personSettingById?.[selectedPersonDetails?.personId]?.mobileChatBotEnabledTs ? 0 : 1}
                        onClick={saveChatBotMobileEnabledCallback}
                        label={'Mobile Chat Bot'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={deviceChatBotEnabledOptionsMemo}
                        activeIndex={!selectedPersonDetails?.personId || !personSettingById?.[selectedPersonDetails?.personId]?.deviceChatBotEnabledTs ? 0 : 1}
                        onClick={saveChatBotDeviceEnabledCallback}
                        label={'Device Chat Bot'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                  </Grid>
                  {/* select buttons 4th row */}
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={sleepScheduleEnabledOptionsMemo}
                        activeIndex={!selectedPersonDetails?.personId || !personSettingById?.[selectedPersonDetails?.personId]?.sleepScheduleEnabledTs ? 0 : 1}
                        onClick={saveSleepScheduleEnabledCallback}
                        label={'Sleep Schedule'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={lunaSleepScheduleSummariesEnabledOptionsMemo}
                        activeIndex={!personSettingById?.[selectedPersonDetails?.personId || '']?.lunaSleepScheduleSummariesEnabledTs ? 0 : 1}
                        onClick={saveLunaSleepScheduleSummariesEnabledCallback}
                        label={'Luna Sleep Schedule Summaries'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={lunaWeeklySummariesEnabledOptionsMemo}
                        activeIndex={!personSettingById?.[selectedPersonDetails?.personId || '']?.lunaWeeklySummariesEnabledTs ? 0 : 1}
                        onClick={saveLunaWeeklySummariesEnabledCallback}
                        label={'Luna Weekly Summaries'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                  </Grid>
                  {/* select buttons 6th row */}
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={enableDeviceTrainingOptionsMemo}
                        activeIndex={!selectedPersonDetails?.personId || !personSettingById?.[selectedPersonDetails?.personId]?.enableDeviceTraining ? 0 : 1}
                        onClick={saveEnableDeviceTrainingCallback}
                        label={'Device Training'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                    <Grid item xs={'auto'} className={style.selectButtonGridItem}>
                      <SelectButton
                        options={showSleepFeaturesOptionsMemo}
                        activeIndex={!selectedPersonDetails?.personId || !personSettingById?.[selectedPersonDetails?.personId]?.showSleepFeatures ? 0 : 1}
                        onClick={saveShowSleepFeaturesCallback}
                        label={'Show Sleep Features'}
                        customPadding={'10px'}
                        customWidth={'305px'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* TODO: Remove legacy user flags once flag cards are fully in place */}
            <UserFlags
              latestTz={latestTimeZone}
              historicSleepLogs={historicSleepLogs}
              historicSurveyResponses={historicSurveyResponses}
              feedItems={feedItems || []}
              historicSleepSchedules={historicSleepSchedules}
              personProgram={personSleepProgram}
            />
            <FlagCards
              personWorkflows={personWorkflows?.filter((workflow) => !workflow.completedTs && !workflow.expirationTs)}
              authenticatedPersonId={authenticatedPersonId || ''}
              personById={personById}
              saveFlagComment={saveFlagComment}
              saveFlagTaskStatus={saveFlagTaskStatus}
            />
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CardHeader
                className={style.assignFlagHeader}
                avatar={
                  <IconButton color="primary" className={style.assignFlagButton} ref={anchorRef} onClick={() => setOpenManualTask(() => !openManualTask)}>
                    <AddCircleIcon fontSize="inherit" />
                  </IconButton>
                }
                title={'Assign manual flag'}
              />
              <Popper open={openManualTask} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 2147483647 }}>
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      <ClickAwayListener onClickAway={handleCloseManualTask}>
                        <MenuList>
                          <MenuItem onClick={handleCreateMenuItemClick}>+ Create new manual flag</MenuItem>
                          <Divider />
                          {Object.values(flagById)
                            ?.filter((option: WorkflowTemplate) => option.workflowType === WorkflowType.FLAG_MANUAL && !option.expirationTs)
                            ?.sort((a: WorkflowTemplate, b: WorkflowTemplate) => a?.name?.localeCompare(b?.name))
                            ?.map((option: WorkflowTemplate, index) => (
                              <MenuItem
                                key={index}
                                disabled={!!personWorkflows?.find((pwf) => pwf.workflowId === option.id && !pwf.completedTs)}
                                onClick={() => handleMenuItemClick(option)}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
            <Dialog fullScreen className={style.sliderDialog} open={openDialog} onClose={() => setOpenDialog(false)} TransitionComponent={defaultDialogTransition}>
              <FlagDetails
                flagType={WorkflowType.FLAG_MANUAL}
                personId={selectedPersonDetails?.personId}
                isLoading={isLoading}
                error={errorContent}
                success={successContent}
                saveSuccessful={saveSuccessful}
                saveFlag={saveFlag}
                flagById={flagById}
                clearResponseStatus={clearResponseStatus}
              />
            </Dialog>
          </Grid>

          <TabbedPage
            initialSelectedTab={openSleepCoachChatTab ? 6 : 0}
            tabPanels={[
              {
                label: 'Sleep Schedule',
                content: (
                  <SleepScheduleTab
                    sleepScheduleById={sleepScheduleById}
                    historicSleepSchedules={historicSleepSchedules}
                    scheduleLogMapping={scheduleLogMapping}
                    scheduleSurveyMapping={scheduleSurveyMapping}
                    displayCreateSleepScheduleState={displayCreateSleepScheduleState}
                    displayCalculationState={displayCalculationState}
                    person={person}
                    userTimeZone={latestTimeZone}
                    latestSleepLog={latestSleepLog}
                    currentSleepScheduleCalculation={currentSleepScheduleCalculation}
                    calculateSleepSchedule={calculateSleepSchedule}
                    saveSleepScheduleAndCalculation={saveSleepScheduleAndCalculation}
                    clearSleepCalculation={clearSleepCalculation}
                    generateSurveyTable={generateSurveyTable}
                    draftScheduleById={draftScheduleById}
                  />
                )
              },
              { label: 'Content', content: <ContentTab feedItems={feedItems || []} userTimeZone={latestTimeZone} /> },
              {
                label: 'Ad Hoc & Push Notification',
                content: (
                  <AdHocTab
                    sendPushNotifications={sendPushNotifications}
                    sendAdHocContent={sendAdHocContent}
                    clearSubmissionResponse={clearPersonResponseStatus}
                    getPushNotifications={getPushNotifications}
                    deviceContent={deviceContentByType?.[DeviceContentType.AD_HOC_NATIVE]}
                    snsError={snsError}
                    adHocError={adHocError}
                    batchLoading={batchLoading}
                    batchSuccess={batchSuccess || false}
                    personId={selectedPersonDetails?.personId}
                    pushNotificationsById={pushNotificationsById}
                    clientDevicesById={clientDevicesById}
                  />
                )
              },
              {
                label: 'ISI Scores',
                content: (
                  <IsiScoreTab selectedPersonDetails={selectedPersonDetails} personIsiScoreById={personIsiScoreById} userTimeZone={latestTimeZone} saveIsiScores={saveIsiScores} />
                )
              },
              {
                label: 'Device, Shipping, & User Info',
                content: (
                  <DevicesShippingUserTab
                    selectedPersonDetails={selectedPersonDetails}
                    personAccountStatuses={personAccountStatuses}
                    deviceTrainings={deviceTrainings}
                    deviceShipments={deviceShipments}
                    shipmentInfo={shipmentInfo}
                    clientDevice={selectedPersonDetails?.latestClientDeviceId ? clientDeviceById?.[selectedPersonDetails?.latestClientDeviceId] : undefined}
                    esId={person?.esId}
                    purchaseTs={selectedPersonDetails?.latestPersonOrderId ? personOrderById?.[selectedPersonDetails?.latestPersonOrderId]?.purchaseTs : undefined}
                    userTimeZone={latestTimeZone}
                    saveShipment={saveShipment}
                    saveOrder={saveOrder}
                    updateEsId={updateEsId}
                  />
                )
              },
              {
                label: 'History',
                content: (
                  <HistoryTab
                    historicWorkflows={personWorkflows?.filter((workflow) => !workflow.expirationTs || (!!workflow.completedTs && !!workflow.expirationTs))}
                    authenticatedPersonId={authenticatedPersonId}
                    personById={personById}
                    saveFlagComment={saveFlagComment}
                    saveFlagTaskStatus={saveFlagTaskStatus}
                  />
                )
              },
              {
                label: 'Sleep Coach Chat',
                preRender: true,
                showRedDot: isLatestPersonSleepCoachMessageUnread,
                content: <Chat chatChannelType={ChatChannelType.SLEEP_COACH} />
              },
              {
                label: 'Luna (Mobile)',
                preRender: true,
                content: <Chat chatChannelType={ChatChannelType.CHAT_BOT_MOBILE} />
              },
              {
                label: 'Luna (Rest)',
                preRender: true,
                content: <Chat chatChannelType={ChatChannelType.CHAT_BOT_DEVICE} />
              }
            ]}
          />
        </Container>
      </div>
      <StatusMessage
        saveSuccessful={
          !hasActiveRecord(isLoading) &&
          !hasActiveRecord(isLoadingContent) &&
          !hasActiveRecord(isSleepScheduleLoading) &&
          !hasActiveRecord(error) &&
          !hasActiveRecord(errorContent) &&
          !hasActiveRecord(scheduleError) &&
          (hasActiveRecord(success) || hasActiveRecord(successContent) || hasActiveRecord(successSchedule))
        }
        saveUnsuccessful={
          !hasActiveRecord(isLoading) &&
          !hasActiveRecord(isSleepScheduleLoading) &&
          !hasActiveRecord(isLoadingContent) &&
          (hasActiveRecord(error) || hasActiveRecord(errorContent) || hasActiveRecord(scheduleError))
        }
        clearResponseStatus={clearResponseStatus}
        saveMessage={flagSuccessMessage || scheduleSuccessMessage}
        errorMessage={errorMessage}
        warningMessage={warningMessage}
        duration={flagSuccessMessage ? 10000 : undefined}
      />
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      adHocError: getAdHocErrorSelector(state),
      authenticatedPersonId: getAuthPersonIdSelector(state),
      batchLoading: getBatchLoadingSelector(state),
      batchSuccess: getBatchSuccessSelector(state),
      breadCrumbs: getBreadcrumbsSelector(state),
      clientDeviceById: getClientDeviceById(state),
      clientDevicesById: getClientDevicesByIdSelector(state),
      currentSleepScheduleCalculation: getCurrentSleepScheduleCalculationSelector(state),
      deviceById: selectDeviceByIdSelector(state),
      deviceContentByType: getDeviceContentByTypeSelector(state),
      deviceShipments: getSelectedPersonDeviceShipments(state),
      deviceState: getDeviceStateByDeviceId(state, getSelectedPersonDetailsSelector(state)?.deviceId),
      deviceTrainings: getSelectedPersonDeviceTrainings(state),
      error: getPersonErrorStateSelector(state),
      errorContent: getContentErrorSelector(state),
      feedItems: getFeedItemsSelector(state),
      flagById: getFlagByIdSelector(state),
      flagSuccessMessage: getSuccessMessageSelector(state),
      scheduleSuccessMessage: getSleepScheduleSuccessMessageSelector(state),
      historicSleepLogs: sleepLogsSelector(state),
      historicSleepSchedules: getHistoricSleepSchedulesSelector(state),
      historicSurveyResponses: getSurveyResponsesSelector(state),
      isLoading: getPersonLoadingStateSelector(state),
      isLoadingContent: getContentIsLoadingSelector(state),
      loadingEventChange: getPersonLoadingEventChangeStateSelector(state),
      locationById: selectLocationByIdSelector(state),
      personAccountStatuses: getSelectedPersonAccountStatuses(state),
      personById: getPersonByIdSelector(state),
      personIsiScoreById: getPersonIsiScoreByIdSelector(state),
      personOrderById: getPersonOrderByIdSelector(state),
      personSettingById: getPersonSettingByIdSelector(state),
      personSleepProgramById: getPersonSleepProgramByIdSelector(state),
      personWorkflows: getPersonWorkflowsSelector(state),
      pushNotificationsById: getPushNotificationsByIdSelector(state),
      saveSuccessful: getSaveSuccessfulSelector(state),
      selectedPersonDetails: getSelectedPersonDetailsSelector(state),
      sleepScheduleById: getSleepSchedulesByIdSelector(state),
      draftScheduleById: getDraftScheduleByIdSelector(state),
      shipmentInfo: getSelectedPersonShipmentInfo(state),
      snsError: getSnsErrorSelector(state),
      scheduleError: getErrorSleepScheduleSelector(state),
      success: getPersonSuccessStateSelector(state),
      successSchedule: getSuccessSleepScheduleSelector(state),
      successContent: getContentSuccessSelector(state),
      warningMessage: getPersonWarningMessageStateSelector(state),
      isSleepScheduleLoading: getIsLoadingSleepScheduleSelector(state)
    };
  },
  (dispatch: Function) => ({
    getPersonDetails: (userId: string): void => {
      dispatch(getPersonDetailsThunk(userId));
    },
    getSleepSchedules: (userId: string): void => {
      dispatch(getSleepSchedulesThunk(userId, 500));
    },
    getSurveyResponses: (userId: string): void => {
      dispatch(getSurveyResponsesByTypeThunk(userId));
    },
    getUserSleepLogs: (userId: string): void => {
      dispatch(getSleepLogsThunk(userId));
    },
    getFeedItems: (userId: string): void => {
      dispatch(getFeedItemsThunk(userId));
    },
    getDeviceContent: (): void => {
      dispatch(getDeviceContentThunk([DeviceContentType.AD_HOC_NATIVE]));
    },
    getPersonFlagContent: (personId: Nullable<string>): void => {
      dispatch(getPersonFlagContentThunk(personId || ''));
    },
    getAllFlagContent: (flagType: WorkflowType): void => {
      dispatch(getAllFlagContentThunk(flagType));
    },
    getPerson: (personId: Nullable<string>): void => {
      dispatch(getPersonByIdThunk(personId || ''));
    },
    getPushNotifications: (personId: Nullable<string>) => {
      dispatch(getPushNotificationsThunk(personId || ''));
    },
    updateAccountStatus: (personAccountStatus: PersonAccountStatus): void => {
      dispatch(putAccountStatusThunk(personAccountStatus));
    },
    updateDeviceGroup: (deviceGroup: DeviceGroup, personId: Nullable<string>): void => {
      dispatch(putDeviceGroupThunk(deviceGroup, personId || ''));
    },
    updateSleepLogDeviceTimesEnabled: (enabled: boolean, personId: Nullable<string>): void => {
      dispatch(putSleepLogDeviceTimesEnabledThunk(enabled, personId || ''));
    },
    updateSleepLogAutoSubmissionEnabled: (enabled: boolean, personId: Nullable<string>): void => {
      dispatch(putEnableSleepLogAutoSubmissionThunk(enabled, personId || ''));
    },
    updateLunaSleepLogSummariesEnabled: (enabled: boolean, personId: Nullable<string>): void => {
      dispatch(putEnableLunaSleepLogSummariesThunk(enabled, personId || ''));
    },
    updateLunaSleepScheduleSummariesEnabled: (enabled: boolean, personId: Nullable<string>): void => {
      dispatch(putEnableLunaSleepScheduleSummariesThunk(enabled, personId || ''));
    },
    updateLunaWeeklySummariesEnabled: (enabled: boolean, personId: Nullable<string>): void => {
      dispatch(putEnableLunaWeeklySummariesThunk(enabled, personId || ''));
    },
    updateChatBotEnabled: (enabled: boolean, personId: Nullable<string>, chatChannelType: ChatChannelType): void => {
      dispatch(putEnableChatBotThunk(enabled, personId || '', chatChannelType));
    },

    updateMobileCustomSleepStoriesEnabled: (enabled: boolean, personId: Nullable<string>): void => {
      dispatch(putEnableMobileCustomSleepStoriesThunk(enabled, personId || ''));
    },
    updateEnableDeviceTraining: (enabled: boolean, personId: Nullable<string>): void => {
      dispatch(putEnableDeviceTrainingThunk(enabled, personId || ''));
    },
    updateShowSleepFeatures: (enabled: boolean, personId: Nullable<string>): void => {
      dispatch(putShowSleepFeaturesThunk(enabled, personId || ''));
    },
    updateSleepScheduleEnabled: (enabled: boolean, personId: Nullable<string>): void => {
      dispatch(putEnableSleepScheduleThunk(enabled, personId || ''));
    },
    calculateSleepSchedule: (personId: Nullable<string>, date: Moment, request: SleepCalculationRequest): Promise<any> => {
      return dispatch(calculateSleepScheduleThunk(personId || '', date, request));
    },
    saveSleepScheduleAndCalculation: (personId: Nullable<string>, sleepCalculation: SleepScheduleCalculationRequest): Promise<any> => {
      return dispatch(saveSleepScheduleAndCalculationThunk(personId || '', sleepCalculation));
    },
    clearSleepCalculation: (): void => {
      dispatch(clearSleepCalculationAction(false));
    },
    sendPushNotifications: (personIds: string[], title: string, body: string): void => {
      dispatch(sendPushNotificationsThunk(personIds, title, body));
    },
    sendAdHocContent: (adHocContentRequest: AdHocContentRequest, proceedToPushNotification?: Nullable<boolean>): void => {
      dispatch(getCreateAdHocBatchContentThunk(adHocContentRequest, proceedToPushNotification));
    },
    saveIsiScores: (isiScores: PersonIsiScore): void => {
      dispatch(saveIsiScoresThunk(isiScores));
    },
    saveOrder: (personOrder: PersonOrder): void => {
      dispatch(saveOrderThunk(personOrder));
    },
    saveShipment: (deviceShipment: DeviceShipment): void => {
      dispatch(saveShipmentThunk(deviceShipment));
    },
    updateEsId: (personId: Nullable<string>, deviceId: string, esId?: Nullable<string>): void => {
      dispatch(putEsIdThunk(personId || '', deviceId, esId));
    },
    saveFlagComment: (personWorkflowComment: PersonWorkflowComment): void => {
      dispatch(saveWorkflowCommentThunk(personWorkflowComment));
    },
    saveFlagTaskStatus: (personWorkflowTaskStatus: PersonWorkflowTaskStatus): void => {
      dispatch(savePersonTaskStatusThunk(personWorkflowTaskStatus));
    },
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]): void => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    saveFlag: (flag: WorkflowTemplate, personId: Nullable<string>): void => {
      dispatch(saveFlagContentThunk(flag, personId || ''));
    },
    clearPersonResponseStatus: (): void => {
      dispatch(clearPersonResponseStatusAction());
    },
    clearContentResponseStatus: (): void => {
      dispatch(getClearResponseStatusAction());
    },
    clearResponseStatusSleepScheduleAction: (): void => {
      dispatch(getClearResponseStatusSleepScheduleAction());
    },
    fetchPerson: getPersonByIdThunk
  })
);

export default compose(connectRedux)(UserDetails) as React.ComponentType;
