import IconButton from '@material-ui/core/IconButton';
import React, { MouseEventHandler } from 'react';
import { SvgIcon } from '@material-ui/core';
import { defaultPrimaryColor } from '../../utils/styles';

interface Props {
  asButton?: Nullable<boolean>;
  buttonSize?: Nullable<'small' | 'medium'>;
  buttonClass?: string; //external component does not support nullable types, so we can't apply Nullable<> type here
  onClick?: Nullable<MouseEventHandler>;
}

export const CopyIcon: React.FC<Props> = ({ asButton, buttonSize, buttonClass, onClick }) => {
  const svgIcon = (): JSX.Element => (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
      <title>Duplicate</title>
      <rect x="128" y="128" width="336" height="336" rx="57" ry="57" fill="none" stroke={defaultPrimaryColor} strokeLinejoin="round" strokeWidth="32" />
      <path
        d="M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24M296 216v160M376 296H216"
        fill="none"
        stroke={defaultPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
      />
    </SvgIcon>
  );

  if (asButton) {
    return (
      //@ts-expect-error: TODO add description
      <IconButton size={buttonSize ? buttonSize : 'medium'} className={buttonClass} onClick={onClick}>
        {svgIcon()}
      </IconButton>
    );
  }
  return svgIcon();
};
