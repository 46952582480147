import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Grid, Hidden, MuiThemeProvider, Snackbar } from '@material-ui/core';
import { defaultTheme } from '../../../../utils/styles';
import style from './DualPane.scss';
import createFrontEndError, { clarifyErrorMessage } from '../../../../utils/createFrontEndError';
import { cameFromStripe, getEmailParam, isExternalUrl } from '../../../../utils/navigationUtils';
import ReactGA from 'react-ga4';
import { AnalyticsEvents, FbPixelCustomEventType, findAnalyticsEvents, PageType, SubmitAnalyticsType } from '../../../../redux/analytics/analyticsTypes';
import { sendFacebookPixel } from '../../../../redux/analytics/analyticsThunks';
import { Error } from '../../../../redux/common/types';
import { useNavigate, useLocation } from 'react-router-dom';

interface Props {
  leftPane: JSX.Element;
  rightPane: (loadingState: [boolean, Dispatch<SetStateAction<boolean>>], handleExternalRouting: (url?: Nullable<string>) => void, handleError: (err: any) => void) => JSX.Element;
  pageLeft: Function;
  pageType: PageType;
  gaParams?: Nullable<any>;
}

export const DualPane: React.FC<Props> = ({ leftPane, rightPane, pageLeft, pageType, gaParams }) => {
  const [errorSaving, setErrorSaving] = useState<string>('');
  const loadingState: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    loadingState[1](false);
    if (gaParams) {
      handleAnalytics(SubmitAnalyticsType.ON_ENTER);
    }
  }, [gaParams]);

  const handleExternalRouting = async (url?: Nullable<string>) => {
    handleAnalytics(SubmitAnalyticsType.ON_EXIT);
    if (url) {
      if (!isExternalUrl(url)) {
        navigate(url);
      } else {
        try {
          await pageLeft(pageType);
          window.location.href = url;
        } catch (err) {
          handleError(err);
        }
      }
    }
  };

  const cameWithEmail = (): boolean => !!getEmailParam(location);
  const isValidCustomer = (): boolean => cameFromStripe(location) || cameWithEmail();

  const handleAnalytics = (analyticsStrategy: SubmitAnalyticsType) => {
    if (isValidCustomer()) {
      if (analyticsStrategy === SubmitAnalyticsType.ON_ENTER) {
        sendFacebookPixel(FbPixelCustomEventType.PAGE_VIEW);
      }
      const events: Nullable<AnalyticsEvents> = findAnalyticsEvents(pageType);
      if (events?.submitAnalytics === analyticsStrategy) {
        if (events?.gaEvent) {
          ReactGA.event(events.gaEvent, !gaParams ? {} : gaParams);
        }
        if (events?.fbEvent) {
          sendFacebookPixel(events.fbEvent);
        }
      }
    }
  };

  const handleError = (err: any): void => {
    const error: Error = createFrontEndError(err);
    const message: string = clarifyErrorMessage(error);
    setErrorSaving(() => message);
    loadingState[1](() => false);
  };

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Grid container>
          {/* @ts-expect-error: TODO add description */}
          <Hidden smDown>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container>
                <Grid className={style.imageGrid} item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {leftPane}
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid container justifyContent="center" alignItems="center" item xs={12} sm={12} md={6} lg={6} xl={6}>
            {rightPane(loadingState, handleExternalRouting, handleError)}
          </Grid>
        </Grid>
        <Snackbar
          ContentProps={{ className: style.snackBar }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={!!errorSaving}
          autoHideDuration={5000}
          onClose={() => setErrorSaving('')}
          message={errorSaving}
        />
      </div>
    </MuiThemeProvider>
  );
};
