import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DeviceContent } from '../../../../redux/content/contentTypes';
import { Backdrop, Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { ContentSubmitter } from '../../../ContentSubmitter/ContentSubmitter';
import style from './AdHocTab.scss';
import { SelectButton } from '../../../../components/SelectButton/SelectButton';
import { contentDisplayName } from '../../../../utils/content';
import { PushNotificationsLog } from '../PushNotificationsLog/PushNotificationsLog';
import { ClientDevice, PushNotification } from '../../../../redux/clientDevice/clientDeviceTypes';

export const AdHocTab: React.FC<{
  sendPushNotifications: Function;
  sendAdHocContent: Function;
  clearSubmissionResponse: () => void;
  getPushNotifications: Function;
  deviceContent?: Nullable<DeviceContent[]>;
  snsError?: Nullable<string>;
  adHocError?: Nullable<string>;
  batchLoading?: Nullable<boolean>;
  batchSuccess?: Nullable<boolean>;
  personId: Nullable<string>;
  pushNotificationsById: Record<string, PushNotification>;
  clientDevicesById: Record<string, ClientDevice>;
}> = ({
  sendPushNotifications,
  sendAdHocContent,
  clearSubmissionResponse,
  getPushNotifications,
  deviceContent,
  snsError,
  adHocError,
  batchLoading,
  batchSuccess,
  personId,
  pushNotificationsById,
  clientDevicesById
}) => {
  const [displaySns, setDisplaySns] = useState<boolean>(false);
  const [displayAdHoc, setDisplayAdHoc] = useState<boolean>(false);

  const toggleDisplay = (showAdHoc?: Nullable<boolean>, showSns?: Nullable<boolean>): void => {
    setDisplayAdHoc(() => showAdHoc || false);
    setDisplaySns(() => showSns || false);
  };

  const updateSuccess = (): boolean => !batchLoading && !!batchSuccess && !adHocError && !snsError;
  const updateFailed = (): boolean => !batchLoading && !batchSuccess && (!!adHocError || !!snsError);

  useEffect(() => {
    if (batchSuccess && !batchLoading) {
      getPushNotifications(personId);
    }
  }, [batchSuccess]);

  const expandedSnsMenu = (
    adHocContentId: string,
    pushNotificationTitleState: [string, Dispatch<SetStateAction<string>>],
    pushNotificationBodyState: [string, Dispatch<SetStateAction<string>>],
    adHocPushNotificationTitleState: [string, Dispatch<SetStateAction<string>>],
    adHocPushNotificationBodyState: [string, Dispatch<SetStateAction<string>>],
    onSelectContent: (deviceContent: Nullable<DeviceContent>) => void,
    onCreateAdHocContent: () => Promise<any>,
    onSendPushNotification: () => Promise<any>
  ): JSX.Element => {
    return (
      <React.Fragment>
        {displaySns && (
          <Grid className={style.center} container>
            <Grid item className={style.gridLine} xs={12}>
              <TextField
                className={style.contentInput}
                required
                placeholder="Push Notification Title (Required, max: 65 chars)"
                label="Title"
                variant="outlined"
                inputProps={{ maxLength: 65 }}
                value={pushNotificationTitleState[0]}
                onChange={(e) => pushNotificationTitleState[1](e.target.value)}
              />
            </Grid>
            <Grid item className={style.gridLine} xs={12}>
              <TextField
                className={style.contentInput}
                multiline
                rows={2}
                placeholder="Push Notification Body (max: 240 chars)"
                label="Body"
                variant="outlined"
                inputProps={{ maxLength: 240 }}
                value={pushNotificationBodyState[0]}
                onChange={(e) => pushNotificationBodyState[1](e.target.value)}
              />
            </Grid>
            <Grid item className={style.gridLine} xs={12}>
              <Button variant="contained" color="primary" disabled={!pushNotificationTitleState[0]} onClick={() => onSendPushNotification()}>
                Send Push Notification
              </Button>
            </Grid>
          </Grid>
        )}
        {displayAdHoc && (
          <Grid className={style.center} container>
            <Grid item className={style.gridLine} xs={12}>
              <SelectButton
                options={deviceContent?.map((deviceContent) => contentDisplayName(deviceContent)) || []}
                activeIndex={-1}
                customWidth={'250px'}
                placeholderOption={'Content (Required)'}
                onClick={(selectedIndex) => onSelectContent(deviceContent?.[selectedIndex])}
                disableConfirmation={true}
                label={'Content'}
              />
            </Grid>
            <Grid item className={style.gridLine} xs={12}>
              <TextField
                className={style.contentInput}
                placeholder="Push Notification Title (Optional)"
                label="Push Notification Title"
                variant="outlined"
                inputProps={{ maxLength: 65 }}
                value={adHocPushNotificationTitleState[0]}
                onChange={(e) => adHocPushNotificationTitleState[1](e.target.value)}
              />
            </Grid>
            <Grid item className={style.gridLine} xs={12}>
              <TextField
                className={style.contentInput}
                multiline
                rows={2}
                placeholder="Push Notification Body (Optional)"
                label="Push Notification Body"
                variant="outlined"
                inputProps={{ maxLength: 240 }}
                value={adHocPushNotificationBodyState[0]}
                onChange={(e) => adHocPushNotificationBodyState[1](e.target.value)}
              />
            </Grid>
            <Grid item className={style.gridLine} xs={12}>
              <Button variant="contained" color="primary" disabled={!adHocContentId} onClick={() => onCreateAdHocContent()}>
                Create Ad-Hoc Content
              </Button>
            </Grid>
          </Grid>
        )}
        <Backdrop className={style.backdrop} open={batchLoading || false}>
          <CircularProgress />
        </Backdrop>
      </React.Fragment>
    );
  };

  return (
    <Box mt={3} minHeight="100vh">
      <Grid container className={style.center}>
        <Grid item className={style.gridLine} xs={12}>
          <SelectButton
            options={['Push Notification', 'Ad Hoc Content']}
            activeIndex={-1}
            customWidth={'250px'}
            placeholderOption={'Select Submission Type'}
            onClick={(selectedIndex) => toggleDisplay(selectedIndex === 1, selectedIndex == 0)}
            disableConfirmation={true}
            revertToInitialState={updateSuccess() && !updateFailed()}
            label={'Submission Type'}
          />
        </Grid>
      </Grid>
      <ContentSubmitter
        personIdsSet={new Set<string>([personId || ''])}
        sendPushNotifications={sendPushNotifications}
        sendAdHocContent={sendAdHocContent}
        clearSubmissionResponse={clearSubmissionResponse}
        toggleDisplay={toggleDisplay}
        submitSuccessful={updateSuccess()}
        submitFailed={updateFailed()}
        errorMessage={snsError || adHocError}
        createJsx={expandedSnsMenu}
      />
      <PushNotificationsLog pushNotificationsById={pushNotificationsById} clientDevicesById={clientDevicesById} />
    </Box>
  );
};
