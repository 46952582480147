import { ClientDevice, ClientDevicePushNotificationsResponse, ClientDeviceState, PushNotification } from './clientDeviceTypes';
import { ReduxState } from '../types';

const getClientDeviceState = (state: ReduxState): ClientDeviceState => {
  return state.clientDevice;
};

export const getClientDeviceIdSelector = (clientDeviceState: ClientDeviceState): Nullable<string> => {
  return clientDeviceState?.clientDeviceId;
};

export const getTimezoneSelector = (clientDeviceState: ClientDeviceState) => {
  return clientDeviceState && clientDeviceState.timeZoneId;
};

export const getPushNotificationsByIdSelector = (state: ReduxState): Record<string, PushNotification> => {
  return getClientDeviceState(state).pushNotificationsById;
};

export const getClientDevicesByIdSelector = (state: ReduxState): Record<string, ClientDevice> => {
  return getClientDeviceState(state).clientDevicesById;
};
