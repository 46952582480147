import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PageType } from '../../redux/analytics/analyticsTypes';
import { pageEnteredThunk, pageLeftThunk } from '../../redux/analytics/analyticsThunks';

interface Props {
  pageEntered: Function;
  pageLeft: Function;
}

const withAnalyticsPageEvents = (pageType: PageType) => (PageComponent) => {
  const Page: React.FC<Props> = (props) => {
    useEffect(() => {
      props.pageEntered();

      return () => {
        props.pageLeft();
      };
    }, []);

    return <PageComponent {...props} />;
  };

  return connect(null, (dispatch: Function) => ({
    pageEntered: () => dispatch(pageEnteredThunk(pageType)),
    pageLeft: () => dispatch(pageLeftThunk(pageType))
  }))(Page);
};

export default withAnalyticsPageEvents;
