import { Container, MuiThemeProvider } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { useAppDispatch } from '../../hooks';
import { getLatestUnreadSleepCoachMessagesSelector } from '../../redux/chat/chatSelectors';
import { getLatestUnreadSleepCoachMessagesThunk } from '../../redux/chat/chatThunks';
import { BreadCrumbData } from '../../redux/common/types';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '../../redux/oauth/oauthSelectors';
import { getUserPermissionsSelector, isLoadingUserPermissionsSelector } from '../../redux/person/personSelector';
import { getUserPermissionsThunk } from '../../redux/person/personThunks';
import { PERMISSION_ROLE_ID_ADMIN, PERMISSION_ROLE_ID_AGENT } from '../../redux/person/personTypes';
import { defaultTheme } from '../../utils/styles';
import { defaultUnreadSleepCoachMessagesBreadCrumb, defaultUnreadSleepCoachMessagesBreadCrumbs } from '../../utils/breadcrumbs';
import { goTo } from '../../utils/util';
import style from './UnreadSleepCoachMessages.scss';
import ChatsList from './components/ChatsList/ChatsList';

const UnreadSleepCoachMessages: React.FC = () => {
  const breadCrumbs = useSelector(getBreadcrumbsSelector);
  const unreadSleepCoachChatMessages = useSelector(getLatestUnreadSleepCoachMessagesSelector);
  const userPermissions = useSelector(getUserPermissionsSelector);
  const isLoadingUserPermissions = useSelector(isLoadingUserPermissionsSelector);

  const dispatch = useAppDispatch();
  const appendBreadCrumbs = (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
    dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
  };

  const isAdminOrAgent = !!userPermissions?.find((p) => p.permissionRoleTypeId === PERMISSION_ROLE_ID_ADMIN || p.permissionRoleTypeId === PERMISSION_ROLE_ID_AGENT);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserPermissionsThunk());
  }, []);

  useEffect(() => {
    if (isAdminOrAgent) {
      dispatch(getLatestUnreadSleepCoachMessagesThunk());
    }
  }, [isAdminOrAgent]);

  const openPersonChat = (personId: string) => goTo(`/user/${personId}/sleepCoachChat`, navigate)();

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            breadCrumbs={breadCrumbs}
            title="New Sleep Coach Chats"
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultUnreadSleepCoachMessagesBreadCrumb}
            defaultCrumbHistory={defaultUnreadSleepCoachMessagesBreadCrumbs}
          />
          <ChatsList
            unreadSleepCoachChatMessages={unreadSleepCoachChatMessages}
            openPersonChat={openPersonChat}
            showAccessNotAllowed={isLoadingUserPermissions ? false : !isAdminOrAgent}
          />
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

export default UnreadSleepCoachMessages;
