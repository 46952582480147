import React from 'react';

import { SleepScheduleDetails } from '../../components/SleepScheduleDetails/SleepScheduleDetails';
import { Person } from '../../redux/person/personTypes';
import { SleepSchedule } from '../../redux/sleepschedule/sleepScheduleTypes';
interface Props {
  sleepScheduleById: Record<string, SleepSchedule>;
  newSleepScheduleId?: Nullable<string>;
  oldSleepScheduleId?: Nullable<string>;
  person: Person;
  putSleepScheduleEditable: (sleepScheduleId: string) => Promise<any>;
  postSleepScheduleSend: (sleepSchedule: SleepSchedule) => Promise<any>;
  updateSleepScheduleLunaSummary: (SleepSchedule: SleepSchedule, updateWeeklySummary: boolean) => Promise<any>;
  currentIndex: number;
  isLastItem: boolean;
  updateIndex: (index: number) => void;
}

export const SleepScheduleDetailsDrawer: React.FC<Props> = ({
  newSleepScheduleId,
  oldSleepScheduleId,
  sleepScheduleById,
  person,
  putSleepScheduleEditable,
  postSleepScheduleSend,
  updateSleepScheduleLunaSummary,
  updateIndex,
  currentIndex,
  isLastItem
}) => {
  return (
    <SleepScheduleDetails
      newSleepScheduleId={newSleepScheduleId}
      oldSleepScheduleId={oldSleepScheduleId}
      sleepScheduleById={sleepScheduleById}
      person={person}
      putSleepScheduleEditable={putSleepScheduleEditable}
      updateSleepScheduleLunaSummary={updateSleepScheduleLunaSummary}
      postSleepScheduleSend={postSleepScheduleSend}
      currentIndex={currentIndex}
      isLastItem={isLastItem}
      updateIndex={updateIndex}
    />
  );
};
