import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  asButton?: Nullable<boolean>;
  buttonSize?: Nullable<'small' | 'medium'>;
  style?: React.CSSProperties; //external component does not support nullable types, so we can't apply Nullable<> type here
}

export const MilestoneFilledIcon: React.FC<Props> = ({ asButton, buttonSize, style }) => {
  const svgIcon = (): JSX.Element => (
    <SvgIcon width="17" height="17" viewBox="0 0 17 17" fill="none" style={style}>
      <path
        d="M6.58279 0.871699C7.44504 0.00948697 8.84298 0.00948679 9.70523 0.871698L15.4146 6.58086C16.277 7.44312 16.277 8.84118 15.4146 9.70344L9.70523 15.4126C8.84298 16.2748 7.44504 16.2748 6.58279 15.4126L0.873374 9.70344C0.011069 8.84118 0.0110686 7.44312 0.873374 6.58086L6.58279 0.871699Z"
        fill="#6361E1"
      />
    </SvgIcon>
  );

  if (asButton) {
    return <IconButton size={buttonSize ? buttonSize : 'medium'}>{svgIcon()}</IconButton>;
  }
  return svgIcon();
};
