import { DateTime } from 'luxon';
import { CareFeedItem, Measurement, MetricIDs, TriggerIDs, UnitIDs } from '../redux/carePlan/carePlanTypes';
import { DeviceContent, DeviceContentPage, DeviceContentType, SurveyOption, SurveyOptionRange, SurveyOptionType, SurveyType } from '../redux/content/contentTypes';
import { normalizeBooleanValue } from './content';
import { tsAsTimeSinceUnit } from './time';
import { isEmpty, transferKgToLbs } from './util';
import { QuesitonIconType } from '../components/QuestionIcon/QuestionIcon';

export const isChartMetric = (metricId: MetricIDs) => [MetricIDs.GLUCOSE_LEVEL, MetricIDs.HEART_RATE, MetricIDs.WEIGHT, MetricIDs.STRESS_LEVEL].includes(metricId);

export const roundToOneDecimal = (num: number): number => Math.round(num * 10) / 10;

export const patientsTableTitle = {
  [MetricIDs.HEART_RATE]: 'Heart rate',
  [MetricIDs.HEART_RATE_MEASURED_BY_USER]: 'Heart rate measured by user',
  [MetricIDs.WEIGHT]: 'Weight',
  [MetricIDs.WEIGHT_MEASURED_BY_USER]: 'Weight measured by user',
  [MetricIDs.GLUCOSE_LEVEL]: 'Glucose level',
  [MetricIDs.GLUCOSE_LEVEL_MEASURED_BY_USER]: 'Glucose level measured by user',
  [MetricIDs.WALK_ACTIVITY]: 'Walk activity',
  [MetricIDs.MEDICATION_REMINDER]: 'Medication reminder',
  [MetricIDs.CHF_BREATHING_LYING_DOWN]: 'Breathing lying down',
  [MetricIDs.CHF_CHEST_PAIN]: 'Chest pain',
  [MetricIDs.CHF_LIGHT_HEADED]: 'Light headed',
  [MetricIDs.CHF_SHORT_OF_BREATH]: 'Short of breath',
  [MetricIDs.CHF_NAUSEOUS]: 'Nauseous',
  [MetricIDs.CHF_SWELLING_FEET]: 'Swelling feet',
  [MetricIDs.CHF_SWELLING_LEGS]: 'Swelling legs',
  [MetricIDs.WEIGHT_EDUCATION_OPEN_QUESTION]: 'Weight education open question',
  [MetricIDs.MEDICATION_SET_REMINDER_QUESTION]: 'Medication reminder set',
  [MetricIDs.COACHING_CALL]: 'Monthly coaching call confirmed',
  [MetricIDs.STRESS_LEVEL]: 'Stress level',
  [MetricIDs.HEALTH_COACH_CONCERNS]: 'Health coach concerns'
};

export const carePlanTableDateFormat = 'MMM dd';

export const getNumberValueFromMeasurement = (metricId: MetricIDs, measurement: Measurement): number | undefined => {
  let value = measurement?.floatValue || measurement?.intValue;
  const weightUnitId = measurement.unitId;
  if (weightUnitId === UnitIDs.KG) {
    value = value && transferKgToLbs(value);
  }
  if (metricId === MetricIDs.WEIGHT) {
    value = value && roundToOneDecimal(value);
  }
  return value;
};

export const isOutOfSurveyRange = (metricId: MetricIDs, personLatestMeasurements?: Record<MetricIDs, Measurement[]>, surveyRange?: SurveyOptionRange): boolean => {
  const value = getLatestNumberValueByMetricId(metricId, personLatestMeasurements);
  if (surveyRange && !isEmpty(value)) {
    const min = surveyRange.minFloatValue;
    const max = surveyRange.maxFloatValue;
    if ((!isEmpty(min) && value! < min) || (!isEmpty(max) && value! > max)) {
      return true;
    }
  }
  return false;
};

export const getLatestNumberValueByMetricId = (metricId: MetricIDs, personLatestMeasurements: Record<MetricIDs, Measurement[]> | undefined): number | undefined => {
  const personLatestMeasurement = personLatestMeasurements && personLatestMeasurements[metricId] && personLatestMeasurements[metricId][0];

  return personLatestMeasurement && getNumberValueFromMeasurement(metricId, personLatestMeasurement);
};

export const getLatestBoolValueByMetricId = (metricId: MetricIDs, personLatestMeasurements: Record<MetricIDs, Measurement[]> | undefined): string | undefined => {
  const personLatestMeasurement = personLatestMeasurements && personLatestMeasurements[metricId] && personLatestMeasurements[metricId][0];

  return normalizeBooleanValue(personLatestMeasurement?.boolValue);
};

export const getLatestStringValueByMetricId = (metricId: MetricIDs, personLatestMeasurements: Record<MetricIDs, Measurement[]> | undefined): string | undefined => {
  const personLatestMeasurement = personLatestMeasurements && personLatestMeasurements[metricId] && personLatestMeasurements[metricId][0];

  return personLatestMeasurement?.stringValue;
};

export const getLatestCreatedTsByMetricId = (
  metricId: MetricIDs,
  timeZoneId: Nullable<string>,
  personLatestMeasurements: Record<MetricIDs, Measurement[]> | undefined
): string | undefined => {
  const personLatestMeasurement = personLatestMeasurements && personLatestMeasurements[metricId] && personLatestMeasurements[metricId][0];
  const created = personLatestMeasurement?.created;
  return timeZoneId && personLatestMeasurements ? tsAsTimeSinceUnit(created, timeZoneId) : undefined;
};

export const getTriggerTitle = (afterTime: string, triggerId: string): string => {
  const dateTime = DateTime.fromISO(afterTime);
  const formattedTime = dateTime.toFormat('h:mm a');
  const triggerLabel = {
    [TriggerIDs.IN_BED]: 'when in bed',
    [TriggerIDs.OUT_BED]: 'when near device' // For now we use this label for the out of bed trigger
  };

  return `${formattedTime}, ${triggerLabel[triggerId]}`;
};

export const getQuestionIconType = (deviceContentPage: Nullable<DeviceContentPage>, deviceContentType?: DeviceContentType): QuesitonIconType => {
  const surveyQuestion = deviceContentPage?.surveyQuestion;
  const surveyOptionsWithoutSkip = surveyQuestion?.surveyOptions?.filter((surveyOption: SurveyOption) => surveyOption.optionType !== SurveyOptionType.SKIP);
  const numSurveyOptions = surveyOptionsWithoutSkip?.length ?? 0;
  const firstSurveyOption = surveyOptionsWithoutSkip && numSurveyOptions > 0 ? surveyOptionsWithoutSkip[0] : null;
  const secondSurveyOption = surveyOptionsWithoutSkip && numSurveyOptions > 1 ? surveyOptionsWithoutSkip[1] : null;

  if (deviceContentType === DeviceContentType.CARE_ASSESSMENT) {
    return 'assesment';
  } else if (firstSurveyOption?.optionType === SurveyOptionType.FLOAT || firstSurveyOption?.optionType === SurveyOptionType.INTEGER) {
    if (secondSurveyOption?.optionType !== SurveyOptionType.FLOAT && secondSurveyOption?.optionType !== SurveyOptionType.INTEGER) {
      return 'number';
    } else {
      return 'multiNumbers';
    }
  } else if (firstSurveyOption?.optionType === SurveyOptionType.BOOLEAN) {
    return 'yesNo';
  } else if (firstSurveyOption?.optionType === SurveyOptionType.STRING) {
    return 'openQuestion';
  } else if (firstSurveyOption?.optionType === SurveyOptionType.FIXED_VALUE) {
    return 'optionSet';
  }

  return 'openQuestion';
};

/**
 * Determines if an activity is in the past based on its scheduled start time.
 * The activity is considered past due if the current time is after the end of the day
 * on which the activity is scheduled to start, taking the provided timezone into account.
 */
export const isActivityPastDue = (careFeedItem: CareFeedItem, timeZoneId: string): boolean => {
  const currentTimeMs = DateTime.now().toMillis();
  const activityStartTimeMs = careFeedItem?.afterTs && DateTime.fromISO(careFeedItem.afterTs).toMillis();
  if (!activityStartTimeMs) return false;
  const activityDayStart = DateTime.fromMillis(activityStartTimeMs).setZone(timeZoneId).startOf('day');
  const activityDayEndMs = activityDayStart.plus({ days: 1 }).toMillis();

  return currentTimeMs > activityDayEndMs;
};

/**
 * Checks if the activity has expired based on its expiration time.
 * The activity is considered expired if the current time is after the expiration time
 */
export const isActivityExpired = (careFeedItem: CareFeedItem, dateStartMs: number): boolean => {
  const currentTimeMs = DateTime.now().toMillis();
  const expirationTimeMs = careFeedItem?.expirationTs && DateTime.fromISO(careFeedItem.expirationTs).toMillis();
  const isExpired = !!expirationTimeMs && expirationTimeMs <= currentTimeMs;

  return isExpired;
};

/**
 * Checks if the activity has been completed.
 * The activity is considered completed if it has a valid completion timestamp (`completedTs`).
 */
export const isActivityCompleted = (careFeedItem: CareFeedItem): boolean => {
  const isCompleted = !!careFeedItem?.completedTs;
  return isCompleted;
};

/**
 * Checks if the activity has been skipped.
 * The activity is considered skipped if any of the survey options in the device content pages
 * has a response type of `SurveyOptionType.SKIP`.
 *
 * The function iterates through the `deviceContentPages` and their associated survey questions and options
 * to determine if a "skip" response exists. If one question is skipped, the entire activity is marked as skipped.
 */
export const isActivitySkipped = (deviceContent?: DeviceContent): boolean => {
  const deviceContentPages = deviceContent?.deviceContentPages;
  if (deviceContentPages) {
    for (let i = 0; i < deviceContentPages.length; i++) {
      const deviceContentPage = deviceContentPages[i];
      const surveyOptions = deviceContentPage?.surveyQuestion?.surveyOptions;
      if (surveyOptions) {
        for (let j = 0; j < surveyOptions.length; j++) {
          const surveyOption = surveyOptions[j];
          const surveyResponseOptionType = surveyOption.surveyResponse?.surveyOption;
          if (surveyResponseOptionType === SurveyOptionType.SKIP) {
            return true; // The entire activity is considered skipped if one question is skipped.
          }
        }
      }
    }
  }

  return false;
};
