import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ClientOnly from './components/ClientOnly/ClientOnly';
import { routesForAuthenticatedOnly, routesForAuthenticatedOnlyNewDashboard, routesForNotAuthenticatedOnly, routesForPublic } from './navigation/routes';
import { getAuthPersonIdSelector } from './redux/oauth/oauthSelectors';
import { getPersonByIdSelector } from './redux/person/personSelector';
import { theme } from './utils/styles';

const FULLSLEEP_ORG_ID = '00000000-0000-0000-0000-000000000001';

const isNewDashboardUser = (orgId: Nullable<string>): boolean => !!orgId && orgId !== FULLSLEEP_ORG_ID;

export default function App() {
  const authPersonId = useSelector(getAuthPersonIdSelector);
  const personById = useSelector(getPersonByIdSelector);
  const authOrgId = personById && authPersonId ? personById[authPersonId]?.organizationId : undefined;
  const isNewDashboard = isNewDashboardUser(authOrgId);

  const routes = [...routesForPublic, ...routesForNotAuthenticatedOnly, ...(isNewDashboard ? routesForAuthenticatedOnlyNewDashboard : routesForAuthenticatedOnly)];
  const router = createBrowserRouter(routes);

  return (
    <ThemeProvider theme={theme}>
      <ClientOnly>
        <RouterProvider router={router} />
      </ClientOnly>
    </ThemeProvider>
  );
}
