import { Error } from '../common/types';
import { LocationType, Person, SensorPerson } from '../person/personTypes';
import { EnvType } from '../../utils/environment';

export interface Device {
  id: string;
  serialNumber: string;
  deviceNumber: number;
  clientDeviceId: string;
  deviceType: string;
  hwRevision: Nullable<HwRevision>;
  wifiId: string;
  startDate: string;
  endDate: string;
  connectivityNotification: boolean;
  latestFactoryResetTs: string;
  created: string;
  modifiedDate: string;
}

export interface SensorDevice {
  id: string;
  sensorId: string;
  deviceId: string;
  locationId?: Nullable<string>;
  startDate: string;
  endDate?: Nullable<string>;
  isCoupleSleeper?: Nullable<boolean>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface UpdateRemoteCommandPortSuccessPayload {
  deviceId: string;
  port: number;
}

export interface AutoUpdateSuccessPayload {
  deviceId: string;
  autoUpdateDisabled: boolean;
}

export interface PutConnectivityNotificationSuccessPayload {
  deviceId: string;
  connectivityNotification: boolean;
}

export interface UnlinkDeviceSuccessPayload {
  deviceId: string;
  personId: string;
}

export enum DeviceLoadingTags {
  GET_DEVICE_VERSION_HISTORY = 'GET_DEVICE_VERSION_HISTORY'
}

export interface DeviceState {
  // state of device redux data
  readonly isLoading: Record<string, boolean>;
  readonly error: Record<string, Error>;
  readonly success: Record<string, boolean>;
  readonly devicePerson: DevicePersonRecord;
  selectedDevicePerson: DevicePerson | undefined;
  readonly deviceById: Record<string, Device>;
  deviceVersionsByDeviceId: Record<string, DeviceVersion[]>;
  readonly sensorDeviceById: Record<string, SensorDevice>;
  readonly locationById: Record<string, Location>;
  readonly deviceRemoteCommandById: Record<string, DeviceRemoteCommand>;
  readonly deviceConfigById: Record<string, DeviceConfig>;
  readonly deviceProvisioningById: Record<string, DeviceProvisioning>;
  readonly deviceStateById: Record<string, DeviceStateEntry>;
  readonly firmwareVersionById: Record<string, FirmwareVersion>;
  readonly firmwareVersionRecentById: Record<string, FirmwareVersion>;
  readonly deviceConnectivityById: Record<string, DeviceConnectivity>;
  readonly hwRevisionFirmwareMappings: HwRevisionFirmware[];
  kokoMapDeviceConnectivityIds: string[];
  successMessage?: Nullable<string>;
}

export interface DeviceStateEntry {
  // "state" refers to the state of the physical device
  id: string;
  softwareVersion: string;
  swVersionStartDateTs: string;
  firmwareVersion: string;
  firmwareVersionUpdate: string;
  firmwareVersionUpdateStatus: DeviceVersionUpdateStatus;
  hwRevision: Nullable<HwRevision>;
  osLockStatus: DeviceLockStatus;
  autoUpdateDisabled: boolean;
  healthStatus: DeviceHealthStatus;
  connectivityStatus: DeviceConnectivityStatus;
  healthStatusTimestamp: string;
  connectivityStatusTimestamp: string;
  deviceComponentStates: DeviceComponentStates;
  fitbitId?: Nullable<string>;
  fitbitAuthToken?: Nullable<string>;
  kobeId?: Nullable<string>;
  museId?: Nullable<string>;
  hexoId?: Nullable<string>;
  deviceSleeperType?: Nullable<DeviceSleeperType>;
}

export interface DeviceAntenna {
  deviceId: string;
  antennaIdx: number;
  serialNumber: number;
  revision: string;
}

export interface DeviceRequest {
  deviceId: string;
  deviceNumber?: Nullable<number>;
  hwRevision: HwRevision;
  deviceType: DeviceType;
  keyId: string;
  keyType: string;
  publicKey: string;
  firmwareVersion?: Nullable<string>;
  softwareVersion?: Nullable<string>;
  userAgent?: Nullable<string>;
}

export interface DeviceProvisioning {
  id: string;
  serialNumber: string;
  versionNumber: string;
  hwRevision: HwRevision;
  userAgent: string;
  keyId: string;
  keyType: AuthKeyType;
  key: string;
  ipAddress: string;
  environmentType: EnvType;
  deviceNumber?: Nullable<number>;
  created: string;
  modifiedDate: string;
}

export interface DeviceRemoteCommand {
  id: string;
  remoteCommand: string;
  port: number;
  moved: boolean;
  deviceNumber?: Nullable<number>;
  created: string;
  modifiedDate: string;
}

export interface DeviceConfig {
  id: string;
  ledsEnabled: boolean;
  micEnabled: boolean;
  created: string;
  modifiedDate: string;
}

export interface DeviceDiagnosticState {
  healthStatus: string;
  stateTimestamp: number;
}

export interface SemanticVersion {
  major: number;
  minor: number;
  patch: number;
  build: number;
}

export interface MainAppDiagnostics {
  diagnosticState: DeviceDiagnosticState;
  version: SemanticVersion;
  restarts: number;
  runTimestamp: number;
  lastButtonPressed: number;
  lastButtonPressedTimestamp: number;
}

export interface RadarDiagnostics {
  diagnosticState: DeviceDiagnosticState;
  lastEnabledTs: number;
  lastStoppedTs: number;
  lastStartedTs: number;
  lastHealthyTs: number;
}

export interface MicDiagnostics {
  diagnosticState: DeviceDiagnosticState;
  lastEnabledTs: number;
  lastStoppedTs: number;
  lastStartedTs: number;
  lastHealthyTs: number;
}

export interface I2CBusDiagnostics {
  diagnosticState: DeviceDiagnosticState;
  lightSensor: DeviceDiagnosticState;
  imuSensor: DeviceDiagnosticState;
  envSensor: DeviceDiagnosticState;
  led: DeviceDiagnosticState;
  display: DeviceDiagnosticState;
}

export interface HostDiagnostics {
  diagnosticState: DeviceDiagnosticState;
  lastHealthyTs: number;
  totalCpuLoadPercentage: number;
  cpuTemperatureCelsius: number;
  availableStorageMegaBytes: number;
}

export interface GpioButtonDiagnostics {
  diagnosticState: DeviceDiagnosticState;
  lastHealthyTs: number;
}

export interface PhoneDiagnostics {
  sipRegistered: boolean;
  diagnosticState: DeviceDiagnosticState;
  sipRegistrationExpirationTimestamp: number;
}

export interface SensorDiagnostics {
  radarModules: any[];
  diagnosticState: DeviceDiagnosticState;
}

export interface ObjectTrackingDiagnostics {
  diagnosticState: DeviceDiagnosticState;
  epochEndTimestamp: number;
  epochWaitTimestamp: number;
  trackUploadEnabled: boolean;
  epochBeginTimestamp: number;
  epochCfarDoneTimestamp: number;
  tracksDetectedTimestamp: number;
  tracksPackagedTimestamp: number;
  mmwaveBatchReadTimestamp: number;
  mmwaveBatchWaitTimestamp: number;
  epochRawProcessedTimestamp: number;
  epochSignalMmwaveTimestamp: number;
  epochTrackingDoneTimestamp: number;
}

export interface MotionInferenceDiagnostics {
  diagnosticState: DeviceDiagnosticState;
  fallCallFromBoxEnabled: boolean;
  fallDetectionHeartbeatTimestamp: number;
}

export interface DeviceComponentStates {
  mainApp: MainAppDiagnostics;
  radar: RadarDiagnostics;
  mic: MicDiagnostics;
  i2cBus: I2CBusDiagnostics;
  host: HostDiagnostics;
  gpioButton: GpioButtonDiagnostics;
  phone: PhoneDiagnostics;
  sensor: SensorDiagnostics;
  objectTracking: ObjectTrackingDiagnostics;
  motionInference: MotionInferenceDiagnostics;
  microphone: MicDiagnostics;
}

export interface DevicePersonLocationResponse {
  device: Device;
  person?: Nullable<Person>;
  location?: Nullable<Location>;
  deviceRemoteCommand?: Nullable<DeviceRemoteCommand>;
  deviceConfig?: Nullable<DeviceConfig>;
  sensorPerson?: Nullable<SensorPerson>;
}

export interface DevicePerson {
  deviceId: string;
  personId?: Nullable<string>;
  locationId?: Nullable<string>;
  sensorId?: Nullable<string>;
}

export interface Location {
  id: string;
  locationType: LocationType;
  name?: Nullable<string>;
  street?: Nullable<string>;
  city?: Nullable<string>;
  state?: Nullable<string>;
  postalCode?: Nullable<string>;
  country?: string;
  isoCountryCode?: Nullable<string>;
  subAdministrativeArea?: Nullable<string>;
  subLocality?: Nullable<string>;
  formattedAddress?: Nullable<string>;
  latitude?: Nullable<number>;
  longitude?: Nullable<number>;
  timeZoneId?: Nullable<string>;
}

export interface DevicePersonRecord {
  byDeviceId: Record<string, DevicePerson>;
  byPersonId: Record<string, DevicePerson>;
}

export interface FirmwareVersion {
  id: string;
  versionName: string;
  groupName: string;
  stopGap: boolean;
  firmwareType: FirmwareType;
  created: string;
  modifiedDate: string;
}

export interface DeviceVersion {
  id: string;
  deviceId: string;
  softwareVersion: string;
  firmwareVersionId: string;
  userAgent: string;
  startDate: string;
  endDate: string;
  created: string;
  modifiedDate: string;
}

export interface DeviceDataCollection {
  id: string;
  kobeId?: Nullable<string>;
  fitbitId?: Nullable<string>;
  fitbitAuthToken?: Nullable<string>;
  museId?: Nullable<string>;
  hexoId?: Nullable<string>;
  deviceSleeperType?: Nullable<DeviceSleeperType>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}
export enum DeviceSleeperType {
  UNKNOWN = 'UNKNOWN',
  SINGLE = 'SINGLE',
  CO_SLEEPER = 'CO_SLEEPER'
}

export interface DeviceNumberPayload {
  deviceId: string;
  deviceNumber?: Nullable<number>;
}

export interface DeviceEnvironmentPayload {
  deviceId: string;
  environmentType: EnvType;
}

export interface ShipmentInfo {
  sensorPersonId: string;
  personId: string;
  deviceNumber?: Nullable<number>;
  installationDate?: Nullable<string>;
  removedDate?: Nullable<string>;
  timeZone?: Nullable<string>;
  shippedDate?: Nullable<string>;
  receivedDate?: Nullable<string>;
}

export interface DeviceShipment {
  id: string;
  personId: string;
  deviceId: string;
  deviceShippedTs?: Nullable<string>;
  deviceReceivedTs?: Nullable<string>;
  daysSinceShipped?: Nullable<number>;
  daysSinceReceived?: Nullable<number>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface GetDeviceFirmwareVersionPayload extends Array<FirmwareVersion> {}
export interface GetDeviceFirmwareVersionsRecentPayload extends Array<FirmwareVersion> {}
export interface GetDevicePersonPayload extends Array<DevicePersonLocationResponse> {}
export interface GetDeviceProvisioningPayload extends Array<DeviceProvisioning> {}
export interface GetDeviceStatePayload extends Array<DeviceStateEntry> {}

export enum DeviceType {
  UNKNOWN = 'UNKNOWN',
  PROVISIONER = 'PROVISIONER',
  RADAR_MAIN = 'RADAR_MAIN'
}

export enum DeviceHealthStatus {
  UNKNOWN = 'UNKNOWN',
  HEALTHY = 'HEALTHY',
  UNHEALTHY = 'UNHEALTHY'
}

export enum DeviceConnectivityStatus {
  UNKNOWN = 'UNKNOWN',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED'
}

export enum DeviceLockStatus {
  DEVICE_LOCKED = 'DEVICE_LOCKED',
  DEVICE_UNLOCKED_TRANSIENT = 'DEVICE_UNLOCKED_TRANSIENT',
  DEVICE_UNLOCKED_DEVELOPMENT = 'DEVICE_UNLOCKED_DEVELOPMENT',
  DEVICE_UNLOCKED_HOTFIX = 'DEVICE_UNLOCKED_HOTFIX'
}

export enum DeviceVersionUpdateStatus {
  UP_TO_DATE = 'UP_TO_DATE',
  UPDATE_FAILED = 'UPDATE_FAILED',
  UPDATE_REQUESTED = 'UPDATE_REQUESTED',
  PULLING = 'PULLING',
  DEPLOYING_BOOTLOADER = 'DEPLOYING_BOOTLOADER',
  RESTARTING_BOOTLOADER = 'RESTARTING_BOOTLOADER',
  DEPLOYING_FILESYSTEM = 'DEPLOYING_FILESYSTEM',
  RESTARTING_FILESYSTEM = 'RESTARTING_FILESYSTEM'
}

export enum PoiType {
  UNKNOWN = 'UNKNOWN',
  NONE = 'NONE',
  BED = 'BED',
  BED_CENTER_TRAINING = 'BED_CENTER_TRAINING',
  BEDROOM = 'BEDROOM',
  BEDSIDE_NEAR = 'BEDSIDE_NEAR',
  BEDSIDE_FAR = 'BEDSIDE_FAR'
}

export enum AuthKeyType {
  UNKNOWN = 'UNKNOWN',
  EC_PEM = 'EC_PEM',
  RSA_PEM = 'RSA_PEM'
}

export interface DeviceConnectivity {
  id: string;
  deviceId: string;
  connectivityStatus: DeviceConnectivityStatus;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}
export interface HwRevisionFirmware {
  id: string;
  hwRevision: HwRevision;
  firmwareType: FirmwareType;
}

export enum HwRevision {
  UNKNOWN = 'UNKNOWN',
  KOKOON_PROTO_NO_LEDS = 'KOKOON_PROTO_NO_LEDS',
  KOKOON_PROTO = 'KOKOON_PROTO',
  KOKOON_PROTO_LIGHT_SENSOR_NO_LEDS = 'KOKOON_PROTO_LIGHT_SENSOR_NO_LEDS',
  KOKOON_PROTO_LIGHT_SENSOR = 'KOKOON_PROTO_LIGHT_SENSOR',
  KOKOON_PROTO2 = 'KOKOON_PROTO2',
  KOKOON_PROTOCM = 'KOKOON_PROTOCM'
}

export enum FirmwareType {
  UNKNOWN = 'UNKNOWN',
  CORAL = 'CORAL',
  NANO = 'NANO'
}

export const deviceHealthStatusList: DeviceHealthStatus[] = Object.values(DeviceHealthStatus);
export const deviceConnectivityStatusList: DeviceConnectivityStatus[] = Object.values(DeviceConnectivityStatus);
export const deviceVersionUpdateStatusList: DeviceVersionUpdateStatus[] = Object.values(DeviceVersionUpdateStatus);
export const deviceLockStatusList: DeviceLockStatus[] = Object.values(DeviceLockStatus);
export const authKeyList: AuthKeyType[] = Object.values(AuthKeyType);
