import React from 'react';
import style from './Chat.scss';
import { ChatMessage } from '../../../../../redux/chat/chatTypes';
import ChatBubble from '../ChatBubble/ChatBubble';
import { Person } from '../../../../../redux/person/personTypes';

const LUNA_NAME = 'Luna';

interface Props {
  timezone: Nullable<string>;
  messages: ChatMessage[];
  person?: Person;
}

const Chat: React.FC<Props> = ({ timezone, messages, person }) => {
  return (
    <div className={style.chatContainer}>
      {messages.map((message) => {
        const isLuna = message.senderId !== person?.id;
        const name = isLuna ? LUNA_NAME : person?.givenNames || '';

        return <ChatBubble key={message.id} time={message.created || ''} name={name} message={message.message} isLuna={isLuna} timezone={timezone} />;
      })}
    </div>
  );
};

export default Chat;
