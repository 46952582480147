import React from 'react';
import style from '../../UserDetails.scss';
import { DeviceContent, FeedItem, ProgramContent } from '../../../../redux/content/contentTypes';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { PersonSleepProgram } from '../../../../redux/person/personTypes';
import { isAdHoc, isLesson } from '../../../../utils/content';
import { textToMoment, tsAsDateTimeStr } from '../../../../utils/time';
import { Moment } from 'moment-timezone';

export const ContentTab: React.FC<{ feedItems?: Nullable<FeedItem[]>; userTimeZone?: Nullable<string> }> = ({ feedItems, userTimeZone }) => {
  const gatherLessonRows = (): FeedItem[] => {
    const displayItems: FeedItem[] = !feedItems ? [] : feedItems?.filter((feedItem) => isLesson(feedItem));
    const adHocItems: FeedItem[] = !feedItems
      ? []
      : feedItems
          ?.filter((feedItem) => isAdHoc(feedItem))
          .map((feedItem) => {
            const clone: FeedItem = { ...feedItem };
            clone.programStep = -1;
            return clone;
          });
    const tableRows = displayItems.concat(adHocItems);
    tableRows.sort((a, b) => {
      const createdA: Nullable<Moment> = textToMoment(a.created);
      const createdB: Nullable<Moment> = textToMoment(b.created);
      return createdB?.isBefore(createdA) ? -1 : 1;
    });
    return tableRows;
  };

  return (
    <Box mt={3}>
      <TableContainer component={Paper}>
        <Table size="small" style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell className={style.headerCell}>LESSON DAY</TableCell>
              <TableCell className={style.headerCell}>LESSON TITLE</TableCell>
              <TableCell className={style.headerCell}>ASSIGNED</TableCell>
              <TableCell className={style.headerCell}>COMPLETED</TableCell>
              <TableCell className={style.headerCell}>FAVORITED?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gatherLessonRows().map((row, index) => {
              const programContent: Nullable<ProgramContent> = row.programContent;
              const personProgram: Nullable<PersonSleepProgram> = row.personProgram;
              const deviceContent: Nullable<DeviceContent> = programContent?.deviceContent;
              const title: Nullable<string> = deviceContent?.title;
              const description: Nullable<string> = deviceContent?.description;
              const programStep: string | undefined =
                row.programStep === -1 ? 'Ad Hoc' : programContent?.programStep ? '' + programContent?.programStep : '' + personProgram?.programStep;
              return (
                <TableRow key={index}>
                  <TableCell className={style.rowCell}>{programStep}</TableCell>
                  <TableCell className={style.rowCell}>{title ? title : description}</TableCell>
                  <TableCell className={style.rowCell}>{tsAsDateTimeStr(row.created, userTimeZone)}</TableCell>
                  <TableCell className={style.rowCell}>{row.completedDate ? tsAsDateTimeStr(row.completedDate, userTimeZone) : 'NOT COMPLETED'}</TableCell>
                  <TableCell className={style.rowCell}>{row.savedDate ? 'TRUE' : 'FALSE'}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
