import { Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { ComponentType, forwardRef, ReactElement } from 'react';
import { HomeCrumb } from '../components/BreadCrumbs/HomeCrumb';
import { LinkCrumb } from '../components/BreadCrumbs/LinkCrumb';
import { SelectCrumb } from '../components/BreadCrumbs/SelectCrumb';
import { SimpleCrumb } from '../components/BreadCrumbs/SimpleCrumb';
import { TextCrumb } from '../components/BreadCrumbs/TextCrumb';
import { UserProfileCrumb } from '../components/BreadCrumbs/UserProfileCrumb';
import { ManualFilledIcon } from '../components/Workflow/ManualFilledIcon/ManualFilledIcon';
import { ManualOutlinedIcon } from '../components/Workflow/ManualOutlinedIcon/ManualOutlinedIcon';
import { MilestoneFilledIcon } from '../components/Workflow/MilestoneFilledIcon/MilestoneFilledIcon';
import { MilestoneOutlinedIcon } from '../components/Workflow/MilestoneOutlinedIcon/MilestoneOutlinedIcon';
import { StateFilledIcon } from '../components/Workflow/StateFilledIcon/StateFilledIcon';
import { StateOutlinedIcon } from '../components/Workflow/StateOutlinedIcon/StateOutlinedIcon';
import { BreadCrumbData, BreadCrumbType, SelectOption } from '../redux/common/types';
import { ProgramSubType, subTypeList, WorkflowType } from '../redux/content/contentTypes';
import { normalizeEnumName } from './content';
import { RouteNames } from './navigationUtils';

const breadCrumbDeserializerMap: Map<BreadCrumbType, (data: BreadCrumbData[]) => JSX.Element> = new Map<BreadCrumbType, (data: BreadCrumbData[]) => JSX.Element>([
  [
    BreadCrumbType.HOME,
    () => {
      return <HomeCrumb />;
    }
  ],
  [
    BreadCrumbType.SIMPLE,
    (data) => {
      return <SimpleCrumb label={data[data.length - 1].title} url={data[data.length - 1].url} state={data} />;
    }
  ],
  [
    BreadCrumbType.SELECT,
    (data) => {
      return <SelectCrumb selectedIndex={data[data.length - 1].selectedIndex || 0} valueOptions={data[data.length - 1].valueOptions || []} />;
    }
  ],
  [
    BreadCrumbType.LINK,
    (data) => {
      return <LinkCrumb label={data[data.length - 1].title} url={data[data.length - 1].url} state={data} />;
    }
  ],
  [
    BreadCrumbType.TEXT,
    (data) => {
      return <TextCrumb label={data[data.length - 1].title} url={data[data.length - 1].url} state={data} />;
    }
  ],
  [
    BreadCrumbType.USER_PROFILE,
    (data) => {
      return <UserProfileCrumb label={data[data.length - 1].title} url={data[data.length - 1].url} state={data} />;
    }
  ]
]);

export const defaultHomeBreadCrumb: BreadCrumbData = { title: 'Home', url: RouteNames.HOME, route: RouteNames.HOME, type: BreadCrumbType.HOME };
export const defaultUsersBreadCrumb: BreadCrumbData = { title: 'Users', url: RouteNames.USERS, route: RouteNames.USERS, type: BreadCrumbType.SIMPLE };
export const defaultAccessCodesBreadCrumb: BreadCrumbData = {
  title: 'Demo Access Codes',
  url: RouteNames.DEMO_ACCESS_CODES,
  route: RouteNames.DEMO_ACCESS_CODES,
  type: BreadCrumbType.SIMPLE
};
export const defaultAllUsersBreadCrumb: BreadCrumbData = { title: 'All Users', url: RouteNames.ALL_USERS, route: RouteNames.ALL_USERS, type: BreadCrumbType.SIMPLE };
export const defaultAllFlagsBreadCrumb: BreadCrumbData = { title: 'All Flags', url: RouteNames.ALL_FLAGS, route: RouteNames.ALL_FLAGS, type: BreadCrumbType.SIMPLE };
export const defaultFlaggedUsersBreadCrumb: BreadCrumbData = {
  title: 'Flagged Users',
  url: RouteNames.FLAGGED_USERS,
  route: RouteNames.FLAGGED_USERS,
  type: BreadCrumbType.SELECT,
  valueOptions: [],
  selectedIndex: -1
};
export const defaultDeviceBreadCrumb: BreadCrumbData = { title: 'Devices', url: RouteNames.DEVICE_OVERVIEW, route: RouteNames.DEVICE_OVERVIEW, type: BreadCrumbType.SIMPLE };
export const defaultUserDetailsCrumb: BreadCrumbData = { title: 'User', url: RouteNames.USER_DETAILS, route: RouteNames.USER_DETAILS, type: BreadCrumbType.SIMPLE };
export const defaultUserDetailsSleepCoachChatCrumb: BreadCrumbData = {
  title: 'User: Sleep Coach Chat',
  url: RouteNames.USER_DETAILS_SLEEP_COACH_CHAT,
  route: RouteNames.USER_DETAILS_SLEEP_COACH_CHAT,
  type: BreadCrumbType.SIMPLE
};
// export const defaultPersonPermissionsCrumb: BreadCrumbData = {
//   title: 'Permissions',
//   url: RouteNames.PERSON_PERMISSIONS,
//   route: RouteNames.PERSON_PERMISSIONS,
//   type: BreadCrumbType.SIMPLE
// };
export const defaultPersonProgramCrumb: BreadCrumbData = {
  title: 'Program Management',
  url: RouteNames.PERSON_PROGRAM,
  route: RouteNames.PERSON_PROGRAM,
  type: BreadCrumbType.SIMPLE
};
export const defaultFirmwareVersionsCrumb: BreadCrumbData = {
  title: 'Firmware Versions',
  url: RouteNames.FIRMWARE_VERSIONS,
  route: RouteNames.FIRMWARE_VERSIONS,
  type: BreadCrumbType.SIMPLE
};
export const defaultDeviceProvisioningCrumb: BreadCrumbData = {
  title: 'Device Provisioning',
  url: RouteNames.DEVICE_PROVISIONING,
  route: RouteNames.DEVICE_PROVISIONING,
  type: BreadCrumbType.SIMPLE
};
export const defaultFlagManagerCrumb: BreadCrumbData = { title: 'Flag Manager', url: RouteNames.FLAG_MANAGER, route: RouteNames.FLAG_MANAGER, type: BreadCrumbType.SIMPLE };
export const defaultContentManagerCrumb: BreadCrumbData = { title: 'Content', url: RouteNames.CONTENT_MANAGER, route: RouteNames.CONTENT_MANAGER, type: BreadCrumbType.SIMPLE };
export const defaultAllContentCrumb: BreadCrumbData = { title: 'All Content', url: RouteNames.ALL_CONTENT, route: RouteNames.ALL_CONTENT, type: BreadCrumbType.SIMPLE };
export const defaultAllTracksCrumb: BreadCrumbData = { title: 'All Tracks', url: RouteNames.ALL_TRACKS, route: RouteNames.ALL_TRACKS, type: BreadCrumbType.SIMPLE };
export const defaultTrackCrumb: BreadCrumbData = {
  title: 'Track',
  url: RouteNames.TRACK,
  route: RouteNames.TRACK,
  type: BreadCrumbType.SELECT,
  valueOptions: subTypeList
    .filter((subType) => subType !== ProgramSubType.UNKNOWN)
    .map((subType) => {
      return { value: RouteNames.TRACK.replace(':subType', subType), label: normalizeEnumName(subType) };
    }),
  selectedIndex: 0
};
export const defaultDeviceContentDetailsCrumb: BreadCrumbData = {
  title: 'Content Details',
  url: RouteNames.DEVICE_CONTENT_DETAILS,
  route: RouteNames.DEVICE_CONTENT_DETAILS,
  type: BreadCrumbType.SIMPLE
};
export const defaultPageDetailsCrumb: BreadCrumbData = { title: 'Page Details', url: RouteNames.PAGE_DETAILS_ADD, route: RouteNames.PAGE_DETAILS_ADD, type: BreadCrumbType.SIMPLE };
export const defaultUnreadSleepCoachMessagesBreadCrumb: BreadCrumbData = {
  title: 'New Sleep Coach Chats',
  url: RouteNames.NEW_SLEEP_COACH_CHATS,
  route: RouteNames.NEW_SLEEP_COACH_CHATS,
  type: BreadCrumbType.SIMPLE
};
export const defaultSleepSchedulesToReviewBreadCrumb: BreadCrumbData = {
  title: 'Sleep Schedules',
  url: RouteNames.SLEEP_SCHEDULE_REVIEW,
  route: RouteNames.SLEEP_SCHEDULE_REVIEW,
  type: BreadCrumbType.SIMPLE
};

// new routes
export const defaultDashboardCrumb: BreadCrumbData = { title: 'Dashboard', url: RouteNames.DASHBOARD, route: RouteNames.DASHBOARD, type: BreadCrumbType.LINK };
export const defaultAllPatientCrumb: BreadCrumbData = { title: 'Patients', url: RouteNames.ALL_PATIENTS, route: RouteNames.ALL_PATIENTS, type: BreadCrumbType.LINK };
export const defaultNewPatientCrumb: BreadCrumbData = { title: 'New patient', url: RouteNames.NEW_PATIENT, route: RouteNames.NEW_PATIENT, type: BreadCrumbType.LINK };
export const getDefaultPatientProfileCrumb = (fullName: string): BreadCrumbData => {
  return { title: fullName || '', url: RouteNames.PATIENT_DETAILS, route: RouteNames.PATIENT_DETAILS, type: BreadCrumbType.USER_PROFILE };
};
export const defaultAssignTaskCrumb: BreadCrumbData = { title: 'New Activity', url: RouteNames.NEW_ACTIVITY, route: RouteNames.NEW_ACTIVITY, type: BreadCrumbType.LINK };

export const defaultHomeBreadCrumbs: BreadCrumbData[] = [];
export const defaultUsersBreadCrumbs: BreadCrumbData[] = defaultHomeBreadCrumbs.concat(defaultHomeBreadCrumb);
export const defaultAccessBreadCrumbs: BreadCrumbData[] = defaultHomeBreadCrumbs.concat(defaultHomeBreadCrumb);
export const defaultPersonPermissionsCrumbs: BreadCrumbData[] = defaultHomeBreadCrumbs.concat(defaultHomeBreadCrumb);
export const defaultPersonProgramCrumbs: BreadCrumbData[] = defaultHomeBreadCrumbs.concat(defaultHomeBreadCrumb);
export const defaultFirmwareVersionsCrumbs: BreadCrumbData[] = defaultHomeBreadCrumbs.concat(defaultHomeBreadCrumb);
export const defaultDeviceProvisioningCrumbs: BreadCrumbData[] = defaultHomeBreadCrumbs.concat(defaultHomeBreadCrumb);
export const defaultFlagManagerCrumbs: BreadCrumbData[] = defaultHomeBreadCrumbs.concat(defaultHomeBreadCrumb);
export const defaultContentManagerCrumbs: BreadCrumbData[] = defaultHomeBreadCrumbs.concat(defaultHomeBreadCrumb);
export const defaultAllContentCrumbs: BreadCrumbData[] = defaultContentManagerCrumbs.concat(defaultContentManagerCrumb);
export const defaultAllTracksCrumbs: BreadCrumbData[] = defaultContentManagerCrumbs.concat(defaultContentManagerCrumb);
export const defaultTrackCrumbs: BreadCrumbData[] = defaultAllTracksCrumbs.concat(defaultAllTracksCrumb);
export const defaultDeviceContentDetailsCrumbs: BreadCrumbData[] = defaultAllContentCrumbs.concat(defaultAllContentCrumb);
export const defaultPageDetailsCrumbs: BreadCrumbData[] = defaultDeviceContentDetailsCrumbs.concat(defaultDeviceContentDetailsCrumb);
export const defaultAllUsersBreadCrumbs: BreadCrumbData[] = defaultUsersBreadCrumbs.concat(defaultUsersBreadCrumb);
export const defaultAllFlagsBreadCrumbs: BreadCrumbData[] = defaultUsersBreadCrumbs.concat(defaultUsersBreadCrumb);
export const defaultFlaggedUsersBreadCrumbs: BreadCrumbData[] = defaultAllFlagsBreadCrumbs.concat(defaultAllFlagsBreadCrumb);
export const defaultDeviceBreadCrumbs: BreadCrumbData[] = defaultHomeBreadCrumbs.concat(defaultHomeBreadCrumb);
export const defaultUserDetailsCrumbs: BreadCrumbData[] = defaultAllUsersBreadCrumbs.concat(defaultAllUsersBreadCrumb);
export const defaultUnreadSleepCoachMessagesBreadCrumbs: BreadCrumbData[] = defaultUsersBreadCrumbs.concat(defaultUsersBreadCrumb);
export const defaultUserDetailsSleepCoachChatCrumbs: BreadCrumbData[] = defaultUnreadSleepCoachMessagesBreadCrumbs.concat(defaultUnreadSleepCoachMessagesBreadCrumb);
export const defaultSleepSchedulesToReviewBreadCrumbs: BreadCrumbData[] = defaultUsersBreadCrumbs.concat(defaultSleepSchedulesToReviewBreadCrumb);

// new route
export const defaultDashboardBreadCrumbs: BreadCrumbData[] = defaultHomeBreadCrumbs.concat(defaultDashboardCrumb);
export const defaultAllPatientBreadCrumbs: BreadCrumbData[] = defaultHomeBreadCrumbs.concat(defaultAllPatientCrumb);
export const defaultNewPatientBreadCrumbs: BreadCrumbData[] = defaultAllPatientBreadCrumbs.concat(defaultNewPatientCrumb);
export const getDefaultPatientProfileBreadCrumbs = (fullName: string): BreadCrumbData[] => defaultAllPatientBreadCrumbs.concat(getDefaultPatientProfileCrumb(fullName));
export const getDefaultAssignTaskBreadCrumbs = (fullName: string): BreadCrumbData[] => getDefaultPatientProfileBreadCrumbs(fullName).concat(defaultAssignTaskCrumb);

export const fromDefault = (
  defaultCrumb: BreadCrumbData,
  overrideUrl?: Nullable<string>,
  overrideTitle?: Nullable<string>,
  overrideType?: Nullable<BreadCrumbType>,
  overrideSelectedIndex?: Nullable<number>,
  overrideValueOptions?: Nullable<SelectOption[]>
): BreadCrumbData => {
  const breadCrumb: BreadCrumbData = { ...defaultCrumb };
  if (overrideUrl) {
    breadCrumb.url = overrideUrl;
  }
  if (overrideTitle) {
    breadCrumb.title = overrideTitle;
  }
  if (overrideType) {
    breadCrumb.type = overrideType;
  }
  if (overrideSelectedIndex) {
    breadCrumb.selectedIndex = overrideSelectedIndex;
  }
  if (overrideValueOptions) {
    breadCrumb.valueOptions = overrideValueOptions;
  }
  return breadCrumb;
};

export const renderBreadCrumbs = (breadCrumbData: BreadCrumbData[]): JSX.Element[] => {
  return breadCrumbData?.map((breadCrumb, index) => {
    const breadCrumbFunc: (data: BreadCrumbData[]) => JSX.Element = breadCrumbDeserializerMap.get(breadCrumb.type) || (() => <React.Fragment />);
    const sliced: BreadCrumbData[] = breadCrumbData.slice(0, index + 1);
    return breadCrumbFunc(sliced);
  });
};

export const getIconForWorkflow = (workflowType: Nullable<WorkflowType>, complete: boolean): Nullable<JSX.Element> => {
  if (workflowType === WorkflowType.FLAG_MILESTONE) {
    return complete ? <MilestoneOutlinedIcon buttonSize="small" /> : <MilestoneFilledIcon buttonSize="small" />;
  } else if (workflowType === WorkflowType.FLAG_MANUAL) {
    return complete ? <ManualOutlinedIcon buttonSize="small" /> : <ManualFilledIcon buttonSize="small" />;
  } else {
    return complete ? <StateOutlinedIcon buttonSize="small" /> : <StateFilledIcon buttonSize="small" />;
  }
};

export const defaultDialogTransition: ComponentType<TransitionProps & { children?: ReactElement<any, any> }> = forwardRef(
  (props: TransitionProps & { children?: ReactElement<any, any> }, ref: React.Ref<unknown>) => <Slide direction="left" ref={ref} {...props} />
);

export const getBreadCrumbsWithLastText = (breadCrumbs?: BreadCrumbData[]): BreadCrumbData[] | undefined =>
  breadCrumbs?.map((breadCrumb, index) => (index === breadCrumbs?.length - 1 ? { ...breadCrumb, type: BreadCrumbType.TEXT } : breadCrumb));
