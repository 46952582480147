import React from 'react';
import { Text } from '../Typography';
import noResponseStatusIcon from '../../assets/images/no-response-status.png';
import arrowsClockwiseIcon from '../../assets/images/arrows-clockwise.png';
import statusDoneIcon from '../../assets/images/status-done.png';
import style from './TagStatus.scss';

export type CareTagStatus = 'done' | 'skipped' | 'no-response';

interface TagStatusProps {
  status: CareTagStatus;
}

const statusDetails: Record<TagStatusProps['status'], { title: string; icon: string }> = {
  done: { title: 'Done', icon: statusDoneIcon },
  skipped: { title: 'Skipped', icon: arrowsClockwiseIcon },
  'no-response': { title: 'No response', icon: noResponseStatusIcon }
};

const TagStatus: React.FC<TagStatusProps> = ({ status }) => {
  const title = statusDetails[status].title;
  const icon = statusDetails[status].icon;

  return (
    <div className={`${style.tagStatus}`}>
      <img src={icon} className={style.icon} />
      <Text variant="xs" className={`${style[status]}`}>
        {title}
      </Text>
    </div>
  );
};

export default TagStatus;
