import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Slide, Typography } from '@material-ui/core';
import style from './ResetPasswordPane.scss';
import { getQueryParamObject, isEmpty, isPasswordValid, passwordAlertMessage } from '../../../../../../utils/util';
import { useLocation } from 'react-router-dom';
import { AccountInput } from '../../../AccountInput/AccountInput';
import { AccountButton } from '../../../AccountButton/AccountButton';
import { AccountMobileHeader } from '../../../AccountMobileHeader/AccountMobileHeader';

interface Props {
  changePassword: Function;
  handleError: (err: any) => void;
  loadingState: [boolean, Dispatch<SetStateAction<boolean>>];
  handleExternalRouting: (url?: Nullable<string>) => void;
}

export const ResetPasswordPane: React.FC<Props> = ({ changePassword, handleError, loadingState, handleExternalRouting }) => {
  const location = useLocation();
  const token: string = getQueryParamObject(location?.search)?.token;

  const passwordState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');
  const confirmPasswordState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');
  const maskPasswordState: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const maskConfirmPasswordState: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const [changed, setChanged] = useState<boolean>(false);

  useEffect(() => {
    loadingState[1](false);
  }, []);

  const submitChangePassword = () => {
    const password: string = passwordState[0];
    loadingState[1](true);
    changePassword({ password }, token)
      .then(() => {
        handleExternalRouting();
        setChanged(true);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const confirmationMatches = (): boolean => !isEmpty(passwordState[0]) && !isEmpty(confirmPasswordState[0]) && passwordState[0] === confirmPasswordState[0];

  const passwordConfirmationAlert = (): Nullable<string> => (!isEmpty(confirmPasswordState[0]) && !confirmationMatches() ? 'Password does not match' : undefined);

  return (
    <React.Fragment>
      <Box className={style.inputBox}>
        <AccountMobileHeader />
        <Slide direction="up" in={changed} mountOnEnter unmountOnExit>
          <Box>
            <Typography className={style.mainHeader}>Hurrah!</Typography>
            <Typography className={style.instructionHeader}>You have successfully changed your password.</Typography>
            <Typography hidden={!changed} className={style.closeInstruction}>
              (You can close this tab)
            </Typography>
          </Box>
        </Slide>
        {!changed && (
          <React.Fragment>
            <Box>
              <Typography className={style.mainHeader}>Reset password</Typography>
              <Typography className={style.instructionHeader}>Please choose a new password.</Typography>
            </Box>
            <AccountInput
              inputState={passwordState}
              maskInputState={maskPasswordState}
              placeholder="Password"
              includeValidation={true}
              validationMessage={passwordAlertMessage(passwordState[0])}
              cssClass={style.marginTop}
            />
            <AccountInput
              inputState={confirmPasswordState}
              maskInputState={maskConfirmPasswordState}
              placeholder="Confirm password"
              includeValidation={true}
              validationMessage={passwordConfirmationAlert()}
            />
            <AccountButton
              label="SAVE NEW PASSWORD"
              onClick={submitChangePassword}
              loading={loadingState[0]}
              disabled={!isPasswordValid(passwordState[0]) || !confirmationMatches()}
              cssClass={style.marginTop}
            />
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
};
