import { Box, styled, Tab, Tabs } from '@mui/material';
import React from 'react';

interface Props {
  setTabValue: (value: number) => void;
  tabValue: number;
  allQuestionsLength: number;
  fixedValueQuestionsLength: number;
  numberQuestionsLength: number;
  freeFormQuestionsLength: number;
  booleanQuestionsLength: number;
}

export const StyledTab = styled(Tab)({
  '&.MuiTab-root': {
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Outfit',
    color: '#6C809A'
  },
  '&.Mui-selected': {
    color: '#194373'
  }
});

export const TabIndicatorProps = { sx: { backgroundColor: '#194373' } };

export const ActivityDrawerTabs: React.FC<Props> = ({
  setTabValue,
  tabValue,
  allQuestionsLength,
  fixedValueQuestionsLength,
  numberQuestionsLength,
  freeFormQuestionsLength,
  booleanQuestionsLength
}) => {
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} TabIndicatorProps={TabIndicatorProps}>
          <StyledTab label={`All (${allQuestionsLength})`} {...a11yProps(0)} />
          <StyledTab label={`Multiple Choice (${fixedValueQuestionsLength})`} {...a11yProps(1)} />
          <StyledTab label={`Numeric (${numberQuestionsLength})`} {...a11yProps(2)} />
          <StyledTab label={`Free Form (${freeFormQuestionsLength})`} {...a11yProps(3)} />
          <StyledTab label={`Yes/No (${booleanQuestionsLength})`} {...a11yProps(4)} />
        </Tabs>
      </Box>
    </Box>
  );
};
