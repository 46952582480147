import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { PageType } from '../../../../../../redux/analytics/analyticsTypes';
import { useLocation } from 'react-router-dom';
import { FREE_TRIAL_URL, getEmailParam, getPriceParam, getStripeCheckoutParameterizedUrl, parameterizeUrl, RouteNames } from '../../../../../../utils/navigationUtils';
import { Box, Hidden, Typography } from '@material-ui/core';
import style from './SixWeekProgramPane.scss';
import bedsideMobile from '../../../../../../assets/images/bedside-mobile.png';
import { HelpLink } from '../../../HelpLink/HelpLink';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LensIcon from '@material-ui/icons/Lens';
import { AccountButton } from '../../../AccountButton/AccountButton';

interface Props {
  loadingState: [boolean, Dispatch<SetStateAction<boolean>>];
  pageType: PageType;
  handleExternalRouting: (url: string) => void;
}

export const SixWeekProgramPane: React.FC<Props> = ({ loadingState, pageType, handleExternalRouting }) => {
  const location = useLocation();

  useEffect(() => {
    loadingState[1](false);
  }, []);

  const submitSignup = () => {
    loadingState[1](true);
    const providedEmail: string = getEmailParam(location);
    const priceValue: string = getPriceParam(location);
    const forwardUrl: string =
      pageType === PageType.CREATE_ACCOUNT_PRE_INTERSTITIAL
        ? parameterizeUrl(RouteNames.CREATE_ACCOUNT_REGISTER, providedEmail, priceValue)
        : getStripeCheckoutParameterizedUrl(providedEmail);
    handleExternalRouting(forwardUrl);
  };

  const submitFreeTrial = () => {
    handleExternalRouting(FREE_TRIAL_URL);
  };

  return (
    <Box className={style.container}>
      {/* @ts-expect-error: TODO add description */}
      <Hidden mdUp>
        <Box className={style.imageContainer}>
          <img className={style.imageBox} src={bedsideMobile} alt="Full Sleep" />
        </Box>
      </Hidden>
      <Box className={style.contentBox}>
        <Box className={style.headerBox}>
          <Typography className={style.mainHeader}>Full Sleep 6 Week Program</Typography>
          <Typography className={style.subHeader}>Track and fix severe sleep issues, for good.</Typography>
        </Box>
        <Box className={style.programDetails}>
          <Typography className={style.includesLabel}>Full Sleep Program includes:</Typography>
          <List>
            <ListItem className={style.bulletContainer}>
              <ListItemIcon className={style.bulletIconContainer}>
                <LensIcon className={style.bulletIcon} />
              </ListItemIcon>
              <ListItemText disableTypography className={style.bulletText}>
                <Typography className={style.includesBullets}>6 - 8 weeks of short daily lessons</Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={style.bulletContainer}>
              <ListItemIcon className={style.bulletIconContainer}>
                <LensIcon className={style.bulletIcon} />
              </ListItemIcon>
              <ListItemText disableTypography className={style.bulletText}>
                <Typography className={style.includesBullets}>24/7 access to your dedicated sleep coach (not a robot)</Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={style.bulletContainer}>
              <ListItemIcon className={style.bulletIconContainer}>
                <LensIcon className={style.bulletIcon} />
              </ListItemIcon>
              <ListItemText disableTypography className={style.bulletText}>
                <Typography className={style.includesBullets}>Science-driven sleep content helps you understand the ‘why’ behind your sleep challenges</Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={style.bulletContainer}>
              <ListItemIcon className={style.bulletIconContainer}>
                <LensIcon className={style.bulletIcon} />
              </ListItemIcon>
              <ListItemText disableTypography className={style.bulletText}>
                <Typography className={style.includesBullets}>Customized weekly sleep and wake schedule</Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={style.bulletContainer}>
              <ListItemIcon className={style.bulletIconContainer}>
                <LensIcon className={style.bulletIcon} />
              </ListItemIcon>
              <ListItemText disableTypography className={style.bulletText}>
                <Typography className={style.includesBullets}>Data-driven insights and progress tracking over time</Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={style.bulletContainer}>
              <ListItemIcon className={style.bulletIconContainer}>
                <LensIcon className={style.bulletIcon} />
              </ListItemIcon>
              <ListItemText disableTypography className={style.bulletText}>
                <Typography className={style.includesBullets}>Optional relapse prevention plan after the program to help you stay on track</Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box className={style.callToActionContainer}>
        <AccountButton label={"I'M READY TO FIX MY SLEEP"} onClick={submitSignup} loading={loadingState[0]} cssClass={style.excitingButton} />
        <Box className={style.freeTrialDescriptionBox}>
          <Typography className={style.freeTrialDescription}>
            If you want to get a preview of the program before purchasing, we offer a 14 day free trial in our mobile app. Every step you take seamlessly carries forward when you
            transition to the full program.
          </Typography>
        </Box>
        <AccountButton label={'I WANT TO TRY IT FIRST'} onClick={submitFreeTrial} cssClass={style.normalButton} />
        <Box className={style.footerDescriptionBox}>
          <Typography className={style.freeTrialDescription}>
            Questions? Text <HelpLink text={'844-SLEEPS1'} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
