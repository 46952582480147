import React, { Dispatch, SetStateAction } from 'react';
import { Box, FormHelperText, IconButton, Input, InputAdornment } from '@material-ui/core';
import { Lock, Visibility, VisibilityOff } from '@material-ui/icons';
import style from './AccountInput.scss';
import clsx from 'clsx';

interface Props {
  inputState: [string, Dispatch<SetStateAction<string>>];
  maskInputState?: Nullable<[boolean, Dispatch<SetStateAction<boolean>>]>;
  placeholder?: string; //external component does not support nullable types, so we can't apply Nullable<> type here
  disabled?: boolean; //external component does not support nullable types, so we can't apply Nullable<> type here
  includeValidation?: Nullable<boolean>;
  validationMessage?: Nullable<string>;
  cssClass?: Nullable<string>;
}

export const AccountInput: React.FC<Props> = ({ inputState, maskInputState, placeholder, disabled, includeValidation, validationMessage, cssClass }) => {
  const addAdornment = (): JSX.Element =>
    disabled ? (
      <InputAdornment position="end">
        <IconButton disabled>
          <Lock />
        </IconButton>
      </InputAdornment>
    ) : maskInputState ? (
      <InputAdornment position="end">
        <IconButton onClick={() => maskInputState[1](!maskInputState[0])}>{maskInputState[0] ? <VisibilityOff /> : <Visibility />}</IconButton>
      </InputAdornment>
    ) : (
      <React.Fragment />
    );

  return (
    <React.Fragment>
      <Input
        placeholder={placeholder}
        disabled={disabled}
        type={!maskInputState?.[0] ? 'text' : 'password'}
        value={inputState[0]}
        className={clsx(style.inputField, cssClass)}
        autoComplete={maskInputState ? 'new-password' : undefined}
        inputProps={{ className: style.innerInputField }}
        onChange={(e) => inputState[1](e.target.value)}
        endAdornment={addAdornment()}
      />
      {!!includeValidation && (
        <Box className={style.helperBox}>
          <FormHelperText hidden={!validationMessage} error>
            {validationMessage}
          </FormHelperText>
        </Box>
      )}
    </React.Fragment>
  );
};
