import React from 'react';
import { DeviceContentPage, FeatureUnlockType, ProgramContent } from '../../redux/content/contentTypes';
import { Chip } from '@material-ui/core';
import style from './FeatureUnlockChips.scss';
import { normalizeEnumName } from '../../utils/content';

interface Props {
  programContents?: Nullable<ProgramContent[]>;
  deviceContentPages?: Nullable<DeviceContentPage[]>;
}

export const FeatureUnlockChips: React.FC<Props> = ({ programContents, deviceContentPages }) => {
  const findFeatureUnlock = (featureUnlockSet: Set<FeatureUnlockType>, content?: Nullable<ProgramContent[] | DeviceContentPage[]>): void => {
    content?.forEach((c) => {
      if (c.featureUnlockType && c.featureUnlockType !== FeatureUnlockType.UNKNOWN) {
        featureUnlockSet.add(c.featureUnlockType);
      }
    });
  };

  const collectFeatureUnlocks = (): FeatureUnlockType[] => {
    const featureUnlocks: Set<FeatureUnlockType> = new Set<FeatureUnlockType>();
    findFeatureUnlock(featureUnlocks, programContents);
    findFeatureUnlock(featureUnlocks, deviceContentPages);
    return Array.from(featureUnlocks).sort();
  };

  return (
    <React.Fragment>
      {collectFeatureUnlocks().map((feature, index) => (
        <Chip key={index} className={style.featureChip} label={normalizeEnumName(feature)} />
      ))}
    </React.Fragment>
  );
};
