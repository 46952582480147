import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Container, MuiThemeProvider, Typography } from '@material-ui/core';
import { ReduxState } from 'redux/types';
import { defaultTheme } from '../../../../utils/styles';
import styles from './LinkToMobile.scss';

const URL_TO_MOBILE_LOGIN = 'fullsleep://login';
const URL_APPLE_STORE = 'https://apps.apple.com/us/app/full-sleep/id1517618563';
const URL_PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.koko.prod.kokoapp&pli=1';

interface Props {}

const LinkToMobile: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const isMobile = /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent);
  const isIOSorMacBook = /iPad|iPhone|iPod|Macintosh|MacIntel/.test(navigator.userAgent);

  const onClick = () => {
    setIsLoading(true);
    if (isMobile) {
      window.location.href = URL_TO_MOBILE_LOGIN;
    }

    // For mobile wait 1 sec to check if the app is opened, if not open link to apple/play store
    // For desktop open to apple/play store immediately
    setTimeout(
      function () {
        // Redirect to the App Store or Play Store
        window.location.href = isIOSorMacBook ? URL_APPLE_STORE : URL_PLAY_STORE;
        setIsLoading(false);
      },
      isMobile ? 1000 : 0
    );
  };

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={styles.root}>
        <Container maxWidth="xl" className={styles.container}>
          <div className={styles.titleContainer}>
            <Typography variant="h3">Please continue to the Full Sleep mobile app</Typography>
            {isMobile && <Typography variant="h5">Download 'Full Sleep' on Your Phone and Log In with Your Credentials</Typography>}
          </div>
          <Button onClick={onClick} disabled={isLoading} color="primary" variant="contained">
            Go to Mobile App
          </Button>
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {};
  },
  (dispatch: Function) => ({})
);

export default compose(connectRedux)(LinkToMobile);
