import React from 'react';
import { Box, Typography } from '@material-ui/core';
import style from './SleepPane.scss';
import checkout from '../../../../../../assets/images/checkout.jpg';

export const SleepPane: React.FC = () => (
  <React.Fragment>
    <Box className={style.headerBox}>
      <Typography className={style.helpHeaderLeft}>Only one more click for a life of better, healthier sleep.</Typography>
    </Box>
    <img className={style.imageBox} src={checkout} alt="Full Sleep" />
    <Box className={style.footerBox}>
      <Typography className={style.helpFooterLeft}>
        Questions?
        <br />
        Text 844-SLEEPS1
      </Typography>
      <Typography className={style.helpSubFooterLeft}>FullSleep.com</Typography>
    </Box>
  </React.Fragment>
);
