import { createReducer, Draft } from '@reduxjs/toolkit';
import { getActionStringWithoutState } from '../../redux/reduxUtils';
import { addNewCareTeamAction, addPersonToCareTeamAction, getCareTeamPersonsAction, getCareTeamsAction, removePersonfromCareTeamAction } from './clinicalActions';
import { CareTeam, ClinicalState } from './clinicalTypes';

export const initialClinicalState: ClinicalState = {
  careTeamById: {},
  personCareTeamById: {},
  careTeamPersons: [],
  isLoading: {},
  error: {},
  success: {}
};

export default createReducer(initialClinicalState, (builder) => {
  builder.addCase(addNewCareTeamAction.start, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = true;
    delete state.error[tag];
  });
  builder.addCase(addNewCareTeamAction.success, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    delete state.error[tag];
    state.success[tag] = true;
    addCareTeamToState(state, action.payload);
  });
  builder.addCase(addNewCareTeamAction.fail, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    state.error[tag] = {
      message: action.payload?.message,
      status: action.payload?.status
    };
  });
  builder.addCase(getCareTeamsAction.start, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = true;
    delete state.error[tag];
  });
  builder.addCase(getCareTeamsAction.success, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    delete state.error[tag];
    state.success[tag] = true;
    action.payload?.forEach((careTeam) => {
      addCareTeamToState(state, careTeam);
    });
  });
  builder.addCase(getCareTeamsAction.fail, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    state.error[tag] = {
      message: action.payload?.message,
      status: action.payload?.status
    };
  });
  builder.addCase(addPersonToCareTeamAction.start, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = true;
    delete state.error[tag];
  });
  builder.addCase(addPersonToCareTeamAction.success, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    delete state.error[tag];
    state.success[tag] = true;
    const careTeam = state.careTeamById[action.payload.careTeamId];
    if (!careTeam.memberPersonCareTeamIds) {
      careTeam.memberPersonCareTeamIds = [];
    }
    careTeam.memberPersonCareTeamIds.push(action.payload.id);
    state.personCareTeamById[action.payload.id] = action.payload;
  });
  builder.addCase(addPersonToCareTeamAction.fail, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    state.error[tag] = {
      message: action.payload?.message,
      status: action.payload?.status
    };
  });
  builder.addCase(removePersonfromCareTeamAction.start, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = true;
    delete state.error[tag];
  });
  builder.addCase(removePersonfromCareTeamAction.success, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    delete state.error[tag];
    state.success[tag] = true;
    const careTeam = state.careTeamById[action.payload.careTeamId];
    if (careTeam.memberPersonCareTeamIds) {
      careTeam.memberPersonCareTeamIds = careTeam.memberPersonCareTeamIds.filter((id) => id !== action.payload.id);
    }
    delete state.personCareTeamById[action.payload.id];
  });
  builder.addCase(removePersonfromCareTeamAction.fail, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    state.error[tag] = {
      message: action.payload?.message,
      status: action.payload?.status
    };
  });
  builder.addCase(getCareTeamPersonsAction.start, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = true;
    delete state.error[tag];
  });
  builder.addCase(getCareTeamPersonsAction.success, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    delete state.error[tag];
    state.success[tag] = true;
    state.careTeamPersons = action.payload.map((person) => person.id);
  });
  builder.addCase(getCareTeamPersonsAction.fail, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    state.error[tag] = {
      message: action.payload?.message,
      status: action.payload?.status
    };
  });
});

const addCareTeamToState = (draftState: Draft<ClinicalState>, careTeam?: Nullable<CareTeam>) => {
  if (careTeam) {
    const members = careTeam.members || [];
    careTeam.members = undefined;
    careTeam.memberPersonCareTeamIds = members.map((member) => member.id);
    draftState.careTeamById[careTeam.id] = careTeam;
    members.forEach((member) => {
      draftState.personCareTeamById[member.id] = member;
    });
  }
};
