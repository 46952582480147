import * as React from 'react';
import { Button, DialogTitle, DialogContent, DialogActions, Dialog, Checkbox, ListItemText, Box } from '@mui/material';
import { CarePlan } from 'redux/carePlan/carePlanTypes';
import style from './SelectCarePlanDialog.scss';
import { Color } from '../../../../utils/color';

export interface Props {
  id: string;
  value: string[];
  open: boolean;
  options: CarePlan[];
  saveButtonDisabled: boolean;
  handleChange: any;
  handleSave: React.MouseEventHandler<HTMLButtonElement>;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}

const SelectCarePlanDialog: React.FC<Props> = React.memo(({ value, open, options, saveButtonDisabled, handleChange, handleSave, onClose, ...other }) => {
  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }} maxWidth="xs" open={open} {...other}>
      <DialogTitle>Select Care Plan</DialogTitle>
      <DialogContent dividers>
        {options.map((option) => (
          <Box key={option.id} display="flex" alignItems="center">
            <Checkbox
              checked={value.indexOf(option.id) > -1}
              onChange={() => handleChange(option.id)}
              sx={{
                color: Color.DARK_BLUE,
                '&.Mui-checked': {
                  color: Color.DARK_BLUE
                }
              }}
            />
            <ListItemText primary={option.name} />
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} className={style.button}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={saveButtonDisabled} className={style.button}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default SelectCarePlanDialog;
