import React, { MouseEventHandler } from 'react';
import style from './ContentTile.scss';
import Box from '@material-ui/core/Box';
import { Avatar, Paper, Typography } from '@material-ui/core';
import ContentEditable from 'react-contenteditable';

export interface Subtitle {
  position?: Nullable<number>;
  text: string;
}

interface Props {
  title?: Nullable<string>;
  subTitles?: Nullable<Subtitle[]>;
  tileNumber?: Nullable<number>;
  headElement?: Nullable<JSX.Element>;
  onClick?: MouseEventHandler; //external component does not support nullable types, so we can't apply Nullable<> type here
  tailElement?: Nullable<JSX.Element>;
  isSubTile?: Nullable<boolean>;
  disableClick?: Nullable<boolean>;
  editTitle?: Nullable<(text: string) => void>;
}

export const ContentTile: React.FC<Props> = ({ title, subTitles, tileNumber, headElement, onClick, tailElement, isSubTile, disableClick, editTitle }) => (
  <Paper className={isSubTile ? style.subTile : style.tile} onClick={disableClick ? () => {} : onClick}>
    {(!!tileNumber || !!headElement) && (
      <Box component="div">
        {!!tileNumber && <Avatar className={style.tileNumber}>{tileNumber}</Avatar>}
        {!!headElement && headElement}
      </Box>
    )}
    <Box component="span" className={style.tileTitle}>
      <Typography variant="h5" component="h2">
        {!editTitle ? title : <ContentEditable html={title || ''} onChange={(e) => editTitle(e.currentTarget.textContent)} />}
      </Typography>
      {subTitles?.map((subtitle, index) => (
        <Box key={index} className={style.subTitleBox}>
          {subtitle.position && (
            <Box component="div" className={style.subTitleItemBox}>
              <Avatar className={style.subtileNumber}>{subtitle.position}</Avatar>
            </Box>
          )}
          <Box component="span" className={style.subTitleItemBox}>
            <Typography variant="body2" component="p">
              {subtitle.text}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
    {tailElement}
  </Paper>
);
