import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import { ContentDropdownOption, DeviceContentType, DeviceContentTypeOrProgramContentType, ProgramType, ValidDropdownType } from '../../redux/content/contentTypes';
import { normalizeEnumName } from '../../utils/content';
import { SelectButton } from '../SelectButton/SelectButton';
import style from './ContentDropdown.scss';

interface ContentDropdownProps {
  onClick: (selectedOption: ContentDropdownOption) => void;
  contentTypeOptions: DeviceContentType[] | ProgramType[];
  selectAll?: boolean;
  selectedOption?: DeviceContentType | ProgramType;
  disabled?: boolean;
}

interface ContentDropdownState {
  dropdownOptions: ContentDropdownOption[];
  selectedOption: ContentDropdownOption;
}

export class ContentDropdown extends Component<ContentDropdownProps, ContentDropdownState> {
  constructor(props: ContentDropdownProps) {
    super(props);

    const selectAll = props.selectAll ?? true;
    const dropdownOptions = this.buildContentDropdown(props.contentTypeOptions, selectAll);
    const selectedOption = dropdownOptions.find((option) => option.value == props.selectedOption) ?? dropdownOptions[0];

    this.state = {
      dropdownOptions,
      selectedOption
    };
  }

  getSelectAllOption(): ContentDropdownOption {
    return {
      label: 'All Content',
      value: 'SELECT_ALL'
    };
  }

  buildContentDropdown(contentTypeOptions: DeviceContentTypeOrProgramContentType[], selectAll: boolean): ContentDropdownOption[] {
    const dropdownOptions: ContentDropdownOption[] = contentTypeOptions.map((option) => this.createDropdownOption(option));
    if (selectAll) {
      dropdownOptions.unshift(this.getSelectAllOption());
    }
    return dropdownOptions;
  }

  createDropdownOption(value: ValidDropdownType): ContentDropdownOption {
    return {
      label: normalizeEnumName(value),
      value: value
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOption !== this.props.selectedOption) {
      this.setState({
        selectedOption: this.state.dropdownOptions.find((option) => option.value == this.props.selectedOption) ?? this.state.dropdownOptions[0]
      });
    }
  }

  render() {
    const { onClick, disabled } = this.props;
    const { dropdownOptions, selectedOption } = this.state;

    const options: string[] = dropdownOptions.map((option) => option.label);
    const selectedIndex = selectedOption ? options.indexOf(selectedOption.label) : 0;

    const onClickWrapper = (selectedIndex: number) => {
      const selectedOption = dropdownOptions[selectedIndex];
      this.setState({ selectedOption });
      onClick(selectedOption);
    };

    return (
      <Box className={style.headerChipBar}>
        <SelectButton
          options={options}
          disableConfirmation={true}
          label={'Content Type'}
          activeIndex={selectedIndex}
          onClick={(selectedIndex) => onClickWrapper(selectedIndex)}
          disableSelection={disabled}
          customWidth={'175px'}
        />
      </Box>
    );
  }
}
