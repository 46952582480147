import React from 'react';
import { Box, Typography } from '@material-ui/core';
import paymentLogo from '../../../../assets/images/paymentlogo.png';
import style from './HeaderLogo.scss';

export const HeaderLogo: React.FC = () => (
  <Box display="flex" justifyContent="flex-start">
    <Box display="flex" flexDirection="column" justifyContent="center" mr={1}>
      <img className={style.purchaseHeaderImg} alt="Full Sleep Icon" src={paymentLogo} />
    </Box>
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography className={style.purchaseHeaderText}>Full Sleep</Typography>
    </Box>
  </Box>
);
