import { createAction } from '@reduxjs/toolkit';
import { CreateNewSessionPayload, PageEnteredPayload, PageLeftPayload } from './analyticsTypes';

export const ANALYTICS_ACTION_PREFIX = 'analytics-redux/';

export const appEnteredAction = createAction<string>(`${ANALYTICS_ACTION_PREFIX}app-entered`);

export const pageEnteredAction = createAction<PageEnteredPayload>(`${ANALYTICS_ACTION_PREFIX}page-entered`);

export const appLeftAction = createAction<number>(`${ANALYTICS_ACTION_PREFIX}app-left`);

export const pageLeftAction = createAction<PageLeftPayload>(`${ANALYTICS_ACTION_PREFIX}page-left`);

export const createSessionAction = createAction<CreateNewSessionPayload>(`${ANALYTICS_ACTION_PREFIX}new-session`);

export const updateSessionEndAction = createAction<number>(`${ANALYTICS_ACTION_PREFIX}extend-session`);

export const updateBackgroundTimeAction = createAction<number>(`${ANALYTICS_ACTION_PREFIX}update-background`);
