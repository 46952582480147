import { Grid, Paper, Typography } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';
import { ChatMessage } from '../../../../redux/chat/chatTypes';
import { DateTime } from 'luxon';
import style from './ChatsList.scss';
import { AccountStatus } from '../../../../redux/person/personTypes';

const renderUnreadMessageListItem = (
  index: number,
  fullName: string,
  message: string,
  timeString: Nullable<string>,
  accountStatus: Nullable<AccountStatus>,
  isPaidAccount: boolean,
  onClick: MouseEventHandler
) => (
  <Paper key={index} elevation={0} className={style.paper} onClick={onClick}>
    <Grid container wrap="nowrap" direction="column">
      <Grid item xs zeroMinWidth className={style.chatList}>
        <Typography className={style.fullname}>{fullName}</Typography>
        <Typography noWrap className={style.message}>
          {message}
        </Typography>
        <Typography className={style.date}>{timeString}</Typography>
        <Typography className={isPaidAccount ? style.accountStatusGreen : style.accountStatus}>{'Account Status: ' + accountStatus}</Typography>
      </Grid>
    </Grid>
  </Paper>
);

interface Props {
  unreadSleepCoachChatMessages: ChatMessage[];
  showAccessNotAllowed: boolean;
  openPersonChat: (personId: string) => void;
}

const ChatsList: React.FC<Props> = ({ unreadSleepCoachChatMessages, showAccessNotAllowed, openPersonChat }) => {
  return !showAccessNotAllowed ? (
    <div className={style.listContainer}>
      {unreadSleepCoachChatMessages.length === 0 && <Typography variant="subtitle2">Great, no new unread messages!</Typography>}
      {unreadSleepCoachChatMessages.map((message: ChatMessage, index) => {
        const timeString = message.created && DateTime.fromISO(message.created).toFormat('LLL dd, h:mm a');
        const onClickChatItem = (_event) => openPersonChat(message.personId);
        const fullName = message.person?.fullName || message.personId;
        const accountStatus = message.person?.accountStatus;
        const isPaidAccount =
          message.person?.accountStatus === AccountStatus.ACTIVE_PROGRAM ||
          message.person?.accountStatus === AccountStatus.ACTIVE_EXTENSION ||
          message.person?.accountStatus === AccountStatus.ACTIVE_RELAPSE_HIGH ||
          message.person?.accountStatus === AccountStatus.ACTIVE_RELAPSE_LOW ||
          message.person?.accountStatus === AccountStatus.ACTIVE_14_DAY_TRIAL_PAID;

        return renderUnreadMessageListItem(index, fullName, message.message, timeString, accountStatus, isPaidAccount, onClickChatItem);
      })}
    </div>
  ) : (
    <Typography variant="subtitle2">Access not allowed</Typography>
  );
};

export default React.memo(ChatsList);
