import React from 'react';
import { Text } from '../../../../../components/Typography/Text';
import style from './ChatBubble.scss';
import { tsAsTimeStr } from '../../../../../utils/time';

interface Props {
  time: string;
  name: string;
  message: string;
  isLuna: boolean;
  timezone: Nullable<string>;
}

const ChatBubble: React.FC<Props> = ({ time, name, message, isLuna, timezone }) => {
  return (
    <div className={style.chatBubbleContainer}>
      <div className={isLuna ? style.avatarLuna : style.avatarUser}>
        <Text variant="md" className={style.avatarLetter}>
          {name[0]}
        </Text>
      </div>
      <div className={style.messageContainer}>
        <div className={style.nameContainer}>
          <Text variant="sm" className={style.name}>
            {name}
          </Text>
          <Text variant="sm" className={style.time}>
            {tsAsTimeStr(time, timezone)}
          </Text>
        </div>
        <Text variant="md">{message}</Text>
      </div>
    </div>
  );
};

export default ChatBubble;
