import {
  OAUTH_ACTION_PREFIX,
  appendBreadcrumbAction,
  deleteAccountNotifySupportEmailActions,
  createDemoAccessCodeAction,
  getClearLoginResponseAction,
  getConfirmEmailActions,
  getDemoAccessCodesAction,
  getLoginActions,
  getLogoutActions,
  getRegisterUserEmailAction,
  getRegisterUserFullNameAction,
  getRequestRegisterActions,
  getResetPasswordActions,
  updateDemoAccessCodesAction,
  getAcceptInviteActions
} from './oauthActions';
import { OauthState, LoginResponsePayload, PasswordResetStatusType } from './oauthTypes';
import { BreadCrumbData } from '../common/types';
import { createReducer } from '@reduxjs/toolkit';

export const initialOauthState: OauthState = {
  refreshing: false,
  isConfirming: false,
  confirmed: false,
  initialAuthFlowLoading: false,
  isLoading: false,
  isLoadingDeleteAccountNotifySupportEmail: false,
  acceptInvitedSuccessfull: false,
  error: {
    message: '',
    status: 0
  },
  registeredUser: {
    email: '',
    firstName: '',
    lastName: ''
  },
  breadCrumbs: [],
  demoAccessCodes: []
};

const authResponseToState = (authResponse: LoginResponsePayload, state: any) => {
  state.accessToken = authResponse.access_token;
  state.expiresIn = Number(authResponse.expires_in);
  state.expires = new Date(authResponse.expires).getTime();
  state.refreshToken = authResponse.refresh_token;
};

export default createReducer(initialOauthState, (builder) => {
  builder
    .addCase(getLoginActions.success, (state, action) => {
      state.personId = action.payload.person?.id;
      authResponseToState(action.payload, state);
    })
    .addCase(getLogoutActions.success, (state, action) => {
      state.personId = undefined;
      state.accessToken = '';
      state.expiresIn = 0;
      state.expires = 0;
      state.refreshToken = '';
    })
    .addCase(getRegisterUserEmailAction, (state, action) => {
      state.registeredUser.email = action.payload.email;
      state.error.message = '';
      state.error.status = 0;
    })
    .addCase(getRegisterUserFullNameAction, (state, action) => {
      state.registeredUser.firstName = action.payload.firstName;
      state.registeredUser.lastName = action.payload.lastName;
    })
    .addCase(getRequestRegisterActions.success, (state, action) => {
      state.personId = action.payload.person?.id;
      authResponseToState(action.payload, state);
    })
    .addCase(getConfirmEmailActions.start, (state, action) => {
      state.isConfirming = true;
    })
    .addCase(getConfirmEmailActions.success, (state, action) => {
      state.isConfirming = false;
      state.confirmed = true;
    })
    .addCase(getConfirmEmailActions.fail, (state, action) => {
      state.isConfirming = false;
    })
    .addCase(getResetPasswordActions.start, (state, action) => {
      state.passwordResetStatus = PasswordResetStatusType.RESET_START;
      state.passwordResetError = null;
    })
    .addCase(getResetPasswordActions.success, (state, action) => {
      state.passwordResetStatus = PasswordResetStatusType.RESET_COMPLETE;
    })
    .addCase(getResetPasswordActions.fail, (state, action) => {
      state.passwordResetStatus = PasswordResetStatusType.RESET_FAILED;
      state.passwordResetError = action.payload;
    })
    .addCase(getAcceptInviteActions.success, (state, action) => {
      state.acceptInvitedSuccessfull = true;
    })
    .addCase(getAcceptInviteActions.start, (state, action) => {
      state.acceptInvitedSuccessfull = false;
      state.acceptInviteError = null;
    })
    .addCase(getAcceptInviteActions.fail, (state, action) => {
      state.acceptInvitedSuccessfull = false;
      state.acceptInviteError = action.payload;
    })
    .addCase(getClearLoginResponseAction, (state, action) => {
      state.isLoading = false;
      state.error.message = '';
      state.error.status = 0;
    })
    .addCase(appendBreadcrumbAction, (state, action) => {
      const breadCrumb: BreadCrumbData = action.payload?.breadCrumbData;
      const defaultCrumbHistory: BreadCrumbData[] = action.payload?.defaultCrumbHistory;
      if (breadCrumb && defaultCrumbHistory) {
        const breadCrumbs: BreadCrumbData[] = state.breadCrumbs;
        if (breadCrumbs.length === 0) {
          breadCrumbs.push(...defaultCrumbHistory);
        }
        const index: number = breadCrumbs.findIndex((b) => b.route === breadCrumb.route);
        if (index >= 0) {
          breadCrumbs.length = index;
        }
        breadCrumbs.push(breadCrumb);
      }
    })
    .addCase(getDemoAccessCodesAction.success, (state, action) => {
      state.demoAccessCodes = action.payload.accessCodes;
    })
    .addCase(createDemoAccessCodeAction.success, (state, action) => {
      state.demoAccessCodes = action.payload.concat(state.demoAccessCodes);
    })
    .addCase(updateDemoAccessCodesAction.success, (state, action) => {
      const index = state.demoAccessCodes.findIndex((dac) => dac.id === action.payload.id);
      state.demoAccessCodes[index] = action.payload;
    })
    .addCase(deleteAccountNotifySupportEmailActions.start, (state, action) => {
      state.isLoadingDeleteAccountNotifySupportEmail = true;
      delete state.errorDeleteAccountNotifySupportEmail;
    })
    .addCase(deleteAccountNotifySupportEmailActions.success, (state, action) => {
      state.isLoadingDeleteAccountNotifySupportEmail = false;
    })
    .addCase(deleteAccountNotifySupportEmailActions.fail, (state, action) => {
      state.isLoadingDeleteAccountNotifySupportEmail = false;
      state.errorDeleteAccountNotifySupportEmail = action.payload;
    })
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-start') && action.type.startsWith(OAUTH_ACTION_PREFIX),
      (state, action) => {
        state.isLoading = true;
        state.error.message = '';
        state.error.status = 0;
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-success') && action.type.startsWith(OAUTH_ACTION_PREFIX),
      (state, action) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-fail') && action.type.startsWith(OAUTH_ACTION_PREFIX),
      (state, action: any) => {
        state.isLoading = false;
        state.error.message = action.payload?.message;
        state.error.status = action.payload?.status;
      }
    );
});
