import React, { Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { resetPasswordEmailThunk } from '../../../../redux/oauth/oauthThunks';
import { ResetPasswordEmailPayload } from '../../../../redux/oauth/oauthTypes';
import { DevicePane } from '../../components/DualPane/Panes/DevicePane/DevicePane';
import { DualPane } from '../../components/DualPane/DualPane';
import { PageType } from '../../../../redux/analytics/analyticsTypes';
import { pageLeftThunk } from '../../../../redux/analytics/analyticsThunks';
import { ForgotPasswordPane } from '../../components/DualPane/Panes/ForgotPasswordPane/ForgotPasswordPane';

interface Props {
  sendEmail: Function;
  pageLeft: Function;
}

const ChangePassword: React.FC<Props> = ({ sendEmail, pageLeft }) => (
  <DualPane
    pageLeft={pageLeft}
    pageType={PageType.FORGOT_PASSWORD}
    leftPane={<DevicePane />}
    rightPane={(loadingState: [boolean, Dispatch<SetStateAction<boolean>>], handleExternalRouting: (url?: Nullable<string>) => void, handleError: (err: any) => void) => (
      <ForgotPasswordPane sendEmail={sendEmail} loadingState={loadingState} handleError={handleError} handleExternalRouting={handleExternalRouting} />
    )}
  />
);

const connectRedux = connect(
  () => {
    return {};
  },
  (dispatch: Function) => ({
    sendEmail: async (resetPasswordEmailPayload: ResetPasswordEmailPayload) => {
      return dispatch(resetPasswordEmailThunk(resetPasswordEmailPayload));
    },
    pageLeft: async (pageType: PageType) => {
      return dispatch(pageLeftThunk(pageType));
    }
  })
);

export default compose(connectRedux)(ChangePassword);
