import React from 'react';
import { QuickSearchField } from './QuickSearchField';

export interface ServerQuickSearchProps {
  customFilter: (searchValue: string) => any;
  searchText: string;
}

export const GridServerQuickSearch: React.FC<ServerQuickSearchProps> = ({ customFilter, searchText }) => {
  const requestSearch = async (searchValue: string): Promise<void> => {
    customFilter(searchValue);
  };

  return <QuickSearchField searchText={searchText} requestSearch={requestSearch} />;
};
