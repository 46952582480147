import { getPushNotificationsActions, getRegisterClientDeviceActions } from './clientDeviceActions';
import { ClientDevice, ClientDevicePushNotificationsResponse, ClientDeviceState } from './clientDeviceTypes';
import { getClientDeviceIdSelector } from './clientDeviceSelectors';
import { timestampAndRandomCombinedUUIDString } from '../../../shared/src/util/uuid';
import { ThunkParams } from '../types';
import { tz } from 'moment-timezone';
import { UserAgent } from '../../utils/userAgent';
import { ErrorPayload } from '../common/types';

const CLIENT_DEVICE = 'clientDevice';

function storeClientDevice(clientDeviceState: ClientDeviceState) {
  if (localStorage) {
    localStorage.setItem(CLIENT_DEVICE, JSON.stringify(clientDeviceState));
  }
}

export function getClientDevice(): ClientDeviceState | null {
  const clientDevice: any = localStorage.getItem(CLIENT_DEVICE);
  return JSON.parse(clientDevice);
}

export function removeClientDevice(): void {
  localStorage.removeItem(CLIENT_DEVICE);
}

function processSuccessClientDeviceRegister(payload: any) {
  const clientDeviceState: ClientDeviceState = {
    clientDeviceId: payload.id,
    isRegistered: true,
    timeZoneId: tz.guess(),
    pushNotificationsById: {},
    clientDevicesById: {}
  };
  storeClientDevice(clientDeviceState);
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getRegisterClientDeviceActions.success(clientDeviceState));
  };
}

function processFailClientDeviceRegister(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getRegisterClientDeviceActions.fail(error));
  };
}

export function registerClientDeviceThunk(clientDeviceState: ClientDeviceState) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    const client = new UserAgent();
    const payload: ClientDevice = {
      id: getClientDeviceIdSelector(clientDeviceState) || timestampAndRandomCombinedUUIDString(),
      personId: undefined,
      appName: client.appName,
      appVersion: client.appVersion,
      appBuild: undefined,
      userAgent: client.userAgent,
      clientAppType: client.clientAppType,
      osType: client.osType,
      osVersion: client.osVersion,
      browserName: client.browserName,
      browserVersion: client.browserVersion,
      deviceName: client.deviceName,
      deviceMaker: client.deviceMaker,
      clientDeviceType: client.clientDeviceType,
      screenWidth: client.screenWidth,
      screenHeight: client.screenHeight,
      preferredLanguages: client.preferredLanguages,
      clientTimeZoneId: client.clientTimeZoneId
    };
    dispatch(getRegisterClientDeviceActions.start());
    apiClient.put(`v1/clientDevice/${payload.id}`, {
      data: payload,
      auth: true,
      successAction: processSuccessClientDeviceRegister,
      failAction: processFailClientDeviceRegister
    });
  };
}

export function getPushNotificationsThunk(personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getPushNotificationsActions.start());
    return apiClient.get(`/v1/clientDevice/pushNotifications/${personId}`, {
      auth: true,
      successAction: processGetPushNotificationsSuccess,
      failAction: processGetPushNotificationsFail
    });
  };
}

export function processGetPushNotificationsSuccess(pushNotifications: ClientDevicePushNotificationsResponse): Function {
  return function (dispatch: Function): void {
    dispatch(getPushNotificationsActions.success(pushNotifications));
  };
}

export function processGetPushNotificationsFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getPushNotificationsActions.fail(error));
  };
}
