import { ReduxState } from 'redux/types';
import { ChatMessage } from './chatTypes';

export const getChatStateSelector = (state: ReduxState) => {
  return state.chat;
};

export const getChatGPTMobileMessagesSelector = (personId: string, state: ReduxState): ChatMessage[] => {
  return getChatStateSelector(state)?.chatGPTMobileMessages[personId] || [];
};

export const getChatGPTDeviceMessagesSelector = (personId: string, state: ReduxState): ChatMessage[] => {
  return getChatStateSelector(state)?.chatGPTDeviceMessages[personId] || [];
};

export const getChatSleepCoachMessagesSelector = (personId: string, state: ReduxState): ChatMessage[] => {
  return getChatStateSelector(state)?.chatSleepCoachMessages[personId] || [];
};

export const getLatestPersonMessageSelector = (personId: string, state: ReduxState): ChatMessage | undefined => {
  const messages = getChatSleepCoachMessagesSelector(personId, state);
  let latestPersonMessage;
  for (let i = messages?.length - 1; i >= 0; i--) {
    const message = messages[i];
    if (message.senderId === personId) {
      latestPersonMessage = message;
      break;
    }
  }

  return latestPersonMessage;
};

export const isLatestPersonSleepCoachMessageUnreadSelector = (personId: string, state: ReduxState): boolean => {
  const lastPersonMessage = getLatestPersonMessageSelector(personId, state);
  return lastPersonMessage ? !lastPersonMessage.messageReadTs : false;
};

export const getLatestUnreadSleepCoachMessagesSelector = (state: ReduxState): ChatMessage[] => {
  return getChatStateSelector(state)?.latestUnreadSleepCoachMessages;
};

export const isLoadingChatGPTDeviceMessagesSelector = (personId: string, state: ReduxState): boolean => {
  const chatState = state.chat;
  return !!chatState.isLoadingChatGPTDeviceMessages && !!chatState.isLoadingChatGPTDeviceMessages[personId];
};
