import { isClient } from '../utils/util';
import * as dotenv from 'dotenv';
const isProduction = process.env.NODE_ENV === 'production';
if (!isClient()) {
  process = {
    ...process,
    listeners: process.listeners,
    env: {
      ...process.env,
      ...dotenv.config().parsed
    }
  };
}

const MISSING_ENVIRONMENT_VARIABLE = 'Error: Missing Environment variable: ';

// unfortunately we have to check the the process.env.varname for each config value.
// Otherwise we won't be able to return a string.
export default {
  getAppBaseUrl: (): string => {
    if (isClient()) {
      return window.__data__.env.APP_BASE_URL;
    } else if (process?.env?.APP_BASE_URL) {
      return process.env.APP_BASE_URL;
    } else {
      throw new Error(MISSING_ENVIRONMENT_VARIABLE + 'APP_BASE_URL');
    }
  },
  getAppVersion: (): string => {
    if (isClient()) {
      return window.__data__.env.APP_VERSION;
    } else if (process?.env?.APP_VERSION) {
      return process.env.APP_VERSION;
    } else {
      throw new Error(MISSING_ENVIRONMENT_VARIABLE + 'APP_VERSION');
    }
  },

  getApiBaseUrl: (): string => {
    if (isClient()) {
      return window.__data__.env.API_BASE_URL;
    } else if (process?.env?.API_BASE_URL) {
      return process.env.API_BASE_URL;
    } else {
      throw new Error(MISSING_ENVIRONMENT_VARIABLE + 'API_BASE_URL');
    }
  },
  getAuthBaseUrl: (): string => {
    if (isClient()) {
      return window.__data__.env.AUTH_BASE_URL;
    } else if (process?.env?.AUTH_BASE_URL) {
      return process.env.AUTH_BASE_URL;
    } else {
      throw new Error(MISSING_ENVIRONMENT_VARIABLE + 'AUTH_BASE_URL');
    }
  },
  getAppId: (): string => {
    if (process?.env?.APP_ID) {
      return process.env.APP_ID;
    } else {
      throw new Error(MISSING_ENVIRONMENT_VARIABLE + 'APP_ID');
    }
  },
  getClientSecret: (): string => {
    if (process?.env?.CLIENT_SECRET) {
      return process.env.CLIENT_SECRET;
    } else {
      throw new Error(MISSING_ENVIRONMENT_VARIABLE + 'CLIENT_SECRET');
    }
  },

  getBugSnagKey: (): string => {
    if (isClient()) {
      return window.__data__.env.BUGSNAG_API_KEY;
    } else if (process?.env?.BUGSNAG_API_KEY) {
      return process.env.BUGSNAG_API_KEY;
    } else {
      throw new Error(MISSING_ENVIRONMENT_VARIABLE + 'BUGSNAG_API_KEY');
    }
  },

  getDeploymentEnv: (): string => {
    if (isClient()) {
      return window.__data__.env.DEPLOYMENT_ENV;
    } else if (process?.env?.DEPLOYMENT_ENV) {
      return process.env.DEPLOYMENT_ENV;
    } else {
      throw new Error(MISSING_ENVIRONMENT_VARIABLE + 'DEPLOYMENT_ENV');
    }
  }
};
