import React from 'react';
import { Text } from '../Typography/Text';
import style from './TextCrumb.scss';

interface Props {
  label: string;
  url: string;
  state?: Nullable<any>;
}

export const TextCrumb: React.FC<Props> = ({ label, url, state }): JSX.Element => (
  <Text variant="md" className={style.text}>
    {label}
  </Text>
);
