import React, { MouseEventHandler } from 'react';
import style from './RankedContentIcons.scss';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { DeviceContentPage, ProgramContent, WorkflowTaskTemplate } from '../../redux/content/contentTypes';
import { RankType } from '../../utils/content';

interface Props {
  remove: MouseEventHandler<any>;
  moveUp: MouseEventHandler<any>;
  moveDown: MouseEventHandler<any>;
  content: ProgramContent | DeviceContentPage | WorkflowTaskTemplate;
  newContent?: Nullable<ProgramContent | DeviceContentPage | WorkflowTaskTemplate>;
  rankFieldName: RankType;
  maxPageRank: number;
  fontSize: 'small' | 'inherit';
  cssClass: 'tileIcon' | 'subTileIcon';
}

export const RankedContentIcons: React.FC<Props> = ({ remove, moveUp, moveDown, content, newContent, rankFieldName, maxPageRank, fontSize, cssClass }) => (
  <React.Fragment>
    {content.id !== newContent?.id && (
      <IconButton className={clsx(style[cssClass], style.redIcon)} component="span" onClick={remove}>
        <DeleteIcon fontSize={fontSize} />
      </IconButton>
    )}
    <IconButton className={style[cssClass]} component="span" onClick={moveUp} disabled={content[rankFieldName] === 1}>
      <ArrowUpwardIcon fontSize={fontSize} />
    </IconButton>
    <IconButton className={style[cssClass]} component="span" onClick={moveDown} disabled={(content[rankFieldName] ? content[rankFieldName] : 0) === maxPageRank}>
      <ArrowDownwardIcon fontSize={fontSize} />
    </IconButton>
  </React.Fragment>
);
