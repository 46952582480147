import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DeviceGroup, Person, PersonSetting, SleepSchedule } from '../../redux/person/personTypes';
import { getAdminPanelApiErrorSelector, getLastLoadedPersonSelector, isAdminPanelApiLoadingSelector } from '../../redux/person/personSelector';
import '../../assets/styles/Table.scss';
import style from './PersonProgram.scss';
import { getPersonProgramAndSettingsByEmailThunk, updatePersonProgramStepThunk, updatePersonSettingsThunk, updateSleepScheduleThunk } from '../../redux/person/personThunks';
import { ReduxState } from '../../redux/types';
import Config from '../../config/config';
import { isCreatingAdHocContentSelector } from '../../redux/content/contentSelectors';
import { ProgramType } from '../../redux/content/contentTypes';
import { timestampAndRandomCombinedUUIDString } from '../../../shared/src/util/uuid';
import { checkIsNumber, minMaxCheck } from '../../utils/util';
import { useLocation, useParams } from 'react-router-dom';
import { NavBar } from '../../components/NavBar/NavBar';
import { BreadCrumbData } from '../../redux/common/types';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '../../redux/oauth/oauthSelectors';
import { defaultPersonProgramCrumb, defaultPersonProgramCrumbs } from '../../utils/breadcrumbs';
import { clearUpdatePersonProgramAction } from '../../redux/person/personActions';

interface Props {
  selectedPerson?: Nullable<Person>;
  breadCrumbs: BreadCrumbData[];
  requestGetPerson: (email: string) => void;
  requestUpdatePersonProgram: (personId: string, programType: ProgramType, programStep: number) => Promise<any>;
  clearPersonProgramStore: () => void;
  requestUpdatePersonSettings: (personSettings: PersonSetting) => Promise<any>;
  isAdminPanelApiLoading: boolean;
  adminPanelApiError?: Nullable<string>;
  isCreatingAdHocContent: boolean;
  requestUpdateSleepSchedule: (sleepSchedule: SleepSchedule) => Promise<any>;
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
}

const PersonProgram: React.FC<Props> = ({
  requestGetPerson,
  breadCrumbs,
  selectedPerson,
  requestUpdatePersonProgram,
  clearPersonProgramStore,
  requestUpdatePersonSettings,
  isAdminPanelApiLoading,
  adminPanelApiError,
  isCreatingAdHocContent,
  requestUpdateSleepSchedule,
  appendBreadCrumbs
}) => {
  const [currentEmail, setCurrentEmail] = useState('');

  let [sleepTimeHourSetting, sleepTimeMinuteSetting] = selectedPerson?.currentSleepSchedule?.sleepTime?.split(':') || ['', ''];
  let [wakeTimeHourSetting, wakeTimeMinuteSetting] = selectedPerson?.currentSleepSchedule?.wakeTime?.split(':') || ['', ''];
  const [selectedWindDownWeek, setSelectedWindDownWeek] = useState<Nullable<number>>(selectedPerson?.currentWindDownWeek);

  const [sleepTimeHour, setSleepTimeHour] = useState<string>(sleepTimeHourSetting);
  const [sleepTimeMinute, setSleepTimeMinute] = useState<string>(sleepTimeMinuteSetting);

  const [wakeTimeHour, setWakeTimeHour] = useState<string>(wakeTimeHourSetting);
  const [wakeTimeMinute, setWakeTimeMinute] = useState<string>(wakeTimeMinuteSetting);

  const [description, setDescription] = useState<string>(selectedPerson?.currentSleepSchedule?.description || '');

  const [testCbtiContentDurationSecondsString, setTestCbtiContentDurationSecondsString] = useState<Nullable<number>>(
    selectedPerson?.personSettings?.testCbtiContentDurationSeconds
  );
  const [cohortIdSetting, setCohortIdSetting] = useState<number>(selectedPerson?.personSettings?.cohortId || 0);
  const [deviceGroupSetting, setDeviceGroupSetting] = useState<Nullable<DeviceGroup | string>>(selectedPerson?.personSettings?.deviceGroup);

  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [apiStatusMessage, setApiStatusMessage] = useState<string>('');

  const location: any = useLocation();
  const params: any = useParams();

  useEffect(() => {
    clearPersonProgramStore();
  }, []);

  useEffect(() => {
    if (params['email']) {
      setCurrentEmail(() => params['email']);
      updateUser(params['email']);
    } else {
      setCurrentEmail(() => '');
    }
  }, [params]);

  useEffect(() => {
    [sleepTimeHourSetting, sleepTimeMinuteSetting] = selectedPerson?.currentSleepSchedule?.sleepTime?.split(':') || ['', ''];
    [wakeTimeHourSetting, wakeTimeMinuteSetting] = selectedPerson?.currentSleepSchedule?.wakeTime?.split(':') || ['', ''];
    setSelectedWindDownWeek(() => selectedPerson?.currentWindDownWeek);
    setSleepTimeHour(() => sleepTimeHourSetting);
    setSleepTimeMinute(() => sleepTimeMinuteSetting);
    setWakeTimeHour(() => wakeTimeHourSetting);
    setWakeTimeMinute(() => wakeTimeMinuteSetting);
    setDescription(() => selectedPerson?.currentSleepSchedule?.description || '');
    setTestCbtiContentDurationSecondsString(() => selectedPerson?.personSettings?.testCbtiContentDurationSeconds);
    setCohortIdSetting(() => selectedPerson?.personSettings?.cohortId || 0);
    setDeviceGroupSetting(() => selectedPerson?.personSettings?.deviceGroup);
  }, [selectedPerson]);

  const updateUser = (email: string) => {
    requestGetPerson(email);
    setLoadingMessage(() => '');
    setApiStatusMessage(() => '');
  };

  const updatePersonProgram = async () => {
    const windDownWeekText = selectedWindDownWeek && selectedWindDownWeek > 0 ? `Week ${selectedWindDownWeek}` : 'Disabled';
    setLoadingMessage(`Updating Wind Down Status to ${windDownWeekText}...`);
    setApiStatusMessage('');
    if (selectedWindDownWeek || selectedWindDownWeek === 0) {
      await requestUpdatePersonProgram(selectedPerson?.id || '', ProgramType.WIND_DOWN, selectedWindDownWeek);
    }
    updateUser(currentEmail);
    setApiStatusMessage(`Wind Down status successfully updated to ${windDownWeekText}`);
  };

  const onPersonProgramChange = (event) => {
    const value = event.target.options[event.target.selectedIndex].value;
    setSelectedWindDownWeek(value);
  };

  const onUpdateBedReset = async (enable: boolean) => {
    setLoadingMessage(enable ? 'Enabling Bed Reset Program...' : 'Disabling Bed Reset Program...');
    setApiStatusMessage('');
    await requestUpdatePersonProgram(selectedPerson?.id || '', ProgramType.BED_RESET, enable ? 1 : 0);
    updateUser(currentEmail);
    setApiStatusMessage(enable ? 'Bed Reset status successfully enabled' : 'Bed Reset status successfully disabled');
  };

  const onUpdateManualSleepLogProgram = async (enable: boolean) => {
    setLoadingMessage(enable ? 'Enabling Manual Sleep Log Program...' : 'Disabling Manual Sleep Log Program...');
    setApiStatusMessage('');
    await requestUpdatePersonProgram(selectedPerson?.id || '', ProgramType.MANUAL_SLEEP_LOG, enable ? 1 : 0);
    updateUser(currentEmail);
    setApiStatusMessage(enable ? 'Manual Sleep Log Program successfully enabled' : 'Manual Sleep Log Program successfully disabled');
  };

  const onUpdateSleepTimes = async () => {
    setLoadingMessage('Updating Bed Times...');
    const newWakeTimeHour = wakeTimeHour && wakeTimeHour.length === 1 ? `0${wakeTimeHour}` : wakeTimeHour;
    const newWakeTimeMinute = wakeTimeMinute && wakeTimeMinute.length === 1 ? `0${wakeTimeMinute}` : wakeTimeMinute;
    const newSleepTimeHour = sleepTimeHour && sleepTimeHour.length === 1 ? `0${sleepTimeHour}` : sleepTimeHour;
    const newSleepTimeMinute = sleepTimeMinute && sleepTimeMinute.length === 1 ? `0${sleepTimeMinute}` : sleepTimeMinute;
    await updateSleepTimes(newWakeTimeHour, newWakeTimeMinute, newSleepTimeHour, newSleepTimeMinute, description);
  };

  const updateSleepTimes = async (
    newWakeTimeHour: Nullable<string>,
    newWakeTimeMinute: Nullable<string>,
    newSleepTimeHour: Nullable<string>,
    newSleepTimeMinute: Nullable<string>,
    description: Nullable<string>
  ) => {
    const newWakeTime = `${newWakeTimeHour}:${newWakeTimeMinute}:00`;
    const newSleepTime = `${newSleepTimeHour}:${newSleepTimeMinute}:00`;
    setApiStatusMessage('');
    if (selectedPerson?.id) {
      const newSleepSchedule: SleepSchedule = {
        id: timestampAndRandomCombinedUUIDString(),
        personId: selectedPerson.id,
        organizationId: selectedPerson.organizationId,
        sleepTime: newSleepTime,
        wakeTime: newWakeTime,
        description: description || undefined,
        createdByUser: false
      };
      await requestUpdateSleepSchedule(newSleepSchedule);
      updateUser(currentEmail);
      setApiStatusMessage(`Bed Times updated to: ${newSleepTime} - ${newWakeTime}`);
    }
  };

  const onUpdateCbtiTestSetting = async () => {
    setLoadingMessage('Updating CBT-I Test content setting...');
    setApiStatusMessage('');
    const newCbtiTestContentValue = testCbtiContentDurationSecondsString || null;
    if (selectedPerson?.personSettings) {
      const newSettings: PersonSetting = {
        ...selectedPerson?.personSettings,
        testCbtiContentDurationSeconds: newCbtiTestContentValue
      };
      await requestUpdatePersonSettings(newSettings);
      updateUser(currentEmail);
      setApiStatusMessage(`CBT-I Content Test setting updated to: ${testCbtiContentDurationSecondsString} seconds`);
    }
  };

  const onUpdateCohortSetting = async () => {
    setLoadingMessage('Updating Cohort and device group setting...');
    setApiStatusMessage('');
    const newCohortIdSetting = cohortIdSetting || null;
    const newDeviceGroupSetting = deviceGroupSetting || null;
    if (selectedPerson?.personSettings) {
      const newSettings: PersonSetting = {
        ...selectedPerson?.personSettings,
        cohortId: newCohortIdSetting,
        deviceGroup: newDeviceGroupSetting as DeviceGroup
      };
      await requestUpdatePersonSettings(newSettings);
      updateUser(currentEmail);
      setApiStatusMessage(`Cohort ID setting updated to: ${cohortIdSetting} and device group updated to ${deviceGroupSetting}`);
    }
  };

  const bedResetEnabledtext = selectedPerson?.currentBedResetStep ? 'enabled' : 'disabled';
  const bedResetButtontext = selectedPerson?.currentBedResetStep ? 'disable' : 'enable';
  const manualSleepLogEnabledtext = selectedPerson?.currentManualSleepLogProgramStep ? 'enabled' : 'disabled';
  const manualSleepLogButtontext = selectedPerson?.currentManualSleepLogProgramStep ? 'disable' : 'enable';

  // is only valid if all fields are set or all fields are null. E.g. only sleep and time are set or both are unset
  const isSleepTimesDataValid =
    (checkIsNumber(sleepTimeHour) && checkIsNumber(sleepTimeMinute) && checkIsNumber(wakeTimeHour) && checkIsNumber(wakeTimeMinute)) ||
    (!sleepTimeHour && !sleepTimeMinute && !wakeTimeHour && !wakeTimeMinute);

  // is only valid if above condition is true and if values have changed.
  const sleepScheduleUpdateEnabled =
    isSleepTimesDataValid &&
    (sleepTimeHourSetting !== sleepTimeHour ||
      sleepTimeMinuteSetting !== sleepTimeMinute ||
      wakeTimeHourSetting !== wakeTimeHour ||
      wakeTimeMinuteSetting !== wakeTimeMinute ||
      description !== selectedPerson?.currentSleepSchedule?.description);

  const cbtiTestSettingUpdateEnabled = selectedPerson?.personSettings?.testCbtiContentDurationSeconds === testCbtiContentDurationSecondsString;
  const cohortIdSettingUpdateEnabled = selectedPerson?.personSettings?.cohortId !== cohortIdSetting || selectedPerson?.personSettings?.deviceGroup !== deviceGroupSetting;

  const sleepScheduleCreatedByUserString = selectedPerson?.currentSleepSchedule?.createdByUser ? 'Yes' : 'No';

  return (
    <div className={style.outerContainer}>
      <NavBar
        breadCrumbs={breadCrumbs}
        appendBreadCrumbs={appendBreadCrumbs}
        defaultCurrentCrumb={defaultPersonProgramCrumb}
        defaultCrumbHistory={defaultPersonProgramCrumbs}
        overrideUrl={location?.pathname}
      />
      <div className={style.container}>
        <div className={style.selectBar}>
          <div className={style.description}>Email Address: &nbsp;</div>
          <input
            className={style.input}
            type="text"
            value={currentEmail}
            onKeyDown={(e) => {
              if (e.key === 'Enter') updateUser(currentEmail);
            }}
            onChange={(e) => setCurrentEmail(e.target.value)}
          />
          <button className={style.button} onClick={() => updateUser(currentEmail)}>
            Get Info
          </button>
        </div>
        {!selectedPerson && (
          <div className={style.resultRow}>
            No Data available yet. This could be due to either of these reasons:
            <ul>
              <li>You have not requested data yet.</li>
              <li>You don't have permission to view the data.</li>
              <li>We could not find the email address you entered.</li>
            </ul>
          </div>
        )}
        {selectedPerson && (
          <div className={style.resultRow}>
            <div>
              <span className={style.title}>Hint:&nbsp;</span>
              <span className={style.body}>The bottom of the page will show a confirmation or error for every action executed on this page.</span>
            </div>
            <div>
              <span className={style.title}>Name:&nbsp;</span>
              <span className={style.body}>{selectedPerson.fullName}</span>
            </div>
            <div>
              <div>
                <span className={style.title}>Cohort ID (leave empty to remove setting):&nbsp;</span>
                <input className={style.cohortIdInput} type="number" value={cohortIdSetting} onChange={(event) => setCohortIdSetting(parseInt(event.target.value))} />
              </div>
              <div>
                <span className={style.title}>Device Group:&nbsp;</span>
                <select
                  className={style.selectDeviceGroup}
                  defaultValue={selectedPerson?.personSettings?.deviceGroup || ''}
                  onChange={(event) => setDeviceGroupSetting(event.target.options[event.target.selectedIndex].value)}
                >
                  <option value={undefined}></option>
                  <option value={DeviceGroup.NO_DEVICE}>No Device</option>
                  <option value={DeviceGroup.DEVICE_B}>Device B</option>
                  <option value={DeviceGroup.DEVICE_C}>Device C</option>
                  <option value={DeviceGroup.EXTERNAL_TESTING}>External Testing</option>
                  <option value={DeviceGroup.INTERNAL_TESTING}>Internal Testing</option>
                  <option value={DeviceGroup.DEVELOPMENT}>Development</option>
                  <option value={DeviceGroup.CLINICAL_TRIAL}>Clinical Trial</option>
                  <option value={DeviceGroup.DEVICE_LL}>Device LL</option>
                  <option value={DeviceGroup.EXTERNAL_DEMO_LL}>External Demo LL</option>
                  <option value={DeviceGroup.UNKNOWN}>Unknown</option>
                </select>
              </div>

              <button className={style.button} onClick={() => onUpdateCohortSetting()} disabled={!cohortIdSettingUpdateEnabled}>
                Update
              </button>
            </div>
            <br />

            <div>
              <span className={style.title}>Current Wind Down Program Week:&nbsp;</span>
              <span className={style.body}>{`${selectedPerson?.currentWindDownWeek || 'Disabled'}`}</span>
            </div>
            <div>
              <span className={style.title}>Change Wind Down Status:&nbsp;</span>
              <select className={style.select} defaultValue={selectedPerson?.currentWindDownWeek || 0} onChange={onPersonProgramChange}>
                <option value={0}>Disabled</option>
                <option value={1}>Sleep Sounds</option>
                <option value={2}>Sleep Stories</option>
                <option value={3}>Imagery</option>
                <option value={4}>Breathing</option>
                <option value={5}>PMR</option>
                <option value={6}>Meditation</option>
                <option value={7}>All</option>
              </select>
              <button className={style.button} onClick={updatePersonProgram}>
                Change Program
              </button>
            </div>

            <div>
              <span className={style.title}>Bed Reset Feature:&nbsp;</span>
              <span className={style.body}>{bedResetEnabledtext}</span>

              <button className={style.button} onClick={() => onUpdateBedReset(!selectedPerson.currentBedResetStep)}>
                {bedResetButtontext}
              </button>
            </div>
            <br />
            <div>
              <div className={style.sectionTitle}>CBT-I Programs</div>
            </div>
            {Config.getDeploymentEnv() != 'production' && (
              <div>
                <span className={style.title}>TESTING: CBT-I lesson update in seconds (leave empty to remove setting):&nbsp;</span>
                <input
                  className={style.cbtTestInput}
                  type="number"
                  value={testCbtiContentDurationSecondsString || ''}
                  onChange={(event) => setTestCbtiContentDurationSecondsString(parseInt(event.target.value))}
                />

                <button className={style.button} onClick={() => onUpdateCbtiTestSetting()} disabled={cbtiTestSettingUpdateEnabled}>
                  Update
                </button>
              </div>
            )}
            <br />
            <div>
              <span className={style.title}>Sleep Schedule (24hr format):&nbsp;</span>
              <div>
                <span className={style.sleepTimeText}>Sleep Time:&nbsp;</span>
                <input
                  className={style.sleepTimeInput}
                  placeholder="hh"
                  min={0}
                  max={23}
                  maxLength={2}
                  type="text"
                  value={sleepTimeHour}
                  onChange={(event) => setSleepTimeHour(minMaxCheck(event))}
                />
                :
                <input
                  className={style.sleepTimeInput}
                  placeholder="mm"
                  min={0}
                  max={59}
                  maxLength={2}
                  type="text"
                  value={sleepTimeMinute}
                  onChange={(event) => setSleepTimeMinute(minMaxCheck(event))}
                />
              </div>
              <div>
                <span>Wake Up Time:&nbsp;</span>
                <input
                  className={style.sleepTimeInput}
                  placeholder="hh"
                  min={0}
                  max={23}
                  maxLength={2}
                  type="text"
                  value={wakeTimeHour}
                  onChange={(event) => setWakeTimeHour(minMaxCheck(event))}
                />
                :
                <input
                  className={style.sleepTimeInput}
                  placeholder="mm"
                  min={0}
                  max={59}
                  maxLength={2}
                  type="text"
                  value={wakeTimeMinute}
                  onChange={(event) => setWakeTimeMinute(minMaxCheck(event))}
                />
              </div>
              <div>
                <span>Sleep Schedule description (leave empty for default text):&nbsp;</span>
                <input className={style.sleepScheduleDescription} placeholder="" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
              </div>
              <div>
                <span>Current sleep schedule created by user:&nbsp;</span>
                <span>{sleepScheduleCreatedByUserString}</span>
              </div>
              <div>Note: Updating the sleep schedule description without changing sleep times will cause a new sleep schedule to be sent to the user.&nbsp;</div>
              <button className={style.buttonSleepTimes} onClick={() => onUpdateSleepTimes()} disabled={!sleepScheduleUpdateEnabled}>
                Update
              </button>
            </div>
            <br />
            <div>
              <span className={style.title}>Manual Sleep Log Program:&nbsp;</span>
              <span className={style.body}>{manualSleepLogEnabledtext}</span>
              <button className={style.button} onClick={() => onUpdateManualSleepLogProgram(!selectedPerson.currentManualSleepLogProgramStep)}>
                {manualSleepLogButtontext}
              </button>
            </div>
            <br />
            <br />
            {(apiStatusMessage || isAdminPanelApiLoading || isCreatingAdHocContent || adminPanelApiError) && (
              <div>
                <span className={style.title}>Status:&nbsp;</span>
                {apiStatusMessage && <span className={style.statusGreen}>{apiStatusMessage}</span>}
                {(isAdminPanelApiLoading || isCreatingAdHocContent) && <span className={style.statusOrgange}>{loadingMessage}</span>}
                {adminPanelApiError && !isAdminPanelApiLoading && !apiStatusMessage && <span className={style.statusRed}>{adminPanelApiError}</span>}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      selectedPerson: getLastLoadedPersonSelector(state),
      isAdminPanelApiLoading: isAdminPanelApiLoadingSelector(state),
      adminPanelApiError: getAdminPanelApiErrorSelector(state),
      isCreatingAdHocContent: isCreatingAdHocContentSelector(state),
      breadCrumbs: getBreadcrumbsSelector(state)
    };
  },
  (dispatch: Function) => ({
    requestGetPerson: (email: string) => {
      dispatch(getPersonProgramAndSettingsByEmailThunk(email));
    },
    requestUpdatePersonProgram: (personId: string, programType: ProgramType, programStep: number) => {
      return dispatch(updatePersonProgramStepThunk(personId, programType, programStep));
    },
    clearPersonProgramStore: () => {
      dispatch(clearUpdatePersonProgramAction());
    },
    requestUpdatePersonSettings: (personSettings: PersonSetting) => {
      return dispatch(updatePersonSettingsThunk(personSettings));
    },
    requestUpdateSleepSchedule: (sleepSchedule: SleepSchedule) => {
      return dispatch(updateSleepScheduleThunk(sleepSchedule));
    },
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    }
  })
);

export default compose(connectRedux)(PersonProgram);
