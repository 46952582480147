import React, { Dispatch, SetStateAction, useEffect } from 'react';
import style from './ThankYouPane.scss';
import { CheckIcon } from '../../../CheckIcon/CheckIcon';
import { BillingIcon } from '../../../BillingIcon/BillingIcon';
import { Box, Fade, Hidden, Typography } from '@material-ui/core';
import { FULLSLEEP_COM_SITE_URL } from '../../../../../../utils/navigationUtils';
import { HeaderLogo } from '../../../HeaderLogo/HeaderLogo';
import { TermsLink } from '../../../TermsLink/TermsLink';
import { AccountButton } from '../../../AccountButton/AccountButton';

interface Props {
  loadingState: [boolean, Dispatch<SetStateAction<boolean>>];
  handleExternalRouting: (url: string) => void;
  transactionAmount: string;
}

export const ThankYouPane: React.FC<Props> = ({ transactionAmount, loadingState, handleExternalRouting }) => {
  useEffect(() => {
    loadingState[1](false);
  }, []);

  const done = () => {
    loadingState[1](true);
    handleExternalRouting(FULLSLEEP_COM_SITE_URL);
  };

  return (
    <React.Fragment>
      {/* @ts-expect-error: TODO add description */}
      <Hidden mdUp>
        <Box className={style.mobileHeader}>
          <Box className={style.weightedEnclosure}>
            <Box className={style.purchaseDetailsHeaderBox}>
              <HeaderLogo />
            </Box>
          </Box>
        </Box>
      </Hidden>

      <Fade in timeout={600} mountOnEnter unmountOnExit>
        <Box className={style.weightedEnclosure}>
          <Box className={style.outerColumnBox}>
            <Box className={style.purchaseDetailsBox}>
              <Box className={style.purchaseFlexColumnEnclosure}>
                <Box className={style.thankYouEntry}>
                  <Box className={style.checkIconBox}>
                    <CheckIcon />
                  </Box>
                  <Box>
                    <Typography className={style.thankYouText}>Thanks for your payment</Typography>
                  </Box>
                </Box>
                <Box className={style.thankYouSlide}>
                  <Box className={style.thankYouDescriptionBox}>
                    <Typography className={style.thankYouDescription}>A payment to Full Sleep - Koko Labs will appear on your statement.</Typography>
                  </Box>
                  <Box className={style.billingEnclosure}>
                    <BillingIcon />
                    <Box className={style.blockDisplay}>
                      <Box className={style.billingItem}>
                        <Box className={style.billingItemEnclosure}>
                          <Box className={style.billLabelBox}>
                            <Typography className={style.billLabel}>FULL SLEEP - KOKO LABS</Typography>
                          </Box>
                        </Box>
                        <Box className={style.billDash} />
                        <Box className={style.billPriceBox}>
                          <Typography className={style.billPrice}>{transactionAmount}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={1}>
                    <AccountButton label={'DONE'} onClick={done} loading={loadingState[0]} cssClass={style.doneButton} />
                  </Box>
                </Box>
              </Box>
            </Box>{' '}
            {/* @ts-expect-error: TODO add description */}
            <Hidden mdUp>
              <Box className={style.thankYouFooter}>
                <TermsLink linkColor={'rgba(26, 26, 26, 0.5)'} />
              </Box>
            </Hidden>
          </Box>
        </Box>
      </Fade>
    </React.Fragment>
  );
};
