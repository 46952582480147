import React from 'react';

export const BillingIcon: React.FC = () => (
  <svg focusable="false" width="100%" height="68" viewBox="0 0 384 68" fill="none">
    <path
      d="M0 3.923C0 1.756 2.686 0 6 0h372c3.314 0 6 1.756 6 3.923V68l-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23L96 68l-8-5.23L80 68l-8-5.23L64 68l-8-5.23L48 68l-8-5.23L32 68l-8-5.23L16 68l-8-5.23L0 68V3.923z"
      fillOpacity=".03"
      fill="#000"
      fillRule="nonzero"
    />
    <path
      d="M375.646 62.538l-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5L8 62.307l-.354.231L.5 67.211V3.923C.5 1.937 2.962.327 6 .327h372c3.038 0 5.5 1.61 5.5 3.596v63.288l-7.146-4.673-.354-.231-.354.231z"
      strokeOpacity=".08"
      stroke="#000"
      strokeWidth=".5"
    />
  </svg>
);
