import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  asButton?: Nullable<boolean>;
  buttonSize?: Nullable<'small' | 'medium'>;
  style?: React.CSSProperties; //external component does not support nullable types, so we can't apply Nullable<> type here
}

export const CommentIcon: React.FC<Props> = ({ asButton, buttonSize, style }) => {
  const svgIcon = (): JSX.Element => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" style={style}>
      <g clipPath="url(#clip0_2217_3002)">
        <rect width="20" height="20" rx="10" fill="#007EB5" />
        <path
          d="M13.5625 5.5H6.4375C6.08971 5.50099 5.75644 5.63959 5.51052 5.88552C5.26459 6.13144 5.12599 6.46471 5.125 6.8125V11.3125C5.12599 11.6603 5.26459 11.9936 5.51052 12.2395C5.75644 12.4854 6.08971 12.624 6.4375 12.625H7.375V14.5L9.57156 12.6686C9.60529 12.6404 9.64785 12.625 9.6918 12.625H13.5625C13.9103 12.624 14.2436 12.4854 14.4895 12.2395C14.7354 11.9936 14.874 11.6603 14.875 11.3125V6.8125C14.874 6.46471 14.7354 6.13144 14.4895 5.88552C14.2436 5.63959 13.9103 5.50099 13.5625 5.5Z"
          stroke="white"
          strokeWidth="0.8"
          strokeLinejoin="round"
        />
        <path
          d="M7.75 9.8125C8.16421 9.8125 8.5 9.47671 8.5 9.0625C8.5 8.64829 8.16421 8.3125 7.75 8.3125C7.33579 8.3125 7 8.64829 7 9.0625C7 9.47671 7.33579 9.8125 7.75 9.8125Z"
          fill="white"
        />
        <path
          d="M10 9.8125C10.4142 9.8125 10.75 9.47671 10.75 9.0625C10.75 8.64829 10.4142 8.3125 10 8.3125C9.58579 8.3125 9.25 8.64829 9.25 9.0625C9.25 9.47671 9.58579 9.8125 10 9.8125Z"
          fill="white"
        />
        <path
          d="M12.249 9.8125C12.6632 9.8125 12.999 9.47671 12.999 9.0625C12.999 8.64829 12.6632 8.3125 12.249 8.3125C11.8348 8.3125 11.499 8.64829 11.499 9.0625C11.499 9.47671 11.8348 9.8125 12.249 9.8125Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2217_3002">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );

  if (asButton) {
    return <IconButton size={buttonSize ? buttonSize : 'medium'}>{svgIcon()}</IconButton>;
  }
  return svgIcon();
};
