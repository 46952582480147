import React from 'react';
import { Text } from '../../../../../components/Typography';
import QuestionIcon, { QuesitonIconType } from '../../../../../components/QuestionIcon/QuestionIcon';
import style from './ActivityItem.scss';
import TagStatus, { CareTagStatus } from '../../../../../components/TagStatus/TagStatus';
import DotsWithDialog from '../../../../../components/DotsWithDialog/DotsWithDialog';

export interface Props {
  question: Nullable<string>;
  questionLabel: Nullable<string>;
  quesitonIconType: QuesitonIconType;
  hideConnector?: boolean;
  status?: CareTagStatus;
  editDisabled: boolean;
  removeDisabled: boolean;
  hideDialog?: boolean;
  onEditClick: () => void;
  onRemoveClick: () => void;
}

const ActivityItem: React.FC<Props> = ({
  question,
  questionLabel,
  quesitonIconType,
  hideConnector,
  status,
  editDisabled,
  removeDisabled,
  hideDialog,
  onEditClick,
  onRemoveClick
}) => {
  return (
    <div className={style.container}>
      <div className={style.typeQuestionContainer}>
        <QuestionIcon type={quesitonIconType} />
        {!hideConnector && <div className={style.connector} />}
      </div>
      <div className={style.statusuWithQuestionBox}>
        {!!status && <TagStatus status={status} />}
        <div className={style.questionWithLabelBox}>
          {!!question && (
            <div className={style.questionBox}>
              <Text variant="sm">{question}</Text>
            </div>
          )}
          {!!questionLabel && (
            <Text variant="sm" className={style.questionLabel}>
              {questionLabel}
            </Text>
          )}
        </div>
      </div>
      {!hideDialog && <DotsWithDialog editDisabled={editDisabled} onEditClick={onEditClick} removeDisabled={removeDisabled} onRemoveClick={onRemoveClick} />}
    </div>
  );
};

export default ActivityItem;
