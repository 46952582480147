import React, { Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pageLeftThunk } from '../../../../redux/analytics/analyticsThunks';
import { PageType } from '../../../../redux/analytics/analyticsTypes';
import { acceptInviteThunk } from '../../../../redux/oauth/oauthThunks';
import { AcceptAccountInviteRequest } from '../../../../redux/oauth/oauthTypes';
import { DualPane } from '../../components/DualPane/DualPane';
import { DevicePane } from '../../components/DualPane/Panes/DevicePane/DevicePane';
import { FinishCreateAccountPane } from '../../components/DualPane/Panes/FinishCreateAccountPane/FinishCreateAccountPane';

interface Props {
  acceptInvite: Function;
  pageLeft: Function;
}

const InviteAccount: React.FC<Props> = ({ acceptInvite, pageLeft }) => (
  <DualPane
    pageLeft={pageLeft}
    pageType={PageType.INVITE_BY_TOKEN}
    leftPane={<DevicePane />}
    rightPane={(loadingState: [boolean, Dispatch<SetStateAction<boolean>>], handleExternalRouting: (url?: Nullable<string>) => void, handleError: (err: any) => void) => (
      <FinishCreateAccountPane acceptInvite={acceptInvite} loadingState={loadingState} handleError={handleError} handleExternalRouting={handleExternalRouting} />
    )}
  />
);

const connectRedux = connect(
  () => {
    return {};
  },
  (dispatch: Function) => ({
    acceptInvite: async (acceptAccountInviteRequest: AcceptAccountInviteRequest, token: any) => {
      return dispatch(acceptInviteThunk(token, acceptAccountInviteRequest));
    },
    pageLeft: async (pageType: PageType) => {
      return dispatch(pageLeftThunk(pageType));
    }
  })
);

export default compose(connectRedux)(InviteAccount);
