import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  asButton?: Nullable<boolean>;
  buttonSize?: Nullable<'small' | 'medium'>;
  style?: React.CSSProperties; //external component does not support nullable types, so we can't apply Nullable<> type here
}

export const StateOutlinedIcon: React.FC<Props> = ({ asButton, buttonSize, style }) => {
  const svgIcon = (): JSX.Element => (
    <SvgIcon width="16" height="18" viewBox="0 0 16 18" fill="none" style={style}>
      <path
        d="M14.383 2.31525C14.009 2.16025 13.579 2.24625 13.293 2.53225C12.029 3.79525 9.972 3.79625 8.707 2.53225C6.662 0.48925 3.337 0.48925 1.293 2.53225C1.105 2.71925 1 2.97425 1 3.23925V16.2393C1 16.7913 1.447 17.2393 2 17.2393C2.553 17.2393 3 16.7913 3 16.2393V11.6863C4.271 10.6893 6.121 10.7753 7.293 11.9463C9.338 13.9893 12.664 13.9893 14.707 11.9463C14.895 11.7583 15 11.5043 15 11.2393V3.23925C15 2.83425 14.756 2.47025 14.383 2.31525Z"
        fill="#FFDBD5"
        stroke="#D0490F"
      />
    </SvgIcon>
  );

  if (asButton) {
    return <IconButton size={buttonSize ? buttonSize : 'medium'}>{svgIcon()}</IconButton>;
  }
  return svgIcon();
};
