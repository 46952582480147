import { createAction } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient';
import { ActivityState } from './activity/activityTypes';
import { AnalyticsState } from './analytics/analyticsTypes';
import { CarePlanState } from './carePlan/carePlanTypes';
import { ChatState } from './chat/chatTypes';
import { ClientDeviceState } from './clientDevice/clientDeviceTypes';
import { ClinicalState } from './clinical/clinicalTypes';
import { ErrorPayload } from './common/types';
import { ContentState } from './content/contentTypes';
import { DeviceState } from './device/deviceTypes';
import { OauthState } from './oauth/oauthTypes';
import { OrganizationState } from './organization/organizationTypes';
import { PaymentState } from './payment/paymentTypes';
import { PersonState } from './person/personTypes';
import { SleepScheduleState } from './sleepschedule/sleepScheduleTypes';

export interface ReduxState {
  readonly activity: ActivityState;
  readonly analytics: AnalyticsState;
  readonly carePlan: CarePlanState;
  readonly clinical: ClinicalState;
  readonly chat: ChatState;
  readonly clientDevice: ClientDeviceState;
  readonly device: DeviceState;
  readonly oauth: OauthState;
  readonly person: PersonState;
  readonly content: ContentState;
  readonly payment: PaymentState;
  readonly sleepSchedule: SleepScheduleState;
  readonly organization: OrganizationState;
}

export interface ReduxAction {
  type: string;
  payload?: Nullable<any>;
}

export interface ThunkParams {
  apiClient: ApiClient;
}

export interface AsyncProps {
  match: {
    params: any;
  };
  helpers: any;
  location?: Nullable<any>;
}

export const createReduxActionCreator = <T, K = undefined, J = ErrorPayload>(prefix) => ({
  start: createAction<K>(`${prefix}-start`),
  success: createAction<T>(`${prefix}-success`),
  fail: createAction<J>(`${prefix}-fail`)
});
