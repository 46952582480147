import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import NavSideBarContentWrapper from '../../components/NavSideBarContentWrapper/NavSideBarContentWrapper';
import { getSleepLogsByPersonIdSelector } from '../../redux/activity/activitySelectors';
import { getSleepLogsThunk } from '../../redux/activity/activityThunks';
import {
  getGrouppedMeasurementsSelector,
  getLatestNightDateUTCMsOfMeasurements,
  getMeasurementsByPersonIdSelector,
  getPersonWithCarePlansByIdSelector,
  getSurveyRangeByMetricIdSelector
} from '../../redux/carePlan/carePlanSelectors';
import { fetchCarePlanMeasurementsThunk, fetchPatientsAndCarePlansThunk } from '../../redux/carePlan/carePlanThunks';
import { Measurement, MetricIDs } from '../../redux/carePlan/carePlanTypes';
import { getDeviceTimeZoneIdSelector } from '../../redux/device/deviceSelectors';
import { getDevicePersonOverviewThunk } from '../../redux/device/deviceThunks';
import { getIsMdRevUserSelector, hasPatientCreatePermissionSelector } from '../../redux/person/personSelector';
import { ReduxState } from '../../redux/types';
import {
  getLatestBoolValueByMetricId,
  getLatestCreatedTsByMetricId,
  getLatestNumberValueByMetricId,
  getLatestStringValueByMetricId,
  isOutOfSurveyRange
} from '../../utils/carePlanUtils';
import { getCarePlanDateUTCFromBedTimeTs, isWithin24HrsCarePlanDate, tsAsTimeStr } from '../../utils/time';
import PatientTable, { PatientTableData } from './components/PatientTable/PatientTable';
import { Display } from '../../components/Typography';
import { Button } from '../../components/Button/Button';
import plusIcon from '../../assets/images/plus.png';
import style from './AllPatients.scss';
import { BreadCrumbData } from '../../redux/common/types';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { defaultAllPatientBreadCrumbs, defaultAllPatientCrumb } from '../../utils/breadcrumbs';

const FETCH_MEASUREMENTS_INTERVAL = 10000; // 10 sec

type PropsFromRedux = ConnectedProps<typeof connectRedux>;

type Props = PropsFromRedux & {};

const AllPatients: React.FC<Props> = ({
  hasPatientCreatePermission,
  grouppedMeasurements,
  measurementsByPersonId,
  latestNightDateUTCMsOfMeasurements,
  getSurveyRangeByMetricId,
  personWithCarePlansById,
  sleepLogsByPersonId,
  isMdRevUser,
  deviceTimeZoneId,
  appendBreadCrumbs,
  fetchCarePlanMeasurements,
  fetchDevicePersonOverview,
  fetchPatientsAndCarePlans,
  fetchUserSleepLogs
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [count, setCount] = useState(0); // use for patientTableData memo

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeasurements = async () => {
      try {
        setIsFetching(true);
        // await fetchDevicePersonOverview(); // fetching to get time zone - commented out because some users doesn't have permission for it
        await fetchCarePlanMeasurements();
        await fetchPatientsAndCarePlans();
        setIsFetching(false);
      } catch (err) {
        setIsFetching(false);
      }
    };
    fetchMeasurements(); // initial fetch
    const intervalId = setInterval(() => {
      fetchMeasurements();
    }, FETCH_MEASUREMENTS_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const fetchSleepLogsData = async () => {
      if (personWithCarePlansById && !isFetching) {
        const userIds = Object.keys(personWithCarePlansById);

        for (const userId of userIds) {
          await fetchUserSleepLogs(userId);
        }

        setCount(count + 1);
      }
    };

    fetchSleepLogsData();
  }, [isFetching]);

  const patientTableData: PatientTableData[] = React.useMemo(() => {
    const tableData: PatientTableData[] = [];
    if (grouppedMeasurements) {
      for (let personId in personWithCarePlansById) {
        // only show persons with care plan or with measurements
        const carePlans = personWithCarePlansById && personWithCarePlansById[personId]?.carePlans;
        const personMeasurements = grouppedMeasurements[personId] || {};
        if (!carePlans?.length && !personMeasurements) {
          continue;
        }
        // latest daily measurements (within one day)
        const latestCarePlanDateUTCMs: number = Number(latestNightDateUTCMsOfMeasurements(personId));
        const personLatestMeasurements: Record<MetricIDs, Measurement[]> | undefined = latestCarePlanDateUTCMs ? personMeasurements[latestCarePlanDateUTCMs] : undefined;
        const personFullName = personWithCarePlansById && personWithCarePlansById[personId]?.fullName;
        // latest sleep log
        let latestSleepLog = sleepLogsByPersonId && sleepLogsByPersonId[personId] && sleepLogsByPersonId[personId][0];
        const latestSleepLogCarePlanDateUTCMs =
          latestSleepLog?.bedTime && latestSleepLog?.timeZone && getCarePlanDateUTCFromBedTimeTs(latestSleepLog.bedTime, latestSleepLog.timeZone)?.toMillis();
        if (latestSleepLogCarePlanDateUTCMs !== latestCarePlanDateUTCMs) {
          latestSleepLog = undefined; // sleep log should match the latest measurements date
        }
        const timeZone = deviceTimeZoneId(personId); // not available without fetchDevicePersonOverview
        /**
         * time zone is not available for some users because of permission
         * to fetch fetchDevicePersonOverview - checking
         * only if it measured within last 24 hrs
         */
        // const isTodayMeasurements = latestCarePlanDateUTCMs && timeZone ? isTodayCarePlanDate(latestCarePlanDateUTCMs, timeZone) : false;
        const isTodayMeasurements = isWithin24HrsCarePlanDate(latestCarePlanDateUTCMs);
        const weightRange = personId ? getSurveyRangeByMetricId(personId, MetricIDs.WEIGHT) : undefined;
        const heartRateRange = personId ? getSurveyRangeByMetricId(personId, MetricIDs.HEART_RATE) : undefined;
        const glucoseRange = personId ? getSurveyRangeByMetricId(personId, MetricIDs.GLUCOSE_LEVEL) : undefined;
        const tableRow: PatientTableData = {
          id: personId,
          isTodayMeasurements,
          name: personFullName || '',
          heartRate: {
            value: getLatestNumberValueByMetricId(MetricIDs.HEART_RATE, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.HEART_RATE, timeZone, personLatestMeasurements),
            showRedAlert: isOutOfSurveyRange(MetricIDs.HEART_RATE, personLatestMeasurements, heartRateRange)
          },
          weight: {
            value: getLatestNumberValueByMetricId(MetricIDs.WEIGHT, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.WEIGHT, timeZone, personLatestMeasurements),
            showRedAlert: isOutOfSurveyRange(MetricIDs.WEIGHT, personLatestMeasurements, weightRange)
          },
          glucoseLevel: {
            value: getLatestNumberValueByMetricId(MetricIDs.GLUCOSE_LEVEL, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.GLUCOSE_LEVEL, timeZone, personLatestMeasurements),
            showRedAlert: isOutOfSurveyRange(MetricIDs.GLUCOSE_LEVEL, personLatestMeasurements, glucoseRange)
          },
          walkActivity: {
            value: getLatestBoolValueByMetricId(MetricIDs.WALK_ACTIVITY, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.WALK_ACTIVITY, timeZone, personLatestMeasurements)
          },
          medicationReminder: {
            value: getLatestBoolValueByMetricId(MetricIDs.MEDICATION_REMINDER, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.MEDICATION_REMINDER, timeZone, personLatestMeasurements)
          },
          medicationReminderMdr: {
            value: getLatestBoolValueByMetricId(MetricIDs.MEDICATION_SET_REMINDER_QUESTION, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.MEDICATION_SET_REMINDER_QUESTION, timeZone, personLatestMeasurements)
          },
          coachCall: {
            value: getLatestBoolValueByMetricId(MetricIDs.COACHING_CALL, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.COACHING_CALL, timeZone, personLatestMeasurements)
          },
          stressLevel: {
            value: getLatestNumberValueByMetricId(MetricIDs.STRESS_LEVEL, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.STRESS_LEVEL, timeZone, personLatestMeasurements)
          },
          healthCoachConcerns: {
            value: getLatestStringValueByMetricId(MetricIDs.HEALTH_COACH_CONCERNS, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.HEALTH_COACH_CONCERNS, timeZone, personLatestMeasurements)
          },
          sleepTime: {
            value: tsAsTimeStr(latestSleepLog?.sleepTime, latestSleepLog?.timeZone) || ''
          },
          wakeTime: {
            value: tsAsTimeStr(latestSleepLog?.wakeTime, latestSleepLog?.timeZone) || ''
          },
          CHFBreathingLyingDown: {
            value: getLatestBoolValueByMetricId(MetricIDs.CHF_BREATHING_LYING_DOWN, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.CHF_BREATHING_LYING_DOWN, timeZone, personLatestMeasurements)
          },
          CHFChestPain: {
            value: getLatestBoolValueByMetricId(MetricIDs.CHF_CHEST_PAIN, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.CHF_CHEST_PAIN, timeZone, personLatestMeasurements)
          },
          CHFLightHeaded: {
            value: getLatestBoolValueByMetricId(MetricIDs.CHF_LIGHT_HEADED, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.CHF_LIGHT_HEADED, timeZone, personLatestMeasurements)
          },
          CHFShortOfBreath: {
            value: getLatestBoolValueByMetricId(MetricIDs.CHF_SHORT_OF_BREATH, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.CHF_SHORT_OF_BREATH, timeZone, personLatestMeasurements)
          },
          CHFNauseous: {
            value: getLatestBoolValueByMetricId(MetricIDs.CHF_NAUSEOUS, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.CHF_NAUSEOUS, timeZone, personLatestMeasurements)
          },
          CHFSwellingFeet: {
            value: getLatestBoolValueByMetricId(MetricIDs.CHF_SWELLING_FEET, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.CHF_SWELLING_FEET, timeZone, personLatestMeasurements)
          },
          CHFSwellingLegs: {
            value: getLatestBoolValueByMetricId(MetricIDs.CHF_SWELLING_LEGS, personLatestMeasurements),
            timeSince: getLatestCreatedTsByMetricId(MetricIDs.CHF_SWELLING_LEGS, timeZone, personLatestMeasurements)
          }
        };
        tableData.push(tableRow);
      }
    }

    return tableData;
  }, [count]);

  const goToPatientProfile = React.useCallback(
    (personId: string) => {
      navigate(`/patients/${personId}`);
    },
    [navigate]
  );

  const goToNewPatientPage = React.useCallback(
    (e) => {
      navigate('/patients/newPatient');
    },
    [navigate]
  );

  return (
    <NavSideBarContentWrapper
      headerText={() => {
        return (
          <div className={style.headerContainer}>
            <Display variant="sm">Patients</Display>
            {hasPatientCreatePermission && (
              <Button variant="contained" onClick={goToNewPatientPage} startIcon={<img src={plusIcon} className={style.plusIcon} />}>
                Create new
              </Button>
            )}
          </div>
        );
      }}
      appendBreadCrumbs={appendBreadCrumbs}
      defaultCurrentCrumb={defaultAllPatientCrumb}
      defaultCrumbHistory={defaultAllPatientBreadCrumbs}
    >
      <PatientTable patientTableData={patientTableData} onPressTableRow={goToPatientProfile} isMdrView={isMdRevUser} />
    </NavSideBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    hasPatientCreatePermission: hasPatientCreatePermissionSelector(state),
    latestNightDateUTCMsOfMeasurements: (personId: string) => getLatestNightDateUTCMsOfMeasurements(personId, state),
    getSurveyRangeByMetricId: (userId: string, metricId: MetricIDs) => getSurveyRangeByMetricIdSelector(userId, metricId, state),
    grouppedMeasurements: getGrouppedMeasurementsSelector(state),
    measurementsByPersonId: getMeasurementsByPersonIdSelector(state),
    personWithCarePlansById: getPersonWithCarePlansByIdSelector(state),
    sleepLogsByPersonId: getSleepLogsByPersonIdSelector(state),
    deviceTimeZoneId: (personId: string) => getDeviceTimeZoneIdSelector(personId, state),
    isMdRevUser: getIsMdRevUserSelector(state)
  }),
  {
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }),
    fetchCarePlanMeasurements: fetchCarePlanMeasurementsThunk,
    fetchDevicePersonOverview: getDevicePersonOverviewThunk,
    fetchPatientsAndCarePlans: fetchPatientsAndCarePlansThunk,
    fetchUserSleepLogs: (userId: string) => getSleepLogsThunk(userId)
  }
);

export default compose(connectRedux)(AllPatients);
