import React, { Dispatch, SetStateAction, useState } from 'react';
import { DeviceTraining, PersonAccountStatus, PersonDetails, PersonOrder } from '../../../../redux/person/personTypes';
import { Moment } from 'moment-timezone';
import { EditableTable } from '../../components/EditableTable/EditableTable';
import { Box } from '@material-ui/core';
import { timestampAndRandomCombinedUUIDString } from '../../../../../shared/src/util/uuid';
import { DeviceShipment, ShipmentInfo } from '../../../../redux/device/deviceTypes';
import { ClientDevice } from '../../../../redux/clientDevice/clientDeviceTypes';

export const DevicesShippingUserTab: React.FC<{
  selectedPersonDetails: Nullable<PersonDetails>;
  personAccountStatuses?: Nullable<PersonAccountStatus[]>;
  deviceTrainings?: Nullable<DeviceTraining[]>;
  deviceShipments?: Nullable<DeviceShipment[]>;
  shipmentInfo?: Nullable<ShipmentInfo[]>;
  clientDevice?: Nullable<ClientDevice>;
  esId?: Nullable<string>;
  purchaseTs?: Nullable<string>;
  userTimeZone?: Nullable<string>;
  saveShipment: Function;
  saveOrder: Function;
  updateEsId: Function;
}> = ({
  selectedPersonDetails,
  personAccountStatuses,
  deviceTrainings,
  deviceShipments,
  shipmentInfo,
  clientDevice,
  esId,
  purchaseTs,
  userTimeZone,
  saveShipment,
  saveOrder,
  updateEsId
}) => {
  const esIdState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');
  const purchasedState: [Nullable<Moment>, Dispatch<SetStateAction<Nullable<Moment>>>] = useState<Nullable<Moment>>(undefined);

  const deviceIdState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');
  const shippedState: [Nullable<Moment>, Dispatch<SetStateAction<Nullable<Moment>>>] = useState<Nullable<Moment>>(undefined);
  const receivedState: [Nullable<Moment>, Dispatch<SetStateAction<Nullable<Moment>>>] = useState<Nullable<Moment>>(undefined);

  const saveShipmentRow = async (editRowIndex: number) => {
    if (selectedPersonDetails?.personId) {
      const shipmentInfo: Nullable<DeviceShipment> = deviceShipments?.[editRowIndex];
      const updatedDeviceShipment: DeviceShipment = {
        id: editRowIndex >= 0 ? shipmentInfo?.id : timestampAndRandomCombinedUUIDString(),
        personId: selectedPersonDetails?.personId,
        deviceId: deviceIdState[0],
        deviceShippedTs: shippedState[0]?.format(),
        deviceReceivedTs: receivedState[0]?.format()
      };
      await saveShipment(updatedDeviceShipment);
    }
  };

  const saveOrderInfo = async () => {
    if (selectedPersonDetails?.personId) {
      const personOrder: PersonOrder = {
        id: selectedPersonDetails?.latestPersonOrderId ? selectedPersonDetails.latestPersonOrderId : timestampAndRandomCombinedUUIDString(),
        personId: selectedPersonDetails?.personId,
        purchaseTs: purchasedState[0]?.format()
      };
      await saveOrder(personOrder);
    }
  };

  const updateEsIdRow = async () => {
    await updateEsId(selectedPersonDetails?.personId, selectedPersonDetails?.deviceId, esIdState[0]);
  };

  return (
    <React.Fragment>
      <Box minHeight="100vh">
        <Box display="flex" alignItems="flex-start" justifyContent="center" mt={3}>
          <EditableTable
            data={{
              headerConfig: [{ value: 'LATEST PURCHASE', attribute: 'purchaseTs', type: 'timestamp', state: purchasedState }],
              rowData: [{ purchaseTs: purchaseTs }]
            }}
            editProps={{
              saveRowToDb: saveOrderInfo
            }}
            timeZone={userTimeZone}
          />
          <Box mr={3} />
          <EditableTable
            data={{
              headerConfig: [{ value: 'ES ID', attribute: 'esId', type: 'text', state: esIdState }],
              rowData: [{ esId: esId }]
            }}
            editProps={{
              saveRowToDb: updateEsIdRow
            }}
            timeZone={userTimeZone}
          />
        </Box>
        <Box display="flex" alignItems="flex-start" justifyContent="center" mt={3}>
          <EditableTable
            data={{
              headerConfig: [
                { value: 'DEVICE ID', attribute: 'deviceId', type: 'text' },
                { value: 'TRAINING TS', attribute: 'trainingTs', type: 'timestamp' }
              ],
              rowData: deviceTrainings
            }}
            timeZone={userTimeZone}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
          <EditableTable
            data={{
              headerConfig: [
                { value: 'MOBILE APP VERSION', attribute: 'appVersion', type: 'text' },
                { value: 'MOBILE APP BUILD', attribute: 'appBuild', type: 'text' },
                { value: 'OS', attribute: 'osType', type: 'text' },
                { value: 'OS VERSION', attribute: 'osVersion', type: 'text' }
              ],
              rowData: clientDevice ? [clientDevice] : []
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
          <EditableTable
            data={{
              headerConfig: [
                { value: 'ACCOUNT STATUS', attribute: 'accountStatus', type: 'text' },
                { value: 'START DATE', attribute: 'startDate', type: 'timestamp' },
                { value: 'END DATE', attribute: 'endDate', type: 'timestamp' }
              ],
              rowData: personAccountStatuses
            }}
            timeZone={userTimeZone}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
          <EditableTable
            data={{
              headerConfig: [
                { value: 'DEVICE ID', attribute: 'deviceId', type: 'text', state: deviceIdState },
                { value: 'SHIPPED', attribute: 'deviceShippedTs', type: 'timestamp', state: shippedState },
                { value: 'RECEIVED', attribute: 'deviceReceivedTs', type: 'timestamp', state: receivedState }
              ],
              rowData: deviceShipments
            }}
            editProps={{
              addRowEnabled: true,
              saveRowToDb: saveShipmentRow
            }}
            timeZone={userTimeZone}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
          <EditableTable
            data={{
              headerConfig: [
                { value: 'DEVICE #', attribute: 'deviceNumber', type: 'number' },
                { value: 'INSTALLED', attribute: 'installationDate', type: 'timestamp' },
                { value: 'REMOVED', attribute: 'removedDate', type: 'timestamp' },
                { value: 'TIMEZONE', attribute: 'timeZone', type: 'text' }
              ],
              rowData: shipmentInfo
            }}
            timeZone={userTimeZone}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};
