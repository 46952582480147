import { ReduxState } from '../types';
import { DemoAccessCode, OauthState, PasswordResetStatusType } from './oauthTypes';
import { BreadCrumbData, Error } from '../common/types';
import { Person } from '../person/personTypes';
import { getPersonByIdSelector } from '../person/personSelector';

const getOauthState = (state: ReduxState): OauthState => {
  return state.oauth;
};

export const isUserLoggedInSelector = (state: ReduxState): boolean => {
  const oauth: OauthState = getOauthState(state);
  return !!getRefreshTokenSelector(state) || !!(oauth.accessToken && oauth.expires && oauth.expires > new Date().getTime());
};

export const userHasAccessTokenSelector = (state: ReduxState): boolean => {
  const oauth: OauthState = getOauthState(state);
  return !!oauth.accessToken;
};

export const getAuthPersonIdSelector = (state: ReduxState): Nullable<string> => {
  const oauth: OauthState = getOauthState(state);
  return oauth.personId;
};

export const getAuthPersonSelector = (state: ReduxState): Nullable<Person> => {
  const oauthPersonId = getAuthPersonIdSelector(state);
  const personById = getPersonByIdSelector(state);
  return personById && oauthPersonId ? personById[oauthPersonId] : null;
};

export const getAccessTokenSelector = (state: ReduxState): Nullable<string> => {
  const oauth: OauthState = getOauthState(state);
  return isAccessTokenValidSelector(state) ? oauth.accessToken : undefined;
};

export const getRefreshTokenSelector = (state: ReduxState): Nullable<string> => {
  const oauth: OauthState = getOauthState(state);
  return oauth.refreshToken;
};

export const isAccessTokenValidSelector = (state: ReduxState): boolean => {
  const oauth: OauthState = getOauthState(state);
  return !!oauth.accessToken && !!oauth.expires && oauth.expires > Date.now();
};

export const getRegisteredUserEmailSelector = (state: ReduxState): Nullable<string> => {
  const oauth: OauthState = getOauthState(state);
  return oauth.registeredUser && oauth.registeredUser.email;
};

export const getRegisteredUserFirstNameSelector = (state: ReduxState): Nullable<string> => {
  const oauth: OauthState = getOauthState(state);
  return oauth.registeredUser && oauth.registeredUser.firstName;
};

export const getRegisteredUserLastNameSelector = (state: ReduxState): Nullable<string> => {
  const oauth: OauthState = getOauthState(state);
  return oauth.registeredUser && oauth.registeredUser.lastName;
};

export const getOauthErrorStatusSelector = (state: ReduxState): Nullable<number> => {
  const oauth: OauthState = getOauthState(state);
  return oauth.error && oauth.error.status;
};

export const getErrorDeleteAccountNotifySupportEmailSelector = (state: ReduxState): Nullable<Error> => {
  const oauth: OauthState = getOauthState(state);
  return oauth.errorDeleteAccountNotifySupportEmail;
};

export const getOauthErrorMessageSelector = (state: ReduxState): Nullable<string> => {
  const oauth: OauthState = getOauthState(state);
  return oauth.error && oauth.error.message;
};

export const isOauthLoadingSelector = (state: ReduxState): boolean => {
  const oauth: OauthState = getOauthState(state);
  return oauth.isLoading;
};

export const isLoadingDeleteAccountNotifySupportEmailSelector = (state: ReduxState): boolean => {
  const oauth: OauthState = getOauthState(state);
  return oauth.isLoadingDeleteAccountNotifySupportEmail;
};

export const isEmailConfirmedSelector = (state: ReduxState): boolean => {
  const oauth: OauthState = getOauthState(state);
  return oauth.confirmed;
};

export const getPasswordResetStatusSelector = (state: ReduxState): Nullable<PasswordResetStatusType> => {
  const oauth: OauthState = getOauthState(state);
  return oauth.passwordResetStatus;
};

export const getPasswordResetErrorMessageSelector = (state: ReduxState): Nullable<string> => {
  const oauth: OauthState = getOauthState(state);
  return oauth.passwordResetError && oauth.passwordResetError.message;
};

export const getBreadcrumbsSelector = (state: ReduxState): BreadCrumbData[] => {
  const oauth: OauthState = getOauthState(state);
  return oauth.breadCrumbs;
};

export const getAllDemoAccessCodes = (state: ReduxState): DemoAccessCode[] => {
  const oauth: OauthState = getOauthState(state);
  return oauth.demoAccessCodes;
};
