import { Store } from 'redux';
import { rootReducer } from './rootReducer';
import ApiClient from '../ApiClient';
import { ReduxState } from './types';
import { configureStore } from '@reduxjs/toolkit';
import { isClient } from '../utils/util';

let preloadedState = {};
if (isClient()) {
  const serverData = window?.__data__ || {};
  preloadedState = serverData.store || {};
}

export const createStore = (apiClient: ApiClient): Store => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { apiClient: apiClient }
        },
        serializableCheck: false
      })
  });
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReduxState;
export type AppDispatch = any;
