import React from 'react';
import style from './PaymentConfirmationPane.scss';
import paymentPurchase from '../../../../../../assets/images/paymentpurchase.png';
import { Box, Fade, Typography } from '@material-ui/core';
import { HeaderLogo } from '../../../HeaderLogo/HeaderLogo';
import { TermsLink } from '../../../TermsLink/TermsLink';
interface Props {
  transactionAmount?: Nullable<string>;
}
export const PaymentConfirmationPane: React.FC<Props> = ({ transactionAmount }) => {
  return (
    <Fade in timeout={600}>
      <Box className={style.purchaseContainer}>
        <Box className={style.flexEnd}>
          <Box className={style.purchaseBox}>
            <HeaderLogo />
            <Box className={style.purchasePayBox}>
              <Box>
                <Typography className={style.purchasePayTitle}>Pay Full Sleep</Typography>
              </Box>
              <Box>
                <Box component="span" className={style.purchasePayAmount}>
                  {transactionAmount}
                </Box>
              </Box>
            </Box>
            <Box className={style.purchaseImageFlex}>
              <Box className={style.purchaseImageBox}>
                <img src={paymentPurchase} alt="Full Sleep Purchase" className={style.purchaseImage} />
              </Box>
              <Box>
                <Box className={style.flexStart}>
                  <Box className={style.programInfo}>6 Week Full Sleep Program</Box>
                  <Box className={style.programInfoPrice}>&nbsp;{transactionAmount}</Box>
                </Box>
                <Box className={style.purchaseDescription}>
                  Qty 1,
                  <br />
                  Six week personalized digital sleep program includes REST bedside device, Full Sleep mobile app and personal sleep coach.
                </Box>
                <Box className={style.flexStart}>
                  <Box className={style.totalPaidLabel}>Total paid</Box>
                  <Box className={style.totalPaidPrice}>{transactionAmount}</Box>
                </Box>
              </Box>
            </Box>
            <TermsLink />
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};
