import { ThunkParams } from '../types';
import { ErrorPayload } from '../common/types';
import {
  getActivityEventsInRangeActions,
  getFallsActions,
  getHeatMapActions,
  getPoiChangeEventsActions,
  getPoisActions,
  getSleepLogsActions,
  getSleepStageEventsActions,
  getSupertrackActions,
  getTracksActions,
  getUploadMp3Actions,
  getVoiceMessageStatusActions,
  savePoiActions
} from './activityActions';
import Config from '../../config/config';
import { ActivityEvent, ActivityType, DailySleepLog, PoiChangeEvent, SleepStageEvent } from './activityTypes';

function processGetTracksSuccess(payload: any): Function {
  return function (dispatch: Function, getState: Function): void {
    dispatch(getTracksActions.success(payload));
  };
}
function processGetTracksFail(error: ErrorPayload): Function {
  return function (dispatch: Function, getState: Function) {
    dispatch(getTracksActions.fail(error));
  };
}
export function getTracksThunk(deviceId, startTime, endTime): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getTracksActions.start());
    return apiClient.get(`v1/activity/${deviceId}/motionTracks`, {
      auth: true,
      params: {
        'start-time': startTime,
        'end-time': endTime,
        count: 1000
      },
      successAction: processGetTracksSuccess,
      failAction: processGetTracksFail
    });
  };
}

function processGetSupertrackSuccess(payload: any): Function {
  return function (dispatch: Function, getState: Function): void {
    dispatch(getSupertrackActions.success(payload));
  };
}
function processGetSupertrackFail(error: ErrorPayload): Function {
  return function (dispatch: Function, getState: Function) {
    dispatch(getSupertrackActions.fail(error));
  };
}
export function getSupertrackThunk(deviceId, resolution, startTime, endTime): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getSupertrackActions.start());
    return apiClient.get(`v1/activity/${deviceId}/supertrack`, {
      auth: true,
      params: {
        'start-time': startTime,
        'end-time': endTime,
        resolution
      },
      successAction: processGetSupertrackSuccess,
      failAction: processGetSupertrackFail
    });
  };
}

function processGetHeatMapSuccess(payload: any): Function {
  return function (dispatch: Function, getState: Function): void {
    dispatch(getHeatMapActions.success(payload));
  };
}
function processGetHeatMapFail(error: ErrorPayload): Function {
  return function (dispatch: Function, getState: Function) {
    dispatch(getHeatMapActions.fail(error));
  };
}
export function getHeatMapThunk(deviceId, resolution, startTime, endTime, startTimeOfDay, endTimeOfDay, timeZone): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getHeatMapActions.start());
    return apiClient.get(`v1/activity/${deviceId}/heatMap`, {
      auth: true,
      params: {
        'start-time': startTime,
        'end-time': endTime,
        'start-time-of-day': startTimeOfDay,
        'end-time-of-day': endTimeOfDay,
        tz: timeZone,
        resolution
      },
      timeout: 10 * 60 * 1000, // 10 min
      successAction: processGetHeatMapSuccess,
      failAction: processGetHeatMapFail
    });
  };
}

function processGetFallsSuccessWrapper(deviceId: string): Function {
  return function processGetFallsSuccess(payload: any): Function {
    return function (dispatch: Function, getState: Function): void {
      payload.meta = {};
      payload.meta.deviceId = deviceId;
      dispatch(getFallsActions.success(payload));
    };
  };
}
function processGetFallsFail(error: ErrorPayload): Function {
  return function (dispatch: Function, getState: Function) {
    dispatch(getFallsActions.fail(error));
  };
}
export function getFallsThunk(deviceId): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getFallsActions.start());
    return apiClient.get(`v1/activity/${deviceId}/activityEvents`, {
      auth: true,
      params: {
        'activity-type': 'FALL',
        count: 1000
      },
      successAction: processGetFallsSuccessWrapper(deviceId),
      failAction: processGetFallsFail
    });
  };
}

function processGetPoisSuccess(payload: any): Function {
  return function (dispatch: Function, getState: Function): void {
    dispatch(getPoisActions.success(payload));
  };
}
function processGetPoisFail(error: ErrorPayload): Function {
  return function (dispatch: Function, getState: Function) {
    dispatch(getPoisActions.fail(error));
  };
}
export function getPoisThunk(deviceId): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getPoisActions.start());
    return apiClient.get(`v1/activity/${deviceId}/pois`, {
      auth: true,
      params: {
        count: 1000
      },
      successAction: processGetPoisSuccess,
      failAction: processGetPoisFail
    });
  };
}

function processSavePoiSuccess(payload: any): Function {
  return function (dispatch: Function, getState: Function): void {
    dispatch(savePoiActions.success(payload));
  };
}
function processSavePoiFail(error: ErrorPayload): Function {
  return function (dispatch: Function, getState: Function) {
    dispatch(savePoiActions.fail(error));
  };
}
export function savePoiThunk(deviceId, poi, method: 'add' | 'update' | 'delete'): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): any {
    dispatch(savePoiActions.start());
    switch (method) {
      case 'add': {
        return apiClient.post(`v1/activity/${deviceId}/poi`, {
          auth: true,
          data: poi,
          successAction: processSavePoiSuccess,
          failAction: processSavePoiFail
        });
      }
      case 'update': {
        return apiClient.put(`v1/activity/${deviceId}/poi/${poi.id}`, {
          auth: true,
          data: poi,
          successAction: processSavePoiSuccess,
          failAction: processSavePoiFail
        });
      }
      case 'delete': {
        return apiClient.delete(`v1/activity/${deviceId}/poi/${poi.id}`, {
          auth: true,
          successAction: processSavePoiSuccess,
          failAction: processSavePoiFail
        });
      }
    }
  };
}

function processUploadMp3FileSuccess(payload: any): Function {
  return function (dispatch: Function, getState: Function): void {
    dispatch(getUploadMp3Actions.success(payload));
  };
}
function processUploadMp3FileUrlFail(error: ErrorPayload): Function {
  return function (dispatch: Function, getState: Function) {
    dispatch(getUploadMp3Actions.fail(error));
  };
}
export function uploadMp3FileThunk(userId: String, file: any): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getUploadMp3Actions.start());
    let data = new FormData();
    data.append('file', file);
    return apiClient.put(`/api/user/${userId}/uploadRecording`, {
      baseUrl: Config.getAuthBaseUrl(),
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        userId: userId
      },
      successAction: processUploadMp3FileSuccess,
      failAction: processUploadMp3FileUrlFail
    });
  };
}

function processGetVoiceMessageStatusSuccess(payload: any): Function {
  return function (dispatch: Function, getState: Function) {
    dispatch(getVoiceMessageStatusActions.success(payload));
  };
}
function processGetVoiceMessageStatusFail(error: ErrorPayload): Function {
  return function (dispatch: Function, getState: Function) {
    dispatch(getVoiceMessageStatusActions.fail(error));
  };
}
export function getVoiceMessageStatusThunk(userId: String): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getVoiceMessageStatusActions.start());
    return apiClient.get(`/v1/assets/voiceMessage/${userId}/status`, {
      successAction: processGetVoiceMessageStatusSuccess,
      failAction: processGetVoiceMessageStatusFail
    });
  };
}

function processGetSleepLogsSuccess(personId: string, payload: DailySleepLog[]): Function {
  return function (dispatch: Function, getState: Function): void {
    dispatch(getSleepLogsActions.success({ personId, payload }));
  };
}
function processGetSleepLogsFail(error: ErrorPayload): Function {
  return function (dispatch: Function, getState: Function) {
    dispatch(getSleepLogsActions.fail(error));
  };
}
export function getSleepLogsThunk(personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getSleepLogsActions.start());
    return apiClient.get(`v1/activity/${personId}/sleepEventLogs`, {
      auth: true,
      successAction: (payload) => processGetSleepLogsSuccess(personId, payload),
      failAction: processGetSleepLogsFail
    });
  };
}

function processGetLatestSleepStageEventsSuccess(payload: SleepStageEvent[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getSleepStageEventsActions.success(payload));
  };
}

function processGetLatestSleepStageEventsFail(payload: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getSleepStageEventsActions.fail(payload));
  };
}

export function getSleepStageEventsThunk(personId: string, startTs: number, endTs: number) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getSleepStageEventsActions.start());
    return apiClient.get('v1/activity/sleepStagesInRange', {
      params: {
        'start-ts': startTs,
        'end-ts': endTs,
        'person-id': personId
      },
      auth: true,
      successAction: processGetLatestSleepStageEventsSuccess,
      failAction: processGetLatestSleepStageEventsFail
    });
  };
}

function processPoiChangeEventsSuccess(payload: PoiChangeEvent[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getPoiChangeEventsActions.success(payload));
  };
}

function processPoiChangeEventsFail(payload: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getPoiChangeEventsActions.fail(payload));
  };
}

export function getPoiChangeEventsThunk(personId: string, startTs: number, endTs: number) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getPoiChangeEventsActions.start());
    return apiClient.get('v1/activity/poiChangeEventsInRange', {
      params: {
        'start-ts': startTs,
        'end-ts': endTs,
        'person-id': personId
      },
      auth: true,
      successAction: processPoiChangeEventsSuccess,
      failAction: processPoiChangeEventsFail
    });
  };
}

function processActivityEventsInRangeSuccess(payload: ActivityEvent[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getActivityEventsInRangeActions.success(payload));
  };
}

function processActivityEventsInRangeFail(payload: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getActivityEventsInRangeActions.fail(payload));
  };
}

export function getActivityEventsInRangeThunk(personId: string, startTs: number, endTs: number, activityTypes: ActivityType[]) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getActivityEventsInRangeActions.start());
    return apiClient.get('v1/activity/activityEventsInRange', {
      params: {
        'start-ts': startTs,
        'end-ts': endTs,
        'person-id': personId,
        'activity-types': activityTypes
      },
      auth: true,
      successAction: processActivityEventsInRangeSuccess,
      failAction: processActivityEventsInRangeFail
    });
  };
}
