import { ContentDropdownOption, DeviceContentType, DeviceContentTypeOrProgramContentType, ProgramType, ValidDropdownType } from '../redux/content/contentTypes';
import { normalizeEnumName } from './content';

export const defaultContentTypes: string[] = ['CBTI_NATIVE', 'AD_HOC_NATIVE', 'CBTI_14_DAY_TRIAL', 'DEMO_CONTENT'];
export const deviceContentTypes: DeviceContentType[] = [
  DeviceContentType.CBTI_NATIVE,
  DeviceContentType.AD_HOC_NATIVE,
  DeviceContentType.CBTI_14_DAY_TRIAL,
  DeviceContentType.DEMO_CONTENT
];
export const programContentTypes: ProgramType[] = [ProgramType.CBTI_NATIVE, ProgramType.AD_HOC_NATIVE, ProgramType.CBTI_14_DAY_TRIAL, ProgramType.DEMO_CONTENT];

export const createDropdownOption = (value: ValidDropdownType): ContentDropdownOption => {
  return {
    label: normalizeEnumName(value),
    value: value
  };
};

export const mapOptionsToContentType = (options: string[], contentType: typeof DeviceContentType | typeof ProgramType): DeviceContentTypeOrProgramContentType[] => {
  return options.map((option) => contentType[option]);
};
