import { Metric } from 'redux/carePlan/carePlanTypes';
import { AD_HOC_CONTENT_CATEGORY, CBTI_14_DAY_FREE_TRIAL_CATEGORY, CBTI_NATIVE_CONTENT_CATEGORY } from '../../utils/content';
import { OperatingSystemType } from '../clientDevice/clientDeviceTypes';
import { Error, ErrorPayload } from '../common/types';
import { DeviceSleeperType } from '../device/deviceTypes';
import { AccountStatus, DeviceGroup, IsiScoreType, PersonSetting, PersonSleepProgram } from '../person/personTypes';

export interface ContentState {
  readonly isLoading: Record<string, boolean>;
  readonly error: Record<string, Error>;
  readonly success: Record<string, boolean>;
  isCreatingAdHocContent: boolean;
  creatingAdHocContentError?: Nullable<ErrorPayload>;
  readonly programContentByType: Record<ProgramType, ProgramContent[]>;
  readonly deviceContentByType: Record<DeviceContentType, DeviceContent[]>;
  readonly carePlanDeviceContentById: Record<string, DeviceContent>;
  readonly flagById: Record<string, WorkflowTemplate>;
  readonly flagsByPersonId: Record<string, Set<string>>;
  surveyResponses: SurveyScoreResponse[];
  feedItems?: Nullable<FeedItem[]>;
  selectedDeviceContent?: Nullable<DeviceContent>;
  selectedDeviceContentPage?: Nullable<DeviceContentPage>;
  personWorkflows: PersonWorkflow[];
  saveSuccessful?: Nullable<boolean>;
  copiedDeviceContentId?: Nullable<string>;
  successMessage?: Nullable<string>;
  selectedContentType?: Nullable<DeviceContentType | ProgramType>;
}

export interface ProgramContent {
  id: string;
  programType: ProgramType;
  programSubType?: Nullable<ProgramSubType>;
  deviceContentId?: Nullable<string>;
  programStep?: Nullable<number>;
  endDateTs?: Nullable<string>;
  featureUnlockType?: Nullable<FeatureUnlockType>;
  deviceContent?: Nullable<DeviceContent>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface DeviceContent {
  id: string;
  categoryId: string;
  deviceContentType: DeviceContentType;
  deviceContentSubType: DeviceContentSubType;
  personId?: Nullable<string>;
  contentFilename?: Nullable<string>;
  title?: Nullable<string>;
  subTitle?: Nullable<string>;
  description?: Nullable<string>;
  durationMillis?: Nullable<number>;
  rank: number;
  contentUrl?: Nullable<string>;
  contentText?: Nullable<string>;
  deviceContentPages?: Nullable<DeviceContentPage[]>;
  programContents?: Nullable<ProgramContent[]>;
  forceOpen?: Nullable<boolean>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface DeviceContentPage {
  id: string;
  pageId: string;
  deviceContentId: string;
  version: number;
  deviceContentPageType: DeviceContentPageType;
  releasedTs?: Nullable<string>;
  rank: number;
  longText?: Nullable<string>;
  quizSuccessText?: Nullable<string>;
  quizFailText?: Nullable<string>;
  surveyQuestionId?: Nullable<string>;
  successSurveyOptionId?: Nullable<string>;
  featureUnlockType?: Nullable<FeatureUnlockType>;
  surveyQuestion?: Nullable<SurveyQuestion>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface Survey {
  id: string;
  name: string;
  surveyType: SurveyType;
  surveyQuestions: SurveyQuestion[];
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface SurveyQuestion {
  id: string;
  surveyId: string;
  questionText: string;
  allowMultipleResponses: boolean;
  rank: number;
  surveyOptions?: Nullable<SurveyOption[]>;
  metric?: Nullable<Metric>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface SurveyOptionRange {
  id: string;
  minAgeMonths: number;
  maxAgeMonths: number;
  minFloatValue: number;
  maxFloatValue: number;
  minAgeMonths2?: Nullable<number>;
  maxAgeMonths2?: Nullable<number>;
  minFloatValue2?: Nullable<number>;
  maxFloatValue2?: Nullable<number>;
  surveyOptionId: string;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface SurveyOption {
  id: string;
  surveyQuestionId: string;
  title: string;
  subtitle?: Nullable<string>;
  rank: number;
  optionType: SurveyOptionType;
  surveyResponse?: Nullable<SurveyResponse>;
  surveyOptionRange?: SurveyOptionRange;
  metricId?: string;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface SurveyResponse {
  id: string;
  personId: string;
  organizationId: string;
  surveyQuestion?: SurveyQuestion;
  surveyQuestionId: string;
  surveyOption?: SurveyOptionType;
  surveyOptionId: string;
  surveyDate: string;
  longText?: Nullable<string>;
  feedItemId?: Nullable<string>;
  surveyType?: Nullable<SurveyType>;
  careFeedItemId?: string;
  measurementId?: string;
  created: string;
  modifiedDate: string;
}

export interface SurveyScoreResponse {
  surveyDate: string;
  surveyScore: number;
  surveyQuestions: SurveyQuestion[];
}

export interface AdHocContentRequest {
  contentId: string;
  personId: string;
  title: string;
  subTitle?: Nullable<string>;
  contentUrl: string;
  personIds?: Nullable<string[]>;
}

export interface FeedItem {
  id: string;
  personId: string;
  organizationId: string;
  programContentId: string;
  completedDate?: Nullable<string>;
  savedDate?: Nullable<string>;
  programContent?: Nullable<ProgramContent>;
  personSetting?: Nullable<PersonSetting>;
  personProgram?: Nullable<PersonSleepProgram>;
  programStep?: Nullable<number>;
  created: string;
  modifiedDate: string;
}

export interface DeviceAndProgramContentUpdateRequest {
  programContent: ProgramContent[];
  deviceContent: DeviceContent[];
}

export interface WorkflowTemplate {
  id: string;
  name: string;
  workflowType: WorkflowType;
  expirationTs?: Nullable<string>;
  workflowTasks?: Nullable<WorkflowTaskTemplate[]>;
  personWorkflows?: Nullable<PersonWorkflow[]>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface WorkflowTaskTemplate {
  id: string;
  title: string;
  workflowId: string;
  rank: number;
  expirationTs?: Nullable<string>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface PersonWorkflow {
  id: string;
  personId: string;
  workflowId?: Nullable<string>;
  name?: Nullable<string>;
  workflowType?: Nullable<WorkflowType>;
  openedTs: string;
  completedTs?: Nullable<string>;
  expirationTs?: Nullable<string>;
  workflow?: Nullable<WorkflowTemplate>;
  personWorkflowStatuses?: Nullable<PersonWorkflowStatus[]>;
  personWorkflowComments?: Nullable<PersonWorkflowComment[]>;
  personWorkflowTasks?: Nullable<PersonWorkflowTask>[];
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface PersonWorkflowTask {
  id: string;
  personWorkflowId: string;
  workflowTaskId: string;
  expirationTs?: Nullable<string>;
  workflowTask?: Nullable<WorkflowTaskTemplate>;
  personWorkflowTaskStatuses?: Nullable<PersonWorkflowTaskStatus[]>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface PersonWorkflowStatus {
  id: string;
  personWorkflowId: string;
  workflowStatusType: WorkflowStatusType;
  submitterPersonId?: Nullable<string>;
  submittedTs: string;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface PersonWorkflowTaskStatus {
  id: string;
  personWorkflowTaskId?: Nullable<string>;
  personWorkflowId: string;
  workflowId?: Nullable<string>;
  workflowTaskStatusType: WorkflowTaskStatusType;
  submitterPersonId?: Nullable<string>;
  submittedTs: string;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface PersonWorkflowComment {
  id: string;
  personWorkflowId: string;
  authorPersonId: string;
  comment: string;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export enum ProgramType {
  UNKNOWN = 'UNKNOWN',
  CBTI_CONTENT = 'CBTI_CONTENT',
  WIND_DOWN = 'WIND_DOWN',
  BED_RESET = 'BED_RESET',
  MANUAL_SLEEP_LOG = 'MANUAL_SLEEP_LOG',
  AD_HOC_CONTENT = 'AD_HOC_CONTENT',
  CBTI_HYBRID = 'CBTI_HYBRID',
  CBTI_NATIVE = 'CBTI_NATIVE',
  SLEEP_NEED_SURVEY = 'SLEEP_NEED_SURVEY',
  AD_HOC_NATIVE = 'AD_HOC_NATIVE',
  CBTI_14_DAY_TRIAL = 'CBTI_14_DAY_TRIAL',
  DEMO_CONTENT = 'DEMO_CONTENT',
  ISI_SURVEY = 'ISI_SURVEY'
}

export enum SurveyType {
  UNKNOWN = 'UNKNOWN',
  ONBOARDING = 'ONBOARDING',
  SLEEP_NEED = 'SLEEP_NEED',
  QUIZ = 'QUIZ',
  ISI = 'ISI',
  CARE_PLAN = 'CARE_PLAN'
}

export enum ProgramSubType {
  UNKNOWN = 'UNKNOWN',
  AGING = 'AGING',
  CHRONIC_PAIN = 'CHRONIC_PAIN',
  DEPRESSION = 'DEPRESSION',
  MENOPAUSE = 'MENOPAUSE',
  GENERAL = 'GENERAL'
}

export enum DeviceContentType {
  UNKNOWN = 'UNKNOWN',
  WIND_DOWN_AUDIO = 'WIND_DOWN_AUDIO',
  SLEEP_SOUND_AUDIO = 'SLEEP_SOUND_AUDIO',
  BED_RESET_AUDIO = 'BED_RESET_AUDIO',
  BED_RESET_CELEBRATION_AUDIO = 'BED_RESET_CELEBRATION_AUDIO',
  CARE_PLAN_UNIT = 'CARE_PLAN_UNIT',
  CBTI_HTML = 'CBTI_HTML',
  CBTI_SURVEY = 'CBTI_SURVEY',
  MANUAL_SLEEP_LOG = 'MANUAL_SLEEP_LOG',
  AD_HOC_CONTENT = 'AD_HOC_CONTENT',
  WAKE_ALARM_AUDIO = 'WAKE_ALARM_AUDIO',
  CBTI_NATIVE = 'CBTI_NATIVE',
  AD_HOC_NATIVE = 'AD_HOC_NATIVE',
  CBTI_14_DAY_TRIAL = 'CBTI_14_DAY_TRIAL',
  DEMO_CONTENT = 'DEMO_CONTENT',
  ISI_SURVEY = 'ISI_SURVEY',
  CARE_ASSESSMENT = 'CARE_ASSESSMENT',
  CARE_QUESTION = 'CARE_QUESTION',
  CARE_CUSTOM_MESSAGE = 'CARE_CUSTOM_MESSAGE'
}

export enum DeviceContentSubType {
  UNKNOWN = 'UNKNOWN',
  TEXT_PLAIN = 'TEXT_PLAIN',
  TEXT_SSML_FRAGMENT = 'TEXT_SSML_FRAGMENT',
  ONBOARDING = 'ONBOARDING',
  MENTAL_HEALTH_SCREENING = 'MENTAL_HEALTH_SCREENING',
  FALL_RISK_SCREENING = 'FALL_RISK_SCREENING'
}

export enum DeviceContentPageType {
  UNKNOWN = 'UNKNOWN',
  COVER = 'COVER',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  ANIMATION = 'ANIMATION',
  QUIZ = 'QUIZ',
  COMPLETED = 'COMPLETED'
}

export enum SurveyOptionType {
  UNKNOWN = 'UNKNOWN',
  STRING = 'STRING',
  FIXED_VALUE = 'FIXED_VALUE',
  BOOLEAN = 'BOOLEAN',
  FLOAT = 'FLOAT',
  INTEGER = 'INTEGER',
  SKIP = 'SKIP'
}

export enum FeatureUnlockType {
  UNKNOWN = 'UNKNOWN',
  WIND_DOWN_SLEEP_SOUNDS = 'WIND_DOWN_SLEEP_SOUNDS',
  WIND_DOWN_SLEEP_STORIES = 'WIND_DOWN_SLEEP_STORIES',
  WIND_DOWN_IMAGERY = 'WIND_DOWN_IMAGERY',
  WIND_DOWN_BREATHING = 'WIND_DOWN_BREATHING',
  WIND_DOWN_PMR = 'WIND_DOWN_PMR',
  WIND_DOWN_MEDITATION = 'WIND_DOWN_MEDITATION',
  WIND_DOWN_ALL = 'WIND_DOWN_ALL',
  ENABLE_SLEEP_LOG = 'ENABLE_SLEEP_LOG',
  ENABLE_BED_RESET = 'ENABLE_BED_RESET',
  ENABLE_LUNA_SLEEP_LOG_SUMMARIES = 'ENABLE_LUNA_SLEEP_LOG_SUMMARIES',
  ENABLE_LUNA_SLEEP_SCHEDULE_SUMMARIES = 'ENABLE_LUNA_SLEEP_SCHEDULE_SUMMARIES',
  ENABLE_LUNA_WEEKLY_SUMMARIES = 'ENABLE_LUNA_WEEKLY_SUMMARIES',
  ENABLE_LUNA_CUSTOM_SLEEP_STORIES_MOBILE = 'ENABLE_LUNA_CUSTOM_SLEEP_STORIES_MOBILE',
  ENABLE_LUNA_CHAT_BOT_MOBILE = 'ENABLE_LUNA_CHAT_BOT_MOBILE',
  ENABLE_LUNA_CHAT_BOT_DEVICE = 'ENABLE_LUNA_CHAT_BOT_DEVICE',
  ENABLE_LUNA_ALL = 'ENABLE_LUNA_ALL',
  ENABLE_MOBILE_SLEEP_COACH = 'ENABLE_MOBILE_SLEEP_COACH',
  ENABLE_SLEEP_LOG_DEVICE_TIMES = 'ENABLE_SLEEP_LOG_DEVICE_TIMES',
  ENABLE_SLEEP_SCHEDULE = 'ENABLE_SLEEP_SCHEDULE',
  ENABLE_SLEEP_LOG_AUTO_SUBMISSION = 'ENABLE_SLEEP_LOG_AUTO_SUBMISSION',
  ISI_SURVEY = 'ISI_SURVEY'
}

export enum WorkflowType {
  UNKNOWN = 'UNKNOWN',
  FLAG_STATE = 'FLAG_STATE',
  FLAG_MILESTONE = 'FLAG_MILESTONE',
  FLAG_MANUAL = 'FLAG_MANUAL'
}

export enum WorkflowStatusType {
  UNKNOWN = 'UNKNOWN',
  OPENED = 'OPENED',
  RESOLVED = 'RESOLVED',
  REOPENED = 'REOPENED'
}

export enum WorkflowTaskStatusType {
  UNKNOWN = 'UNKNOWN',
  OPENED = 'OPENED',
  COMPLETED = 'COMPLETED',
  DISMISSED = 'DISMISSED',
  REOPENED = 'REOPENED'
}

export enum SortBy {
  ALPHABETICAL = 'ALPHABETICAL',
  BY_PEOPLE = 'BY_PEOPLE'
}

const contentTypeIds: Map<DeviceContentType, string> = new Map<DeviceContentType, string>([
  [DeviceContentType.CBTI_NATIVE, CBTI_NATIVE_CONTENT_CATEGORY],
  [DeviceContentType.AD_HOC_NATIVE, AD_HOC_CONTENT_CATEGORY],
  [DeviceContentType.CBTI_14_DAY_TRIAL, CBTI_14_DAY_FREE_TRIAL_CATEGORY]
]);

export const findCategoryId = (deviceContentType: DeviceContentType): string => contentTypeIds.get(deviceContentType) || '-1';

export const subTypeList: ProgramSubType[] = Object.values(ProgramSubType);
export const pageTypeList: DeviceContentPageType[] = Object.values(DeviceContentPageType);
export const deviceGroupList: DeviceGroup[] = Object.values(DeviceGroup);
export const accountStatusList: AccountStatus[] = Object.values(AccountStatus);
export const programTypeList: ProgramType[] = Object.values(ProgramType);
export const deviceContentTypeList: DeviceContentType[] = Object.values(DeviceContentType);
export const featureUnlockTypeList: FeatureUnlockType[] = Object.values(FeatureUnlockType);
export const isiScoreTypesList: IsiScoreType[] = Object.values(IsiScoreType);
export const operatingSystemTypesList: OperatingSystemType[] = Object.values(OperatingSystemType);
export const deviceSleeperTypeList: DeviceSleeperType[] = Object.values(DeviceSleeperType);

export type ValidDropdownType = DeviceContentType | ProgramType | 'SELECT_ALL';
export type DeviceContentTypeOrProgramContentType = DeviceContentType | ProgramType;

export interface ContentDropdownOption {
  label: string;
  value: ValidDropdownType;
}

export interface SaveFlagContentResponsePayload {
  workflowTemplate: WorkflowTemplate;
  personId?: Nullable<string>;
}

export interface SavePersonTaskStatusResponsePayload {
  personWorkflow: PersonWorkflow;
  personWorkflowTaskStatus: PersonWorkflowTaskStatus;
}
