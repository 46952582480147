import { Moment } from 'moment';
import { ErrorPayload } from '../common/types';
import {
  calculateSleepScheduleActions,
  getSleepSchedulesActions,
  getValidSleepSchedulesActions,
  saveIsiScoreActions,
  saveOrderActions,
  saveSleepScheduleAndCalculationsActions,
  updateSleepScheduleActions
} from '../person/personActions';
import {
  PersonIsiScore,
  PersonOrder,
  SleepCalculationRequest,
  SleepSchedule,
  SleepScheduleCalculation,
  SleepScheduleCalculationRequest,
  WorkflowUpdatingResponse
} from '../person/personTypes';
import { ThunkParams } from '../types';
import {
  createNewSleepScheduleActions,
  getLatestSleepScheduleActions,
  getSleepSchedulesForReviewActions,
  postSleepScheduleSendActions,
  putSleepScheduleEditableActions,
  updateSleepScheduleLunaSummaryActions
} from './sleepScheduleActions';

function processGetSleepSchedulesSuccess(payload: SleepSchedule[]): Function {
  return function (dispatch: Function): void {
    dispatch(getSleepSchedulesActions.success(payload));
  };
}
function processGetSleepSchedulesFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getSleepSchedulesActions.fail(error));
  };
}
export function getSleepSchedulesThunk(personId: string, count?: Nullable<number>) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getSleepSchedulesActions.start());
    return apiClient.get(`/v1/sleepSchedule/${personId}/sleepSchedules`, {
      auth: true,
      params: {
        count: count
      },
      successAction: processGetSleepSchedulesSuccess,
      failAction: processGetSleepSchedulesFail
    });
  };
}

function processCalculateSleepScheduleSuccess(payload: SleepScheduleCalculation): Function {
  return function (dispatch: Function): void {
    dispatch(calculateSleepScheduleActions.success(payload));
  };
}
function processCalculateSleepScheduleFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(calculateSleepScheduleActions.fail(error));
  };
}
export function calculateSleepScheduleThunk(personId: string, date: Moment, sleepScheduleCalculationRequest: SleepCalculationRequest) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(calculateSleepScheduleActions.start());
    return apiClient.post(`/v1/person/${personId}/calculateLast7DaysSleepSchedule?date=${date.format('yyyy-MM-DD')}`, {
      auth: true,
      data: sleepScheduleCalculationRequest,
      successAction: processCalculateSleepScheduleSuccess,
      failAction: processCalculateSleepScheduleFail
    });
  };
}

function processSaveSleepScheduleAndCalculationSuccess(
  sleepScheduleCalculation: SleepScheduleCalculation,
  ignoreWarnings: boolean,
  sleepSchedule: WorkflowUpdatingResponse<SleepSchedule>
): Function {
  return function (dispatch: Function): void {
    dispatch(
      saveSleepScheduleAndCalculationsActions.success({
        sleepScheduleCalculation,
        ignoreWarnings,
        sleepScheduleUpdateResponse: sleepSchedule
      })
    );
  };
}
function processSaveSleepScheduleAndCalculationFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(saveSleepScheduleAndCalculationsActions.fail(error));
  };
}
export function saveSleepScheduleAndCalculationThunk(personId: string, sleepScheduleCalculationRequest: SleepScheduleCalculationRequest) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(saveSleepScheduleAndCalculationsActions.start());
    return apiClient.post(`/v1/person/${personId}/sleepScheduleAndCalculation`, {
      auth: true,
      data: sleepScheduleCalculationRequest,
      successAction: (sleepSchedule: WorkflowUpdatingResponse<SleepSchedule>) =>
        processSaveSleepScheduleAndCalculationSuccess(sleepScheduleCalculationRequest.sleepScheduleCalculation, sleepScheduleCalculationRequest.ignoreWarnings, sleepSchedule),
      failAction: processSaveSleepScheduleAndCalculationFail
    });
  };
}

export function processSaveIsiScoresSuccess(personIsiScores: PersonIsiScore): Function {
  return function (dispatch: Function): void {
    dispatch(saveIsiScoreActions.success(personIsiScores));
  };
}
function processSaveIsiScoresFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(saveIsiScoreActions.fail(error));
  };
}
export function saveIsiScoresThunk(personIsiScores: PersonIsiScore) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(saveIsiScoreActions.start());
    return apiClient.post(`v1/person/isiScores`, {
      auth: true,
      data: personIsiScores,
      successAction: () => processSaveIsiScoresSuccess(personIsiScores),
      failAction: processSaveIsiScoresFail
    });
  };
}

export function processSaveOrderSuccess(personOrder: PersonOrder): Function {
  return function (dispatch: Function): void {
    dispatch(saveOrderActions.success(personOrder));
  };
}
function processSaveOrderFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(saveOrderActions.fail(error));
  };
}

function processGetValidSleepSchedulesSuccess(payload: SleepSchedule[]) {
  return function (dispatch: Function) {
    dispatch(getValidSleepSchedulesActions.success(payload));
  };
}

function processGetValidSleepSchedulesFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(getValidSleepSchedulesActions.fail(payload));
  };
}

export function getValidSleepSchedulesThunk(personId: string, startTs: number, endTs: number): Function {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getValidSleepSchedulesActions.start());
    return apiClient.get(`v1/person/${personId}/validSleepSchedulesInRange`, {
      params: {
        'start-ts': startTs,
        'end-ts': endTs
      },
      auth: true,
      successAction: processGetValidSleepSchedulesSuccess,
      failAction: processGetValidSleepSchedulesFail
    });
  };
}

// new thunk to get sleepschedules for review
function processGetSleepSchedulesForReviewSuccess(payload: SleepSchedule[]) {
  return function (dispatch: Function) {
    dispatch(getSleepSchedulesForReviewActions.success(payload));
  };
}

function processGetSleepSchedulesForReviewFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(getSleepSchedulesForReviewActions.fail(payload));
  };
}

export function getSleepSchedulesForReviewThunk(): Function {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getSleepSchedulesForReviewActions.start());
    return apiClient.get(`v1/sleepSchedule/reviews`, {
      auth: true,
      successAction: processGetSleepSchedulesForReviewSuccess,
      failAction: processGetSleepSchedulesForReviewFail
    });
  };
}

// new thunk to get latest sleep schedule for a person
function processGetLatestSleepScheduleSuccess(payload: Nullable<SleepSchedule>) {
  return function (dispatch: Function) {
    dispatch(getLatestSleepScheduleActions.success(payload));
  };
}

function processGetLatestSleepScheduleFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(getLatestSleepScheduleActions.fail(payload));
  };
}

export function getLatestSleepScheduleThunk(personId: string): Function {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getLatestSleepScheduleActions.start());
    return apiClient.get(`v1/sleepSchedule/person/${personId}/latestValid`, {
      auth: true,
      successAction: processGetLatestSleepScheduleSuccess,
      failAction: processGetLatestSleepScheduleFail
    });
  };
}

// new thunk to make sleep schedule editable
function processPutSleepScheduleEditableSuccess(payload: SleepSchedule) {
  return function (dispatch: Function) {
    dispatch(putSleepScheduleEditableActions.success(payload));
  };
}

function processPutSleepScheduleEditableFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(putSleepScheduleEditableActions.fail(payload));
  };
}

export function putSleepScheduleEditableThunk(sleepScheduleId: string): Function {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putSleepScheduleEditableActions.start());
    return apiClient.put(`v1/sleepSchedule/${sleepScheduleId}/edit`, {
      auth: true,
      successAction: processPutSleepScheduleEditableSuccess,
      failAction: processPutSleepScheduleEditableFail
    });
  };
}

// new thunk to update sleep schedule luna summary
function processUpdateSleepScheduleLunaSummarySuccess(payload: SleepSchedule) {
  return function (dispatch: Function) {
    dispatch(updateSleepScheduleLunaSummaryActions.success(payload));
  };
}

function processUpdateSleepScheduleLunaSummaryFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(updateSleepScheduleLunaSummaryActions.fail(payload));
  };
}

export function updateSleepScheduleLunaSummaryThunk(sleepSchedule: SleepSchedule, updateWeeklySummary): Function {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(updateSleepScheduleLunaSummaryActions.start());
    return apiClient.post(`v1/sleepSchedule/summary/update`, {
      auth: true,
      params: {
        'update-weekly-summary': updateWeeklySummary
      },
      data: sleepSchedule,
      successAction: processUpdateSleepScheduleLunaSummarySuccess,
      failAction: processUpdateSleepScheduleLunaSummaryFail
    });
  };
}

// new thunk to send sleep schedule
function processPostSleepScheduleSendSuccess(payload: SleepSchedule) {
  return function (dispatch: Function) {
    dispatch(postSleepScheduleSendActions.success(payload));
  };
}

function processPostSleepScheduleSendFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(postSleepScheduleSendActions.fail(payload));
  };
}

export function postSleepScheduleSendThunk(sleepSchedule: SleepSchedule): Function {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(postSleepScheduleSendActions.start());
    return apiClient.post(`v1/sleepSchedule/send`, {
      auth: true,
      data: sleepSchedule,
      successAction: processPostSleepScheduleSendSuccess,
      failAction: processPostSleepScheduleSendFail
    });
  };
}

// new thunk to create new sleep schedule
function processCreateNewSleepScheduleSuccess(payload: SleepSchedule) {
  return function (dispatch: Function) {
    dispatch(createNewSleepScheduleActions.success(payload));
  };
}

function processCreateNewSleepScheduleFail(payload: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(createNewSleepScheduleActions.fail(payload));
  };
}

export function createNewSleepScheduleThunk(personId: string, sleepCalculationRequest: SleepCalculationRequest): Function {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(createNewSleepScheduleActions.start());
    return apiClient.post(`v1/sleepSchedule/person/${personId}/newSchedule`, {
      auth: true,
      data: sleepCalculationRequest,
      successAction: processCreateNewSleepScheduleSuccess,
      failAction: processCreateNewSleepScheduleFail
    });
  };
}
