import { Divider, Drawer, Grid, MuiThemeProvider, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { BreadCrumbData, Error } from 'redux/common/types';
import { ReduxState } from 'redux/types';
import { NavBar } from '../../components/NavBar/NavBar';
import { SleepScheduleCard } from '../../components/SleepScheduleCard/SleepScheduleCard';
import { SleepScheduleDetailsDrawer } from '../../components/SleepScheduleDetailsDrawer/SleepScheduleDetailsDrawer';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '../../redux/oauth/oauthSelectors';
import { getPersonByIdSelector } from '../../redux/person/personSelector';
import { Person } from '../../redux/person/personTypes';
import { getClearResponseStatusSleepScheduleAction } from '../../redux/sleepschedule/sleepScheduleActions';
import {
  getErrorSleepScheduleSelector,
  getIsLoadingSleepScheduleSelector,
  getLatestSleepScheduleIdByPersonIdSelector,
  getSleepScheduleSuccessMessageSelector,
  getSleepSchedulesByIdSelector,
  getSleepSchedulesToReviewSelector,
  getSuccessSleepScheduleSelector
} from '../../redux/sleepschedule/sleepScheduleSelector';
import {
  getLatestSleepScheduleThunk,
  getSleepSchedulesForReviewThunk,
  postSleepScheduleSendThunk,
  putSleepScheduleEditableThunk,
  updateSleepScheduleLunaSummaryThunk
} from '../../redux/sleepschedule/sleepScheduleThunks';
import { SleepSchedule } from '../../redux/sleepschedule/sleepScheduleTypes';
import { clarifyErrorMessage, getActiveRecord, hasActiveRecord } from '../../utils/createFrontEndError';
import { RouteNames } from '../../utils/navigationUtils';
import { defaultTheme } from '../../utils/styles';
import { defaultSleepSchedulesToReviewBreadCrumb, defaultSleepSchedulesToReviewBreadCrumbs } from '../../utils/breadcrumbs';
import { goTo } from '../../utils/util';
import style from './SleepScheduleReview.scss';

interface Props {
  sleepScheduleById: Record<string, SleepSchedule>;
  latestSleepScheduleIdByPersonId: Record<string, string>;
  personById: Record<string, Person>;
  sleepSchedulesToReview: string[];
  getSleepSchedulesToReview: () => Promise<any>;
  getLatestSleepScheduleForPersonId: (personId: string) => Promise<any>;
  putSleepScheduleEditable: (sleepScheduleId: string) => Promise<any>;
  updateSleepScheduleLunaSummary: (sleepSchedule: SleepSchedule, weeklySummary: boolean) => Promise<any>;
  postSleepScheduleSend: (sleepSchedule: SleepSchedule) => Promise<any>;
  appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
  clearResponseStatusSleepScheduleAction: () => void;
  breadCrumbs: BreadCrumbData[];
  successSchedule: Record<string, boolean>;
  scheduleSuccessMessage: Nullable<string>;
  scheduleError: Record<string, Error>;
  isSleepScheduleLoading: Record<string, boolean>;
}

const SleepScheduleReview: React.FC<Props> = ({
  sleepScheduleById,
  latestSleepScheduleIdByPersonId,
  personById,
  sleepSchedulesToReview,
  getSleepSchedulesToReview,
  getLatestSleepScheduleForPersonId,
  putSleepScheduleEditable,
  updateSleepScheduleLunaSummary,
  postSleepScheduleSend,
  appendBreadCrumbs,
  breadCrumbs,
  successSchedule,
  scheduleSuccessMessage,
  scheduleError,
  isSleepScheduleLoading,
  clearResponseStatusSleepScheduleAction
}) => {
  // const [currentIndex, setCurrentIndex] = React.useState<number>(-1);
  // const [isAutomaticSelected, setIsAutomaticSelected] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const sleepScheduleId: Nullable<string> = params['sleepScheduleId'];
  const [currentSleepScheduleId, setCurrentSleepScheduleId] = React.useState<Nullable<string>>(sleepScheduleId);

  useEffect(() => {
    getSleepSchedulesToReview();
  }, []);

  const manualSleepScheduleReviews: SleepSchedule[] = [];
  const automaticSleepScheduleReviews: SleepSchedule[] = [];

  let sleepSchedules: SleepSchedule[] = [];
  for (const sleepScheduleId of sleepSchedulesToReview) {
    const sleepSchedule = sleepScheduleById[sleepScheduleId];
    if (sleepSchedule && !sleepSchedule.enabled) {
      sleepSchedules.push(sleepSchedule);
    }
  }

  sleepSchedules = sleepSchedules.sort((a: SleepSchedule, b: SleepSchedule) => {
    if (a.enabledTs && b?.enabledTs) {
      return a.enabledTs > b.enabledTs ? -1 : 1;
    } else if (a.enabledTs) {
      return -1;
    }
    return 1;
  });

  const currentIndex = currentSleepScheduleId ? sleepSchedules.findIndex((schedule: SleepSchedule) => schedule?.id === currentSleepScheduleId) : -1;

  const onSleepScheduleSelection = (sleepSchedule: Nullable<SleepSchedule>) => {
    setCurrentSleepScheduleId(sleepSchedule?.id ?? null);
    if (sleepSchedule && sleepSchedule?.personId) {
      getLatestSleepScheduleForPersonId(sleepSchedule.personId);
      goTo(`${RouteNames.SLEEP_SCHEDULE_REVIEW}/${sleepSchedule.id}`, navigate, {}).call(this);
    } else {
      goTo(`${RouteNames.SLEEP_SCHEDULE_REVIEW}`, navigate, {}).call(this);
    }
  };

  const onPutSleepScheduleEditable = async (sleepScheduleId: string) => {
    const sleepSchedule = await putSleepScheduleEditable(sleepScheduleId);
    setCurrentSleepScheduleId(sleepScheduleId);
  };

  const onPostSleepScheduleSend = async (sleepSchedule: SleepSchedule) => {
    const wasLastitem = currentIndex === sleepSchedules.length - 1;
    const nextIndex = wasLastitem ? currentIndex - 1 : currentIndex + 1;
    const nextSleepSchedule = nextIndex > -1 && nextIndex < sleepSchedules.length ? sleepSchedules[nextIndex] : null;
    await postSleepScheduleSend(sleepSchedule);
    onSleepScheduleSelection(null);
    setTimeout(() => {
      if (nextSleepSchedule) {
        onSleepScheduleSelection(nextSleepSchedule);
      }
    }, 500);
  };

  let selectedSleepSchedule: Nullable<SleepSchedule> = currentSleepScheduleId ? sleepScheduleById[currentSleepScheduleId] : null;

  const oldSleepScheduleId = selectedSleepSchedule?.personId ? latestSleepScheduleIdByPersonId[selectedSleepSchedule?.personId] : null;
  const oldSleepSchedule = oldSleepScheduleId ? sleepScheduleById[oldSleepScheduleId] : null;

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.container}>
        <NavBar
          breadCrumbs={breadCrumbs}
          appendBreadCrumbs={appendBreadCrumbs}
          defaultCurrentCrumb={defaultSleepSchedulesToReviewBreadCrumb}
          defaultCrumbHistory={defaultSleepSchedulesToReviewBreadCrumbs}
          overrideUrl={location?.pathname}
        />
        <Typography className={style.reviewHeader}>Sleep Schedules</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" component="h3">
              Manual Send
            </Typography>
            <Divider />
            {sleepSchedules.map((sleepSchedule: SleepSchedule, index: number) => {
              if (!sleepSchedule?.enabledTs) {
                return (
                  <div key={sleepSchedule.id}>
                    <SleepScheduleCard
                      sleepSchedule={sleepSchedule}
                      person={personById[sleepSchedule.personId]}
                      onClick={() => onSleepScheduleSelection(sleepSchedule)}
                    ></SleepScheduleCard>
                  </div>
                );
              }
            })}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" component="h3">
              Auto Send
            </Typography>
            <Divider />
            {sleepSchedules.map((sleepSchedule: SleepSchedule, index: number) => {
              if (sleepSchedule?.enabledTs) {
                return (
                  <div key={sleepSchedule.id}>
                    <SleepScheduleCard
                      sleepSchedule={sleepSchedule}
                      person={personById[sleepSchedule.personId]}
                      onClick={() => onSleepScheduleSelection(sleepSchedule)}
                    ></SleepScheduleCard>
                  </div>
                );
              }
            })}
          </Grid>
        </Grid>
        <Drawer anchor="right" open={selectedSleepSchedule !== null} onClose={() => onSleepScheduleSelection(null)}>
          <SleepScheduleDetailsDrawer
            newSleepScheduleId={selectedSleepSchedule?.id}
            oldSleepScheduleId={oldSleepSchedule?.id}
            sleepScheduleById={sleepScheduleById}
            person={personById[selectedSleepSchedule?.personId ?? '']}
            putSleepScheduleEditable={onPutSleepScheduleEditable}
            updateSleepScheduleLunaSummary={updateSleepScheduleLunaSummary}
            postSleepScheduleSend={onPostSleepScheduleSend}
            currentIndex={currentIndex}
            isLastItem={currentIndex === sleepSchedules.length - 1}
            updateIndex={(index: number) => onSleepScheduleSelection(sleepSchedules[index])}
          />
        </Drawer>
        <StatusMessage
          saveSuccessful={!hasActiveRecord(isSleepScheduleLoading) && !hasActiveRecord(scheduleError) && hasActiveRecord(successSchedule)}
          saveUnsuccessful={!hasActiveRecord(isSleepScheduleLoading) && hasActiveRecord(scheduleError)}
          clearResponseStatus={() => clearResponseStatusSleepScheduleAction()}
          saveMessage={scheduleSuccessMessage}
          errorMessage={clarifyErrorMessage(getActiveRecord(scheduleError))}
        />
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      sleepScheduleById: getSleepSchedulesByIdSelector(state),
      latestSleepScheduleIdByPersonId: getLatestSleepScheduleIdByPersonIdSelector(state),
      sleepSchedulesToReview: getSleepSchedulesToReviewSelector(state),
      breadCrumbs: getBreadcrumbsSelector(state),
      personById: getPersonByIdSelector(state),
      scheduleSuccessMessage: getSleepScheduleSuccessMessageSelector(state),
      successSchedule: getSuccessSleepScheduleSelector(state),
      isSleepScheduleLoading: getIsLoadingSleepScheduleSelector(state),
      scheduleError: getErrorSleepScheduleSelector(state)
    };
  },
  (dispatch: Function) => ({
    getSleepSchedulesToReview: (): Promise<any> => {
      return dispatch(getSleepSchedulesForReviewThunk());
    },
    getLatestSleepScheduleForPersonId: (personId: string): Promise<any> => {
      return dispatch(getLatestSleepScheduleThunk(personId));
    },
    putSleepScheduleEditable: (sleepScheduleId: string): Promise<any> => {
      return dispatch(putSleepScheduleEditableThunk(sleepScheduleId));
    },
    updateSleepScheduleLunaSummary: (sleepSchedule: SleepSchedule, weeklySummary: boolean): Promise<any> => {
      return dispatch(updateSleepScheduleLunaSummaryThunk(sleepSchedule, weeklySummary));
    },
    postSleepScheduleSend: (sleepSchedule: SleepSchedule): Promise<any> => {
      return dispatch(postSleepScheduleSendThunk(sleepSchedule));
    },
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]): void => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    clearResponseStatusSleepScheduleAction: (): void => {
      dispatch(getClearResponseStatusSleepScheduleAction());
    }
  })
);

export default compose(connectRedux)(SleepScheduleReview);
