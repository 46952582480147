import React, { Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DualPane } from '../../components/DualPane/DualPane';
import { PageType } from '../../../../redux/analytics/analyticsTypes';
import { pageLeftThunk } from '../../../../redux/analytics/analyticsThunks';
import { BedsidePane } from '../../components/DualPane/Panes/BedsidePane/BedsidePane';
import { SixWeekProgramPane } from '../../components/DualPane/Panes/SixWeekProgramPane/SixWeekProgramPane';

interface Props {
  pageLeft: Function;
}

const PostInterstitial: React.FC<Props> = ({ pageLeft }) => (
  <DualPane
    pageLeft={pageLeft}
    pageType={PageType.CREATE_ACCOUNT_POST_INTERSTITIAL}
    leftPane={<BedsidePane />}
    rightPane={(loadingState: [boolean, Dispatch<SetStateAction<boolean>>], handleExternalRouting: (url: string) => void) => (
      <SixWeekProgramPane pageType={PageType.CREATE_ACCOUNT_POST_INTERSTITIAL} loadingState={loadingState} handleExternalRouting={handleExternalRouting} />
    )}
  />
);

const connectRedux = connect(
  () => {
    return {};
  },
  (dispatch: Function) => ({
    pageLeft: async (pageType: PageType) => {
      return dispatch(pageLeftThunk(pageType));
    }
  })
);

export default compose(connectRedux)(PostInterstitial);
