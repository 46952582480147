import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import style from './DeleteAccountConfirm.scss';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ReduxState } from '../../redux/types';
import DeleteAccountDataList from '../../components/DeleteAccount/DeleteAccountDataList';
import { getQueryParamObject } from '../../utils/util';
import { useLocation } from 'react-router-dom';
import { deleteAccountNotifySupportEmailThunk } from '../../redux/oauth/oauthThunks';
import { getErrorDeleteAccountNotifySupportEmailSelector, isLoadingDeleteAccountNotifySupportEmailSelector } from '../../redux/oauth/oauthSelectors';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux;

const DeleteAccountConfirm: React.FC<Props> = ({ errorDeleteAccountNotifySupportEmail, isLoadingDeleteAccountNotifySupportEmail, deleteAccountNotifySupportEmail }) => {
  const [deleteAccountEmailSuccesfull, setDeleteAccountEmailSuccesfull] = useState<boolean>(false);

  const location = useLocation();
  const token: string = getQueryParamObject(location?.search)?.token;

  const handleConfirmDelete = async () => {
    try {
      await deleteAccountNotifySupportEmail(token);
      setDeleteAccountEmailSuccesfull(true);
    } catch (err) {}
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Full Sleep Account Deletion
      </Typography>

      {deleteAccountEmailSuccesfull ? (
        <Box sx={{ my: 2 }}>
          <Typography variant="body1" color={'black'}>
            Your request to delete your account and all associated data has been received and is currently being processed. The estimated time for completing the data deletion
            request is 30 days.
            <br />
            <br />
            For any questions or further assistance, please contact us at <strong>support@fullsleep.com.</strong>
          </Typography>
        </Box>
      ) : (
        <>
          <Typography paragraph>Are you sure you want to permanently delete your account?</Typography>
          <DeleteAccountDataList />
          <Button variant="contained" color="secondary" onClick={handleConfirmDelete} sx={{ mt: 1 }} disabled={isLoadingDeleteAccountNotifySupportEmail}>
            Permanently Delete Account
          </Button>
        </>
      )}

      {errorDeleteAccountNotifySupportEmail && (
        <Box sx={{ my: 2 }}>
          <Typography variant="body1" color={'red'}>
            Something went wrong. Please try again. <br />
            If the issue persists, contact <strong>support@fullsleep.com</strong> for assistance.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    errorDeleteAccountNotifySupportEmail: getErrorDeleteAccountNotifySupportEmailSelector(state),
    isLoadingDeleteAccountNotifySupportEmail: isLoadingDeleteAccountNotifySupportEmailSelector(state)
  }),
  {
    deleteAccountNotifySupportEmail: deleteAccountNotifySupportEmailThunk
  }
);

export default compose(connectRedux)(DeleteAccountConfirm);
