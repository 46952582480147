import React from 'react';
import { useNavigate } from 'react-router-dom';
import { goTo } from '../../utils/util';
import { Text } from '../Typography/Text';
import style from './LinkCrumb.scss';

interface Props {
  label: string;
  url: string;
  state?: Nullable<any>;
}

export const LinkCrumb: React.FC<Props> = ({ label, url, state }): JSX.Element => (
  <div onClick={goTo(url, useNavigate(), state)}>
    <Text variant="md" className={style.linkText}>
      {label}
    </Text>
  </div>
);
