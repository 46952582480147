import React, { Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { registerThunk } from '../../../../redux/oauth/oauthThunks';
import { RegisterPayload } from '../../../../redux/oauth/oauthTypes';
import { SignupPane } from '../../components/DualPane/Panes/SignupPane/SignupPane';
import { DevicePane } from '../../components/DualPane/Panes/DevicePane/DevicePane';
import { DualPane } from '../../components/DualPane/DualPane';
import { PageType } from '../../../../redux/analytics/analyticsTypes';
import { pageLeftThunk } from '../../../../redux/analytics/analyticsThunks';

interface Props {
  register: Function;
  pageLeft: Function;
}

const Register: React.FC<Props> = ({ register, pageLeft }) => (
  <DualPane
    pageLeft={pageLeft}
    pageType={PageType.CREATE_ACCOUNT_REGISTER}
    leftPane={<DevicePane />}
    rightPane={(loadingState: [boolean, Dispatch<SetStateAction<boolean>>], handleExternalRouting: (url: string) => void, handleError: (err: any) => void) => (
      <SignupPane register={register} loadingState={loadingState} handleError={handleError} handleExternalRouting={handleExternalRouting} />
    )}
  />
);

const connectRedux = connect(
  () => {
    return {};
  },
  (dispatch: Function) => ({
    register: async (registerPayload: RegisterPayload) => {
      return dispatch(registerThunk(registerPayload));
    },
    pageLeft: async (pageType: PageType) => {
      return dispatch(pageLeftThunk(pageType));
    }
  })
);

export default compose(connectRedux)(Register);
