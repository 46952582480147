import React from 'react';
import style from './DateControl.scss';
import caretLeftIcon from '../../assets/images/caret-left.png';
import caretRightIcon from '../../assets/images/caret-right.png';
import { Text } from '../Typography';
import { timestampToLocaleDateString } from '../../utils/time';

interface Props {
  date: string;
  timezone: Nullable<string>;
  onClickLeftCaret: () => void;
  onClickRightCaret: () => void;
}

const DateControl: React.FC<Props> = ({ date, timezone, onClickLeftCaret, onClickRightCaret }) => {
  return (
    <div className={style.container}>
      <div onClick={onClickLeftCaret} className={style.caretContainer}>
        <img src={caretLeftIcon} className={style.icon} />
      </div>
      <div onClick={onClickRightCaret} className={style.caretContainer}>
        <img src={caretRightIcon} className={style.icon} />
      </div>
      <div className={style.dateContainer}>
        <Text variant="md">{timestampToLocaleDateString(date, timezone)}</Text>
      </div>
    </div>
  );
};

export default DateControl;
