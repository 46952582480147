import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import style from './PurchasePane.scss';
import { PageType } from '../../../../../../redux/analytics/analyticsTypes';
import { HelpLink } from '../../../HelpLink/HelpLink';
import { getEmailParam, getPriceParam, getPriceParamOrDefault, getStripeCheckoutParameterizedUrl, parameterizeUrl, RouteNames } from '../../../../../../utils/navigationUtils';
import checkoutImage from '../../../../../../assets/images/checkout-mobile.jpg';
import { useLocation } from 'react-router-dom';
import { AccountButton } from '../../../AccountButton/AccountButton';

interface Props {
  loadingState: [boolean, Dispatch<SetStateAction<boolean>>];
  pageType: PageType;
  handleExternalRouting: (url: string) => void;
}

export const PurchasePane: React.FC<Props> = ({ loadingState, pageType, handleExternalRouting }) => {
  const location = useLocation();

  useEffect(() => {
    loadingState[1](false);
  }, []);

  const submitSignup = () => {
    loadingState[1](true);
    const providedEmail: string = getEmailParam(location);
    const priceValue: string = getPriceParam(location);
    const forwardUrl: string =
      pageType === PageType.CREATE_ACCOUNT_PRE_INTERSTITIAL
        ? parameterizeUrl(RouteNames.CREATE_ACCOUNT_REGISTER, providedEmail, priceValue)
        : getStripeCheckoutParameterizedUrl(providedEmail);
    handleExternalRouting(forwardUrl);
  };

  return (
    <Box className={style.inputBox}>
      {/* @ts-expect-error: TODO add description */}
      <Hidden mdUp>
        <Box className={style.imageHeaderBox}>
          <img className={style.imageBox} src={checkoutImage} alt="Full Sleep" />
        </Box>
      </Hidden>
      <Box className={style.headerBox}>
        <Typography className={style.helpHeaderRight}>Happy days! You've secured one of a limited amount of programs!</Typography>
      </Box>
      <Box className={style.discountGridBox}>
        <Grid className={style.discountGrid} container>
          <Grid className={style.discountGridHeader} item xs={12}>
            <Typography className={style.discountGridCell}>Summary</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box marginX={1}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography className={style.discountGridCell}>Full Sleep Program</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={style.discountGridPrice}>${getPriceParamOrDefault(location)}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={style.discountGridCell}>Shipping</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={style.discountGridPrice}>FREE</Typography>
                </Grid>
                <Grid className={style.borderTop} item xs={8}>
                  <Typography className={style.discountGridCellTotal}>Subtotal</Typography>
                </Grid>
                <Grid className={style.borderTop} item xs={4}>
                  <Typography className={style.discountGridPriceTotal}>${getPriceParamOrDefault(location)}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={style.footerBox}>
        <Typography className={style.helpFooterRight}>
          We offer a 30 day money back guarantee if Full Sleep does not work for you, so there's no risk besides the risk of losing more and more sleep if you don't order today.
        </Typography>
      </Box>
      <Box>
        <AccountButton label={'CONTINUE TO PURCHASE'} onClick={submitSignup} loading={loadingState[0]} cssClass={style.purchaseButton} />
      </Box>
      {/* @ts-expect-error: TODO add description */}
      <Hidden mdUp>
        <Box className={style.subFooterBox}>
          <Typography className={style.helpSubFooterRight}>
            Questions? Text us at <HelpLink text={'844-SLEEPS1'} underline={true} />
            <br />
            FullSleep.com
          </Typography>
        </Box>
      </Hidden>
    </Box>
  );
};
