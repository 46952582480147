import React, { Dispatch, SetStateAction, useState } from 'react';
import { AdHocContentRequest, DeviceContent } from '../../redux/content/contentTypes';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';

interface Props {
  personIdsSet: Set<string>;
  sendPushNotifications: Function;
  sendAdHocContent: Function;
  clearSubmissionResponse: () => void;
  toggleDisplay: (x?: Nullable<boolean>, y?: Nullable<boolean>) => void;
  submitSuccessful: boolean;
  submitFailed: boolean;
  errorMessage?: Nullable<string>;
  createJsx: (
    adHocContentId: string,
    pushNotificationTitleState: [string, Dispatch<SetStateAction<string>>],
    pushNotificationBodyState: [string, Dispatch<SetStateAction<string>>],
    adHocPushNotificationTitleState: [string, Dispatch<SetStateAction<string>>],
    adHocPushNotificationBodyState: [string, Dispatch<SetStateAction<string>>],
    onSelectContent: (deviceContent: Nullable<DeviceContent>) => void,
    onCreateAdHocContent: () => Promise<any>,
    onSendPushNotification: () => Promise<any>
  ) => JSX.Element;
}
export const ContentSubmitter: React.FC<Props> = ({
  personIdsSet,
  sendPushNotifications,
  sendAdHocContent,
  clearSubmissionResponse,
  toggleDisplay,
  submitSuccessful,
  submitFailed,
  errorMessage,
  createJsx
}) => {
  const pushNotificationTitleState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');
  const pushNotificationBodyState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');

  const [adHocContentId, setAdHocContentId] = useState<string>('');
  const [adHocTitle, setAdHocTitle] = useState<string>('');
  const [adHocSubTitle, setAdHocSubTitle] = useState<string>('');
  const [adHocContentUrl, setAdHocContentUrl] = useState<string>('');

  const adHocPushNotificationTitleState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');
  const adHocPushNotificationBodyState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');

  const onSelectContent = (deviceContent: Nullable<DeviceContent>): void => {
    setAdHocContentId(deviceContent?.id ? deviceContent.id : '');
    setAdHocTitle(deviceContent?.title ? deviceContent.title : '');
    setAdHocSubTitle(deviceContent?.subTitle ? deviceContent.subTitle : '');
    setAdHocContentUrl(deviceContent?.contentUrl ? deviceContent.contentUrl : '');
  };

  const onSendPushNotification = async (): Promise<any> => {
    toggleDisplay();
    await sendPushNotifications(Array.from(personIdsSet.values()), pushNotificationTitleState[0], pushNotificationBodyState[0]);
    pushNotificationTitleState[1](() => '');
    pushNotificationBodyState[1](() => '');
  };

  const onCreateAdHocContent = async (): Promise<any> => {
    toggleDisplay();
    const personIds: string[] = Array.from(personIdsSet.values());
    const adHocContentRequest: AdHocContentRequest = {
      contentId: adHocContentId,
      personId: personIds[0],
      title: adHocTitle,
      subTitle: adHocSubTitle,
      contentUrl: adHocContentUrl,
      personIds: personIds
    };
    const includeSns: boolean = !!adHocPushNotificationTitleState[0];
    await sendAdHocContent(adHocContentRequest, includeSns);
    if (includeSns) {
      await sendPushNotifications(personIds, adHocPushNotificationTitleState[0], adHocPushNotificationBodyState[0]);
    }
    setAdHocContentId('');
    setAdHocTitle('');
    setAdHocSubTitle('');
    setAdHocContentUrl('');
    adHocPushNotificationTitleState[1]('');
    adHocPushNotificationBodyState[1]('');
  };

  return (
    <React.Fragment>
      <StatusMessage
        saveSuccessful={submitSuccessful}
        saveUnsuccessful={submitFailed}
        clearResponseStatus={clearSubmissionResponse}
        saveMessage={'Update Successful'}
        errorMessage={errorMessage}
      />
      {createJsx(
        adHocContentId,
        pushNotificationTitleState,
        pushNotificationBodyState,
        adHocPushNotificationTitleState,
        adHocPushNotificationBodyState,
        onSelectContent,
        onCreateAdHocContent,
        onSendPushNotification
      )}
    </React.Fragment>
  );
};
