import React from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent } from '@mui/material';
import style from './SendMessageDialog.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  onSend: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  setText: (string) => void;
}

const SendMessageDialog: React.FC<Props> = React.memo(({ open, onClose, onSend, text, setText }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          minWidth: '350px',
          maxWidth: '80%'
        }
      }}
    >
      <DialogContent>
        <TextField
          multiline
          autoFocus
          margin="dense"
          id="name"
          label="Enter text"
          type="text"
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
          className={style.textField}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className={style.button}>
          Cancel
        </Button>
        <Button onClick={onSend} className={style.button}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default SendMessageDialog;
