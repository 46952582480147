import { createAction } from '@reduxjs/toolkit';
import { createReduxActionCreator } from '../types';
import {
  DeviceAndProgramContentUpdateRequest,
  DeviceContent,
  DeviceContentPage,
  DeviceContentType,
  FeedItem,
  PersonWorkflow,
  PersonWorkflowComment,
  PersonWorkflowTaskStatus,
  ProgramContent,
  ProgramType,
  SaveFlagContentResponsePayload,
  SavePersonTaskStatusResponsePayload,
  SurveyScoreResponse,
  WorkflowTemplate
} from './contentTypes';

export const CONTENT_ACTION_PREFIX = 'content-redux/';

export const createAdHocBatchContentActions = createReduxActionCreator<Nullable<boolean>>(`${CONTENT_ACTION_PREFIX}create-ad-hoc-batch-content`);
export const getCreateAdHocContentActions = createReduxActionCreator<undefined>(`${CONTENT_ACTION_PREFIX}create-ad-hoc-content`);

export const getProgramContentByTypeActions = createReduxActionCreator<ProgramContent[], ProgramType>(`${CONTENT_ACTION_PREFIX}get-program-content-by-type`);

export const getSurveyResponsesByTypeActions = createReduxActionCreator<SurveyScoreResponse[]>(`${CONTENT_ACTION_PREFIX}get-survey-responses-by-type`);

export const getFeedItemsActions = createReduxActionCreator<FeedItem[]>(`${CONTENT_ACTION_PREFIX}get-feed-items`);

export const getDeviceContentByTypeActions = createReduxActionCreator<DeviceContent[], Nullable<DeviceContentType[]>>(`${CONTENT_ACTION_PREFIX}get-device-content-by-type`);

export const updateDeviceContentActions = createReduxActionCreator<DeviceContent>(`${CONTENT_ACTION_PREFIX}update-device-content`);

export const saveDeviceAndProgramContentsActions = createReduxActionCreator<DeviceAndProgramContentUpdateRequest>(`${CONTENT_ACTION_PREFIX}save-device-and-program-contents`);

export const addDeviceContentActions = createReduxActionCreator<DeviceContent>(`${CONTENT_ACTION_PREFIX}add-device-content`);

export const getDeviceContentPageActions = createReduxActionCreator<DeviceContentPage>(`${CONTENT_ACTION_PREFIX}get-device-content-page`);

export const updateDeviceContentPageActions = createReduxActionCreator<DeviceContentPage>(`${CONTENT_ACTION_PREFIX}update-device-content-page`);

export const addDeviceContentPageActions = createReduxActionCreator<DeviceContentPage>(`${CONTENT_ACTION_PREFIX}add-device-content-page`);

export const removeDeviceContentPageActions = createReduxActionCreator<string>(`${CONTENT_ACTION_PREFIX}remove-device-content-page`);

export const getDeviceContentDetailsActions = createReduxActionCreator<DeviceContent>(`${CONTENT_ACTION_PREFIX}get-device-content-details`);

export const getAllFlagContentActions = createReduxActionCreator<WorkflowTemplate[]>(`${CONTENT_ACTION_PREFIX}get-all-flag-content`);

export const getFlagContentActions = createReduxActionCreator<WorkflowTemplate>(`${CONTENT_ACTION_PREFIX}get-flag-content`);

export const getPersonFlagContentActions = createReduxActionCreator<PersonWorkflow[]>(`${CONTENT_ACTION_PREFIX}get-person-flag-content`);

export const saveFlagContentActions = createReduxActionCreator<SaveFlagContentResponsePayload>(`${CONTENT_ACTION_PREFIX}save-flag-content`);

export const saveWorkflowCommentActions = createReduxActionCreator<PersonWorkflowComment>(`${CONTENT_ACTION_PREFIX}save-workflow-comment`);

export const savePersonTaskStatusActions = createReduxActionCreator<SavePersonTaskStatusResponsePayload>(`${CONTENT_ACTION_PREFIX}save-person-task-status`);

export const copyDeviceContentDetailsActions = createReduxActionCreator<string>(`${CONTENT_ACTION_PREFIX}copy-device-content-details`);

export const syncContentActions = createReduxActionCreator<undefined>(`${CONTENT_ACTION_PREFIX}sync-content`);

export const getClearDeviceContentDetailsAction = createAction<undefined>(`${CONTENT_ACTION_PREFIX}clear-device-content-details`);

export const getClearResponseStatusAction = createAction<undefined>(`${CONTENT_ACTION_PREFIX}clear-response-status`);
