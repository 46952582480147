import React from 'react';
import { Box, CardHeader, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import style from '../../UserDetails.scss';
import { ClientDevice, PushNotification } from '../../../../redux/clientDevice/clientDeviceTypes';
import { tsAsDateTimeStr } from '../../../../utils/time';

export const PushNotificationsLog: React.FC<{
  pushNotificationsById: Record<string, PushNotification>;
  clientDevicesById: Record<string, ClientDevice>;
}> = ({ pushNotificationsById, clientDevicesById }) => {
  return (
    <Box mt={3}>
      <CardHeader title={'Push Notification Log'} style={{ align: 'left', paddingLeft: '0px', paddingTop: '0px' }} />
      <TableContainer component={Paper}>
        <Table size="small" style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell className={style.pushNotificationHeaderCell}>DATE (CURRENT TIMEZONE)</TableCell>
              <TableCell className={style.pushNotificationHeaderCell}>DATE (CLIENT TIMEZONE)</TableCell>
              <TableCell className={style.pushNotificationHeaderCell}>TITLE</TableCell>
              <TableCell className={style.pushNotificationHeaderCell}>BODY</TableCell>
              <TableCell className={style.pushNotificationHeaderCell}>PUSH NOTIFICATION TYPE</TableCell>
              <TableCell className={style.pushNotificationHeaderCell}>OS TYPE</TableCell>
              <TableCell className={style.pushNotificationHeaderCell}>OS VERSION</TableCell>
              <TableCell className={style.pushNotificationHeaderCell}>APP VERSION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pushNotificationsById &&
              Object.entries(pushNotificationsById)
                ?.sort(([, a], [, b]) => (a?.created && b?.created ? b?.created?.localeCompare(a?.created) : 0))
                ?.map(([id, pushNotification]) => {
                  const clientDevice: Nullable<ClientDevice> = clientDevicesById[pushNotification.clientDeviceId];
                  const created: Nullable<string> = pushNotification?.created;
                  const title: Nullable<string> = pushNotification?.title;
                  const body: Nullable<string> = pushNotification?.body;
                  const pushNotificationType: Nullable<string> = pushNotification?.pushNotificationType;
                  const osType: Nullable<string> = clientDevice?.osType;
                  const osVersion: Nullable<string> = clientDevice?.osVersion;
                  const appVersion: Nullable<string> = clientDevice?.appVersion;
                  return (
                    <TableRow key={id}>
                      <TableCell className={style.rowCell}>{tsAsDateTimeStr(created)}</TableCell>
                      <TableCell className={style.rowCell}>{tsAsDateTimeStr(created, clientDevice.clientTimeZoneId)}</TableCell>
                      <TableCell className={style.rowCell}>{title}</TableCell>
                      <TableCell className={style.rowCell}>{body}</TableCell>
                      <TableCell className={style.rowCell}>{pushNotificationType}</TableCell>
                      <TableCell className={style.rowCell}>{osType}</TableCell>
                      <TableCell className={style.rowCell}>{osVersion}</TableCell>
                      <TableCell className={style.rowCell}>{appVersion}</TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
