import IconButton from '@material-ui/core/IconButton';
import React, { MouseEventHandler } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  asButton?: Nullable<boolean>;
  buttonSize?: Nullable<'small' | 'medium'>;
  style?: React.CSSProperties; //external component does not support nullable types, so we can't apply Nullable<> type here
  onClick?: Nullable<MouseEventHandler<any>>;
}

export const CompleteIcon: React.FC<Props> = ({ asButton, buttonSize, style, onClick }) => {
  const svgIcon = (): JSX.Element => ( //@ts-expect-error: TODO add description
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" style={style} onClick={onClick}>
      <rect x="2.80005" y="2.7998" width="14.4" height="14.4" rx="1.6" fill="#007EB5" />
      <path d="M8.40013 13.5215L5.20013 10.3215L6.32013 9.20147L8.40013 11.2815L13.6801 6.00146L14.8001 7.12147L8.40013 13.5215Z" fill="white" />
    </SvgIcon>
  );

  if (asButton) {
    return <IconButton size={buttonSize ? buttonSize : 'medium'}>{svgIcon()}</IconButton>;
  }
  return svgIcon();
};
