import React from 'react';
import { Link } from '@material-ui/core';

interface Props {
  text: string;
  underline?: Nullable<boolean>;
}

export const HelpLink: React.FC<Props> = ({ text, underline }) => (
  <Link href={'sms:18447533771'} underline={underline ? 'always' : 'hover'}>
    {text}
  </Link>
);
