import { Person } from 'redux/person/personTypes';
import { createReduxActionCreator } from '../types';
import { CareTeam, PersonCareTeam } from './clinicalTypes';

export const CARE_PLAN_ACTION_PREFIX = 'clinical-redux/';

export const addNewCareTeamAction = createReduxActionCreator<CareTeam>(`${CARE_PLAN_ACTION_PREFIX}add-new-care-team`);
export const getCareTeamsAction = createReduxActionCreator<CareTeam[]>(`${CARE_PLAN_ACTION_PREFIX}get-care-teams`);
export const addPersonToCareTeamAction = createReduxActionCreator<PersonCareTeam>(`${CARE_PLAN_ACTION_PREFIX}add-person-to-care-team`);
export const removePersonfromCareTeamAction = createReduxActionCreator<PersonCareTeam>(`${CARE_PLAN_ACTION_PREFIX}remove-person-from-care-team`);
export const getCareTeamPersonsAction = createReduxActionCreator<Person[]>(`${CARE_PLAN_ACTION_PREFIX}get-care-team-persons`);
