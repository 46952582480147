import { ReduxState } from '../types';
import { ActivityEvent, ActivityLoadingTags, ActivityState, DailySleepLog, PoiChangeEvent, SleepStageEvent } from './activityTypes';
import { isLoadingDeviceVersionHistory } from '../device/deviceSelectors';
import { getUploadMp3Actions } from './activityActions';
import { getCarePlanDateUTCFromBedTimeTs } from '../../utils/time';

const getActivityState = (state: ReduxState): ActivityState => {
  return state.activity;
};

export const selectTracksSelector = (state: ReduxState): Array<any> => {
  const activity: ActivityState = getActivityState(state);
  return activity.tracks;
};

export const selectSupertrackSelector = (state: ReduxState): Array<any> => {
  const activity: ActivityState = getActivityState(state);
  return activity.supertrack;
};

export const selectFallsByDeviceIdSelector = (state: ReduxState, deviceId: string): Array<any> => {
  const activity: ActivityState = getActivityState(state);
  return activity.falls?.byDeviceId?.[deviceId];
};

export const selectHeatMapSelector = (state: ReduxState): Record<string, any> => {
  const activity: ActivityState = getActivityState(state);
  return activity.heatMap;
};

export const selectPoisSelector = (state: ReduxState): Array<any> => {
  const activity: ActivityState = getActivityState(state);
  return activity.pois;
};

export const isUploadMp3LoadingSelector = (state: ReduxState): boolean => {
  const activity: ActivityState = getActivityState(state);
  return !!activity.isLoading[getUploadMp3Actions.start.type];
};

export const isUploadMp3FailedSelector = (state: ReduxState): boolean => {
  const activity: ActivityState = getActivityState(state);
  return !!activity.error[getUploadMp3Actions.fail.type];
};

export const selectVoiceMessageStatusSelector = (state: ReduxState): string => {
  const activity: ActivityState = getActivityState(state);
  return activity.voiceMessageStatus;
};

export const sleepLogsSelector = (state: ReduxState): Nullable<DailySleepLog[]> => {
  const activity: ActivityState = getActivityState(state);
  return activity.sleepLogs;
};

export const getSleepLogsByPersonIdSelector = (state: ReduxState): Nullable<Record<string, DailySleepLog[]>> => {
  const activity: ActivityState = getActivityState(state);
  return activity.sleepLogsByPersonId;
};

export const getSleepLogByCarePlanDateUTCMsSelector = (userId: string, state: ReduxState): Record<string, DailySleepLog> => {
  const sleepLogsByDate = {};
  const sleepLogsByPersonId = getSleepLogsByPersonIdSelector(state);
  if (userId && sleepLogsByPersonId) {
    const sleepLogs = sleepLogsByPersonId[userId];
    sleepLogs?.forEach((sleepLog) => {
      const carePlanDateUTCMs = getCarePlanDateUTCFromBedTimeTs(sleepLog.bedTime, sleepLog.timeZone)?.toMillis();
      if (carePlanDateUTCMs) {
        sleepLogsByDate[carePlanDateUTCMs] = sleepLog;
      }
    });
  }

  return sleepLogsByDate;
};

export const kokoMapSleepStageEventsSelector = (state: ReduxState): Nullable<SleepStageEvent[]> => {
  const activityState = getActivityState(state);
  return activityState.kokoMapSleepStageEvents;
};

export const kokoMapPoiChangeEventSelector = (state: ReduxState): Nullable<PoiChangeEvent[]> => {
  const activityState = getActivityState(state);
  return activityState.kokoMapPoiChangeEvents;
};

export const kokoMapActivityEventSelector = (state: ReduxState): Nullable<ActivityEvent[]> => {
  const activityState = getActivityState(state);
  return activityState.kokoMapActivityEvents;
};

export const isKokoMapLoadingSelector = (state: ReduxState): boolean => {
  const activityState = getActivityState(state);
  return (
    activityState.isLoading[ActivityLoadingTags.GET_ACTIVITY_EVENTS_RANGE] ||
    activityState.isLoading[ActivityLoadingTags.GET_POI_CHANGE_EVENTS] ||
    activityState.isLoading[ActivityLoadingTags.GET_SLEEP_STAGES] ||
    isLoadingDeviceVersionHistory(state)
  );
};
