import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { DualPane } from '../../components/DualPane/DualPane';
import { PageType } from '../../../../redux/analytics/analyticsTypes';
import { connect } from 'react-redux';
import { pageLeftThunk } from '../../../../redux/analytics/analyticsThunks';
import { compose } from 'redux';
import { PaymentConfirmationPane } from '../../components/DualPane/Panes/PaymentConfirmationPane/PaymentConfirmationPane';
import { ThankYouPane } from '../../components/DualPane/Panes/ThankYouPane/ThankYouPane';
import { getQueryParamObject } from '../../../../utils/util';
import { useLocation } from 'react-router-dom';
import { cameFromStripe } from '../../../../utils/navigationUtils';
import { getTransactionAmountThunk } from '../../../../redux/payment/paymentThunks';
import { ReduxState } from '../../../../redux/types';
import { getTransactionAmountSelector } from '../../../../redux/payment/paymentSelector';
import { TransactionAmountPayload } from '../../../../redux/payment/paymentTypes';

interface Props {
  pageLeft: Function;
  getTransactionAmount: ({ csId }) => void;
  transactionAmount?: Nullable<string>;
}

const Payment: React.FC<Props> = ({ pageLeft, getTransactionAmount, transactionAmount }) => {
  const location = useLocation();
  const csId: string = getQueryParamObject(location?.search)?.csId;

  useEffect(() => {
    if (csId) {
      getTransactionAmount({ csId });
    }
  }, [csId]);

  let gaParams;

  const buildPurchaseParams = async () => {
    if (cameFromStripe(location)) {
      gaParams = null;
      await getTransactionAmount({ csId });
      return {
        transaction_id: csId,
        value: transactionAmount,
        currency: 'USD',
        items: [
          {
            item_id: 'FULL_SLEEP_PROMO',
            item_name: '6 Week Full Sleep Program'
          }
        ]
      };
    }
    return {};
  };

  return (
    <DualPane
      pageLeft={pageLeft}
      pageType={PageType.PAYMENT_CONFIRMATION}
      leftPane={<PaymentConfirmationPane transactionAmount={transactionAmount} />}
      rightPane={(loadingState: [boolean, Dispatch<SetStateAction<boolean>>], handleExternalRouting: (url: string) => void) => (
        <ThankYouPane transactionAmount={transactionAmount || ''} loadingState={loadingState} handleExternalRouting={handleExternalRouting} />
      )}
      gaParams={buildPurchaseParams()}
    />
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      transactionAmount: getTransactionAmountSelector(state)
    };
  },
  (dispatch: Function) => ({
    pageLeft: async (pageType: PageType) => {
      dispatch(pageLeftThunk(pageType));
    },
    getTransactionAmount: (transactionAmountPayload: TransactionAmountPayload) => {
      dispatch(getTransactionAmountThunk(transactionAmountPayload));
    }
  })
);

export default compose(connectRedux)(Payment);
