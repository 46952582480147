import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  asButton?: Nullable<boolean>;
  buttonSize?: Nullable<'small' | 'medium'>;
  style?: React.CSSProperties; // external component does not support nullable types, so we can't apply Nullable<> type here ;
}

export const ManualFilledIcon: React.FC<Props> = ({ asButton, buttonSize, style }) => {
  const svgIcon = (): JSX.Element => (
    <SvgIcon width="13" height="13" viewBox="0 0 13 13" fill="none" style={style}>
      <path
        d="M10.2822 0.000181232C11.5015 0.000208585 12.49 0.988703 12.4901 2.20808L12.4902 10.2822C12.4903 11.5017 11.5017 12.4903 10.2822 12.4902L2.20808 12.4901C0.988704 12.49 0.000208417 11.5015 0.000181063 10.2822L5.69344e-10 2.208C-2.73949e-05 0.988544 0.988544 -2.73949e-05 2.208 5.69344e-10L10.2822 0.000181232Z"
        fill="#F4BC2B"
      />
    </SvgIcon>
  );

  if (asButton) {
    return <IconButton size={buttonSize ? buttonSize : 'medium'}>{svgIcon()}</IconButton>;
  }
  return svgIcon();
};
