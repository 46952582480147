import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ReduxState } from '../../redux/types';
import style from './KokoMap.scss';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { getQueryParamObject } from '../../utils/util';
import { getPersonDetailsThunk, getValidSleepSchedulesThunk } from '../../redux/person/personThunks';
import { getKokoMapSleepSchedulesSelector, getPersonByIdSelector, getPersonSettingByIdSelector, getSelectedPersonDetailsSelector } from '../../redux/person/personSelector';
import { getActivityEventsInRangeThunk, getPoiChangeEventsThunk, getSleepStageEventsThunk } from '../../redux/activity/activityThunks';
import { isKokoMapLoadingSelector, kokoMapActivityEventSelector, kokoMapPoiChangeEventSelector, kokoMapSleepStageEventsSelector } from '../../redux/activity/activitySelectors';
import { DateTime } from 'luxon';
import { ActivityEvent, ActivityType, PoiChangeEvent, SleepStage, SleepStageEvent } from '../../redux/activity/activityTypes';
import { DEFAULT_TIMEZONE_ID, isoStringToShortDateTimeString, isoStringToShortDateTimeStringIncludingMs, isTsInRange, msToMin, mSToShortDateTimeString } from '../../utils/time';
import TimeInput from '../../components/TimeInput/TimeInput';
import moment from 'moment-timezone';
import { getPoiEventColor, getSleepStageColor, KokoMapColor } from '../../utils/color';
import { Device, DeviceConnectivity, DeviceConnectivityStatus, DeviceVersion, Location, PoiType, SensorDevice } from '../../redux/device/deviceTypes';
import { Person, PersonDetails, PersonSetting, SleepSchedule } from '../../redux/person/personTypes';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  getDeviceVersionHistoryBydeviceId,
  getKokoMapDeviceConnectivityValuesSelector,
  selectDeviceByIdSelector,
  selectLocationByIdSelector,
  selectSensorDeviceFromSelectedPersonDetailsByRangeOverlap
} from '../../redux/device/deviceSelectors';
import { MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { getConnectivityValuesByRangeThunk, getDeviceVersionHistoryThunk } from '../../redux/device/deviceThunks';

import loadingImage from '../../assets/images/loading.gif';

const DEFAULT_REFRESH_PAGE_MS = 1000 * 60 * 5;

interface Props {
  selectedPersonDetails?: Nullable<PersonDetails>;
  sleepStageEvents?: Nullable<SleepStageEvent[]>;
  poiChangeEvents?: Nullable<PoiChangeEvent[]>;
  activityEvents?: Nullable<ActivityEvent[]>;
  personById: Record<string, Person>;
  deviceById: Record<string, Device>;
  locationById: Record<string, Location>;
  sensorDevice?: Nullable<SensorDevice>;
  personSettingById: Record<string, PersonSetting>;
  sleepSchedules: SleepSchedule[];
  deviceConnectivities: DeviceConnectivity[];
  deviceVersionHistory: DeviceVersion[];
  isKokoMapLoading: boolean;
  getSleepStageEvents: Function;
  getPersonDetails: Function;
  getPoiChangeEvents: Function;
  getValidSleepSchedules: Function;
  getConnectivityValuesByRange: Function;
  getDeviceVersionHistory: Function;
  getActivityEventsInRange: Function;
}

const KokoMap: React.FC<Props> = ({
  selectedPersonDetails,
  getSleepStageEvents,
  getPersonDetails,
  getPoiChangeEvents,
  getActivityEventsInRange,
  sleepStageEvents,
  poiChangeEvents,
  activityEvents,
  personById,
  deviceById,
  locationById,
  sensorDevice,
  personSettingById,
  sleepSchedules,
  getValidSleepSchedules,
  deviceConnectivities,
  getConnectivityValuesByRange,
  getDeviceVersionHistory,
  isKokoMapLoading,
  deviceVersionHistory
}) => {
  const location = useLocation();
  const startTsString: string = getQueryParamObject(location?.search)?.startTs;
  const startTsStringRef = useRef(startTsString);
  startTsStringRef.current = startTsString;
  const endTsString: string = getQueryParamObject(location?.search)?.endTs;
  const endTsStringRef = useRef(endTsString);
  endTsStringRef.current = endTsString;

  const timeZoneId = selectedPersonDetails?.locationId && locationById?.[selectedPersonDetails?.locationId]?.timeZoneId;
  const timezone: string = timeZoneId || DEFAULT_TIMEZONE_ID;
  const startDate = DateTime.fromISO(startTsStringRef.current, { zone: timezone }).set({ minute: 0, second: 0, millisecond: 0 });
  const endDate = DateTime.fromISO(endTsStringRef.current, { zone: timezone }).set({ minute: 0, second: 0, millisecond: 0 });

  const fromDevice: boolean = getQueryParamObject(location?.search)?.fromDevice;
  const [chartDimensions, setChartDimensions] = useState({ width: 0, height: 0 });
  const [startDateMoment, setStartDateMoment] = useState(moment.tz(startTsStringRef.current, timezone));
  const [endDateMoment, setEndDateMoment] = useState(moment.tz(endTsStringRef.current, timezone));
  const [autoRefresh, setAutoRefresh] = React.useState(DateTime.now().setZone(timezone).minus({ hours: 2 }) < endDate);
  const [autoRefreshTime, setAutoRefreshTime] = useState(DEFAULT_REFRESH_PAGE_MS);
  const autoRefreshRef = useRef(autoRefresh);
  autoRefreshRef.current = autoRefresh;
  const autoRefreshTimeRef = useRef(DEFAULT_REFRESH_PAGE_MS);
  const [showSleepData, setShowSleepData] = React.useState(false);
  const [showContextData, setShowContextData] = React.useState(false);

  const params = useParams();
  const targetRef: any = useRef();
  const navigate = useNavigate();
  const refreshTimeoutRef = useRef<Nullable<ReturnType<typeof setTimeout>>>(null);

  const activityTypes = [
    'WIND_DOWN_CANCELED',
    'WIND_DOWN_COMPLETED',
    'SLEEP_SOUND_ENDED',
    'BED_DEPARTURE_REQUEST_COMPLETED',
    'BED_DEPARTURE_REQUEST_IGNORED',
    'SLEEP_LOG_BED_TIME',
    'SLEEP_LOG_TRY_TO_SLEEP_TIME',
    'SLEEP_LOG_SLEEP_TIME',
    'SLEEP_LOG_WAKE_TIME',
    'SLEEP_LOG_OUT_OF_BED_TIME',
    'SLEEP_LOG_NIGHT_WAKE_UP_COUNT',
    'SLEEP_LOG_NIGHT_AWAKE_DURATION'
  ];
  useEffect(() => {
    refreshPage();
  }, [startTsString, endTsString]);

  useEffect(() => {
    if (selectedPersonDetails?.deviceId) {
      getConnectivityValuesByRange(selectedPersonDetails.deviceId, startDate.toMillis(), endDate.toMillis());
      getDeviceVersionHistory(selectedPersonDetails.deviceId, startDate.toMillis());
    }
  }, [selectedPersonDetails]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityStateChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityStateChange);
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, []);

  const handleVisibilityStateChange = () => {
    if (document.visibilityState !== 'visible') {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    } else {
      refreshPage(true);
    }
  };

  useLayoutEffect(() => {
    if (targetRef.current) {
      setChartDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, []);

  const refreshPage = (onlySetTimer?: Nullable<boolean>) => {
    if (!onlySetTimer) {
      loadPageData();
    }
    if (autoRefreshRef.current) {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
        if (autoRefreshTimeRef.current) {
          refreshTimeoutRef.current = setTimeout(refreshPage, autoRefreshTimeRef.current);
        }
      }
    }
  };
  const secondsInTimeFrame = endDate.diff(startDate, 'seconds').seconds;
  const pixelPerSecond = chartDimensions.width / secondsInTimeFrame;
  const hoursInTimeFrame = secondsInTimeFrame / 3600;
  const yAxisLabels: any = [];
  for (let i = 0; i <= hoursInTimeFrame; i++) {
    const labelObject = {
      label: startDate.plus({ hours: i }).hour,
      x: i * 3600 * pixelPerSecond
    };
    yAxisLabels.push(labelObject);
  }

  const loadPageData = () => {
    getPersonDetails(params['userId']);
    getValidSleepSchedules(params['userId'], startDate.toMillis(), endDate.toMillis());
    getSleepStageEvents(params['userId'], startDate.minus({ hours: 10 }).toMillis(), endDate.plus({ hours: 10 }).toMillis());
    getPoiChangeEvents(params['userId'], startDate.minus({ hours: 10 }).toMillis(), endDate.plus({ hours: 10 }).toMillis());
    getActivityEventsInRange(params['userId'], startDate.toMillis(), endDate.toMillis(), activityTypes);
    setStartDateMoment(moment.tz(startTsStringRef.current, timezone));
    setEndDateMoment(moment.tz(endTsStringRef.current, timezone));
  };

  const reloadKokoMap = (startTs: DateTime, endTs: DateTime) => {
    const url = `/user/${selectedPersonDetails?.personId}/kokoMap?startTs=${startTs.toISO()}&endTs=${endTs.toISO()}` + (fromDevice ? '&fromDevice=true' : '');
    autoRefreshRef.current = DateTime.now().setZone(timezone).minus({ hours: 2 }) < endTs;
    setAutoRefresh(autoRefreshRef.current);
    navigate(url, { state: {} });
  };

  const onSubmit = () => {
    const startDateNew = DateTime.fromISO(startDateMoment.tz(timezone).toISOString(true));
    const endDateNew = startDateMoment.isBefore(endDateMoment) ? DateTime.fromISO(endDateMoment.tz(timezone).toISOString(true)) : startDateNew.plus({ hours: 24 });

    reloadKokoMap(startDateNew, endDateNew);
  };

  const onBackButton = () => {
    const url = fromDevice ? `/devices/${selectedPersonDetails?.deviceId}` : `/user/${selectedPersonDetails?.personId}`;
    navigate(url, { state: {} });
  };

  const moveTimestamp = (subtract: boolean, isEndDate: boolean, moveByDay: boolean) => {
    let addHours = moveByDay ? 24 : 1;
    if (subtract) {
      addHours *= -1;
    }
    let newStartDate = startDate;
    let newEndDate = endDate;
    if (moveByDay) {
      newStartDate = newStartDate.plus({ hours: addHours });
      newEndDate = newEndDate.plus({ hours: addHours });
    } else {
      newStartDate = isEndDate ? startDate : startDate.plus({ hours: addHours });
      newEndDate = isEndDate ? endDate.plus({ hours: addHours }) : endDate;
    }
    reloadKokoMap(newStartDate, newEndDate);
  };

  const renderOverlay = (start: DateTime, end: Nullable<DateTime>, event: Nullable<string>, info: Nullable<string>, top: Nullable<number>) => {
    if (top === null) {
      top = 75;
    }
    return (
      <div
        className={style.dataBlockHover}
        style={{
          top: `${top}px`
        }}
      >
        {start && <div>Start: {start.toFormat('LL/dd HH:mm:ss')}</div>}
        {end && <div>End: {end.toFormat('LL/dd HH:mm:ss')}</div>}
        <div>{event}</div>
        <div>{info}</div>
      </div>
    );
  };

  const renderData = (
    index: number,
    backgroundColor: string,
    startTs: DateTime,
    endTs: Nullable<DateTime>,
    overlayText: string,
    startSeconds: number,
    endSeconds: Nullable<number>,
    zIndex: Nullable<number>,
    top: Nullable<number>,
    renderAsCircle: boolean,
    label: Nullable<string>,
    circleLineHeight: Nullable<number>,
    blockHeight: Nullable<number>,
    info: Nullable<string>,
    overlayTop: Nullable<number>
  ): JSX.Element => {
    let left = startSeconds * pixelPerSecond;
    let width = endSeconds ? endSeconds * pixelPerSecond : 0;

    if (left < 0) {
      width += left; // because left is negative, this will fit the width to correct size
      left = 0;
    }
    if (left + width > chartDimensions.width) {
      width = chartDimensions.width - left;
    }
    const dataStyle = {
      left: `${left}px`,
      width: `${width}px`,
      background: `${backgroundColor}`
    };
    if (typeof top === 'number' && renderAsCircle) {
      dataStyle['top'] = `${top}px`;
    }
    if (typeof blockHeight === 'number') {
      dataStyle['height'] = `${blockHeight}px`;
    }

    if (!renderAsCircle) {
      return (
        <div key={index} className={style.dataBlock} style={dataStyle}>
          {renderOverlay(startTs, endTs, overlayText, info, overlayTop)}
        </div>
      );
    } else {
      return (
        <div
          key={index}
          className={style.event}
          style={{
            left: `${left - 20}px`,
            backgroundColor: `${backgroundColor}`,
            top: `${top}px`
          }}
        >
          <span className={style.eventLabel}>{label}</span>
          <div className={style.eventLine} style={{ height: `${circleLineHeight}px` }} />
          {renderOverlay(startTs, endTs, overlayText, info, overlayTop)}
        </div>
      );
    }
  };

  const getGradientFromColorToWhite = (color: string) => {
    return `linear-gradient(90deg, ${color} 0%, rgba(255,255,255,1) 50%)`;
  };
  const getGradientFromColors = (color1: string, color2: string) => {
    return `linear-gradient(90deg, ${color1} 0%, ${color2} 100%)`;
  };

  const sleepStageBlocks: any = [];
  let latestSleepStageEvent;
  let latestSleepStageEventTs;

  const processSleepStageEvents = (sleepStageEntry, index, info, isStitchedEvent) => {
    let color = getSleepStageColor(sleepStageEntry.oldSleepStage);
    if (!info) {
      info = '';
    }
    const newTs = DateTime.fromISO(sleepStageEntry?.newSleepStageTs, { zone: timezone });
    let oldSleepStageTs = sleepStageEntry.oldSleepStageTs;
    let oldSleepStage = sleepStageEntry.oldSleepStage;
    let created = sleepStageEvents && index < sleepStageEvents.length - 1 ? sleepStageEvents[index + 1].created : sleepStageEntry.created;
    if (sleepStageEvents && !sleepStageEntry.oldSleepStageTs && sleepStageEntry.oldSleepStage === SleepStage.UNKNOWN && index < sleepStageEvents.length - 1) {
      const previousSleepEvent = sleepStageEvents[index + 1];
      oldSleepStageTs = previousSleepEvent.newSleepStageTs;
      if (previousSleepEvent.newSleepStage && previousSleepEvent.newSleepStage !== SleepStage.UNKNOWN) {
        oldSleepStage = previousSleepEvent.newSleepStage;
        color = getGradientFromColors(getSleepStageColor(previousSleepEvent.newSleepStage), getSleepStageColor(sleepStageEntry.oldSleepStage));
        info += ` Event stitched as result of device restarting at ${newTs.toFormat('LL/dd HH:mm:ss')}`;
      }
    }

    const oldTs = DateTime.fromISO(oldSleepStageTs, { zone: timezone });
    if (!latestSleepStageEventTs || latestSleepStageEventTs < newTs) {
      latestSleepStageEventTs = newTs;
      latestSleepStageEvent = sleepStageEntry;
    }

    if (isTsInRange(newTs, startDate, endDate) || isTsInRange(oldTs, startDate, endDate)) {
      const startSeconds = oldTs.diff(startDate, 'seconds').seconds;
      const endSeconds = newTs.diff(oldTs, 'seconds').seconds;

      if (isStitchedEvent) {
        color = getGradientFromColorToWhite(color);
      }

      const createdString = sleepStageEntry.created ? `Created: ${DateTime.fromISO(created, { zone: timezone })?.toFormat('LL/dd HH:mm:ss')}` : null;
      if (createdString) {
        info = createdString + info;
      }
      sleepStageBlocks.push(renderData(index, color, oldTs, newTs, `Sleep Stage:${oldSleepStage}`, startSeconds, endSeconds, null, null, false, null, null, null, info, 50));
    }
  };

  sleepStageEvents?.forEach((sleepStageEntry: any, index) => {
    processSleepStageEvents(sleepStageEntry, index, null, false);
  });
  if (latestSleepStageEvent) {
    const latestTs = DateTime.now() < endDate ? DateTime.now() : endDate;
    if (latestSleepStageEventTs < latestTs) {
      const stitchedEvent: SleepStageEvent = {
        oldSleepStage: latestSleepStageEvent.newSleepStage,
        oldSleepStageTs: latestSleepStageEventTs.toISO(),
        newSleepStageTs: latestTs.toISO() || '',
        newSleepStage: SleepStage.UNKNOWN,
        organizationId: personById?.[params!['userId']!]?.organizationId,
        created: latestSleepStageEvent.created
      };
      processSleepStageEvents(stitchedEvent, sleepStageEvents?.length, ' This is a stitched event, duration might not be accurate.', true);
    }
  }

  const poiEventsBlocks: any = [];
  let latestPoiChangeEvent;
  let latestPoiChangeEventTs;
  const processPoiChangeEvents = (poiChangeEvent, index, info, isStitchedEvent) => {
    if (!info) {
      info = '';
    }
    let color = getPoiEventColor(poiChangeEvent.oldPoiType);
    let oldPoiTs = poiChangeEvent.oldPoiTs;
    let oldPoiType = poiChangeEvent.oldPoiType;
    let created = poiChangeEvents && index < poiChangeEvents.length - 1 ? poiChangeEvents[index + 1].created : poiChangeEvent.created;
    const newTs = DateTime.fromISO(poiChangeEvent?.newPoiTs, { zone: timezone });
    if (poiChangeEvents && !poiChangeEvent.oldPoiTs && poiChangeEvent.oldPoiType === PoiType.UNKNOWN && index < poiChangeEvents.length - 1) {
      const previousPoiEvent = poiChangeEvents[index + 1];
      oldPoiTs = previousPoiEvent.newPoiTs;
      if (previousPoiEvent?.newPoiType && previousPoiEvent.newPoiType !== PoiType.UNKNOWN) {
        oldPoiType = previousPoiEvent.oldPoiType;
        color = getGradientFromColors(getPoiEventColor(previousPoiEvent.newPoiType), getPoiEventColor(poiChangeEvent.oldPoiType));
        info += ` Event stitched as result of device restarting at ${newTs.toFormat('LL/dd HH:mm:ss')}`;
      }
    }

    const oldTs = DateTime.fromISO(oldPoiTs, { zone: timezone });
    if (!latestPoiChangeEventTs || latestPoiChangeEventTs < newTs) {
      latestPoiChangeEventTs = newTs;
      latestPoiChangeEvent = poiChangeEvent;
    }
    if (isTsInRange(newTs, startDate, endDate) || isTsInRange(oldTs, startDate, endDate)) {
      const startSeconds = oldTs.diff(startDate, 'seconds').seconds;
      const endSeconds = newTs.diff(oldTs, 'seconds').seconds;
      const createdString = poiChangeEvent.created ? `Created: ${DateTime.fromISO(created, { zone: timezone })?.toFormat('LL/dd HH:mm:ss')}` : null;
      if (createdString) {
        info = createdString + info;
      }

      if (isStitchedEvent) {
        color = getGradientFromColorToWhite(color);
      }

      poiEventsBlocks.push(renderData(index, color, oldTs, newTs, `Bed/Poi Event:${oldPoiType}`, startSeconds, endSeconds, null, null, false, null, null, null, info, 50));
    }
  };

  poiChangeEvents?.forEach((poiChangeEvent: any, index) => {
    processPoiChangeEvents(poiChangeEvent, index, null, false);
  });
  if (latestPoiChangeEvent) {
    const latestTs = DateTime.now() < endDate ? DateTime.now() : endDate;
    if (poiChangeEvents && latestPoiChangeEventTs < latestTs) {
      const stitchedEvent: PoiChangeEvent = {
        oldPoiType: latestPoiChangeEvent.newPoiType,
        oldPoiTs: latestPoiChangeEventTs.toISO(),
        newPoiTs: latestTs.toISO() || '',
        newPoiType: PoiType.UNKNOWN,
        organizationId: personById?.[params!['userId']!]?.organizationId,
        created: latestPoiChangeEvent.created
      };
      processPoiChangeEvents(stitchedEvent, poiChangeEvents.length, ' This is a stitched event, duration might not be accurate.', true);
    }
  }

  const connectionEvents: any = [];
  let previousCreatedDate;
  deviceConnectivities
    ?.sort((a, b) => {
      return a.created && b.created ? new Date(a.created).getTime() + new Date(b.created).getTime() : 0;
    })
    ?.forEach((deviceConnectivity: DeviceConnectivity, index) => {
      if (deviceConnectivity.created) {
        const createdDate = DateTime.fromISO(deviceConnectivity.created, { zone: timezone });
        const newTs = previousCreatedDate ? previousCreatedDate : endDate;
        const oldTs = createdDate;
        previousCreatedDate = createdDate;
        if (isTsInRange(newTs, startDate, endDate) || isTsInRange(oldTs, startDate, endDate)) {
          const startSeconds = oldTs.diff(startDate, 'seconds').seconds;
          const endSeconds = newTs.diff(oldTs, 'seconds').seconds;
          let color = deviceConnectivity.connectivityStatus === DeviceConnectivityStatus.CONNECTED ? KokoMapColor.DEVICE_ONLINE : KokoMapColor.DEVICE_OFFLINE;
          connectionEvents.push(
            renderData(index, color, oldTs, newTs, `DEVICE: ${deviceConnectivity.connectivityStatus}`, startSeconds, endSeconds, null, null, false, null, null, 20, null, null)
          );
        }
      }
    });

  const sleepLogEvents: JSX.Element[] = [];
  const wdBrEvents: JSX.Element[] = [];
  let sleepLogsCreatedDateStrings: string[] = [];
  let wakeDateTime;
  const sleepLogData = {};
  activityEvents?.forEach((activityEvent: ActivityEvent, index) => {
    let color;
    let label;
    let renderAsCircle = true;
    let renderDataPoint = true;
    let zIndex = 2;
    let startTs = activityEvent.startDate ? DateTime.fromISO(activityEvent.startDate, { zone: timezone }) : undefined;
    let endTs: Nullable<DateTime> = activityEvent.endDate ? DateTime.fromISO(activityEvent.endDate, { zone: timezone }) : undefined;
    let top = 70;
    let circleLineHeight = 365;
    let sleepLogCreated;
    let infoString = activityEvent.groupId ? `groupId: ${activityEvent.groupId}` : '';
    const deviceTs = activityEvent.longValue ? DateTime.fromMillis(activityEvent.longValue, { zone: timezone }) : undefined;
    let addTowdBrEvents = false;
    let overlayTop: Nullable<number> = null;
    let overlayCircleTop: Nullable<number> = null;
    if (activityEvent.groupId && !sleepLogData[activityEvent.groupId] && activityEvent.groupId) {
      sleepLogData[activityEvent.groupId] = {};
    }
    switch (activityEvent.activityType) {
      case ActivityType.WIND_DOWN_COMPLETED: {
        color = KokoMapColor.WD_COMPLETED;
        label = 'WD';
        renderAsCircle = false;
        addTowdBrEvents = true;
        infoString = 'AUTOMATIC PLAYBACK: ' + !!activityEvent.boolValue;
        overlayTop = 60;
        break;
      }
      case ActivityType.SLEEP_SOUND_ENDED: {
        color = KokoMapColor.SS_COMPLETED;
        label = 'SS';
        renderAsCircle = false;
        addTowdBrEvents = true;
        infoString = 'AUTOMATIC PLAYBACK: ' + !!activityEvent.boolValue;
        overlayTop = 60;
        break;
      }
      case ActivityType.WIND_DOWN_CANCELED: {
        color = KokoMapColor.WD_CANCELED;
        label = 'WD';
        addTowdBrEvents = true;
        overlayTop = 60;
        infoString = 'AUTOMATIC PLAYBACK: ' + !!activityEvent.boolValue;
        break;
      }
      case ActivityType.BED_DEPARTURE_REQUEST_COMPLETED:
      case ActivityType.BED_DEPARTURE_REQUEST_IGNORED: {
        color = activityEvent.activityType === ActivityType.BED_DEPARTURE_REQUEST_COMPLETED ? KokoMapColor.BR_C : KokoMapColor.BR_F;
        label = 'BR';
        addTowdBrEvents = true;
        overlayTop = 60;
        break;
      }
      case ActivityType.SLEEP_LOG_BED_TIME: {
        if (activityEvent.groupId) {
          sleepLogData[activityEvent.groupId][ActivityType.SLEEP_LOG_BED_TIME] = {
            userValue: activityEvent.endDate,
            deviceValue: typeof activityEvent.longValue === 'number' ? activityEvent.longValue : '',
            deviceValueConfirmed: activityEvent.boolValue
          };
          sleepLogData[activityEvent.groupId]['SLEEP_LOG_CREATED'] = {
            created: activityEvent.created
          };
        }
        color = KokoMapColor.SL_IN_BED;
        label = 'BT';
        startTs = DateTime.fromISO(activityEvent.endDate, { zone: timezone });
        activityEvents.forEach((ae) => {
          if (ae.activityType === ActivityType.SLEEP_LOG_SLEEP_TIME && ae.groupId === activityEvent.groupId) {
            endTs = DateTime.fromISO(ae.endDate, { zone: timezone });
          }
        });
        renderAsCircle = false;
        zIndex = 0;
        circleLineHeight = 240;
        sleepLogCreated = DateTime.fromISO(activityEvent.created, { zone: timezone });
        overlayCircleTop = 35;
        break;
      }
      case ActivityType.SLEEP_LOG_TRY_TO_SLEEP_TIME: {
        if (activityEvent.groupId) {
          sleepLogData[activityEvent.groupId][ActivityType.SLEEP_LOG_TRY_TO_SLEEP_TIME] = {
            userValue: activityEvent.endDate
          };
        }

        startTs = DateTime.fromISO(activityEvent.endDate, { zone: timezone });
        color = KokoMapColor.SL_TTS;
        label = 'TTS';
        circleLineHeight = 265;
        overlayCircleTop = 35;
        break;
      }
      case ActivityType.SLEEP_LOG_SLEEP_TIME: {
        if (activityEvent.groupId) {
          sleepLogData[activityEvent.groupId][ActivityType.SLEEP_LOG_SLEEP_TIME] = {
            userValue: activityEvent.endDate,
            deviceValue: typeof activityEvent.longValue === 'number' ? activityEvent.longValue : '',
            deviceValueConfirmed: activityEvent.boolValue
          };
        }
        color = KokoMapColor.SL_ASLEEP;
        label = 'ST';
        startTs = DateTime.fromISO(activityEvent.endDate, { zone: timezone });
        activityEvents.forEach((ae) => {
          if (ae.activityType === ActivityType.SLEEP_LOG_WAKE_TIME && ae.groupId === activityEvent.groupId) {
            endTs = DateTime.fromISO(ae.endDate, { zone: timezone });
          }
        });
        zIndex = 1;
        renderAsCircle = false;
        top = 25;
        circleLineHeight = 285;
        overlayCircleTop = 55;
        break;
      }
      case ActivityType.SLEEP_LOG_WAKE_TIME: {
        if (activityEvent.groupId) {
          sleepLogData[activityEvent.groupId][ActivityType.SLEEP_LOG_WAKE_TIME] = {
            userValue: activityEvent.endDate,
            deviceValue: typeof activityEvent.longValue === 'number' ? activityEvent.longValue : '',
            deviceValueConfirmed: activityEvent.boolValue
          };
          renderDataPoint = false;
          label = 'WT';
          top = -1;
          circleLineHeight = 311;
          wakeDateTime = endTs;
          overlayCircleTop = 90;
        }
        break;
      }
      case ActivityType.SLEEP_LOG_OUT_OF_BED_TIME: {
        if (activityEvent.groupId) {
          sleepLogData[activityEvent.groupId][ActivityType.SLEEP_LOG_OUT_OF_BED_TIME] = {
            userValue: activityEvent.endDate,
            deviceValue: typeof activityEvent.longValue === 'number' ? activityEvent.longValue : '',
            deviceValueConfirmed: activityEvent.boolValue
          };
          renderAsCircle = false;
          color = KokoMapColor.SL_IN_BED;
          label = 'OBT';
          activityEvents.forEach((ae) => {
            if (ae.activityType === ActivityType.SLEEP_LOG_WAKE_TIME && ae.groupId === activityEvent.groupId) {
              startTs = DateTime.fromISO(ae.endDate, { zone: timezone });
            }
          });
          circleLineHeight = 333;
          top = -22;
          zIndex = 3;
          overlayCircleTop = 105;
        }
        break;
      }
      case ActivityType.SLEEP_LOG_NIGHT_WAKE_UP_COUNT: {
        if (activityEvent.groupId) {
          sleepLogData[activityEvent.groupId][ActivityType.SLEEP_LOG_NIGHT_WAKE_UP_COUNT] = {
            userValue: activityEvent.intValue,
            deviceValue: typeof activityEvent.longValue === 'number' ? activityEvent.longValue : '',
            deviceValueConfirmed: activityEvent.boolValue
          };
        }
        return;
      }
      case ActivityType.SLEEP_LOG_NIGHT_AWAKE_DURATION: {
        if (activityEvent.groupId) {
          sleepLogData[activityEvent.groupId][ActivityType.SLEEP_LOG_NIGHT_AWAKE_DURATION] = {
            userValue: activityEvent.intValue,
            deviceValue: typeof activityEvent.longValue === 'number' ? activityEvent.longValue : '',
            deviceValueConfirmed: activityEvent.boolValue
          };
        }
        return;
      }
      case ActivityType.SLEEP_LOG_AWAKE_20_MIN: {
        if (activityEvent.groupId) {
          sleepLogData[activityEvent.groupId][ActivityType.SLEEP_LOG_AWAKE_20_MIN] = {
            userValue: activityEvent.intValue,
            deviceValue: typeof activityEvent.longValue === 'number' ? activityEvent.longValue : '',
            deviceValueConfirmed: activityEvent.boolValue
          };
        }
        return;
      }
      case ActivityType.SLEEP_LOG_BED_RESET_FOLLOWED: {
        if (activityEvent.groupId) {
          sleepLogData[activityEvent.groupId][ActivityType.SLEEP_LOG_BED_RESET_FOLLOWED] = {
            userValue: activityEvent.intValue,
            deviceValue: typeof activityEvent.longValue === 'number' ? activityEvent.longValue : '',
            deviceValueConfirmed: activityEvent.boolValue
          };
        }
        return;
      }
      case ActivityType.SLEEP_LOG_BED_RESET_HEARD: {
        if (activityEvent.groupId) {
          sleepLogData[activityEvent.groupId][ActivityType.SLEEP_LOG_BED_RESET_HEARD] = {
            userValue: activityEvent.intValue
          };
        }
        return;
      }
    }

    if (startTs && renderDataPoint && (isTsInRange(endTs, startDate, endDate) || isTsInRange(startTs, startDate, endDate))) {
      const startSeconds = startTs?.diff(startDate, 'seconds')?.seconds;
      const endSeconds = endTs?.diff(startTs, 'seconds')?.seconds;
      const newEvent: JSX.Element = renderData(
        index,
        color,
        startTs,
        endTs,
        `Event:${activityEvent.activityType}`,
        startSeconds,
        endSeconds,
        zIndex,
        45,
        renderAsCircle,
        label,
        circleLineHeight,
        null,
        infoString,
        overlayTop
      );
      if (addTowdBrEvents) {
        if (renderAsCircle) {
          wdBrEvents.push(newEvent);
        } else {
          wdBrEvents.unshift(newEvent);
        }
      } else {
        if (renderAsCircle) {
          sleepLogEvents.push(newEvent);
        } else {
          sleepLogEvents.unshift(newEvent);
        }
      }
    }
    if (deviceTs) {
      const startSeconds = deviceTs.diff(startDate, 'seconds').seconds;
      if (startSeconds >= 0 && startSeconds <= secondsInTimeFrame) {
        if (addTowdBrEvents) {
          wdBrEvents.push(
            renderData(
              index + startSeconds,
              KokoMapColor.DEVICE_DETECTED,
              deviceTs,
              null,
              `Event:${activityEvent.activityType}`,
              startSeconds,
              null,
              zIndex,
              top,
              true,
              label,
              circleLineHeight,
              null,
              infoString,
              overlayCircleTop
            )
          );
        } else {
          sleepLogEvents.push(
            renderData(
              index + startSeconds,
              KokoMapColor.DEVICE_DETECTED,
              deviceTs,
              null,
              `Event:${activityEvent.activityType} / DEVICE DETECTED`,
              startSeconds,
              null,
              zIndex,
              top,
              true,
              label,
              circleLineHeight,
              null,
              infoString,
              overlayCircleTop
            )
          );
        }
      }
    }
    if (sleepLogCreated) {
      const startSeconds = sleepLogCreated.diff(startDate, 'seconds').seconds;
      if (startSeconds >= 0 && startSeconds <= secondsInTimeFrame) {
        sleepLogEvents.push(
          renderData(
            index + startSeconds,
            KokoMapColor.SL_CREATED,
            sleepLogCreated,
            null,
            `Event: Sleep Log Created By User`,
            startSeconds,
            null,
            zIndex,
            top,
            true,
            'SC',
            circleLineHeight,
            null,
            infoString,
            35
          )
        );
      }
      sleepLogsCreatedDateStrings.push(sleepLogCreated.toFormat('LL/dd HH:mm:ss') + ', groupId: ' + activityEvent.groupId);
    }
  });

  if (wakeDateTime) {
    let wakeAlarmDateTime;
    sleepSchedules.forEach((sleepSchedule) => {
      if (sleepSchedule.startDate) {
        const startTs = DateTime.fromISO(sleepSchedule.startDate, { zone: timezone });
        const endTs = sleepSchedule.endDate ? DateTime.fromISO(sleepSchedule.endDate, { zone: timezone }) : null;
        if (endTs && wakeDateTime >= startTs && (wakeDateTime < endTs || endTs === null)) {
          if (sleepSchedule.wakeAlarmTime) {
            wakeAlarmDateTime = DateTime.fromISO(sleepSchedule.wakeAlarmTime, { zone: timezone });

            wakeAlarmDateTime = wakeDateTime.set({ hours: wakeAlarmDateTime.hour, minutes: wakeAlarmDateTime.minute, seconds: wakeAlarmDateTime.second });
            const startSeconds = wakeAlarmDateTime.diff(startDate, 'seconds').seconds;
            if (startSeconds >= 0 && startSeconds <= secondsInTimeFrame) {
              sleepLogEvents.push(
                renderData(
                  200 + startSeconds,
                  KokoMapColor.SL_WAKE_ALARM_TIME,
                  wakeAlarmDateTime,
                  null,
                  `Event: Wake alarm time`,
                  startSeconds,
                  null,
                  0,
                  63,
                  true,
                  'AT',
                  250,
                  null,
                  'Approximated from above times',
                  35
                )
              );
            }
          }
        }
      }
    });
  }

  let previousDeviceVersion;
  deviceVersionHistory?.forEach((deviceVersion: DeviceVersion, index) => {
    const versionUpdateDate = DateTime.fromISO(deviceVersion.startDate, { zone: timezone });
    if (isTsInRange(versionUpdateDate, startDate, endDate)) {
      const startSeconds = versionUpdateDate.diff(startDate, 'seconds').seconds;
      wdBrEvents.push(
        renderData(
          index,
          KokoMapColor.DV_UPDATE,
          versionUpdateDate,
          null,
          `New SW version: ${deviceVersion?.softwareVersion}`,
          startSeconds,
          null,
          4,
          -40,
          true,
          'SW',
          450,
          20,
          `Previous SW version: ${previousDeviceVersion?.softwareVersion}`,
          50
        )
      );
    }
    previousDeviceVersion = deviceVersion;
  });

  startDateMoment.tz(timezone);
  endDateMoment.tz(timezone);

  const handleAutorefreshChange = () => {
    autoRefreshRef.current = !autoRefreshRef.current;
    if (!autoRefresh) {
      refreshPage();
    } else if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }
    setAutoRefresh(!autoRefresh);
  };

  const handleAutorefreshTimeChange = (value: number) => {
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }
    autoRefreshTimeRef.current = value;
    refreshTimeoutRef.current = setTimeout(refreshPage, autoRefreshTimeRef.current);
    setAutoRefreshTime(autoRefreshTimeRef.current);
  };

  return (
    <div className={style.container}>
      <div className={style.userInfo}>
        <div className={style.userBackButton} onClick={onBackButton}>
          <ArrowBackIcon fontSize="large" />
        </div>
        <TableContainer component={Paper}>
          <Table size="small" style={{ whiteSpace: 'nowrap', width: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell className={style.surveyHeaderCell}>Auto Refresh</TableCell>
                <TableCell className={style.surveyHeaderCell}>Auto Refresh Time</TableCell>
                <TableCell className={style.surveyHeaderCell}>Device Number</TableCell>
                <TableCell className={style.surveyHeaderCell}>ES ID</TableCell>
                <TableCell className={style.surveyHeaderCell}>Device Timezone</TableCell>
                <TableCell className={style.surveyHeaderCell}>Couple Sleeper</TableCell>
                <TableCell className={style.surveyHeaderCell}>Device Prefills enabled</TableCell>
                <TableCell className={style.surveyHeaderCell}>Sleep Log Created Dates</TableCell>
                <TableCell className={style.surveyHeaderCell}>Current Device SW version</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={style.rowCell}>
                  <input type="checkbox" checked={autoRefresh} onChange={handleAutorefreshChange} />
                </TableCell>
                <TableCell className={style.rowCell}>
                  <Select
                    value={autoRefreshTimeRef.current}
                    label="Auto Refresh Time"
                    onChange={(event) => handleAutorefreshTimeChange(typeof event?.target?.value === 'number' ? event?.target?.value : DEFAULT_REFRESH_PAGE_MS)}
                    MenuProps={{ classes: { paper: style.menuItem } }}
                  >
                    <MenuItem value={300000}>5 Minutes</MenuItem>
                    <MenuItem value={120000}>2 Minutes</MenuItem>
                    <MenuItem value={60000}>1 Minute</MenuItem>
                    <MenuItem value={30000}>30 Seconds</MenuItem>
                    <MenuItem value={10000}>10 Seconds</MenuItem>
                    <MenuItem value={5000}>5 Seconds</MenuItem>
                    <MenuItem value={2000}>2 Seconds</MenuItem>
                  </Select>
                </TableCell>
                <TableCell className={style.rowCell}>{`D${selectedPersonDetails?.deviceId ? deviceById?.[selectedPersonDetails?.deviceId]?.deviceNumber : ''}`}</TableCell>
                <TableCell className={style.rowCell}>{`${selectedPersonDetails?.personId ? personById?.[selectedPersonDetails?.personId]?.esId : ''}`}</TableCell>
                <TableCell className={style.rowCell}>{timezone}</TableCell>
                <TableCell className={style.rowCell}>{`${!!sensorDevice?.isCoupleSleeper}`}</TableCell>
                <TableCell className={style.rowCell}>
                  {isoStringToShortDateTimeString(
                    selectedPersonDetails?.personId ? personSettingById[selectedPersonDetails?.personId]?.sleepLogDeviceTimesEnabledTs : null,
                    timezone
                  )}
                </TableCell>
                <TableCell className={style.rowCell}>{sleepLogsCreatedDateStrings.join('\n')}</TableCell>
                <TableCell className={style.rowCell}>{deviceVersionHistory?.length > 0 ? deviceVersionHistory[deviceVersionHistory.length - 1].softwareVersion : ''}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <TableContainer component={Paper}>
          <Table size="small" style={{ whiteSpace: 'nowrap', width: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell className={style.surveyHeaderCell}>Sleep Schedule: Start Date</TableCell>
                <TableCell className={style.surveyHeaderCell}>End Date</TableCell>
                <TableCell className={style.surveyHeaderCell}>Sleep Time</TableCell>
                <TableCell className={style.surveyHeaderCell}>Wake Time</TableCell>
                <TableCell className={style.surveyHeaderCell}>Wake Alarm time</TableCell>
                <TableCell className={style.surveyHeaderCell}>User created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sleepSchedules.map((sleepSchedule, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className={style.rowCell}>{isoStringToShortDateTimeString(sleepSchedule.startDate, timezone)}</TableCell>
                    <TableCell className={style.rowCell}>{isoStringToShortDateTimeString(sleepSchedule.endDate, timezone)}</TableCell>
                    <TableCell className={style.rowCell}>{sleepSchedule.sleepTime}</TableCell>
                    <TableCell className={style.rowCell}>{sleepSchedule.wakeTime}</TableCell>
                    <TableCell className={style.rowCell}>{sleepSchedule.wakeAlarmTime}</TableCell>
                    <TableCell className={style.rowCell}>{`${!!sleepSchedule?.createdByUser}`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <TableContainer component={Paper}>
          <Table size="small" style={{ whiteSpace: 'nowrap', width: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell className={style.surveyHeaderCell}>Sleep log created</TableCell>
                <TableCell className={style.surveyHeaderCell}>GroupId</TableCell>
                <TableCell className={style.surveyHeaderCell}>Bed Time</TableCell>
                <TableCell className={style.surveyHeaderCell}>Night Wake Up Count</TableCell>
                <TableCell className={style.surveyHeaderCell}>Night Wake up Duration (Minutes)</TableCell>
                <TableCell className={style.surveyHeaderCell}>Night awake &gt; 20 min</TableCell>
                <TableCell className={style.surveyHeaderCell}>Bed Resets followed</TableCell>
                <TableCell className={style.surveyHeaderCell}>Bed resets heard</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(sleepLogData).map((groupId, index) => {
                const sleepLogInfo = sleepLogData[groupId];
                return (
                  <TableRow key={index}>
                    <TableCell className={style.rowCell}>
                      {isoStringToShortDateTimeString(sleepLogInfo['SLEEP_LOG_CREATED']?.created, timezone)}
                      <br />
                    </TableCell>
                    <TableCell className={style.rowCell}>{groupId}</TableCell>
                    <TableCell className={style.rowCell}>
                      User value: {isoStringToShortDateTimeString(sleepLogInfo[ActivityType.SLEEP_LOG_BED_TIME]?.userValue, timezone)}
                      <br />
                      Device value: {mSToShortDateTimeString(sleepLogInfo[ActivityType.SLEEP_LOG_BED_TIME]?.deviceValue, timezone)}
                      <br />
                      User confirmed device value: {sleepLogInfo[ActivityType.SLEEP_LOG_BED_TIME]?.deviceValueConfirmed?.toString()}
                      <br />
                    </TableCell>
                    <TableCell className={style.rowCell}>
                      User value: {sleepLogInfo[ActivityType.SLEEP_LOG_NIGHT_WAKE_UP_COUNT]?.userValue}
                      <br />
                      Device value: {sleepLogInfo[ActivityType.SLEEP_LOG_NIGHT_WAKE_UP_COUNT]?.deviceValue}
                      <br />
                      User confirmed device value: {sleepLogInfo[ActivityType.SLEEP_LOG_NIGHT_WAKE_UP_COUNT]?.deviceValueConfirmed?.toString()}
                      <br />
                    </TableCell>
                    <TableCell className={style.rowCell}>
                      User value: {msToMin(sleepLogInfo[ActivityType.SLEEP_LOG_NIGHT_AWAKE_DURATION]?.userValue)}
                      <br />
                      Device value: {msToMin(sleepLogInfo[ActivityType.SLEEP_LOG_NIGHT_AWAKE_DURATION]?.deviceValue)}
                      <br />
                      User confirmed device value: {sleepLogInfo[ActivityType.SLEEP_LOG_NIGHT_AWAKE_DURATION]?.deviceValueConfirmed?.toString()}
                      <br />
                    </TableCell>
                    <TableCell className={style.rowCell}>
                      User value: {isoStringToShortDateTimeString(sleepLogInfo[ActivityType.SLEEP_LOG_AWAKE_20_MIN]?.userValue, timezone)}
                      <br />
                      Device value: {mSToShortDateTimeString(sleepLogInfo[ActivityType.SLEEP_LOG_AWAKE_20_MIN]?.deviceValue, timezone)}
                      <br />
                      User confirmed device value: {sleepLogInfo[ActivityType.SLEEP_LOG_AWAKE_20_MIN]?.deviceValueConfirmed?.toString()}
                      <br />
                    </TableCell>
                    <TableCell className={style.rowCell}>
                      User value: {isoStringToShortDateTimeString(sleepLogInfo[ActivityType.SLEEP_LOG_BED_RESET_FOLLOWED]?.userValue, timezone)}
                      <br />
                      Device value: {mSToShortDateTimeString(sleepLogInfo[ActivityType.SLEEP_LOG_BED_RESET_FOLLOWED]?.deviceValue, timezone)}
                      <br />
                      User confirmed device value: {sleepLogInfo[ActivityType.SLEEP_LOG_BED_RESET_FOLLOWED]?.deviceValueConfirmed?.toString()}
                      <br />
                    </TableCell>
                    <TableCell className={style.rowCell}>
                      User value: {isoStringToShortDateTimeString(sleepLogInfo[ActivityType.SLEEP_LOG_BED_RESET_HEARD]?.userValue, timezone)}
                      <br />
                      Device value: {mSToShortDateTimeString(sleepLogInfo[ActivityType.SLEEP_LOG_BED_RESET_HEARD]?.deviceValue, timezone)}
                      <br />
                      User confirmed device value: {sleepLogInfo[ActivityType.SLEEP_LOG_BED_RESET_HEARD]?.deviceValueConfirmed?.toString()}
                      <br />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={style.headerRow}>
        <div className={style.timeSelectorHeader}>
          <div className={style.timeSelectorTopRow}>
            <button className={style.minueButton} onClick={() => moveTimestamp(true, false, false)}>
              -
            </button>
            <span className={style.plusMinusLabel}>1 hour</span>
            <button className={style.minueButton} onClick={() => moveTimestamp(false, false, false)}>
              +
            </button>
          </div>
          <div className={style.timeSelectorBottomRow}>
            <span className={style.headerLabel}>Start Timestamp:</span>
            <TimeInput moment={startDateMoment} timeZone={timezone} timeIntervals={60} onChange={setStartDateMoment} customWidth="220px" popperPlacement="bottom-start" />
          </div>
        </div>
        <div className={style.timeSelectorHeader}>
          <div className={style.timeSelectorTopRow}>
            <button className={style.minueButton} onClick={() => moveTimestamp(true, true, false)}>
              -
            </button>
            <span className={style.plusMinusLabel}>1 hour</span>
            <button className={style.minueButton} onClick={() => moveTimestamp(false, true, false)}>
              +
            </button>
          </div>
          <div className={style.timeSelectorBottomRow}>
            <span className={style.headerLabel}>End Timestamp:</span>
            <TimeInput moment={endDateMoment} timeZone={timezone} timeIntervals={60} onChange={setEndDateMoment} customWidth="220px" popperPlacement="bottom-start" />
          </div>
        </div>
        <button className={style.buttonLoad} onClick={onSubmit}>
          Submit
        </button>
        <div className={style.daySelector}>
          <button className={style.dayMinusButton} onClick={() => moveTimestamp(true, true, true)}>
            &lt;
          </button>
          <span className={style.plusMinusLabel}> -/+ 1 Day</span>
          <button className={style.dayPlusButton} onClick={() => moveTimestamp(false, true, true)}>
            &gt;
          </button>
        </div>
      </div>
      <div className={style.chartContainer} ref={targetRef}>
        <div className={style.yAxis}>
          <div className={style.yAxisLabelContainer}>
            {yAxisLabels.map((label, i) => (
              <span key={i} className={style.yAxisLabel} style={{ left: `${label.x}px` }}>
                {label.label}
              </span>
            ))}
          </div>
        </div>
        <div className={style.poiChangeContainer}>{poiEventsBlocks}</div>
        <div className={style.sleepStageContainer}>{sleepStageBlocks}</div>
        <div className={style.eventsContainer}>{sleepLogEvents}</div>
        <div className={style.soundsContainer}>{wdBrEvents}</div>
        <div className={style.connectionContainer}>{connectionEvents}</div>
      </div>
      <div className={style.legend}>
        <div className={style.legendBlock}>
          <div className={style.legendHeader}>1st row: Wind Down / Bed Reset</div>
          <div className={style.legendItemCircle} style={{ backgroundColor: KokoMapColor.WD_CANCELED, color: 'white' }}>
            <span className={style.legendItemText} style={{ backgroundColor: KokoMapColor.WD_CANCELED, color: 'white' }}>
              Wind down canceled
            </span>
          </div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.WD_COMPLETED, color: 'white' }}>
            Wind down completed
          </div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.SS_COMPLETED, color: 'white' }}>
            Sleep Sound Completed
          </div>
          <div className={style.legendItemCircle} style={{ backgroundColor: KokoMapColor.BR_C, color: 'white' }}>
            <span className={style.legendItemText} style={{ backgroundColor: KokoMapColor.BR_C, color: 'white' }}>
              Bed Reset completed
            </span>
          </div>
          <div className={style.legendItemCircle} style={{ backgroundColor: KokoMapColor.BR_F, color: 'white' }}>
            <span className={style.legendItemText} style={{ backgroundColor: KokoMapColor.BR_F, color: 'white' }}>
              Bed Reset ignored
            </span>
          </div>
        </div>
        <div className={style.legendBlock}>
          <div className={style.legendHeader}>2nd row: Sleep Log</div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.SL_IN_BED }}>
            User truth: In Bed
          </div>
          <div className={style.legendItemCircle} style={{ backgroundColor: KokoMapColor.SL_TTS }}>
            <span className={style.legendItemText} style={{ backgroundColor: KokoMapColor.SL_TTS }}>
              User truth: Time to sleep
            </span>
          </div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.SL_ASLEEP }}>
            User truth: Asleep
          </div>
          <div className={style.legendItemCircle} style={{ backgroundColor: KokoMapColor.DEVICE_DETECTED }}>
            <span className={style.legendItemText} style={{ backgroundColor: KokoMapColor.DEVICE_DETECTED }}>
              Device Detected: BD, ST, WT, OBDT
            </span>
          </div>
          <div className={style.legendItemCircle} style={{ backgroundColor: KokoMapColor.SL_CREATED }}>
            <span className={style.legendItemText} style={{ backgroundColor: KokoMapColor.SL_CREATED }}>
              Sleep log created
            </span>
          </div>
        </div>
        <div className={style.legendBlock}>
          <div className={style.legendHeader}>3rd row: Sleep Stages</div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.SS_AWAKE }}>
            AWAKE
          </div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.SS_ASLEEP }}>
            ASLEEP
          </div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.SS_NONE }}>
            NONE
          </div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.SS_UNKNOWN }}>
            UNKNOWN
          </div>
        </div>
        <div className={style.legendBlock}>
          <div className={style.legendHeader}>4th row: Bed Tracker/Poi Events</div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.POI_BED }}>
            IN BED
          </div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.POI_BEDROOOM }}>
            OUTSIDE BED
          </div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.POI_NONE }}>
            NONE
          </div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.POI_UNKNOWN }}>
            UNKNOWN
          </div>
        </div>
        <div className={style.legendBlock}>
          <div className={style.legendHeader}>Top row: Device Connectivity/ SW updates</div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.DEVICE_OFFLINE }}>
            DISCONNECTED
          </div>
          <div className={style.legendItem} style={{ backgroundColor: KokoMapColor.DEVICE_ONLINE }}>
            CONNECTED
          </div>
          <div className={style.legendItemCircle} style={{ backgroundColor: KokoMapColor.DV_UPDATE }}>
            <span className={style.legendItemText} style={{ backgroundColor: KokoMapColor.DV_UPDATE }}>
              SW/FW Update
            </span>
          </div>
        </div>
      </div>
      <div className={style.trackerDataContainer}>
        <div className={style.tracker}>
          <div
            className={style.trackerButton}
            onClick={() => {
              setShowSleepData(!showSleepData);
            }}
          >
            {(showSleepData ? '- Hide' : '+ Show') + ' Sleep Tracker Data'}
          </div>
          {showSleepData && (
            <div className={style.trackerData}>
              <TableContainer component={Paper}>
                <Table size="small" style={{ whiteSpace: 'nowrap', width: 'auto' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={style.surveyHeaderCell}>old_sleep_stage</TableCell>
                      <TableCell className={style.surveyHeaderCell}>old_sleep_stage_local_ts</TableCell>
                      <TableCell className={style.surveyHeaderCell}>new_sleep_stage</TableCell>
                      <TableCell className={style.surveyHeaderCell}>new_sleep_stage_local_ts</TableCell>
                      <TableCell className={style.surveyHeaderCell}>confidence</TableCell>
                      <TableCell className={style.surveyHeaderCell}>created</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sleepStageEvents?.map((sleepStage, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className={style.rowCell}>{sleepStage.oldSleepStage}</TableCell>
                          <TableCell className={style.rowCell}>{isoStringToShortDateTimeStringIncludingMs(sleepStage.oldSleepStageTs, timezone)}</TableCell>
                          <TableCell className={style.rowCell}>{sleepStage.newSleepStage}</TableCell>
                          <TableCell className={style.rowCell}>{isoStringToShortDateTimeStringIncludingMs(sleepStage.newSleepStageTs, timezone)}</TableCell>
                          <TableCell className={style.rowCell}>{sleepStage.confidence}</TableCell>
                          <TableCell className={style.rowCell}>{isoStringToShortDateTimeStringIncludingMs(sleepStage.created, timezone)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
        <div className={style.contextTracker}>
          <div
            className={style.trackerButton}
            onClick={() => {
              setShowContextData(!showContextData);
            }}
          >
            {(showContextData ? '- Hide' : '+ Show') + ' Context(Bed) Tracker Data'}
          </div>
          {showContextData && (
            <div className={style.trackerData}>
              <TableContainer component={Paper}>
                <Table size="small" style={{ whiteSpace: 'nowrap', width: 'auto' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={style.surveyHeaderCell}>old_poi_name</TableCell>
                      <TableCell className={style.surveyHeaderCell}>old_poi_local_ts</TableCell>
                      <TableCell className={style.surveyHeaderCell}>new_poi_name</TableCell>
                      <TableCell className={style.surveyHeaderCell}>new_poi_local_ts</TableCell>
                      <TableCell className={style.surveyHeaderCell}>confidence</TableCell>
                      <TableCell className={style.surveyHeaderCell}>created</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {poiChangeEvents?.map((poiChangeEvent, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className={style.rowCell}>{poiChangeEvent.oldPoiType}</TableCell>
                          <TableCell className={style.rowCell}>{isoStringToShortDateTimeStringIncludingMs(poiChangeEvent.oldPoiTs, timezone)}</TableCell>
                          <TableCell className={style.rowCell}>{poiChangeEvent.newPoiType}</TableCell>
                          <TableCell className={style.rowCell}>{isoStringToShortDateTimeStringIncludingMs(poiChangeEvent.newPoiTs, timezone)}</TableCell>
                          <TableCell className={style.rowCell}>{poiChangeEvent.confidence}</TableCell>
                          <TableCell className={style.rowCell}>{isoStringToShortDateTimeStringIncludingMs(poiChangeEvent.created, timezone)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
      {isKokoMapLoading && (
        <div className={style.loadingScreen}>
          <img className={style.loadingImage} src={loadingImage} alt="Full Sleep" />
        </div>
      )}
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState, ownProps: any) => {
    const startTs = DateTime.fromISO(getQueryParamObject(ownProps.location?.search)?.startTs);

    return {
      selectedPersonDetails: getSelectedPersonDetailsSelector(state),
      sleepStageEvents: kokoMapSleepStageEventsSelector(state),
      poiChangeEvents: kokoMapPoiChangeEventSelector(state),
      activityEvents: kokoMapActivityEventSelector(state),
      personById: getPersonByIdSelector(state),
      deviceById: selectDeviceByIdSelector(state),
      locationById: selectLocationByIdSelector(state),
      sensorDevice: selectSensorDeviceFromSelectedPersonDetailsByRangeOverlap(state, startTs),
      personSettingById: getPersonSettingByIdSelector(state),
      sleepSchedules: getKokoMapSleepSchedulesSelector(state),
      deviceConnectivities: getKokoMapDeviceConnectivityValuesSelector(state),
      deviceVersionHistory: getDeviceVersionHistoryBydeviceId(state, ownProps.selectedPersonDetails?.deviceId),
      isKokoMapLoading: isKokoMapLoadingSelector(state)
    };
  },
  (dispatch: Function) => ({
    getSleepStageEvents: (personId: string, startTs: number, endTs: number) => {
      dispatch(getSleepStageEventsThunk(personId, startTs, endTs));
    },
    getPersonDetails: (personId: string) => {
      dispatch(getPersonDetailsThunk(personId));
    },
    getPoiChangeEvents: (personId: string, startTs: number, endTs: number) => {
      dispatch(getPoiChangeEventsThunk(personId, startTs, endTs));
    },
    getActivityEventsInRange: (personId: string, startTs: number, endTs: number, activityTypes: ActivityType[]) => {
      dispatch(getActivityEventsInRangeThunk(personId, startTs, endTs, activityTypes));
    },
    getValidSleepSchedules: (personId: string, startTs: number, endTs: number) => {
      dispatch(getValidSleepSchedulesThunk(personId, startTs, endTs));
    },
    getConnectivityValuesByRange: (personId: string, startTs: number, endTs: number) => {
      dispatch(getConnectivityValuesByRangeThunk(personId, startTs, endTs));
    },
    getDeviceVersionHistory: (deviceId: string, startTs: number, endTs?: Nullable<number>) => {
      dispatch(getDeviceVersionHistoryThunk(deviceId, startTs, endTs));
    }
  })
);

export default compose(connectRedux)(KokoMap) as React.ComponentType;
