import { ReduxState } from 'redux/types';

export const getclinicalStateSelector = (state: ReduxState) => {
  return state.clinical;
};

export const getCareTeamByIdSelector = (state: ReduxState) => {
  return getclinicalStateSelector(state).careTeamById;
};

export const getCareTeamPersonsSelector = (state: ReduxState) => {
  return getclinicalStateSelector(state).careTeamPersons;
};

export const getPersonCareTeamByIdSelector = (state: ReduxState) => {
  return getclinicalStateSelector(state).personCareTeamById;
};

export const getIsLoadingSelector = (state: ReduxState) => {
  return getclinicalStateSelector(state).isLoading;
};

export const getErrorSelector = (state: ReduxState) => {
  return getclinicalStateSelector(state).error;
};

export const getSuccessSelector = (state: ReduxState) => {
  return getclinicalStateSelector(state).success;
};
