
import UuidParse from 'uuid-parse';

import RandomBytes from 'random-bytes';

// We used _rng method from node-uuid package before which is deprecated. The same randome bytes function from Crypto is now available in node.js itself, but we also need it in the browser.
// crypto-js exposes it as crypto.lib.WordArray.random(16) and is usable on all platforms
// https://github.com/broofa/node-uuid/blob/master/lib/rng.js

const rng = (): Buffer => {
    return RandomBytes.sync(16);
};


const TWO_PWR_32_DBL = 0x100000000; // don't use 1 << 32 as this will result in 1 due to 32 bit precision for shift ops
// generates UUID with both time and space uniquenes.
// see https://github.com/broofa/node-uuid V4 for description of options

export const stringToUuid = (uuidString: string): Buffer => {
    return UuidParse.parse(uuidString, Buffer.alloc(16));
};
export const uuidToString = (uuid: Buffer): string => {
    return UuidParse.unparse(uuid);
};
export const uuid16ToBase64 = (uuid: Buffer): string => {

    const base64 = uuid.toString('base64').substr(0, 22)
        .replace(/\+/g, '-') // Convert '+' to '-'
        .replace(/\//g, '_'); // Convert '/' to '_'
    return base64;
};
// returns a url safe base64 encoding
export const uuid16StringToBase64 = (uuidString: string): string => {
    const uuid = stringToUuid(uuidString);
    return uuid16ToBase64(uuid);
};

// converts url safe base64 encoding to binary uuid
export const base64ToUuid16 = (base64UrlSafeString: string): Buffer => {
    const base64String = base64UrlSafeString
        .replace(/\-/g, '+') // Convert '-' to '+'
        .replace(/\_/g, '/'); // Convert '_' to '/'
    //+ '=='; // Add removed at end '='
    return new Buffer(base64String, 'base64');
};

// converts url safe base64 encoding to uuid string
export const base64ToUuid16String = (base64UrlSafeString: string): string => {
    if (base64UrlSafeString.length < 22) {
        return "";
    }
    const uuid = base64ToUuid16(base64UrlSafeString);
    return uuidToString(uuid);
};

export const timestampAndRandomCombinedUUID = (opt?: any, buffer?: Buffer | null, offset?: number, millisSince1970?: number): Buffer => {
    let buf = buffer;
    let options = opt;
    const i = (buf && offset) || 0;
    const millisDelta = millisSince1970 !== undefined ? millisSince1970 : +new Date();

    // note: javascript internally uses float values
    // when using bitwise operators, it converts the value to 32 bits
    // since millisDelta is a long value, we need to split it into two 32 bit numbers
    // to prevent losing precision

    const lo = (millisDelta % TWO_PWR_32_DBL) | 0;
    const hi = (millisDelta / TWO_PWR_32_DBL) | 0;

    if (typeof options === 'string') {
        buf = options === 'binary' ? Buffer.alloc(16) : null;
        options = {};
    }
    options = options || {};

    // generate 16 random bytes, technically only the last 10 are needed
    const rnds = options.random || (options.rng || rng)();

    // copy lower 6 byte of time into the first 6 byte of UUID
    rnds[5] = lo;
    rnds[4] = lo >> 8;
    rnds[3] = lo >> 16;
    rnds[2] = lo >> 24;
    rnds[1] = hi;
    rnds[0] = hi >> 8;

    // Copy bytes to buffer, if provided
    if (buf) {
        for (let ii = 0; ii < 16; ii++) {
            buf[i + ii] = rnds[ii];
        }
    }
    return buf || rnds;
};

export const timestampAndRandomCombinedUUIDBase64 = (timestamp?: number) => {
    const uuid = timestampAndRandomCombinedUUID({}, Buffer.alloc(16), 0, timestamp);

    return uuid16ToBase64(uuid);
};

export const timestampAndRandomCombinedUUIDString = (timestamp?: number) => {
    const id64 = timestampAndRandomCombinedUUIDBase64(timestamp);
    return UuidParse.unparse(base64ToUuid16(id64));
};
