import { ThunkParams } from '../types';
import { ErrorPayload } from '../common/types';
import {
  AdHocContentRequest,
  DeviceAndProgramContentUpdateRequest,
  DeviceContent,
  DeviceContentPage,
  DeviceContentType,
  FeedItem,
  PersonWorkflow,
  PersonWorkflowComment,
  PersonWorkflowTaskStatus,
  ProgramContent,
  ProgramSubType,
  ProgramType,
  SurveyScoreResponse,
  WorkflowTemplate,
  WorkflowType
} from './contentTypes';
import {
  addDeviceContentActions,
  addDeviceContentPageActions,
  copyDeviceContentDetailsActions,
  getAllFlagContentActions,
  getCreateAdHocContentActions,
  getDeviceContentByTypeActions,
  getDeviceContentDetailsActions,
  getDeviceContentPageActions,
  getFeedItemsActions,
  getFlagContentActions,
  getPersonFlagContentActions,
  getProgramContentByTypeActions,
  getSurveyResponsesByTypeActions,
  removeDeviceContentPageActions,
  saveDeviceAndProgramContentsActions,
  saveFlagContentActions,
  savePersonTaskStatusActions,
  saveWorkflowCommentActions,
  syncContentActions,
  updateDeviceContentActions,
  updateDeviceContentPageActions
} from './contentActions';

function processCreateAdHocContentSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getCreateAdHocContentActions.success());
  };
}
function processCreateAdHocContentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getCreateAdHocContentActions.fail(error));
  };
}
export function getCreateAdHocContentThunk(adHocContentRequest: AdHocContentRequest): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getCreateAdHocContentActions.start());
    return apiClient.post(`/v1/content/adHoc`, {
      auth: true,
      data: adHocContentRequest,
      successAction: processCreateAdHocContentSuccess,
      failAction: processCreateAdHocContentFail
    });
  };
}

function processGetProgramContentByTypeSuccess(payload: ProgramContent[]): Function {
  return function (dispatch: Function): void {
    dispatch(getProgramContentByTypeActions.success(payload));
  };
}
function processGetProgramContentByTypeFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getProgramContentByTypeActions.fail(error));
  };
}
export function getProgramContentByTypeThunk(programType: ProgramType, programSubType?: Nullable<ProgramSubType>): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getProgramContentByTypeActions.start(programType));
    return apiClient.get(`/v1/content/program`, {
      auth: true,
      params: {
        'program-type': programType,
        'program-sub-type': programSubType
      },
      successAction: processGetProgramContentByTypeSuccess,
      failAction: processGetProgramContentByTypeFail
    });
  };
}

function processGetSurveyResponsesByTypeSuccess(payload: SurveyScoreResponse[]): Function {
  return function (dispatch: Function): void {
    dispatch(getSurveyResponsesByTypeActions.success(payload));
  };
}
function processGetSurveyResponsesByTypeFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getSurveyResponsesByTypeActions.fail(error));
  };
}
export function getSurveyResponsesByTypeThunk(personId?: Nullable<string>) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getSurveyResponsesByTypeActions.start());
    return apiClient.get('/v1/survey/sleepNeedScores', {
      auth: true,
      params: {
        'person-id': personId
      },
      successAction: processGetSurveyResponsesByTypeSuccess,
      failAction: processGetSurveyResponsesByTypeFail
    });
  };
}

function processGetFeedItemsSuccess(payload: FeedItem[]): Function {
  return function (dispatch: Function): void {
    dispatch(getFeedItemsActions.success(payload));
  };
}
function processGetFeedItemsFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getFeedItemsActions.fail(error));
  };
}
export function getFeedItemsThunk(personId?: Nullable<string>) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getFeedItemsActions.start());
    return apiClient.get(`/v1/content/historicFeeds`, {
      auth: true,
      params: {
        'person-id': personId
      },
      successAction: processGetFeedItemsSuccess,
      failAction: processGetFeedItemsFail
    });
  };
}

function processDeviceContentSuccess(payload: DeviceContent[]): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceContentByTypeActions.success(payload));
  };
}
function processDeviceContentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceContentByTypeActions.fail(error));
  };
}
export function getDeviceContentThunk(deviceContentTypes?: Nullable<DeviceContentType[]>) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceContentByTypeActions.start(deviceContentTypes));
    return apiClient.get(`/v1/content/deviceContent`, {
      auth: true,
      params: {
        'device-content-types': deviceContentTypes ? JSON.stringify(deviceContentTypes) : undefined
      },
      successAction: processDeviceContentSuccess,
      failAction: processDeviceContentFail
    });
  };
}

function processUpdateDeviceContentSuccess(payload: DeviceContent): Function {
  return function (dispatch: Function): void {
    dispatch(updateDeviceContentActions.success(payload));
  };
}
function processUpdateDeviceContentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(updateDeviceContentActions.fail(error));
  };
}
export function updateDeviceContentThunk(deviceContent: DeviceContent): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(updateDeviceContentActions.start());
    return apiClient.put(`/v1/content/deviceContent`, {
      auth: true,
      data: deviceContent,
      successAction: () => processUpdateDeviceContentSuccess(deviceContent),
      failAction: processUpdateDeviceContentFail
    });
  };
}

function processSaveDeviceAndProgramContentsSuccess(payload: DeviceAndProgramContentUpdateRequest): Function {
  return function (dispatch: Function): void {
    dispatch(saveDeviceAndProgramContentsActions.success(payload));
  };
}
function processSaveDeviceAndProgramContentsFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(saveDeviceAndProgramContentsActions.fail(error));
  };
}
export function saveDeviceAndProgramContentsThunk(deviceAndProgramContentUpdateRequest: DeviceAndProgramContentUpdateRequest): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(saveDeviceAndProgramContentsActions.start());
    return apiClient.post(`/v1/content/deviceAndProgramContents`, {
      auth: true,
      data: deviceAndProgramContentUpdateRequest,
      successAction: () => processSaveDeviceAndProgramContentsSuccess(deviceAndProgramContentUpdateRequest),
      failAction: processSaveDeviceAndProgramContentsFail
    });
  };
}

function processSyncContentSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(syncContentActions.success());
  };
}
function processSyncContentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(syncContentActions.fail(error));
  };
}
export function syncContentThunk(deviceAndProgramContentUpdateRequest: DeviceAndProgramContentUpdateRequest, baseUrl: string, authToken: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(syncContentActions.start());
    return apiClient.put(`/v1/content/copyFromExisting`, {
      auth: authToken,
      baseUrl,
      data: deviceAndProgramContentUpdateRequest,
      successAction: processSyncContentSuccess,
      failAction: processSyncContentFail
    });
  };
}

function processAddDeviceContentSuccess(payload: DeviceContent): Function {
  return function (dispatch: Function): void {
    dispatch(addDeviceContentActions.success(payload));
  };
}
function processAddDeviceContentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(addDeviceContentActions.fail(error));
  };
}
export function addDeviceContentThunk(deviceContent: DeviceContent): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(addDeviceContentActions.start());
    return apiClient.post(`/v1/content/deviceContent`, {
      auth: true,
      data: deviceContent,
      successAction: () => processAddDeviceContentSuccess(deviceContent),
      failAction: processAddDeviceContentFail
    });
  };
}

function processGetDeviceContentPageSuccess(payload: DeviceContentPage): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceContentPageActions.success(payload));
  };
}
function processGetDeviceContentPageFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceContentPageActions.fail(error));
  };
}
export function getDeviceContentPageThunk(deviceContentPageId: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceContentPageActions.start());
    return apiClient.put(`/v1/content/cbtiNative/page`, {
      auth: true,
      params: {
        'device-content-page-id': deviceContentPageId
      },
      successAction: processGetDeviceContentPageSuccess,
      failAction: processGetDeviceContentPageFail
    });
  };
}

function processUpdateDeviceContentPageSuccess(payload: DeviceContentPage): Function {
  return function (dispatch: Function): void {
    dispatch(updateDeviceContentPageActions.success(payload));
  };
}
function processUpdateDeviceContentPageFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(updateDeviceContentPageActions.fail(error));
  };
}
export function updateDeviceContentPageThunk(deviceContentPage: DeviceContentPage): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(updateDeviceContentPageActions.start());
    return apiClient.put(`/v1/content/cbtiNative/page`, {
      auth: true,
      data: deviceContentPage,
      successAction: () => processUpdateDeviceContentPageSuccess(deviceContentPage),
      failAction: processUpdateDeviceContentPageFail
    });
  };
}

function processAddDeviceContentPageSuccess(payload: DeviceContentPage): Function {
  return function (dispatch: Function): void {
    dispatch(addDeviceContentPageActions.success(payload));
  };
}
function processAddDeviceContentPageFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(addDeviceContentPageActions.fail(error));
  };
}
export function addDeviceContentPageThunk(deviceContentPage: DeviceContentPage): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(addDeviceContentPageActions.start());
    return apiClient.post(`/v1/content/cbtiNative/page`, {
      auth: true,
      data: deviceContentPage,
      successAction: () => processAddDeviceContentPageSuccess(deviceContentPage),
      failAction: processAddDeviceContentPageFail
    });
  };
}

function processRemoveDeviceContentPageSuccess(pageId: string): Function {
  return function (dispatch: Function): void {
    dispatch(removeDeviceContentPageActions.success(pageId));
  };
}
function processRemoveDeviceContentPageFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(removeDeviceContentPageActions.fail(error));
  };
}
export function removeDeviceContentPageThunk(pageId: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(removeDeviceContentPageActions.start());
    return apiClient.delete(`/v1/content/page/${pageId}`, {
      auth: true,
      successAction: () => processRemoveDeviceContentPageSuccess(pageId),
      failAction: processRemoveDeviceContentPageFail
    });
  };
}

function processDeviceContentDetailsSuccess(payload: DeviceContent): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceContentDetailsActions.success(payload));
  };
}
function processDeviceContentDetailsFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceContentDetailsActions.fail(error));
  };
}
export function getDeviceContentDetailsThunk(deviceContentId: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceContentDetailsActions.start());
    return apiClient.get(`/v1/content/${deviceContentId}`, {
      auth: true,
      successAction: processDeviceContentDetailsSuccess,
      failAction: processDeviceContentDetailsFail
    });
  };
}

function processCopyDeviceContentDetailsSuccess(payload: string): Function {
  return function (dispatch: Function): void {
    dispatch(copyDeviceContentDetailsActions.success(payload));
  };
}
function processCopyDeviceContentDetailsFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(copyDeviceContentDetailsActions.fail(error));
  };
}
export function copyDeviceContentDetailsThunk(deviceContentId: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(copyDeviceContentDetailsActions.start());
    return apiClient.post(`/v1/content/${deviceContentId}/duplicate`, {
      auth: true,
      successAction: processCopyDeviceContentDetailsSuccess,
      failAction: processCopyDeviceContentDetailsFail
    });
  };
}

function processAllFlagContentSuccess(payload: WorkflowTemplate[]): Function {
  return function (dispatch: Function): void {
    dispatch(getAllFlagContentActions.success(payload));
  };
}
function processAllFlagContentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getAllFlagContentActions.fail(error));
  };
}
export function getAllFlagContentThunk(flagType?: Nullable<WorkflowType>) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getAllFlagContentActions.start());
    return apiClient.get(`/v1/content/allFlags`, {
      auth: true,
      params: {
        'flag-type': flagType
      },
      successAction: processAllFlagContentSuccess,
      failAction: processAllFlagContentFail
    });
  };
}

function processFlagContentSuccess(payload: WorkflowTemplate): Function {
  return function (dispatch: Function): void {
    dispatch(getFlagContentActions.success(payload));
  };
}
function processFlagContentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getFlagContentActions.fail(error));
  };
}
export function getFlagContentThunk(flagId: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getFlagContentActions.start());
    return apiClient.get(`/v1/content/flag/${flagId}`, {
      auth: true,
      successAction: processFlagContentSuccess,
      failAction: processFlagContentFail
    });
  };
}

function processPersonFlagContentSuccess(payload: PersonWorkflow[]): Function {
  return function (dispatch: Function): void {
    dispatch(getPersonFlagContentActions.success(payload));
  };
}
function processPersonFlagContentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getPersonFlagContentActions.fail(error));
  };
}
export function getPersonFlagContentThunk(personId: string) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getPersonFlagContentActions.start());
    return apiClient.get(`/v1/content/flagByPerson/${personId}`, {
      auth: true,
      successAction: processPersonFlagContentSuccess,
      failAction: processPersonFlagContentFail
    });
  };
}

function processSaveFlagContentSuccess(workflowTemplate: WorkflowTemplate, personId?: Nullable<string>): Function {
  return function (dispatch: Function): void {
    dispatch(saveFlagContentActions.success({ workflowTemplate, personId }));
  };
}
function processSaveFlagContentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(saveFlagContentActions.fail(error));
  };
}
export function saveFlagContentThunk(flag: WorkflowTemplate, personId?: Nullable<string>) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(saveFlagContentActions.start());
    return apiClient.post(`/v1/content/flag`, {
      auth: true,
      data: flag,
      successAction: (payload: WorkflowTemplate) => processSaveFlagContentSuccess(payload, personId),
      failAction: processSaveFlagContentFail
    });
  };
}

function processSaveWorkflowCommentSuccess(payload: PersonWorkflowComment): Function {
  return function (dispatch: Function): void {
    dispatch(saveWorkflowCommentActions.success(payload));
  };
}
function processSaveWorkflowCommentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(saveWorkflowCommentActions.fail(error));
  };
}
export function saveWorkflowCommentThunk(personWorkflowComment: PersonWorkflowComment) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(saveWorkflowCommentActions.start());
    return apiClient.post(`/v1/content/workflowComment`, {
      auth: true,
      data: personWorkflowComment,
      successAction: processSaveWorkflowCommentSuccess,
      failAction: processSaveWorkflowCommentFail
    });
  };
}

function processSavePersonTaskStatusSuccess(personWorkflow: PersonWorkflow, personWorkflowTaskStatus: PersonWorkflowTaskStatus): Function {
  return function (dispatch: Function): void {
    dispatch(savePersonTaskStatusActions.success({ personWorkflow, personWorkflowTaskStatus }));
  };
}
function processPersonTaskStatusFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(savePersonTaskStatusActions.fail(error));
  };
}
export function savePersonTaskStatusThunk(personWorkflowTaskStatus: PersonWorkflowTaskStatus) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(savePersonTaskStatusActions.start());
    return apiClient.post(`/v1/content/personTaskStatus`, {
      auth: true,
      data: personWorkflowTaskStatus,
      successAction: (personWorkflow) => processSavePersonTaskStatusSuccess(personWorkflow, personWorkflowTaskStatus),
      failAction: processPersonTaskStatusFail
    });
  };
}
