import React from 'react';
import { PersonWorkflow, PersonWorkflowComment, PersonWorkflowTaskStatus } from '../../../../redux/content/contentTypes';
import { Person } from '../../../../redux/person/personTypes';
import { FlagCards } from '../../../../components/Workflow/FlagCards/FlagCards';

interface Props {
  historicWorkflows?: Nullable<PersonWorkflow[]>;
  authenticatedPersonId?: Nullable<string>;
  personById: Record<string, Person>;
  saveFlagComment: (personWorkflowComment: PersonWorkflowComment) => void;
  saveFlagTaskStatus: (personWorkflowTaskStatus: PersonWorkflowTaskStatus) => void;
}

export const HistoryTab: React.FC<Props> = ({ historicWorkflows, authenticatedPersonId, personById, saveFlagComment, saveFlagTaskStatus }) => (
  <FlagCards
    personWorkflows={historicWorkflows}
    authenticatedPersonId={authenticatedPersonId}
    personById={personById}
    saveFlagComment={saveFlagComment}
    saveFlagTaskStatus={saveFlagTaskStatus}
    includeWorkflowStatuses
  />
);
