import React from 'react';
import { Box, Link } from '@material-ui/core';
import style from './TermsLink.scss';
import { FULLSLEEP_COM_SITE_URL } from '../../../../utils/navigationUtils';

interface Props {
  linkColor?: Nullable<string>;
}

export const TermsLink: React.FC<Props> = ({ linkColor }) => (
  <Box className={style.flexBox} color={linkColor}>
    <Link
      style={{ color: linkColor || 'hsla(0, 0%, 100%, 0.5)' }}
      className={style.linkBox}
      underline="always"
      href={`${FULLSLEEP_COM_SITE_URL}/help/terms-of-service`}
      target="_blank"
      rel="noopener"
    >
      <Box component="span" className={style.linkText}>
        Terms
      </Box>
    </Link>
  </Box>
);
