import MoreVertIcon from '@mui/icons-material/MoreVert';
import { List, ListItem, MenuItem, Popover } from '@mui/material';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIcon from '../../assets/images/drag.png';
import { Text } from '../../components/Typography';
import { ActivityListItem } from '../../redux/carePlan/carePlanTypes';
import { DeviceContent, DeviceContentType } from '../../redux/content/contentTypes';
import { getQuestionIconType } from '../../utils/carePlanUtils';
import { normalizeEnumName } from '../../utils/content';
import style from './DraggableList.scss';
import QuestionIcon from '../../components/QuestionIcon/QuestionIcon';

interface Props {
  activityListItems: ActivityListItem[];
  activityById: Record<string, DeviceContent>;
  setActivitListItems: (items: ActivityListItem[]) => void;
  onEditListItem: (index: number) => void;
}

const DraggableList: React.FC<Props> = ({ activityListItems: activitListItems, activityById, setActivitListItems, onEditListItem }) => {
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<Nullable<HTMLElement>[]>(Array(activitListItems.length).fill(null));
  const [menuOpen, setMenuOpen] = React.useState<boolean[]>(Array(activitListItems.length).fill(false));

  const handleMoreButtonClick = (index: number, event: any) => {
    const moreAnchorElCopy = [...moreAnchorEl];
    moreAnchorElCopy[index] = event.currentTarget;
    setMoreAnchorEl(moreAnchorElCopy);
    const menuOpenCopy = [...menuOpen];
    menuOpenCopy[index] = true;
    setMenuOpen(menuOpenCopy);
  };

  const handleMoreButtonClose = (index: number) => {
    const moreAnchorElCopy = [...moreAnchorEl];
    moreAnchorElCopy[index] = null;
    setMoreAnchorEl(moreAnchorElCopy);
    const menuOpenCopy = [...menuOpen];
    menuOpenCopy[index] = false;
    setMenuOpen(menuOpenCopy);
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(activitListItems);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    // setItems(reorderedItems);
    setActivitListItems(reorderedItems);
  };

  const onRemoveItem = (index) => {
    const itemsCopy = [...activitListItems];
    itemsCopy.splice(index, 1);
    setActivitListItems(itemsCopy);
    const menuOpenCopy = [...menuOpen];
    menuOpenCopy[index] = false;
    setMenuOpen(menuOpenCopy);
  };

  const onEditItem = (index) => {
    const menuOpenCopy = [...menuOpen];
    menuOpenCopy[index] = false;
    setMenuOpen(menuOpenCopy);
    onEditListItem(index);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd} className={style.container}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <List {...provided.droppableProps} ref={provided.innerRef}>
            {activitListItems.map((item, index) => {
              const isMessage = typeof item !== 'string';
              // const id = !isMessage ? item : index;
              const id = isMessage ? item.id : item;
              const deviceContent = !isMessage ? activityById[item] : null;
              const surveyQuestion = !isMessage && deviceContent?.deviceContentPages?.length ? deviceContent.deviceContentPages[0].surveyQuestion : null;
              let text: Nullable<string> = isMessage ? item.message : surveyQuestion?.questionText;
              if (deviceContent?.deviceContentType === DeviceContentType.CARE_ASSESSMENT) {
                text = deviceContent?.title;
              }
              const firstDeviceContentPage = deviceContent?.deviceContentPages?.length ? deviceContent.deviceContentPages[0] : null;
              const categoryText = normalizeEnumName(deviceContent?.deviceContentSubType);
              return (
                <Draggable key={id} draggableId={id} index={index}>
                  {(provided) => (
                    <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={style.listItem} aria-owns={`more-menu-${index}`}>
                      <img src={DragIcon} className={style.dragIcon} />
                      <QuestionIcon type={getQuestionIconType(firstDeviceContentPage, deviceContent?.deviceContentType)} className={style.contentIcon} />
                      <div className={style.contentBox}>
                        <Text variant="md" className={style.contentText}>
                          {text}
                        </Text>
                        <Text variant="sm" className={style.contentCategory}>
                          {categoryText}
                        </Text>
                      </div>
                      <MoreVertIcon className={style.moreIcon} onClick={(event) => handleMoreButtonClick(index, event)} />
                      <Popover
                        anchorEl={moreAnchorEl[index]}
                        open={menuOpen[index]}
                        onClose={() => handleMoreButtonClose(index)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        className={style.popover}
                      >
                        <List className={style.contextMenu}>
                          <MenuItem onClick={() => onEditItem(index)}>
                            <Text variant="md" className={style.menuItem}>
                              Edit
                            </Text>
                          </MenuItem>
                          <MenuItem onClick={() => onRemoveItem(index)}>
                            <Text variant="md" className={style.menuItemRed}>
                              Remove
                            </Text>
                          </MenuItem>
                        </List>
                      </Popover>
                    </ListItem>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
