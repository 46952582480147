import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel } from './TabPanel';
import style from './TabbedPage.scss';
import { Box, Theme, Typography, useTheme } from '@material-ui/core';

const TabLabelWithRedDot = ({ label, showRedDot }) => {
  return (
    <Box display="flex" flexDirection="row">
      <Typography className={style.tabLabel}>{label}</Typography>
      {showRedDot && <Box className={style.redDot} />}
    </Box>
  );
};

export interface TabPanelProps {
  label: string;
  content?: Nullable<React.ReactElement>;
  preRender?: Nullable<boolean>; // render the content before the tab is selected
  showRedDot?: Nullable<boolean>;
}

export const TabbedPage: React.FC<{ tabPanels: TabPanelProps[]; initialSelectedTab: number }> = React.memo(({ tabPanels, initialSelectedTab }) => {
  const [selectedTab, setSelectedTab] = useState<number>(initialSelectedTab);

  const theme: Theme = useTheme();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs value={selectedTab} onChange={handleChange} className={style.tabs} variant="scrollable" scrollButtons="auto">
        {tabPanels.map((tabPanel, index) => {
          return (
            <Tab
              key={index}
              value={index}
              label={<TabLabelWithRedDot label={tabPanel.label} showRedDot={tabPanel.showRedDot} />}
              style={{ backgroundColor: index === selectedTab ? theme.palette.primary.main : 'white', color: index === selectedTab ? 'white' : 'black' }}
            />
          );
        })}
      </Tabs>
      {tabPanels.map((tabPanel, index) => {
        return <TabPanel key={index} props={tabPanel} index={index} selectedIndex={selectedTab} />;
      })}
    </>
  );
});
