import React from 'react';
import style from './BrandingHeader.scss';

interface Props {
  logoImageUrl: Nullable<string>;
}

const BrandingHeader: React.FC<Props> = ({ logoImageUrl }) => {
  return (
    <div className={style.container}>
      <img src={logoImageUrl || ''} alt="Logo" className={style.image} />
    </div>
  );
};

export default BrandingHeader;
