import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  asButton?: Nullable<boolean>;
  buttonSize?: Nullable<'small' | 'medium'>;
  style?: React.CSSProperties; //external component does not support nullable types, so we can't apply Nullable<> type here
}

export const ManualOutlinedIcon: React.FC<Props> = ({ asButton, buttonSize, style }) => {
  const svgIcon = (): JSX.Element => (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" style={style}>
      <path
        d="M11.2822 1.00018C12.5015 1.00021 13.49 1.9887 13.4901 3.20808L13.4902 11.2822C13.4903 12.5017 12.5017 13.4903 11.2822 13.4902L3.20808 13.4901C1.9887 13.49 1.00021 12.5015 1.00018 11.2822L1 3.208C0.999973 1.98854 1.98854 0.999973 3.208 1L11.2822 1.00018Z"
        fill="#FDF7DF"
        stroke="#F4BC2B"
      />
    </SvgIcon>
  );

  if (asButton) {
    return <IconButton size={buttonSize ? buttonSize : 'medium'}>{svgIcon()}</IconButton>;
  }
  return svgIcon();
};
