import { ReduxState } from '../types';
import { PaymentState } from './paymentTypes';
const getPaymentState = (state: ReduxState): PaymentState => {
  return state.payment;
};

export const getTransactionAmountSelector = (state: ReduxState): string => {
  const transactionAmount = getPaymentState(state).transactionAmount;
  return transactionAmount ? `$${transactionAmount.toFixed(2)}` : '';
};
