import React, { Dispatch, SetStateAction, useState } from 'react';
import { textToMoment, tsAsDateTimeStr } from '../../../../utils/time';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import style from './EditableTable.scss';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import TimeInput from '../../../../components/TimeInput/TimeInput';
import { SelectButton } from '../../../../components/SelectButton/SelectButton';

export interface HeaderCellConfig {
  value: string;
  attribute: string;
  type: 'text' | 'number' | 'timestamp' | 'select';
  state?: Nullable<[any, Dispatch<SetStateAction<any>>]>;
  defaultValue?: Nullable<any>;
  selectOptions?: Nullable<string[]>;
}

export interface EditTableData {
  headerConfig: HeaderCellConfig[];
  rowData: Nullable<any[]>;
}

export interface EditProps {
  addRowEnabled?: Nullable<boolean>;
  saveRowToDb: (editRowIndex: number) => Promise<any>;
}

interface Props {
  data: EditTableData;
  timeZone?: Nullable<string>;
  editProps?: Nullable<EditProps>;
}

export const EditableTable: React.FC<Props> = ({ data, timeZone, editProps }) => {
  const [editRowIndex, setEditRowIndex] = useState<Nullable<number>>(-1);
  const [editNewRow, setEditNewRow] = useState<boolean>(false);

  const getDefault = (cellConfig: HeaderCellConfig): any => {
    if (cellConfig.defaultValue) {
      return cellConfig.defaultValue;
    } else if (cellConfig.type === 'text') {
      return '';
    } else if (cellConfig.type === 'number') {
      return 0;
    }
    return undefined;
  };

  const getAttributeValue = (value: any, type: 'text' | 'number' | 'timestamp' | 'select'): any => {
    if (type === 'timestamp') {
      return textToMoment(value, timeZone);
    }
    return value;
  };

  const beginEdit = (row: any, index?: Nullable<number>): void => {
    data?.headerConfig?.forEach((config) => config?.state?.[1](() => getAttributeValue(row[config.attribute], config.type) || getDefault(config)));
    setEditNewRow(() => false);
    setEditRowIndex(() => index);
  };

  const beginAdd = (): void => {
    data?.headerConfig?.forEach((config) => config?.state?.[1](() => getDefault(config)));
    setEditNewRow(() => true);
    setEditRowIndex(() => -1);
  };

  const clearEdit = (): void => {
    setEditRowIndex(() => -1);
    setEditNewRow(() => false);
  };

  const saveRow = async () => {
    if (editRowIndex || editRowIndex === 0) {
      await editProps?.saveRowToDb(editRowIndex);
      setEditNewRow(() => false);
      setEditRowIndex(() => -1);
    }
  };

  const disableDone = (): boolean => {
    for (let i = 0; i < data?.headerConfig?.length; i++) {
      const headerConf: HeaderCellConfig = data?.headerConfig?.[i];
      if (headerConf.type === 'number' && headerConf.state && !headerConf.state?.[0] && headerConf.state?.[0] !== 0) {
        return true;
      }
    }
    return false;
  };

  const createCellInput = (headerCellConfig: HeaderCellConfig, row: any, editCondition: boolean): JSX.Element => (
    <React.Fragment>
      {editCondition && !!headerCellConfig.state ? (
        headerCellConfig.type === 'timestamp' ? (
          <TimeInput
            moment={headerCellConfig.state[0]}
            timeZone={timeZone}
            onChange={headerCellConfig.state[1]}
            datePickerClass={style.datePickerInput}
            customWidth={'180px'}
            popperPlacement="bottom-start"
          />
        ) : headerCellConfig.type === 'select' ? (
          <SelectButton
            options={headerCellConfig.selectOptions || []}
            disableConfirmation={true}
            customPadding={'0px'}
            activeIndex={!headerCellConfig.state[0] || !headerCellConfig.selectOptions ? 0 : headerCellConfig.selectOptions.indexOf(headerCellConfig.state[0])}
            onClick={(selectedIndex) => (headerCellConfig?.state ? headerCellConfig?.state[1](headerCellConfig.selectOptions?.[selectedIndex]) : undefined)}
          />
        ) : (
          <TextField
            type={headerCellConfig.type}
            variant="outlined"
            InputProps={{ className: style.editInput }}
            value={headerCellConfig.state[0]}
            onChange={(e) => (headerCellConfig.state ? headerCellConfig.state[1](e.target.value) : undefined)}
          />
        )
      ) : headerCellConfig.type === 'timestamp' ? (
        tsAsDateTimeStr(row?.[headerCellConfig.attribute], timeZone)
      ) : (
        row?.[headerCellConfig.attribute]
      )}
    </React.Fragment>
  );

  const createEditableCell = (headerCellConfig: HeaderCellConfig, row: any, editCondition: boolean, index: number): JSX.Element => (
    <TableCell key={index} className={clsx(style.rowCell, style[headerCellConfig.type])}>
      {createCellInput(headerCellConfig, row, editCondition)}
    </TableCell>
  );

  const createEditableRow = (editCondition: boolean, index?: Nullable<number>, row?: Nullable<any>): JSX.Element => (
    <TableRow key={index}>
      {!!editProps?.saveRowToDb && (
        <TableCell className={style.rowEditCell}>
          {!editCondition ? (
            <IconButton size="small" onClick={row ? () => beginEdit(row, index) : beginAdd}>
              {row ? <EditIcon /> : <AddIcon />}
            </IconButton>
          ) : (
            <React.Fragment>
              <IconButton size="small" onClick={clearEdit}>
                <ClearIcon />
              </IconButton>
              <IconButton size="small" disabled={disableDone()} onClick={saveRow}>
                <DoneIcon />
              </IconButton>
            </React.Fragment>
          )}
        </TableCell>
      )}
      {data?.headerConfig?.map((headConf, index) => createEditableCell(headConf, row, editCondition, index))}
    </TableRow>
  );

  return (
    <TableContainer className={style.editTable} component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {!!editProps && <TableCell className={style.headerCell} />}
            {data?.headerConfig?.map((head, index) => (
              <TableCell key={index} className={clsx(style.headerCell, style[head.type])}>
                {head.value}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.rowData?.map((row, index) => createEditableRow(editRowIndex === index, index, row))}
          {!!editProps && !!editProps.addRowEnabled && createEditableRow(editNewRow)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
