import { Chip, Theme, emphasize, unstable_createMuiStrictModeTheme, withStyles } from '@material-ui/core';
import { createTheme } from '@mui/material/styles';
import { grey, red } from '@material-ui/core/colors';
import { Color } from './color';

export const defaultPrimaryColor: string = '#007EB5';

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'FTBlank',
      color: Color.DARK_BLUE
    },
    h4: {
      fontSize: '28px',
      fontWeight: '700'
    },
    h5: {
      fontWeight: '700'
    },
    h6: {
      fontSize: '20px',
      fontWeight: '700'
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: '700'
    },
    subtitle2: {
      fontSize: '12px',
      fontWeight: '700'
    },
    body1: {
      fontSize: '14px',
      fontWeight: '400'
    },
    body2: {
      fontSize: '12px',
      fontWeight: '400'
    },
    button: {
      fontSize: '12px',
      fontWeight: '400'
    }
  }
});

export const defaultTheme: Theme = unstable_createMuiStrictModeTheme({
  typography: {
    fontFamily: 'FTBlank'
  },
  palette: {
    primary: {
      main: defaultPrimaryColor
    }
  }
});

export const chipTheme: Theme = unstable_createMuiStrictModeTheme({
  typography: {
    fontFamily: 'FTBlank'
  },
  palette: {
    primary: {
      main: '#E0F1E0'
    },
    secondary: {
      main: red[50]
    }
  }
});

export const freshdeskTheme: Theme = unstable_createMuiStrictModeTheme({
  typography: {
    fontFamily: 'FTBlank'
  },
  palette: {
    primary: {
      main: grey[50]
    }
  }
});

const chipStyles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeightRegular: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300]
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12)
    }
  }
});

// Apply the styles to the Chip component
export const StyledBreadcrumb = withStyles(chipStyles)(Chip) as typeof Chip;
