import { getPushNotificationsActions, getRegisterClientDeviceActions } from './clientDeviceActions';
import { ClientDevice, ClientDevicePushNotificationsResponse, ClientDeviceState, PushNotification } from './clientDeviceTypes';
import { createReducer } from '@reduxjs/toolkit';

export const initialClientDeviceState: ClientDeviceState = {
  clientDeviceId: undefined,
  isRegistered: false,
  timeZoneId: 'UTC',
  pushNotificationsById: {},
  clientDevicesById: {}
};

export default createReducer(initialClientDeviceState, (builder) => {
  builder
    .addCase(getRegisterClientDeviceActions.success, (state, action) => {
      state.clientDeviceId = action.payload.clientDeviceId;
      state.isRegistered = action.payload.isRegistered;
      state.timeZoneId = action.payload.timeZoneId;
    })
    .addCase(getPushNotificationsActions.success, (state, action) => {
      setPushNotificationDetails(state, action.payload);
    });
});

const setPushNotificationDetails = (draftState: ClientDeviceState, clientDevicePushNotificationsResponse: ClientDevicePushNotificationsResponse): void => {
  const pushNotifications: PushNotification[] = clientDevicePushNotificationsResponse?.clientDevicePushNotifications;
  pushNotifications?.forEach((pushNotification) => {
    draftState.pushNotificationsById[pushNotification.id] = pushNotification;
  });
  const clientDevices: ClientDevice[] = clientDevicePushNotificationsResponse?.clientDevices;
  clientDevices?.forEach((clientDevice) => {
    draftState.clientDevicesById[clientDevice.id] = clientDevice;
  });
};
