import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import { Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import { MetricIDs } from '../../../../redux/carePlan/carePlanTypes';
import { patientsTableTitle } from '../../../../utils/carePlanUtils';
import style from './CollapsibleTable.scss';

function CollapsibleRow(props: { row: PatientProfileTableRow; isMdrView?: boolean }) {
  const { row, isMdrView } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow className={style.tableRow} onClick={() => setOpen(!open)}>
        <TableCell className={style.tableCellBody}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={`${style.tableCellBody} ${style.tableCellBodyDate}`} component="th" scope="row">
          {row.dateLabel?.toUpperCase()}
        </TableCell>
        {!isMdrView && (
          <>
            <TableCell className={style.tableCellBody} align="left">
              {row.sleepTime}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.wakeTime}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.walkActivity}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.medicationReminder}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.CHFBreathingLyingDown}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.CHFChestPain}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.CHFLightHeaded}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.CHFShortOfBreath}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.CHFNauseous}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.CHFSwellingFeet}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.CHFSwellingLegs}
            </TableCell>
          </>
        )}
        {isMdrView && (
          <>
            <TableCell className={style.tableCellBody} align="left">
              {row.medicationReminderMdr}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.coachCall}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.stressLevel}
            </TableCell>
            <TableCell className={style.tableCellBody} align="left">
              {row.healthCoachConcerns}
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow className={style.tableRow} sx={{ display: open ? 'table-row' : 'none' }}>
        <TableCell className={style.tableCellBody} colSpan={isMdrView ? 5 : 13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={style.openRowContentContainer}>
              <>
                <Typography variant="subtitle2" gutterBottom>
                  {isMdrView ? patientsTableTitle[MetricIDs.HEALTH_COACH_CONCERNS] : patientsTableTitle[MetricIDs.WEIGHT_EDUCATION_OPEN_QUESTION]}:
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {(isMdrView ? row.healthCoachConcerns : row.CHFWeightEducationOpenQuestion) || 'No Answer'}
                </Typography>
              </>
              {!!row?.history &&
                !!Object.keys(row?.history)?.length &&
                Object.keys(row?.history).map((metricId: string) => {
                  const historyRowData = row?.history[metricId];
                  const key = row?.history[metricId][0]?.id + '1';
                  return (
                    <Box key={key}>
                      <Typography variant="subtitle2" gutterBottom className={style.openRowContentTitle}>
                        {patientsTableTitle[metricId]}
                      </Typography>
                      <Table className={style.openRowTable} size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow className={style.tableRow}>
                            <TableCell className={style.tableCellHead}>Response</TableCell>
                            <TableCell className={style.tableCellHead} align="right">
                              Time
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {historyRowData.map((historyRow) => (
                            <TableRow key={historyRow.id} className={style.tableRow}>
                              <TableCell className={style.tableCellBody} component="th" scope="row">
                                {historyRow.value}
                              </TableCell>
                              <TableCell className={style.tableCellBody} align="right">
                                {historyRow.created}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  );
                })}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export type PatientProfileTableRow = {
  id: string;
  dateLabel: Nullable<string>;
  walkActivity?: Nullable<string>;
  medicationReminder?: Nullable<string>;
  sleepTime?: Nullable<string>;
  wakeTime?: Nullable<string>;
  CHFBreathingLyingDown?: Nullable<string>;
  CHFChestPain?: Nullable<string>;
  CHFLightHeaded?: Nullable<string>;
  CHFShortOfBreath?: Nullable<string>;
  CHFNauseous?: Nullable<string>;
  CHFSwellingFeet?: Nullable<string>;
  CHFSwellingLegs?: Nullable<string>;
  CHFWeightEducationOpenQuestion?: Nullable<string>;
  heartRate?: Nullable<number>;
  medicationReminderMdr?: Nullable<string>;
  coachCall?: Nullable<string>;
  stressLevel?: Nullable<number>;
  healthCoachConcerns?: Nullable<string>;
  history: Record<string, { id: string; value: string; created: string }[]>;
};

interface Props {
  rowsData: PatientProfileTableRow[];
  isMdrView?: boolean;
}

const CollapsibleTable: React.FC<Props> = React.memo(({ rowsData, isMdrView }) => {
  return (
    <TableContainer component={Paper} className={style.tableContainer}>
      <Table aria-label="collapsible table" className={style.table}>
        <TableHead>
          <TableRow className={style.tableRow}>
            <TableCell className={style.tableCellHead} />
            <TableCell className={style.tableCellHead}>DATE</TableCell>
            {!isMdrView && (
              <>
                <TableCell className={style.tableCellHead} align="left">
                  SLEEP TIME
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  WAKE TIME
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.WALK_ACTIVITY]}
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.MEDICATION_REMINDER]}
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.CHF_BREATHING_LYING_DOWN]}
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.CHF_CHEST_PAIN]}
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.CHF_LIGHT_HEADED]}
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.CHF_SHORT_OF_BREATH]}
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.CHF_NAUSEOUS]}
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.CHF_SWELLING_FEET]}
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.CHF_SWELLING_LEGS]}
                </TableCell>
              </>
            )}
            {isMdrView && (
              <>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.MEDICATION_SET_REMINDER_QUESTION]}
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.COACHING_CALL]}
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.STRESS_LEVEL]}
                </TableCell>
                <TableCell className={style.tableCellHead} align="left">
                  {patientsTableTitle[MetricIDs.HEALTH_COACH_CONCERNS]}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData.map((row) => (
            <CollapsibleRow key={row.id} row={row} isMdrView />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default CollapsibleTable;
