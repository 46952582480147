import { createAction } from '@reduxjs/toolkit';
import { createReduxActionCreator } from '../types';
import { SleepSchedule } from './sleepScheduleTypes';

// Action Prefixes
export const SLEEP_SCHEDULE_ACTION_PREFIX = 'sleep-schedule-redux/';

export const getSleepSchedulesForReviewActions = createReduxActionCreator<SleepSchedule[]>(`${SLEEP_SCHEDULE_ACTION_PREFIX}get-sleep-schedules-for-review`);
export const getLatestSleepScheduleActions = createReduxActionCreator<Nullable<SleepSchedule>>(`${SLEEP_SCHEDULE_ACTION_PREFIX}get-latest-sleep-schedule`);

export const putSleepScheduleEditableActions = createReduxActionCreator<SleepSchedule>(`${SLEEP_SCHEDULE_ACTION_PREFIX}put-sleep-schedule-editable`);

export const updateSleepScheduleLunaSummaryActions = createReduxActionCreator<SleepSchedule>(`${SLEEP_SCHEDULE_ACTION_PREFIX}update-sleep-schedule-luna-summary`);

export const postSleepScheduleSendActions = createReduxActionCreator<SleepSchedule>(`${SLEEP_SCHEDULE_ACTION_PREFIX}post-sleep-schedule-send`);

export const createNewSleepScheduleActions = createReduxActionCreator<SleepSchedule>(`${SLEEP_SCHEDULE_ACTION_PREFIX}create-new-sleep-schedule`);

export const getClearResponseStatusSleepScheduleAction = createAction<undefined>(`${SLEEP_SCHEDULE_ACTION_PREFIX}clear-response-status`);
