import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { Snooze } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Moment } from 'moment';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { timestampAndRandomCombinedUUIDString } from '../../../../../shared/src/util/uuid';
import SleepScheduleCreator from '../../../../components/SleepScheduleDetails/SleepScheduleCreator/SleepScheduleCreator';
import { SleepScheduleDetails } from '../../../../components/SleepScheduleDetails/SleepScheduleDetails';
import { DailySleepLog } from '../../../../redux/activity/activityTypes';
import { SurveyScoreResponse } from '../../../../redux/content/contentTypes';
import { Person, SleepCalculationRequest, SleepScheduleCalculation, SleepScheduleCalculationRequest } from '../../../../redux/person/personTypes';
import { SleepSchedule } from '../../../../redux/sleepschedule/sleepScheduleTypes';
import { RouteNames } from '../../../../utils/navigationUtils';
import { UNMAPPED } from '../../../../utils/sleepSchedule';
import { isBefore, msToHr, msToMin, timeAsTimeStr, tsAsNightDateStr, tsAsTimeStr } from '../../../../utils/time';
import { goTo, minMaxValueCheck } from '../../../../utils/util';
import style from './SleepScheduleTab.scss';

export const SleepScheduleTab: React.FC<{
  sleepScheduleById: Record<string, SleepSchedule>;
  historicSleepSchedules?: Nullable<SleepSchedule[]>;
  scheduleLogMapping: Record<string, DailySleepLog[]>;
  scheduleSurveyMapping: Record<string, SurveyScoreResponse[]>;
  displayCreateSleepScheduleState: [boolean, Dispatch<SetStateAction<boolean>>];
  displayCalculationState: [boolean, Dispatch<SetStateAction<boolean>>];
  person?: Nullable<Person>;
  userTimeZone?: Nullable<string>;
  latestSleepLog?: Nullable<DailySleepLog>;
  currentSleepScheduleCalculation?: Nullable<SleepScheduleCalculation>;
  calculateSleepSchedule: (personId: string, date: Moment, request: SleepCalculationRequest) => Promise<any>;
  saveSleepScheduleAndCalculation: (personId: string, sleepCalculation: SleepScheduleCalculationRequest) => Promise<any>;
  clearSleepCalculation: () => void;
  generateSurveyTable: (scheduleSurveys: SurveyScoreResponse[]) => JSX.Element;
  draftScheduleById: Record<string, SleepSchedule>;
}> = ({
  sleepScheduleById,
  historicSleepSchedules,
  scheduleLogMapping,
  scheduleSurveyMapping,
  displayCreateSleepScheduleState,
  displayCalculationState,
  person,
  userTimeZone,
  latestSleepLog,
  currentSleepScheduleCalculation,
  calculateSleepSchedule,
  saveSleepScheduleAndCalculation,
  clearSleepCalculation,
  generateSurveyTable,
  draftScheduleById
}) => {
  const [displayCreateSleepSchedule, setDisplayCreateSleepSchedule] = displayCreateSleepScheduleState;

  const navigate = useNavigate();

  useEffect(() => {
    clearSleepCalculation();
  }, [displayCreateSleepScheduleState[0]]);

  const updateSleepTimes = async (
    newWakeTimeHour: string,
    newWakeTimeMinute: string,
    newSleepTimeHour: string,
    newSleepTimeMinute: string,
    description: string,
    ignoreWarnings: boolean,
    sleepScheduleCalculation: Nullable<SleepScheduleCalculation>
  ): Promise<any> => {
    const newWakeTime = `${newWakeTimeHour}:${newWakeTimeMinute}:00`;
    const newSleepTime = `${newSleepTimeHour}:${newSleepTimeMinute}:00`;
    const newSleepCalculation: SleepScheduleCalculation = {
      ...sleepScheduleCalculation,
      id: timestampAndRandomCombinedUUIDString()
    };
    if (person?.id) {
      const newSleepSchedule: SleepSchedule = {
        id: timestampAndRandomCombinedUUIDString(),
        personId: person.id,
        organizationId: person.organizationId,
        sleepTime: newSleepTime,
        wakeTime: newWakeTime,
        description: description || undefined,
        createdByUser: false,
        sleepScheduleCalculationId: newSleepCalculation.id
      };
      const sleepScheduleCalculationRequest: SleepScheduleCalculationRequest = {
        sleepScheduleCalculation: newSleepCalculation,
        sleepSchedule: newSleepSchedule,
        ignoreWarnings
      };
      await saveSleepScheduleAndCalculation(person.id, sleepScheduleCalculationRequest);
      setDisplayCreateSleepSchedule(!displayCreateSleepSchedule);
    }
  };

  const setScheduleTimeSegments = async (
    sleepTime: Moment,
    wakeTime: Moment,
    description: string,
    ignoreWarnings: boolean,
    sleepScheduleCalculation: Nullable<SleepScheduleCalculation>
  ): Promise<any> => {
    const sleepTimeHrs: string = minMaxValueCheck('' + sleepTime.hour(), 0, 23);
    const sleepTimeMins: string = minMaxValueCheck('' + sleepTime.minute(), 0, 59);
    const wakeTimeHrs: string = minMaxValueCheck('' + wakeTime.hour(), 0, 23);
    const wakeTimeMins: string = minMaxValueCheck('' + wakeTime.minute(), 0, 59);
    await updateSleepTimes(wakeTimeHrs, wakeTimeMins, sleepTimeHrs, sleepTimeMins, description, ignoreWarnings, sleepScheduleCalculation);
  };

  const generateLogTable = (scheduleLogs?: Nullable<DailySleepLog[]>): JSX.Element => (
    <TableContainer component={Paper}>
      <Table size="small" style={{ whiteSpace: 'nowrap' }}>
        <TableHead>
          <TableRow>
            <TableCell className={style.headerCell}>NIGHT DATE</TableCell>
            <TableCell className={style.headerCell}>BED TIME</TableCell>
            <TableCell className={style.headerCell}>TRY TO SLEEP TIME</TableCell>
            <TableCell className={style.headerCell}>LATENCY TO SLEEP</TableCell>
            <TableCell className={style.headerCell}>SLEEP TIME</TableCell>
            <TableCell className={style.headerCell}>WAKE TIME</TableCell>
            <TableCell className={style.headerCell}>OUT-OF-BED TIME</TableCell>
            <TableCell className={style.headerCell}>TIME IN BED</TableCell>
            <TableCell className={style.headerCell}>TOTAL SLEEP TIME</TableCell>
            <TableCell className={style.headerCell}>SLEEP EFFICIENCY</TableCell>
            <TableCell className={style.headerCell}>NIGHT AWAKE DURATION</TableCell>
            <TableCell className={style.headerCell}>NIGHT WAKEUP COUNT</TableCell>
            <TableCell className={style.headerCell}>AWAKE 20 MIN</TableCell>
            <TableCell className={style.headerCell}>BED RESET FOLLOWED</TableCell>
            <TableCell className={style.headerCell}>BED RESET HEARD</TableCell>
            <TableCell className={style.headerCell}>SLEEP QUALITY</TableCell>
            <TableCell className={style.headerCell}>TAGS</TableCell>
            <TableCell className={style.headerCell}>COMMENTS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scheduleLogs?.map((row, index) => {
            const tz: string = row.timeZone;
            return (
              <TableRow key={index}>
                <TableCell className={style.rowCell}>{tsAsNightDateStr(row.bedTime, tz)}</TableCell>
                <TableCell className={style.rowCell}>{tsAsTimeStr(row.bedTime, tz)}</TableCell>
                <TableCell className={style.rowCell}>{tsAsTimeStr(row.tryToSleepTime, tz) || 'N/A'}</TableCell>
                <TableCell className={style.rowCell}>{row.latencyToSleepMs ? msToMin(row.latencyToSleepMs) : 'N/A'}</TableCell>
                <TableCell className={style.rowCell}>{tsAsTimeStr(row.sleepTime, tz)}</TableCell>
                <TableCell className={style.rowCell}>{tsAsTimeStr(row.wakeTime, tz)}</TableCell>
                <TableCell className={style.rowCell}>{tsAsTimeStr(row.outOfBedTime, tz)}</TableCell>
                <TableCell className={style.rowCell}>{msToHr(row.inBedMs)}</TableCell>
                <TableCell className={style.rowCell}>{msToHr(row.totalSleepMs)}</TableCell>
                <TableCell className={style.rowCell}>{row.sleepEfficiency?.toFixed(2)}</TableCell>
                <TableCell className={style.rowCell}>{msToMin(row.nightAwakeDuration)}</TableCell>
                <TableCell className={style.rowCell}>{row.nightWakeupCount}</TableCell>
                <TableCell className={style.rowCell}>{row.awake20Min}</TableCell>
                <TableCell className={style.rowCell}>{row.bedResetFollowed}</TableCell>
                <TableCell className={style.rowCell}>{row.bedResetHeard}</TableCell>
                <TableCell className={style.rowCell}>{row.sleepQuality}</TableCell>
                <TableCell className={style.rowCell}>{row.tags?.join(', ')}</TableCell>
                <TableCell className={style.rowCell}>{row.comments}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const generateSleepCalculationTableRow = (sleepCalculation: Nullable<SleepScheduleCalculation>): JSX.Element => {
    if (!sleepCalculation) {
      return <React.Fragment />;
    }
    const avgSleepEfficiencPercent: string = +(sleepCalculation?.avgSleepEfficiency || 0).toFixed(2) * 100 + '%';
    return (
      <TableContainer component={Paper}>
        <Table size="small" style={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              <TableCell className={style.headerCell}>TOTAL SLEEP TIME</TableCell>
              <TableCell className={style.headerCell}>TIME IN BED</TableCell>
              <TableCell className={style.headerCell}>NWAK</TableCell>
              <TableCell className={style.headerCell}>SLEEP EFFICIENCY</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={style.rowCell}>{msToHr(sleepCalculation?.avgTotalSleepMs)} Hrs</TableCell>
              <TableCell className={style.rowCell}>{msToHr(sleepCalculation?.avgInBedMs)} Hrs</TableCell>
              <TableCell className={style.rowCell}>{sleepCalculation?.avgWakeUpCount?.toFixed(1)}</TableCell>
              <TableCell className={style.rowCell}>{avgSleepEfficiencPercent}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const generateMergedSleepScheduleCards = (historicSleepSchedules?: Nullable<SleepSchedule[]>): JSX.Element => {
    const elements: JSX.Element[] = [];
    if (historicSleepSchedules?.length) {
      let adjustedSleepTime: Nullable<string> = undefined;
      let adjustedWakeTime: Nullable<string> = undefined;
      let adjustedLogs: DailySleepLog[] = [];
      let adjustedSurveys: SurveyScoreResponse[] = [];
      for (let i = 0; i < historicSleepSchedules.length; i++) {
        const schedule: SleepSchedule = historicSleepSchedules[i];
        if (schedule.id && schedule.enabled) {
          const scheduleLogs: DailySleepLog[] = scheduleLogMapping?.[schedule.id];
          if (scheduleLogs && scheduleLogs?.length) {
            adjustedLogs.push(...scheduleLogs);
          }
          const scheduleSurveys: SurveyScoreResponse[] = scheduleSurveyMapping?.[schedule.id];
          if (scheduleSurveys && scheduleSurveys?.length) {
            adjustedSurveys.push(...scheduleSurveys);
          }
          const sleepTime: string = timeAsTimeStr(schedule?.sleepTime, userTimeZone);
          const wakeTime: string = timeAsTimeStr(schedule?.wakeTime, userTimeZone);
          if (schedule.createdByUser) {
            if (!adjustedSleepTime) {
              adjustedSleepTime = sleepTime;
              adjustedWakeTime = wakeTime;
            }
          } else if (isBefore(schedule?.enabledTs)) {
            const sleepLogs = generateLogTable(adjustedLogs);
            const surveys = generateSurveyTable(adjustedSurveys);
            elements.push(
              <SleepScheduleDetails
                key={schedule.id}
                newSleepScheduleId={schedule.id}
                sleepScheduleById={sleepScheduleById}
                person={person}
                viewMode
                sleepLogs={sleepLogs}
                surveys={surveys}
              />
            );
            adjustedSleepTime = undefined;
            adjustedWakeTime = undefined;
            adjustedLogs = [];
            adjustedSurveys = [];
          }
        }
      }
    }
    return <React.Fragment>{elements}</React.Fragment>;
  };

  const unreviewedSleepSchedule: Nullable<SleepSchedule> = historicSleepSchedules?.find((schedule) => schedule.enabled === false);

  return (
    <React.Fragment>
      <Grid container>
        <Grid className={style.sleepSchedule} item xs={12} hidden={displayCreateSleepScheduleState[0]}>
          {!unreviewedSleepSchedule && (
            <Box className={style.addScheduleRow}>
              <CardHeader
                className={style.assignFlagHeader}
                avatar={
                  <IconButton color="primary" className={style.assignFlagButton} onClick={() => displayCreateSleepScheduleState[1](!displayCreateSleepScheduleState[0])}>
                    <AddCircleIcon fontSize="inherit" />
                  </IconButton>
                }
                title={'Create new sleep schedule'}
              />
              {/* <Typography className={style.newScheduleLabel}>Create new sleep schedule</Typography> */}
            </Box>
          )}
          {unreviewedSleepSchedule && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Snooze />}
              onClick={(e) => goTo(`${RouteNames.SLEEP_SCHEDULE_REVIEW}/${unreviewedSleepSchedule.id}`, navigate, {}).call(this)}
            >
              Review New Sleep Schedule
            </Button>
          )}
        </Grid>
        <Grid className={style.sleepSchedule} item xs={12} hidden={!displayCreateSleepScheduleState[0]}>
          <SleepScheduleCreator
            person={person}
            draftScheduleById={draftScheduleById}
            onHideCreator={() => displayCreateSleepScheduleState[1](!displayCreateSleepScheduleState[0])}
          />
        </Grid>
      </Grid>
      {generateMergedSleepScheduleCards(historicSleepSchedules)}
      {!!scheduleLogMapping?.[UNMAPPED]?.length && (
        <Card variant="outlined" className={style.card}>
          <CardContent>
            <div>
              <Typography>
                <b>No Sleep Schedule Assigned</b>
                <br />
                <b>Unassigned Surveys:</b> {scheduleSurveyMapping?.[UNMAPPED].length ? 'TRUE' : 'FALSE'}
                <br />
              </Typography>
              {generateSurveyTable(scheduleSurveyMapping?.[UNMAPPED])}
              <Typography>
                <b>Sleep Logs: (total: {scheduleLogMapping[UNMAPPED].length})</b>
              </Typography>
            </div>
            {generateLogTable(scheduleLogMapping[UNMAPPED])}
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
};
