import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  asButton?: Nullable<boolean>;
  buttonSize?: Nullable<'small' | 'medium'>;
  style?: React.CSSProperties; //external component does not support nullable types, so we can't apply Nullable<> type here
}

export const MilestoneOutlinedIcon: React.FC<Props> = ({ asButton, buttonSize, style }) => {
  const svgIcon = (): JSX.Element => (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" style={style}>
      <path
        d="M6.45542 1.41421C7.23647 0.633164 8.50279 0.633165 9.28384 1.41421L14.4554 6.58579C15.2365 7.36684 15.2365 8.63317 14.4554 9.41421L9.28384 14.5858C8.50279 15.3668 7.23646 15.3668 6.45542 14.5858L1.28384 9.41421C0.502793 8.63316 0.502794 7.36684 1.28384 6.58579L6.45542 1.41421Z"
        fill="#F8E4FF"
        stroke="#6361E1"
      />
    </SvgIcon>
  );

  if (asButton) {
    return <IconButton size={buttonSize ? buttonSize : 'medium'}>{svgIcon()}</IconButton>;
  }
  return svgIcon();
};
