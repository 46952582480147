import React from "react";
import classNames from "classnames";
import style from './TextField.scss';
import { PolymorphicElement } from "types/PolymorphicElement";
import { Text } from '../Typography';
import alertCircleIcon from '../../assets/images/alert-circle.png';

export type TextVariant = "xl" | "lg" | "md" | "sm" | "xs"
 "xs";
export type TextWeight = 400 | 500 | 600 | 700;

export type TextProps<T extends "input"> = PolymorphicElement<T> & {
  className?: string;
  label?: React.ReactNode;
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
  error?: React.ReactNode;
  helperText?: React.ReactNode;
}

export const TextField = <T extends "input">(props: TextProps<T>) => {
  const {
    className,
    label,
    leading,
    trailing,
    error,
    helperText,
    onClick,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = React.useState(false);

  const handleOnBlue = (event) => {
    setIsFocused(false);
    props?.onBlur?.(event);
  }

  const handleOnFocus = (event) => {
    setIsFocused(true);
    props?.onFocus?.(event);
  }

  return (
    <div className={classNames(style.container, className)} onClick={onClick}>
      {label && <Text variant="sm" className={style.label}>{label}</Text>}
      <div className={classNames(
        style.inputContainer,
        props.disabled && style.disabled,
        error && style.error,
        isFocused && style.isFocused
      )}>
        {leading && (
          <span className={style.leading}>
            {leading}
          </span>
        )}

        <input {...rest} onBlur={handleOnBlue} onFocus={handleOnFocus} className={style.input} />

        {trailing && !error && <span className={style.trailing}>{trailing}</span>}
        {error && <img src={alertCircleIcon} className={style.alertCircleIcon} />}
      </div>

      {error && <Text variant="sm" className={style.error}>{error}</Text>}
      {!error && helperText && <Text variant="sm" className={style.helperText}>{helperText}</Text>}
    </div>
  )
}
