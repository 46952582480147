import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Slide, Typography } from '@material-ui/core';
import style from './ForgotPasswordPane.scss';
import { isEmailValid, isEmpty } from '../../../../../../utils/util';
import { AccountInput } from '../../../AccountInput/AccountInput';
import { AccountButton } from '../../../AccountButton/AccountButton';
import { AccountMobileHeader } from '../../../AccountMobileHeader/AccountMobileHeader';

interface Props {
  sendEmail: Function;
  handleError: (err: any) => void;
  loadingState: [boolean, Dispatch<SetStateAction<boolean>>];
  handleExternalRouting?: Nullable<(url?: Nullable<string>) => void>;
}

export const ForgotPasswordPane: React.FC<Props> = ({ sendEmail, handleError, loadingState, handleExternalRouting }) => {
  const emailState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');
  const [changed, setChanged] = useState<boolean>(false);

  useEffect(() => {
    loadingState[1](false);
  }, []);

  const submitEmail = () => {
    const email: string = emailState[0];
    loadingState[1](true);
    sendEmail({ email })
      .then(() => {
        if (handleExternalRouting) {
          handleExternalRouting();
        }
        setChanged(true);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const emailValidationMessage = (): string => (!isEmpty(emailState[0]) && !isEmailValid(emailState[0]) ? 'Please provide a valid email' : '');

  return (
    <React.Fragment>
      <Box className={style.inputBox}>
        <AccountMobileHeader />
        <Slide direction="up" in={changed} mountOnEnter unmountOnExit>
          <Box>
            <Typography className={style.instructionHeader}>{`An email with instructions to reset your password has been sent to ${emailState[0]}`}</Typography>
            <Typography hidden={!changed} className={style.closeInstruction}>
              (You can close this tab)
            </Typography>
          </Box>
        </Slide>
        {!changed && (
          <React.Fragment>
            <Box>
              <Typography className={style.mainHeader}>Forgot password</Typography>
              <Typography className={style.instructionHeader}>Please enter your email.</Typography>
            </Box>
            <AccountInput inputState={emailState} placeholder="Email" includeValidation={true} validationMessage={emailValidationMessage()} cssClass={style.marginTop} />
            <AccountButton label="RESET PASSWORD" onClick={submitEmail} loading={loadingState[0]} disabled={!isEmailValid(emailState[0])} cssClass={style.marginTop} />
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
};
