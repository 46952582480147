import { createAction } from '@reduxjs/toolkit';
import { createReduxActionCreator } from '../types';
import { ActivityEvent, DailySleepLog, PoiChangeEvent, SleepStageEvent } from './activityTypes';

export const ACTIVITY_ACTION_PREFIX = 'activity-redux/';

export const getTracksActions = createReduxActionCreator<any>(`${ACTIVITY_ACTION_PREFIX}tracks`);

export const getSupertrackActions = createReduxActionCreator<any>(`${ACTIVITY_ACTION_PREFIX}supertrack`);

export const getHeatMapActions = createReduxActionCreator<any>(`${ACTIVITY_ACTION_PREFIX}heatmap`);

export const getFallsActions = createReduxActionCreator<any>(`${ACTIVITY_ACTION_PREFIX}falls`);

export const getPoisActions = createReduxActionCreator<any>(`${ACTIVITY_ACTION_PREFIX}pois`);

export const savePoiActions = createReduxActionCreator<any>(`${ACTIVITY_ACTION_PREFIX}save-poi`);

export const getUploadMp3Actions = createReduxActionCreator<any>(`${ACTIVITY_ACTION_PREFIX}upload-mp3`);

export const getVoiceMessageStatusActions = createReduxActionCreator<string>(`${ACTIVITY_ACTION_PREFIX}get-voice-message-status`);

export const getSleepLogsActions = createReduxActionCreator<{ personId: string; payload: DailySleepLog[] }>(`${ACTIVITY_ACTION_PREFIX}get-sleep-logs`);

export const getSleepStageEventsActions = createReduxActionCreator<SleepStageEvent[]>(`${ACTIVITY_ACTION_PREFIX}get-sleep-stages`);

export const getPoiChangeEventsActions = createReduxActionCreator<PoiChangeEvent[]>(`${ACTIVITY_ACTION_PREFIX}get-poi-change-events`);

export const getActivityEventsInRangeActions = createReduxActionCreator<ActivityEvent[]>(`${ACTIVITY_ACTION_PREFIX}get-activity-events-range`);

export const setPoisAction = createAction<Array<any>>(`${ACTIVITY_ACTION_PREFIX}set-pois`);
