import Config from '../config/config';
export enum EnvUrl { // FIXME: load from config
  LOCAL = 'http://localhost:8080/',
  STAGING = 'https://api.staging.meetkoko.com/',
  PROD = 'https://api.meetkoko.com/'
}
export enum StripeCheckoutUrl {
  LOCAL = 'https://checkout.fullsleep.com/b/test_fZeg0f7t69gqdcAcMO',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  STAGING = 'https://checkout.fullsleep.com/b/test_fZeg0f7t69gqdcAcMO',
  PROD = 'https://buy.stripe.com/28ofZpdhm4hB4veeVc'
}

export enum EnvType {
  UNKNOWN = 'UNKNOWN',
  PROVISION = 'PROVISION',
  LOCAL = 'LOCAL',
  STAGING = 'STAGING',
  PROD = 'PROD'
}

export const getEnvUrlFromEnvType = (envType: EnvType) => {
  switch (envType) {
    case EnvType.LOCAL:
      return EnvUrl.LOCAL;
    case EnvType.STAGING:
      return EnvUrl.STAGING;
    case EnvType.PROD:
      return EnvUrl.PROD;
    default:
      return EnvUrl.PROD;
  }
};

export const getStripeCheckoutUrl = () => {
  const envType = getCurrentAppEnvironment();
  switch (envType) {
    case EnvType.LOCAL:
      return StripeCheckoutUrl.LOCAL;
    case EnvType.STAGING:
      return StripeCheckoutUrl.STAGING;
    case EnvType.PROD:
      return StripeCheckoutUrl.PROD;
    default:
      return EnvUrl.PROD;
  }
};

export const getCurrentAppEnvironment = (): EnvType => {
  const envTypeString = Config.getDeploymentEnv() === 'production' ? 'PROD' : Config.getDeploymentEnv().toUpperCase();
  return EnvType[envTypeString];
};

export const isProductionEnvironment = (): boolean => getCurrentAppEnvironment() === EnvType.PROD;
export const isStagingEnvironment = (): boolean => getCurrentAppEnvironment() === EnvType.STAGING;
