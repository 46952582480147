import { PushNotificationType } from '../person/personTypes';

export interface ClientDeviceState {
  clientDeviceId?: Nullable<string>;
  isRegistered: boolean;
  timeZoneId: string;
  readonly pushNotificationsById: Record<string, PushNotification>;
  readonly clientDevicesById: Record<string, ClientDevice>;
}

export enum ClientAppType {
  GENERIC = 'GENERIC',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  WEB = 'WEB'
}

export enum ClientDeviceType {
  UNSPECIFIC = 'unspecific',
  PHONE = 'phone',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
  LAPTOP = 'laptop'
}

export enum OperatingSystemType {
  UNSPECIFIC = 'unspecific',
  ANDROID = 'android',
  IOS = 'ios',
  LINUX = 'linux',
  MACOS = 'osx',
  WINDOWS = 'windows'
}

export enum PushPlatformType {
  APNS = 'APNS',
  APNS_SANDBOX = 'APNS_SANDBOX',
  GCM = 'GCM'
}

export interface ClientDevice {
  id: string;
  personId?: Nullable<string>;
  clientAppType: ClientAppType;
  appName: string;
  appVersion: string;
  appBuild?: Nullable<string>;
  userAgent: string;
  osType: OperatingSystemType;
  osVersion: string;
  browserName: string;
  browserVersion: string;
  deviceName: string;
  deviceMaker: string;
  clientDeviceType: ClientDeviceType;
  screenWidth: number;
  screenHeight: number;
  preferredLanguages: string;
  clientTimeZoneId: string;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface ClientDevicePushInfo {
  id: string;
  endpointArn: string;
  pushToken: string;
  pushPlatformType: PushPlatformType;
  subscriptionArn?: Nullable<string>;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface PushNotification {
  id: string;
  clientDeviceId: string;
  title: string;
  body: string;
  payload?: Nullable<string>;
  pushNotificationType: PushNotificationType;
  created?: Nullable<string>;
  modifiedDate?: Nullable<string>;
}

export interface ClientDevicePushNotificationsResponse {
  clientDevicePushNotifications: PushNotification[];
  clientDevices: ClientDevice[];
}
