import React from 'react';
import { MetricIDs } from 'redux/carePlan/carePlanTypes';
import { patientsTableTitle } from '../../../../utils/carePlanUtils';
import style from './PatientTable.scss';
import TableCell, { CellProp } from './TableCell';
import TableHeader from './TableHeader';

export interface PatientTableData {
  id: string;
  isTodayMeasurements: boolean | undefined;
  name: string;
  heartRate?: CellProp;
  weight?: CellProp;
  glucoseLevel?: CellProp;
  walkActivity?: CellProp;
  medicationReminder?: CellProp;
  medicationReminderMdr?: CellProp;
  coachCall?: CellProp;
  stressLevel?: CellProp;
  healthCoachConcerns?: CellProp;
  sleepTime?: CellProp;
  wakeTime?: CellProp;
  CHFBreathingLyingDown?: CellProp;
  CHFChestPain?: CellProp;
  CHFLightHeaded?: CellProp;
  CHFShortOfBreath?: CellProp;
  CHFNauseous?: CellProp;
  CHFSwellingFeet?: CellProp;
  CHFSwellingLegs?: CellProp;
}

export interface TableProps extends React.ComponentPropsWithoutRef<'table'> {
  patientTableData: PatientTableData[];
  onPressTableRow: (personId: string) => void;
  isMdrView?: boolean;
}

const Table: React.FC<TableProps> = React.memo(({ patientTableData, onPressTableRow, isMdrView }) => {
  return (
    <table className={style.patientsTable}>
      <thead>
        <tr className={style.tableRow}>
          <TableHeader>Name</TableHeader>
          <TableHeader>{`${patientsTableTitle[MetricIDs.HEART_RATE]} (bpm)`}</TableHeader>
          <TableHeader>{`${patientsTableTitle[MetricIDs.WEIGHT]} (lbs)`}</TableHeader>
          <TableHeader>{`${patientsTableTitle[MetricIDs.GLUCOSE_LEVEL]} (bpm)`}</TableHeader>
          {isMdrView && (
            <>
              <TableHeader>{patientsTableTitle[MetricIDs.MEDICATION_SET_REMINDER_QUESTION]}</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.COACHING_CALL]}</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.STRESS_LEVEL]}</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.HEALTH_COACH_CONCERNS]}</TableHeader>
            </>
          )}
          {!isMdrView && (
            <>
              <TableHeader>Sleep time</TableHeader>
              <TableHeader>Wake time</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.WALK_ACTIVITY]}</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.MEDICATION_REMINDER]}</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.CHF_BREATHING_LYING_DOWN]}</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.CHF_CHEST_PAIN]}</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.CHF_LIGHT_HEADED]}</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.CHF_SHORT_OF_BREATH]}</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.CHF_NAUSEOUS]}</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.CHF_SWELLING_FEET]}</TableHeader>
              <TableHeader>{patientsTableTitle[MetricIDs.CHF_SWELLING_LEGS]}</TableHeader>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {patientTableData.map((patient) => {
          const {
            id,
            isTodayMeasurements,
            name,
            heartRate,
            weight,
            glucoseLevel,
            medicationReminderMdr,
            coachCall,
            stressLevel,
            healthCoachConcerns,
            walkActivity,
            medicationReminder,
            sleepTime,
            wakeTime,
            CHFBreathingLyingDown,
            CHFChestPain,
            CHFLightHeaded,
            CHFShortOfBreath,
            CHFNauseous,
            CHFSwellingFeet,
            CHFSwellingLegs
          } = patient;
          const handleRowClick = () => {
            onPressTableRow(id);
          };
          const isGreyed = !isTodayMeasurements;

          return (
            <tr key={id} onClick={handleRowClick} className={style.tableRow}>
              <TableCell name={name} />
              <TableCell cellProp={heartRate} isGreyed={isGreyed} />
              <TableCell cellProp={weight} isGreyed={isGreyed} />
              <TableCell cellProp={glucoseLevel} isGreyed={isGreyed} />
              {isMdrView && (
                <>
                  <TableCell cellProp={medicationReminderMdr} isGreyed={isGreyed} />
                  <TableCell cellProp={coachCall} isGreyed={isGreyed} />
                  <TableCell cellProp={stressLevel} isGreyed={isGreyed} />
                  <TableCell cellProp={healthCoachConcerns} isGreyed={isGreyed} />
                </>
              )}
              {!isMdrView && (
                <>
                  <TableCell cellProp={sleepTime} isGreyed={isGreyed} />
                  <TableCell cellProp={wakeTime} isGreyed={isGreyed} />
                  <TableCell cellProp={walkActivity} isGreyed={isGreyed} />
                  <TableCell cellProp={medicationReminder} isGreyed={isGreyed} />
                  <TableCell cellProp={CHFBreathingLyingDown} isGreyed={isGreyed} />
                  <TableCell cellProp={CHFChestPain} isGreyed={isGreyed} />
                  <TableCell cellProp={CHFLightHeaded} isGreyed={isGreyed} />
                  <TableCell cellProp={CHFShortOfBreath} isGreyed={isGreyed} />
                  <TableCell cellProp={CHFNauseous} isGreyed={isGreyed} />
                  <TableCell cellProp={CHFSwellingFeet} isGreyed={isGreyed} />
                  <TableCell cellProp={CHFSwellingLegs} isGreyed={isGreyed} />
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});

export default Table;
