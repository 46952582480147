import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import style from './Login.scss';
import { ReduxState } from '../../redux/types';
import Login from './Login';
import { getClearLoginResponseAction } from '../../redux/oauth/oauthActions';
import { getOauthErrorStatusSelector, isOauthLoadingSelector, userHasAccessTokenSelector } from '../../redux/oauth/oauthSelectors';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';

export enum Tabs {
  LOGIN = 'login',
  SIGNUP = 'signup'
}

interface Props {
  isLoading: boolean;
  errorStatus?: Nullable<number>;
  isLoggedIn: boolean;
  login: Function;
  errorMessage: any;
  clearLoginResponse: () => void;
}

const LoginWrapper: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <div className={style.wrapper}>
        <button className={`${style.tab} ${style.tab_active}`} disabled>
          Log In
        </button>
        <Login {...props}></Login>
      </div>
      <StatusMessage
        saveUnsuccessful={!props.isLoading && !!props.errorStatus}
        clearResponseStatus={props.clearLoginResponse}
        errorMessage={props.errorStatus === 401 ? 'Username or password not found' : 'Something went wrong'}
      />
    </React.Fragment>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      isLoading: isOauthLoadingSelector(state),
      isLoggedIn: userHasAccessTokenSelector(state),
      errorStatus: getOauthErrorStatusSelector(state)
    };
  },
  (dispatch: Function) => ({
    clearLoginResponse: () => {
      dispatch(getClearLoginResponseAction());
    }
  })
);

export default compose(connectRedux)(LoginWrapper);
