import { createReducer } from '@reduxjs/toolkit';
import { SleepScheduleState } from 'redux/sleepschedule/sleepScheduleTypes';
import { getSleepSchedulesActions } from '../person/personActions';
import { PersonState, SleepSchedule } from '../person/personTypes';
import { getActionStringWithoutState } from '../reduxUtils';
import {
  SLEEP_SCHEDULE_ACTION_PREFIX,
  createNewSleepScheduleActions,
  getClearResponseStatusSleepScheduleAction,
  getLatestSleepScheduleActions,
  getSleepSchedulesForReviewActions,
  postSleepScheduleSendActions,
  putSleepScheduleEditableActions,
  updateSleepScheduleLunaSummaryActions
} from './sleepScheduleActions';

export const initialSleepScheduleState: SleepScheduleState = {
  isLoading: {},
  error: {},
  success: {},
  sleepSchedulesForReview: [],
  sleepScheduleById: {},
  latestSleepScheduleIdByPersonId: {},
  draftScheduleById: {}
};

export default createReducer(initialSleepScheduleState, (builder) => {
  builder
    .addCase(getSleepSchedulesForReviewActions.success, (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.sleepSchedulesForReview = [];
        action.payload.forEach((sleepSchedule: SleepSchedule) => {
          state.sleepScheduleById[sleepSchedule.id] = sleepSchedule;
          state.sleepSchedulesForReview.push(sleepSchedule.id);
        });
      }
    })
    .addCase(getLatestSleepScheduleActions.success, (state, action) => {
      if (action.payload) {
        state.sleepScheduleById[action.payload.id] = action.payload;
        state.latestSleepScheduleIdByPersonId[action.payload.personId] = action.payload.id;
      }
    })
    .addCase(putSleepScheduleEditableActions.success, (state, action) => {
      if (action.payload) {
        state.sleepScheduleById[action.payload.id] = action.payload;
      }
    })
    .addCase(updateSleepScheduleLunaSummaryActions.success, (state, action) => {
      if (action.payload) {
        if (state.draftScheduleById[action.payload.personId]) {
          state.draftScheduleById[action.payload.personId] = action.payload;
        } else {
          state.sleepScheduleById[action.payload.id] = action.payload;
        }
      }
    })
    .addCase(postSleepScheduleSendActions.success, (state, action) => {
      if (action.payload) {
        state.sleepScheduleById[action.payload.id] = action.payload;
        if (state.draftScheduleById[action.payload.personId]) {
          delete state.draftScheduleById[action.payload.personId];
        }
        state.success[action.type] = true;
        state.successMessage = `Sleep Schedule successfully sent!`;
      }
    })
    .addCase(createNewSleepScheduleActions.success, (state, action) => {
      if (action.payload) {
        state.draftScheduleById[action.payload.personId] = action.payload;
      }
    })

    // .addCase(saveSleepScheduleAndCalculationsActions.start, (state, action) => {
    //   state.loadingEventChange = true;
    //   state.currentSleepScheduleCalculation = undefined;
    // })
    // .addCase(saveSleepScheduleAndCalculationsActions.fail, (state, action) => {
    //   state.loadingEventChange = false;
    //   state.currentSleepScheduleCalculation = undefined;
    // })
    // .addCase(updateSleepScheduleActions.start, (state, action) => {
    //   state.isUpdateSleepScheduleLoading = true;
    // })
    // .addCase(updateSleepScheduleActions.success, (state, action) => {
    //   state.isUpdateSleepScheduleLoading = false;
    //   const sleepSchedule = action.payload;
    //   if (sleepSchedule?.personId) {
    //     const person: Person = state.personById[sleepSchedule.personId];
    //     if (!!person) {
    //       person.currentSleepSchedule = sleepSchedule;
    //       state.personById[sleepSchedule.personId] = person;
    //     }
    //   }
    //   state.success[action.type] = true;
    // })
    // .addCase(updateSleepScheduleActions.fail, (state, action) => {
    //   state.error[action.type] = {
    //     message: action.payload.message,
    //     status: action.payload.status,
    //   };
    //   state.isUpdateSleepScheduleLoading = false;
    //   state.updateSleepScheduleError = action.payload?.message;
    // })
    // .addCase(getSleepSchedulesActions.start, (state, action) => {
    //   state.historicSleepSchedules = undefined;
    // })
    .addCase(getSleepSchedulesActions.success, (state, action) => {
      if (action.payload && action.payload.length > 0) {
        action.payload.forEach((sleepSchedule: SleepSchedule) => {
          state.sleepScheduleById[sleepSchedule.id] = sleepSchedule;
        });
      }
    })
    // .addCase(calculateSleepScheduleActions.start, (state, action) => {
    //   state.currentSleepScheduleCalculation = undefined;
    //   state.loadingEventChange = true;
    // })
    // .addCase(calculateSleepScheduleActions.success, (state, action) => {
    //   state.currentSleepScheduleCalculation = action.payload;
    //   state.loadingEventChange = false;
    // })
    // .addCase(calculateSleepScheduleActions.fail, (state, action) => {
    //   state.error[action.type] = {
    //     message: action.payload.message,
    //     status: action.payload.status,
    //   };
    //   state.loadingEventChange = false;
    // })
    // .addCase(saveSleepScheduleAndCalculationsActions.success, (state, action) => {
    //   const sleepScheduleCalculationResponse: SleepScheduleCalculationResponse = action.payload;
    //   const sleepScheduleResponse: WorkflowUpdatingResponse<SleepSchedule> = sleepScheduleCalculationResponse.sleepScheduleUpdateResponse;
    //   const sleepSchedule: SleepSchedule = sleepScheduleResponse?.updatedValue;
    //   sleepSchedule.sleepScheduleCalculation = {
    //     ...sleepScheduleCalculationResponse.sleepScheduleCalculation,
    //     created: sleepScheduleCalculationResponse.sleepScheduleCalculation.created || sleepSchedule.created,
    //     modifiedDate: sleepScheduleCalculationResponse.sleepScheduleCalculation.modifiedDate || sleepSchedule.modifiedDate,
    //   };

    //   const historicSleepSchedules: SleepSchedule[] = state.historicSleepSchedules || [];
    //   if (historicSleepSchedules?.length) {
    //     historicSleepSchedules[0].endDate = sleepSchedule.startDate;
    //   }
    //   historicSleepSchedules.unshift(sleepSchedule);

    //   if (sleepScheduleCalculationResponse.ignoreWarnings && sleepSchedule.validationWarning) {
    //     state.warningMessage = sleepSchedule.validationWarning;
    //   }

    //   state.historicSleepSchedules = historicSleepSchedules;
    //   state.loadingEventChange = false;
    //   state.success[action.type] = true;
    // })
    // .addCase(clearSleepCalculationAction, (state, action) => {
    //   clearSleepCalculation(state, action.payload);
    // })

    // .addCase(getValidSleepSchedulesActions.start, (state, action) => {
    //   state.kokoMapSleepScheduleIds = [];
    // })
    // .addCase(getValidSleepSchedulesActions.success, (state, action) => {
    //   action.payload?.forEach((sleepSchedule) => {
    //     state.kokoMapSleepScheduleIds.push(sleepSchedule.id);
    //     state.sleepScheduleById[sleepSchedule.id] = sleepSchedule
    //   });
    // })
    // .addCase(saveIsiScoreActions.success, (state, action) => {
    //   const upsertedIsiScore: PersonIsiScore = action.payload;
    //   if (upsertedIsiScore.id) {
    //     state.personIsiScoreById[upsertedIsiScore.id] = upsertedIsiScore;
    //   }

    //   const selectedPersonDetails: Nullable<PersonDetails> = state.selectedPersonDetails;
    //   if (selectedPersonDetails?.personId === upsertedIsiScore?.personId) {
    //     const isiScoresIds: Nullable<string>[] = selectedPersonDetails?.personIsiScoreIds || [];
    //     if (!isiScoresIds.some(sc => sc === upsertedIsiScore.id) && upsertedIsiScore.id) {
    //       isiScoresIds.push(upsertedIsiScore.id);
    //     }
    //     isiScoresIds.sort((a, b) => {
    //       if (a && b) {
    //         const isiScoreA: PersonIsiScore = state.personIsiScoreById[a];
    //         const isiScoreB: PersonIsiScore = state.personIsiScoreById[b];
    //         return isiScoreB.isiTs.localeCompare(isiScoreA.isiTs);
    //       }
    //       return 0;

    //     });
    //     selectedPersonDetails.personIsiScoreIds = isiScoresIds;
    //     state.selectedPersonDetails = selectedPersonDetails;
    //   }
    //   state.success[action.type] = true;
    // })
    .addCase(getClearResponseStatusSleepScheduleAction, (state, action) => {
      state.error = {};
      state.isLoading = {};
      state.success = {};
    })
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-start') && action.type.startsWith(SLEEP_SCHEDULE_ACTION_PREFIX),
      (state, action) => {
        if (action?.type) {
          const tag = getActionStringWithoutState(action.type);
          state.isLoading[getActionStringWithoutState(action.type)] = true;
          delete state.error[tag];
          delete state.success[tag];
        }
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-success') && action.type.startsWith(SLEEP_SCHEDULE_ACTION_PREFIX),
      (state, action) => {
        if (action?.type) {
          const tag = getActionStringWithoutState(action.type);
          state.isLoading[tag] = false;
        }
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-fail') && action.type.startsWith(SLEEP_SCHEDULE_ACTION_PREFIX),
      (state, action: any) => {
        if (action?.type) {
          const tag = getActionStringWithoutState(action.type);
          delete state.isLoading[tag];
          state.error[tag] = {
            message: action.payload?.message,
            status: action.payload?.status
          };
          state.success[tag] = false;
        }
      }
    );
});

const clearSleepCalculation = (state: PersonState, clearHistoric?: Nullable<boolean>): void => {
  if (clearHistoric) {
    state.historicSleepSchedules = undefined;
  }
  clearCurrentSleepCalculation(state);
};

const clearCurrentSleepCalculation = (state: PersonState): void => {
  state.currentSleepScheduleCalculation = undefined;
};
