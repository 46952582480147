import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material';

export const BreadCrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  '& .MuiBreadcrumbs-separator': {
    margin: '0 8px',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#70707B',
  },
}));
