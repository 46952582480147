import React, { Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SleepPane } from '../../components/DualPane/Panes/SleepPane/SleepPane';
import { PurchasePane } from '../../components/DualPane/Panes/PurchasePane/PurchasePane';
import { DualPane } from '../../components/DualPane/DualPane';
import { PageType } from '../../../../redux/analytics/analyticsTypes';
import { pageLeftThunk } from '../../../../redux/analytics/analyticsThunks';

interface Props {
  pageLeft: Function;
}

const Checkout: React.FC<Props> = ({ pageLeft }) => (
  <DualPane
    pageLeft={pageLeft}
    pageType={PageType.CREATE_ACCOUNT_PURCHASE}
    leftPane={<SleepPane />}
    rightPane={(loadingState: [boolean, Dispatch<SetStateAction<boolean>>], handleExternalRouting: (url: string) => void) => (
      <PurchasePane pageType={PageType.CREATE_ACCOUNT_PURCHASE} loadingState={loadingState} handleExternalRouting={handleExternalRouting} />
    )}
  />
);

const connectRedux = connect(
  () => {
    return {};
  },
  (dispatch: Function) => ({
    pageLeft: async (pageType: PageType) => {
      return dispatch(pageLeftThunk(pageType));
    }
  })
);

export default compose(connectRedux)(Checkout);
