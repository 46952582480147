import React, { FormEventHandler, KeyboardEventHandler } from 'react';
import {
  Grid,
  Divider,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Fab,
  ListItemAvatar,
  Box,
  Checkbox,
  FormControlLabel,
  Tooltip,
  IconButton
} from '@material-ui/core';
import {
  Send as SendIcon,
  CheckCircle as CheckCircleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  SentimentSatisfiedOutlined as SentimentSatisfiedOutlinedIcon
} from '@material-ui/icons';
import lunaIcon from '../../../../assets/images/luna.png';
import { ChatChannelType, ChatMessage } from '../../../../redux/chat/chatTypes';
import style from './Chat.scss';
import { DateTime } from 'luxon';
import { Person } from 'redux/person/personTypes';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

const renderChatBubble = (message: string, timeString: Nullable<string>, isLeftAligned: boolean) => (
  <Grid container direction="column" className={isLeftAligned ? style.leftSideBubble : style.rightSideBubble}>
    <Grid item xs>
      <ListItemText
        // adding variant="body1" style={{ whiteSpace: 'pre-line' }} enables line breaks in the chat
        primary={
          <Typography className={isLeftAligned ? style.leftChatMessageText : style.rightChatMessageText} variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {message}
          </Typography>
        }
        secondary={<Typography className={isLeftAligned ? style.leftChatDate : style.rightChatDate}>{timeString}</Typography>}
      />
    </Grid>
  </Grid>
);

const renderCheckmarkLeftSide = (checkmarkValue: boolean, onClickCheckmark: any) => (
  <FormControlLabel
    label={<Typography className={checkmarkValue ? style.checkmarkLabelBlue : style.checkmarkLabelRed}>{checkmarkValue ? 'READ' : 'UNREAD'}</Typography>}
    control={
      <Checkbox
        checked={checkmarkValue}
        onChange={onClickCheckmark}
        inputProps={{ 'aria-label': 'controlled' }}
        size="small"
        color={checkmarkValue ? 'primary' : 'secondary'}
        icon={<CheckCircleOutlineIcon color={checkmarkValue ? 'primary' : 'secondary'} fontSize="small" className={style.checkmarkIcon} />}
        checkedIcon={<CheckCircleIcon />}
      />
    }
  />
);

const renderSendMessageFooter = (
  handleShowEmojiPicker: () => void,
  sleepCoachMessageFormValue?: Nullable<string>,
  onChangeFormSleepCoachMessages?: (FormEventHandler<HTMLElement> | undefined) | undefined,
  onSubmitFormSleepCoachMessage?: React.MouseEventHandler<HTMLButtonElement> | undefined,
  handleKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => KeyboardEventHandler<HTMLElement> | undefined
) => {
  return (
    <div className={style.chatMessageFooterContainer}>
      <Grid container className={style.chatMessageFooter}>
        <Grid item xs="auto" className={style.chatMessageFooterButtonEmoji}>
          <Tooltip title="Emoji" placement="top">
            <IconButton onClick={handleShowEmojiPicker}>
              <SentimentSatisfiedOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={true}>
          <TextField
            value={sleepCoachMessageFormValue}
            onInput={onChangeFormSleepCoachMessages}
            onKeyPress={handleKeyPress}
            label="Type Message"
            fullWidth
            multiline
            maxRows={10}
            autoFocus
          />
        </Grid>
        <Grid item xs="auto" className={style.chatMessageFooterButtonSend}>
          <Fab color="primary" onClick={onSubmitFormSleepCoachMessage} disabled={!sleepCoachMessageFormValue?.length}>
            <SendIcon />
          </Fab>
        </Grid>
      </Grid>
    </div>
  );
};

interface Props {
  messages: ChatMessage[];
  latestPersonMessage?: Nullable<ChatMessage>;
  userId: string;
  personById: Record<string, Person>;
  showAccessNotAllowed: boolean;
  showAgentName?: Nullable<boolean>;
  showSendMessageFooter?: Nullable<boolean>;
  blueBackground?: Nullable<boolean>;
  showEmojiPicker: boolean;
  onChangeFormSleepCoachMessages: FormEventHandler<HTMLDivElement>;
  onSubmitFormSleepCoachMessage: React.MouseEventHandler<HTMLButtonElement>;
  changePersonMessageReadStatus: (personId: string, messageId: string, messageReadStatus: boolean) => void;
  handleKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => KeyboardEventHandler<HTMLDivElement> | undefined;
  handleEmojiSelect: (emoji: EmojiClickData, event: MouseEvent) => void;
  handleShowEmojiPicker: () => void;
  sleepCoachMessageFormValue?: Nullable<string>;
  scrollRef: React.RefObject<HTMLLIElement>;
  emojiPickerRef: React.RefObject<HTMLDivElement>;
}

const Chat: React.FC<Props> = ({
  messages,
  latestPersonMessage,
  userId,
  personById,
  showAccessNotAllowed,
  showAgentName,
  showSendMessageFooter,
  blueBackground,
  sleepCoachMessageFormValue,
  scrollRef,
  emojiPickerRef,
  showEmojiPicker,
  onChangeFormSleepCoachMessages,
  onSubmitFormSleepCoachMessage,
  changePersonMessageReadStatus,
  handleKeyPress,
  handleEmojiSelect,
  handleShowEmojiPicker
}) => {
  return !showAccessNotAllowed ? (
    <Box className={blueBackground ? style.chatContainerBlue : style.oldChatContainer}>
      <div className={style.chatContentContainer}>
        <List className={style.listContainer}>
          {messages.map((message, index) => {
            const timeStringFormat = 'LLL dd, h:mm a';
            const renderMessageOnLeftSide = message.senderId === userId;
            const renderAvatarOnLeftSide = renderMessageOnLeftSide && message.chatChannelType === ChatChannelType.SLEEP_COACH;
            const renderAvatarOnRightSide = !renderMessageOnLeftSide && message.chatChannelType !== ChatChannelType.SLEEP_COACH;
            const senderPerson = message.senderId ? personById[message.senderId] : undefined;
            const fullName = senderPerson?.fullName;
            const showFullName = (renderMessageOnLeftSide || showAgentName) && !!fullName;
            const messageCreatedTimeString = message.created && DateTime.fromISO(message.created).toFormat(timeStringFormat);
            const isLatestPersonSleepCoachMessage = latestPersonMessage && latestPersonMessage.id === message.id && message.chatChannelType === ChatChannelType.SLEEP_COACH;
            const checkmarkValue = !!latestPersonMessage?.messageReadTs;
            const onClickCheckmark = (_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean) =>
              changePersonMessageReadStatus(userId, latestPersonMessage?.id || '', !latestPersonMessage?.messageReadTs);
            const showAgentMessageStatus = message.senderId !== userId && message.chatChannelType === ChatChannelType.SLEEP_COACH;
            const agentMessageReadTimeString = message.messageReadTs && DateTime.fromISO(message.messageReadTs).toFormat(timeStringFormat);
            return (
              <ListItem key={index} className={renderMessageOnLeftSide ? style.chatMessage : style.chatMessageRight}>
                {renderAvatarOnLeftSide && (
                  <ListItemAvatar className={style.listItemAvatarLeft}>
                    <Avatar className={style.chatAvatarLeft} />
                  </ListItemAvatar>
                )}
                <div>
                  {showFullName && <Typography className={renderMessageOnLeftSide ? style.leftChatFullName : style.rightChatFullName}>{fullName?.toUpperCase()}</Typography>}
                  {renderChatBubble(message.message, messageCreatedTimeString, renderMessageOnLeftSide)}
                  {!!isLatestPersonSleepCoachMessage && renderCheckmarkLeftSide(checkmarkValue, onClickCheckmark)}
                  {showAgentMessageStatus && (
                    <Box>
                      <Typography className={style.agentMessageRead}>{agentMessageReadTimeString ? `READ ${agentMessageReadTimeString}` : 'DELIVERED'}</Typography>
                    </Box>
                  )}
                </div>
                {renderAvatarOnRightSide && (
                  <ListItemAvatar className={style.listItemAvatarRight}>
                    <Avatar className={style.chatAvatarRight}>
                      <img className={style.lunaIcon} src={lunaIcon} />
                    </Avatar>
                  </ListItemAvatar>
                )}
              </ListItem>
            );
          })}
          {/* this is the last item that scrolls into view when the effect is run */}
          <ListItem ref={scrollRef} />
        </List>
        <div ref={emojiPickerRef} style={{ position: 'relative' }}>
          {showEmojiPicker && (
            <div style={{ position: 'absolute', bottom: '100%', left: 0 }}>
              <EmojiPicker onEmojiClick={handleEmojiSelect} />
            </div>
          )}
          {showSendMessageFooter && <Divider />}
        </div>
        {showSendMessageFooter &&
          renderSendMessageFooter(handleShowEmojiPicker, sleepCoachMessageFormValue, onChangeFormSleepCoachMessages, onSubmitFormSleepCoachMessage, handleKeyPress)}
      </div>
    </Box>
  ) : (
    <Box className={style.oldChatContainer} minHeight="100vh">
      <Typography variant="subtitle2">Access not allowed</Typography>
    </Box>
  );
};

export default React.memo(Chat);
