import {
  GetDeviceStatePayload,
  GetDeviceProvisioningPayload,
  GetDevicePersonPayload,
  GetDeviceFirmwareVersionPayload,
  GetDeviceFirmwareVersionsRecentPayload,
  DeviceNumberPayload,
  DeviceDataCollection,
  DeviceConfig,
  DeviceConnectivity,
  DeviceEnvironmentPayload,
  DeviceVersion,
  SensorDevice,
  UpdateRemoteCommandPortSuccessPayload,
  AutoUpdateSuccessPayload,
  PutConnectivityNotificationSuccessPayload,
  UnlinkDeviceSuccessPayload,
  HwRevisionFirmware
} from './deviceTypes';
import { createReduxActionCreator } from '../types';
import { createAction } from '@reduxjs/toolkit';

export const DEVICE_ACTION_PREFIX = 'device-redux/';

export const getDeviceProvisioningEnvUpdateActions = createReduxActionCreator<DeviceEnvironmentPayload>(`${DEVICE_ACTION_PREFIX}device-provisioning-env-update`);

export const getDeviceRetireActions = createReduxActionCreator<undefined>(`${DEVICE_ACTION_PREFIX}device-retire`);

export const getUpdateRemoteCommandPortActions = createReduxActionCreator<UpdateRemoteCommandPortSuccessPayload>(`${DEVICE_ACTION_PREFIX}update-remote-command-port`);

export const getSaveDeviceDataCollectionActions = createReduxActionCreator<DeviceDataCollection>(`${DEVICE_ACTION_PREFIX}device-save-data-collection`);

export const getUpdateDeviceConfigActions = createReduxActionCreator<DeviceConfig>(`${DEVICE_ACTION_PREFIX}update-device-config`);

export const resetDeviceActions = createReduxActionCreator<string>(`${DEVICE_ACTION_PREFIX}reset-device`);

export const getDeviceConectivityActions = createReduxActionCreator<DeviceConnectivity[]>(`${DEVICE_ACTION_PREFIX}get-device-connectivity`);

export const getDeviceVersionHistoryActions = createReduxActionCreator<DeviceVersion[]>(`${DEVICE_ACTION_PREFIX}get-device-version-history`);

export const putAutoUpdateActions = createReduxActionCreator<AutoUpdateSuccessPayload>(`${DEVICE_ACTION_PREFIX}put-auto-update-flag`);

export const putConnectivityNotificationActions = createReduxActionCreator<PutConnectivityNotificationSuccessPayload>(`${DEVICE_ACTION_PREFIX}put-connectivity-notification-flag`);

export const postUnlinkDeviceActions = createReduxActionCreator<UnlinkDeviceSuccessPayload>(`${DEVICE_ACTION_PREFIX}post-unlink-device-`);

export const getSensorDeviceActions = createReduxActionCreator<SensorDevice>(`${DEVICE_ACTION_PREFIX}get-sensor-device`);

export const getDeviceStateOverviewActions = createReduxActionCreator<GetDeviceStatePayload>(`${DEVICE_ACTION_PREFIX}device-state-overview`);

export const getDeviceProvisioningOverviewActions = createReduxActionCreator<GetDeviceProvisioningPayload>(`${DEVICE_ACTION_PREFIX}device-provisioning-overview`);

export const putProvisioningDeviceNumberActions = createReduxActionCreator<DeviceNumberPayload>(`${DEVICE_ACTION_PREFIX}put-provisioning-device-number`);

export const getDevicePersonOverviewActions = createReduxActionCreator<GetDevicePersonPayload>(`${DEVICE_ACTION_PREFIX}device-person-overview`);

export const getDeviceFirmwareVersionOverviewActions = createReduxActionCreator<GetDeviceFirmwareVersionPayload>(`${DEVICE_ACTION_PREFIX}device-firmware-version-overview`);

export const getDeviceFirmwareVersionsRecentActions = createReduxActionCreator<GetDeviceFirmwareVersionsRecentPayload>(`${DEVICE_ACTION_PREFIX}device-firmware-versions-recent`);

export const getDeviceCheckStatusActions = createReduxActionCreator<undefined>(`${DEVICE_ACTION_PREFIX}device-check-status`);

export const getDeviceRebootActions = createReduxActionCreator<undefined>(`${DEVICE_ACTION_PREFIX}device-reboot-status`);

export const getDeviceRestartAppActions = createReduxActionCreator<undefined>(`${DEVICE_ACTION_PREFIX}device-restart-app`);

export const getDeviceUpdateActions = createReduxActionCreator<undefined>(`${DEVICE_ACTION_PREFIX}device-update-app`);

export const getDeviceCancelUpdateActions = createReduxActionCreator<undefined>(`${DEVICE_ACTION_PREFIX}device-cancel-update`);

export const getDeviceUpdateFirmwareVersionActions = createReduxActionCreator<undefined>(`${DEVICE_ACTION_PREFIX}device-update-firmware-version`);

export const getDeviceProvisioningActions = createReduxActionCreator<undefined>(`${DEVICE_ACTION_PREFIX}device-provisioning`);

export const getDeviceProvisioningEnvMoveOutActions = createReduxActionCreator<undefined>(`${DEVICE_ACTION_PREFIX}device-provisioning-env-move-out`);

export const getHwRevisionsAndFirmwareMappingsActions = createReduxActionCreator<HwRevisionFirmware[]>(`${DEVICE_ACTION_PREFIX}hw-revisions-and-firmware-types`);

export const setSelectedDevicePersonActions = createReduxActionCreator<string>(`${DEVICE_ACTION_PREFIX}set-selected-device-person`);
export const getClearDeviceResponseStatus = createAction<undefined>(`${DEVICE_ACTION_PREFIX}clear-device-response-status`);
