import { Error } from 'redux/common/types';
import { ReduxState } from 'redux/types';
import { addNewOrganizationAction, updateOrganizationAction } from './organizationActions';
import { Organization } from './organizationTypes';

export const getOrganizationStateSelector = (state: ReduxState) => {
  return state.organization;
};

export const getOrganizationByIdSelector = (state: ReduxState): Record<string, Organization> => {
  return getOrganizationStateSelector(state).organizationById;
};

export const getIsLoadingSelector = (state: ReduxState): Record<string, boolean> => {
  return getOrganizationStateSelector(state).isLoading;
};

export const isLoadingAddNewOrganizationSelector = (state: ReduxState): boolean => {
  return getIsLoadingSelector(state)[addNewOrganizationAction.start.type];
};

export const isLoadingUpdateOrganizationSelector = (state: ReduxState): boolean => {
  return getIsLoadingSelector(state)[updateOrganizationAction.start.type];
};

export const getErrorSelector = (state: ReduxState): Record<string, Error> => {
  return getOrganizationStateSelector(state).error;
};

export const getSuccessSelector = (state: ReduxState): Record<string, boolean> => {
  return getOrganizationStateSelector(state).success;
};
