import { ThunkParams } from '../types';
import { ErrorPayload } from '../common/types';
import { getTransactionAmountActions } from './paymentActions';
import { TransactionAmountPayload } from './paymentTypes';
import Config from '../../config/config';

function processGetTransactionAmountSuccess(payload: string): Function {
  return function (dispatch: Function): void {
    dispatch(getTransactionAmountActions.success(payload));
  };
}
function processGetTransactionAmountFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getTransactionAmountActions.fail(error));
  };
}
export function getTransactionAmountThunk(payload: TransactionAmountPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getTransactionAmountActions.start());
    return apiClient.get(`/api/payment/checkoutDetails`, {
      baseUrl: Config.getAuthBaseUrl(),
      params: payload,
      successAction: processGetTransactionAmountSuccess,
      failAction: processGetTransactionAmountFail
    });
  };
}
