import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { ReduxState } from 'redux/types';
import plusIcon from '../../../assets/images/plus.png';
import { Button } from '../../../components/Button/Button';
import DateControl from '../../../components/DateControl/DateControl';
import { getRenderedCareFeedItemsSelector, isDeletingCareFeedItemSelector, isLoadingCareFeedItemsSelector } from '../../../redux/carePlan/carePlanSelectors';
import { deleteCareFeedItemThunk, fetchPatientCareFeedItemsThunk } from '../../../redux/carePlan/carePlanThunks';
import { RenderedCareFeedItem } from '../../../redux/carePlan/carePlanTypes';
import { getChatGPTDeviceMessagesThunk } from '../../../redux/chat/chatThunks';
import { selectLocationByIdSelector } from '../../../redux/device/deviceSelectors';
import { getPersonByIdSelector, getSelectedPersonDetailsSelector } from '../../../redux/person/personSelector';
import { decrementDay, DEFAULT_TIMEZONE_ID, getStartOfToday, incrementDay } from '../../../utils/time';
import ActivityTab from './components/ActivityTab/ActivityTab';
import Chat from './components/Chat/Chat';
import style from './Feed.scss';

const CHAT_UPDATE_INTERVAL_MS = 20000;
const FEED_AND_RESPONSES_UPDATE_INTERVAL_MS = 20000;

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const Feed: React.FC<Props> = ({
  isLoadingCareFeedItems,
  isDeletingCareFeedItem,
  locationById,
  personById,
  selectedPersonDetails,
  renderedCareFeedItemsByDate,
  deleteCareFeedItem,
  fetchChatGPTDeviceMessages,
  fetchPatientCareFeedItems
}) => {
  const [messages, setMessages] = useState([]);
  const [selectedDate, setSelectedDate] = useState<string>();

  const params = useParams();
  const navigate = useNavigate();
  const { userId } = params || {};

  const person = userId ? personById[userId] : undefined;

  const timeZoneId = selectedPersonDetails?.locationId && locationById?.[selectedPersonDetails?.locationId]?.timeZoneId;
  const timezone: string = timeZoneId || DEFAULT_TIMEZONE_ID;

  const nowMs = DateTime.now().toMillis();
  const startMs = selectedDate ? DateTime.fromISO(selectedDate).toMillis() : undefined;
  const endMs = selectedDate ? DateTime.fromISO(selectedDate).plus({ day: 1 }).toMillis() : undefined;

  const hideUpcoming = !!endMs && endMs <= nowMs;
  const hidePast = !!startMs && startMs > nowMs;

  const upcomingCareFeedItems = useMemo((): RenderedCareFeedItem[] => {
    if (startMs && timezone) {
      const upcomingRenderedCareFeedItems = renderedCareFeedItemsByDate(startMs, true, timezone);
      return upcomingRenderedCareFeedItems;
    }
    return [];
  }, [renderedCareFeedItemsByDate, startMs, timezone, isLoadingCareFeedItems]);

  const pastCareFeedItems = useMemo((): RenderedCareFeedItem[] => {
    if (startMs && timezone) {
      const pastRenderedCareFeedItems = renderedCareFeedItemsByDate(startMs, false, timezone);
      return pastRenderedCareFeedItems;
    }
    return [];
  }, [renderedCareFeedItemsByDate, startMs, timezone, isLoadingCareFeedItems]);

  useEffect(() => {
    if (timezone && !!selectedPersonDetails) {
      setSelectedDate(getStartOfToday(timezone));
    }
  }, [timezone, selectedPersonDetails]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (userId && startMs && endMs) {
        try {
          const response = await fetchChatGPTDeviceMessages(userId, startMs, endMs);
          setMessages(response.data);
        } catch (err) {
          console.log('error fetch messages', err);
        }
      }
    };

    const fetchCareFeedItems = async () => {
      if (userId && startMs && endMs) {
        fetchPatientCareFeedItems(userId, startMs, endMs);
      }
    };

    const setChatUpdateIntervals = () => {
      return setInterval(() => {
        fetchMessages();
      }, CHAT_UPDATE_INTERVAL_MS);
    };

    const setFeedAndResponsesUpdateIntervals = () => {
      return setInterval(() => {
        fetchCareFeedItems();
      }, FEED_AND_RESPONSES_UPDATE_INTERVAL_MS);
    };

    let updateChatMessagesInterval;
    let updateFeedAndResponsesInterval;

    if (userId && !!selectedPersonDetails && !!selectedDate) {
      fetchMessages();
      fetchCareFeedItems();
      updateChatMessagesInterval = setChatUpdateIntervals();
      updateFeedAndResponsesInterval = setFeedAndResponsesUpdateIntervals();
    }

    return () => {
      clearInterval(updateChatMessagesInterval);
      clearInterval(updateFeedAndResponsesInterval);
    };
  }, [userId, selectedDate, selectedPersonDetails]);

  const handleAssignTaskClick = () => {
    navigate(`/patients/${userId}/newActivity`);
  };

  const onClickLeftCaret = () => {
    const prevDate = decrementDay(selectedDate!);
    setSelectedDate(prevDate);
  };

  const onClickRightCaret = () => {
    const nextDate = incrementDay(selectedDate!);
    setSelectedDate(nextDate);
  };

  if (!selectedDate) {
    return null;
  }

  const onRemoveCareFeedItemClick = async (careFeedItemId: string) => {
    deleteCareFeedItem(careFeedItemId, startMs!);
  };

  const onEditCareFeedItemClick = async (careFeedItemId: string) => {};

  return (
    <div className={style.container}>
      <div className={style.titleContainer}>
        <DateControl date={selectedDate} timezone={timezone} onClickLeftCaret={onClickLeftCaret} onClickRightCaret={onClickRightCaret} />
        <div>
          <Button variant="contained" onClick={handleAssignTaskClick} startIcon={<img src={plusIcon} className={style.plusIcon} />}>
            Add activity
          </Button>
        </div>
      </div>
      <div className={style.subContainer}>
        <div className={style.horizontalDivider} />
        <div className={style.contentContainer}>
          <Chat timezone={timezone} messages={messages} person={person} />
          <ActivityTab
            upcomingCareFeedItems={upcomingCareFeedItems}
            pastCareFeedItems={pastCareFeedItems}
            hidePast={hidePast}
            hideUpcoming={hideUpcoming}
            onEditCareFeedItemClick={onEditCareFeedItemClick}
            onRemoveCareFeedItemClick={onRemoveCareFeedItemClick}
            editCareFeedItemDisabled={false}
            removeCareFeedItemDisabled={isDeletingCareFeedItem}
          />
        </div>
      </div>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    locationById: selectLocationByIdSelector(state),
    isDeletingCareFeedItem: isDeletingCareFeedItemSelector(state),
    isLoadingCareFeedItems: isLoadingCareFeedItemsSelector(state),
    personById: getPersonByIdSelector(state),
    renderedCareFeedItemsByDate: (startMs: number, isUpcoming: boolean, timezone: string) => getRenderedCareFeedItemsSelector(startMs, isUpcoming, timezone, state),
    selectedPersonDetails: getSelectedPersonDetailsSelector(state)
  }),
  {
    deleteCareFeedItem: deleteCareFeedItemThunk,
    fetchChatGPTDeviceMessages: getChatGPTDeviceMessagesThunk,
    fetchPatientCareFeedItems: fetchPatientCareFeedItemsThunk
  }
);

export default compose(connectRedux)(Feed);
