import { Error } from 'redux/common/types';
import { SleepSchedule } from '../person/personTypes';
import { ReduxState } from '../types';

export const getSleepSchedulesByIdSelector = (state: ReduxState): Record<string, SleepSchedule> => {
  return state.sleepSchedule.sleepScheduleById;
};

export const getSleepSchedulesToReviewSelector = (state: ReduxState): string[] => {
  return state.sleepSchedule.sleepSchedulesForReview;
};

export const getLatestSleepScheduleIdByPersonIdSelector = (state: ReduxState): Record<string, string> => {
  return state.sleepSchedule.latestSleepScheduleIdByPersonId;
};

export const getDraftScheduleByIdSelector = (state: ReduxState): Record<string, SleepSchedule> => {
  return state.sleepSchedule.draftScheduleById;
};

export const getIsLoadingSleepScheduleSelector = (state: ReduxState): Record<string, boolean> => {
  return state.sleepSchedule.isLoading;
};

export const getErrorSleepScheduleSelector = (state: ReduxState): Record<string, Error> => {
  return state.sleepSchedule.error;
};

export const getSuccessSleepScheduleSelector = (state: ReduxState): Record<string, boolean> => {
  return state.sleepSchedule.success;
};
export const getSleepScheduleSuccessMessageSelector = (state: ReduxState): Nullable<string> => {
  return state.sleepSchedule.successMessage;
};
