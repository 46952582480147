import { ErrorPayload } from 'redux/common/types';
import { Person } from 'redux/person/personTypes';
import { ThunkParams } from 'redux/types';
import { addNewCareTeamAction, addPersonToCareTeamAction, getCareTeamPersonsAction, getCareTeamsAction, removePersonfromCareTeamAction } from './clinicalActions';
import { CareTeam, PersonCareTeam } from './clinicalTypes';

function processAddNewCareTeamSuccess(payload: CareTeam) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(addNewCareTeamAction.success(payload));
  };
}

function processAddNewCareTeamFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(addNewCareTeamAction.fail(error));
  };
}

export function addNewCareTeamThunk(careTeamName: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(addNewCareTeamAction.start());
    return apiClient.post('v1/clinical/newCareTeam', {
      auth: true,
      data: { name: careTeamName },
      successAction: processAddNewCareTeamSuccess,
      failAction: processAddNewCareTeamFail
    });
  };
}

function processGetCareTeamsSuccess(payload: CareTeam[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getCareTeamsAction.success(payload));
  };
}

function processGetCareTeamsFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getCareTeamsAction.fail(error));
  };
}

export function getCareTeamsThunk() {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getCareTeamsAction.start());
    return apiClient.get('v1/clinical/careTeams', {
      auth: true,
      successAction: processGetCareTeamsSuccess,
      failAction: processGetCareTeamsFail
    });
  };
}

function proceessAddPersonToCareTeamSuccess(payload: PersonCareTeam) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(addPersonToCareTeamAction.success(payload));
  };
}

function processAddPersonToCareTeamFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(addPersonToCareTeamAction.fail(error));
  };
}

export function addPersonToCareTeamThunk(careTeamId: string, personId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(addPersonToCareTeamAction.start());
    return apiClient.put(`v1/clinical/careTeam/${careTeamId}/${personId}`, {
      auth: true,
      successAction: proceessAddPersonToCareTeamSuccess,
      failAction: processAddPersonToCareTeamFail
    });
  };
}

function processRemovePersonFromCareTeamSuccess(payload: PersonCareTeam) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(removePersonfromCareTeamAction.success(payload));
  };
}

function processRemovePersonFromCareTeamFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(removePersonfromCareTeamAction.fail(error));
  };
}

export function removePersonFromCareTeamThunk(careTeamId: string, personId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(removePersonfromCareTeamAction.start());
    return apiClient.delete(`v1/clinical/careTeam/${careTeamId}/${personId}`, {
      auth: true,
      successAction: processRemovePersonFromCareTeamSuccess,
      failAction: processRemovePersonFromCareTeamFail
    });
  };
}

function getCareTeamPersonsSuccess(payload: Person[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getCareTeamPersonsAction.success(payload));
  };
}

function getCareTeamPersonsFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getCareTeamPersonsAction.fail(error));
  };
}

export function getCareTeamPersonsThunk() {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getCareTeamPersonsAction.start());
    return apiClient.get(`v1/clinical/careTeamPersons`, {
      auth: true,
      successAction: getCareTeamPersonsSuccess,
      failAction: getCareTeamPersonsFail
    });
  };
}
