import React from 'react';

/*
Prevent html and css conflicts between server and client due to dynamic changes/resizing
https://www.joshwcomeau.com/react/the-perils-of-rehydration/
 */
const ClientOnly = ({ children, ...delegated }) => {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return <React.Fragment {...delegated}>{children}</React.Fragment>;
};

export default ClientOnly;
