import { ReduxAction } from '../types';
import { PaymentState } from './paymentTypes';
import { getTransactionAmountActions } from './paymentActions';
import { createReducer } from '@reduxjs/toolkit';
import { getActionStringWithoutState } from '../reduxUtils';

export const initialPaymentState: PaymentState = {
  isLoading: {},
  error: {},
  success: {}
};

export default createReducer(initialPaymentState, (builder) => {
  builder
    .addCase(getTransactionAmountActions.start, (state, action) => {
      setDefaultStart(state, getActionStringWithoutState(action.type));
    })
    .addCase(getTransactionAmountActions.success, (state, action) => {
      if (action.payload) {
        setDefaultSuccess(state, getActionStringWithoutState(action.type));
        const transactionPayload: number = parseInt(action.payload);
        state.transactionAmount = transactionPayload / 100;
      }
    })
    .addCase(getTransactionAmountActions.fail, (state, action) => {
      setDefaultFail(state, getActionStringWithoutState(action.type), action);
    });
});
const setDefaultSuccess = (state: PaymentState, tag: string): void => {
  state.isLoading[tag] = false;
  delete state.error[tag];
  state.success[tag] = true;
};
const setDefaultStart = (state: PaymentState, tag: string): void => {
  state.isLoading[getActionStringWithoutState(tag)] = true;
  delete state.error[tag];
  delete state.success[tag];
};

const setDefaultFail = (state: PaymentState, tag: string, action: ReduxAction): void => {
  delete state.isLoading[tag];
  state.error[tag] = {
    message: action.payload?.message,
    status: action.payload.status
  };
  state.success[tag] = false;
};
