import { ChartsReferenceLine, LineChart, axisClasses } from '@mui/x-charts';
import React from 'react';
import { Color } from '../../../utils/color';

export interface Props {
  data: number[];
  xLabels: string[];
  yAxisLabel?: string;
  minRange?: number;
  maxRange?: number;
  minYValue?: number;
  maxYValue?: number;
  valueFormatter?: (index: number) => string;
}

const Chart: React.FC<Props> = React.memo(({ data, xLabels, yAxisLabel, minRange, maxRange, minYValue, maxYValue, valueFormatter }) => {
  let delta = 30;

  // Determine the minimum and maximum values for the y-axis
  let minY = minRange != null ? Math.min(...data, minRange) : Math.min(...data);
  let maxY = maxRange != null ? Math.max(...data, maxRange) : Math.max(...data);

  if (minYValue === 0 || minYValue) {
    minY = minYValue;
    delta = 0;
  }
  if (maxYValue === 0 || maxYValue) {
    maxY = maxYValue;
    delta = 0;
  }

  return (
    <LineChart
      series={[{ curve: 'linear', data, valueFormatter }]}
      yAxis={[{ label: yAxisLabel, min: minY - delta, max: maxY + delta }]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      colors={[Color.DARK_BLUE]}
      sx={{
        [`.${axisClasses.left} .${axisClasses.label}`]: {
          transform: 'translate(-10px, 0)'
        }
      }}
    >
      {maxRange && <ChartsReferenceLine y={maxRange} alwaysShow={true} label={`max: ${maxRange}`} lineStyle={{ stroke: Color.RED_2, strokeDasharray: '4 4' }} />}
      {minRange && <ChartsReferenceLine y={minRange} alwaysShow={true} label={`min: ${minRange}`} lineStyle={{ stroke: Color.RED_2, strokeDasharray: '4 4' }} />}
    </LineChart>
  );
});

export default Chart;
