import React, { useEffect, useRef, useState } from 'react';
import DotsThreeVerticalImg from '../../assets/images/dots-three-vertical.png';
import styles from './DotsWithDialog.scss';
import { Button } from '../Button/Button';

interface Props {
  editDisabled: boolean;
  removeDisabled: boolean;
  onEditClick: () => void;
  onRemoveClick: () => void;
}

const DotsWithDialog: React.FC<Props> = ({ editDisabled, removeDisabled, onEditClick, onRemoveClick }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement | null>(null);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleRemove = () => {
    onRemoveClick();
    setIsOpen(false);
  };

  const handleEdit = () => {
    onEditClick();
  };

  return (
    <div className={styles.container}>
      <div className={styles.dotsContainer} onClick={togglePopup}>
        <img src={DotsThreeVerticalImg} className={styles.dotsImg} alt="Menu dots" />
      </div>
      {isOpen && (
        <div ref={popupRef} className={styles.dialog}>
          <div className={styles.listContainer}>
            <Button onClick={handleEdit} disabled={removeDisabled} variant="text" disableRipple>
              Edit
            </Button>
          </div>
          <div className={styles.listContainer}>
            <Button onClick={handleRemove} disabled={removeDisabled} variant="text" disableRipple className={styles.removeText}>
              Remove
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DotsWithDialog;
