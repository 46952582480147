import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import {
  CarePlanAutocomplete,
  CarePlanAutocompletePaper,
  CarePlanAutocompletePopper,
  CarePlanAutocompleteTextField as CarePlanTextField
} from '../../components/CarePlanAutocomplete/CarePlanAutocomplete';
import NavSideBarContentWrapper from '../../components/NavSideBarContentWrapper/NavSideBarContentWrapper';
import { getCarePlanByIdSelector } from '../../redux/carePlan/carePlanSelectors';
import { fetchCarePlansThunk } from '../../redux/carePlan/carePlanThunks';
import { CarePlan } from '../../redux/carePlan/carePlanTypes';
import { hasPatientCreatePermissionSelector, isLoadingPostNewPatientSelector } from '../../redux/person/personSelector';
import { postNewPatientThunk } from '../../redux/person/personThunks';
import { GenderType, NewPatientRequest, genderTypeList } from '../../redux/person/personTypes';
import { ReduxState } from '../../redux/types';
import { normalizeEnumName } from '../../utils/content';
import { emailAlertMessage, getCleanPhoneNumber, getFormattedPhoneNumber, isEmailValid, isPhoneNumberValid, phoneNumberAlertMessage } from '../../utils/util';
import style from './NewPatient.scss';
import { Button } from '../../components/Button/Button';
import { Text } from '../../components/Typography/Text';
import { TextField } from '../../components/TextField';
import envelopeSimpleIcon from '../../assets/images/envelope-simple.png';
import caretDownIcon from '../../assets/images/caret-down.png';
import { DatePicker } from '../../components/DatePicker';
import { BreadCrumbData, BreadCrumbType } from '../../redux/common/types';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '../../redux/oauth/oauthSelectors';
import { defaultNewPatientBreadCrumbs, defaultNewPatientCrumb } from '../../utils/breadcrumbs';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const NewPatient: React.FC<Props> = ({ breadCrumbs, carePlanById, hasPatientCreatePermission, isLoadingPostNewPatient, appendBreadCrumbs, fetchCarePlans, postNewPatient }) => {
  if (!hasPatientCreatePermission) {
    return null;
  }
  const [personFormData, setPersonFormData] = React.useState({
    givenNames: '',
    familyName: '',
    phoneNumber: '',
    email: '',
    birthDate: '',
    genderType: null
  });
  const [selectedCarePlan, setSelectedCarePlan] = React.useState<CarePlan | null>(null);
  const [errors, setErrors] = React.useState({
    phoneNumber: '',
    email: '',
    birthDate: ''
  });
  const [successMessage, setSuccessMessage] = React.useState('');
  const [apiError, setApiError] = React.useState('');

  const isSubmitBtnDisabled =
    isLoadingPostNewPatient || !personFormData.givenNames || !personFormData.familyName || !personFormData.phoneNumber || !personFormData.email || !personFormData.birthDate;

  useEffect(() => {
    fetchCarePlans();
  }, []);

  const handlePersonChange = (e) => {
    setSuccessMessage('');
    setApiError('');
    const { name, value } = e.target;
    setPersonFormData({
      ...personFormData,
      [name]: value
    });
  };

  const handlePhoneNumberChange = (e) => {
    setSuccessMessage('');
    setApiError('');
    const value = e.target.value;
    const formattedPhoneNumber = getFormattedPhoneNumber(value);

    setPersonFormData({
      ...personFormData,
      phoneNumber: formattedPhoneNumber
    });
  };

  const handleBirthDateChange = (date: Date) => {
    setSuccessMessage('');
    setApiError('');

    setPersonFormData({
      ...personFormData,
      birthDate: date.toISOString()
    });
  };

  const handleGenderTypeChange = (event, value) => {
    setSuccessMessage('');
    setApiError('');
    setPersonFormData({
      ...personFormData,
      genderType: value
    });
  };

  const handleCarePlanChange = (event, value) => {
    setSuccessMessage('');
    setApiError('');
    setSelectedCarePlan(value as CarePlan);
  };

  const validateForm = () => {
    const newErrors = {
      phoneNumber: isPhoneNumberValid(personFormData.phoneNumber) ? '' : phoneNumberAlertMessage(personFormData.phoneNumber) || '',
      email: isEmailValid(personFormData.email) ? '' : emailAlertMessage(personFormData.email) || '',
      birthDate: '',
      genderType: ''
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const cleanPhoneNumber = getCleanPhoneNumber(personFormData.phoneNumber);
      const newPatient: NewPatientRequest = {
        ...personFormData,
        genderType: personFormData.genderType || GenderType.UNKNOWN,
        phoneNumber: cleanPhoneNumber,
        carePlanId: selectedCarePlan?.id
      };
      try {
        setApiError('');
        await postNewPatient(newPatient);
        setSuccessMessage('Success!');
        setPersonFormData({
          givenNames: '',
          familyName: '',
          phoneNumber: '',
          email: '',
          birthDate: '',
          genderType: null
        });
        setSelectedCarePlan(null);
      } catch (err) {
        setApiError('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <NavSideBarContentWrapper
      headerText="New patient"
      breadCrumbs={breadCrumbs}
      appendBreadCrumbs={appendBreadCrumbs}
      defaultCurrentCrumb={defaultNewPatientCrumb}
      defaultCrumbHistory={defaultNewPatientBreadCrumbs}
    >
      <form onSubmit={handleFormSubmit} className={style.mainContainer}>
        <Text variant="xl">Demographics</Text>
        <div className={style.subContainer}>
          <div className={style.formRow}>
            {/* FIRST NAME */}
            <TextField name="givenNames" value={personFormData.givenNames} onChange={handlePersonChange} label="First name" placeholder="First name" autoComplete="new-password" />
            {/* LAST NAME */}
            <TextField name="familyName" value={personFormData.familyName} onChange={handlePersonChange} label="Last name" placeholder="Last name" autoComplete="new-password" />
          </div>
          {/* EMAIL */}
          <div className={style.formRow}>
            <TextField
              name="email"
              value={personFormData.email}
              onChange={handlePersonChange}
              label="Email"
              placeholder="Email"
              autoComplete="new-password"
              error={errors.email}
              leading={<img src={envelopeSimpleIcon} />}
            />
          </div>
          {/* PHONE */}
          <div className={style.formRow}>
            <TextField
              name="phoneNumber"
              value={personFormData.phoneNumber}
              onChange={handlePhoneNumberChange}
              label="Phone"
              placeholder="+1 (555) 000-0000"
              autoComplete="new-password"
              error={errors.phoneNumber}
              leading={
                <>
                  <Text variant="md" className={style.phoneLeadingText}>
                    US
                  </Text>
                  <img src={caretDownIcon} />
                </>
              }
            />
          </div>
          {/* BIRTH DATE */}
          <DatePicker
            date={personFormData.birthDate ? new Date(personFormData.birthDate) : undefined}
            onChange={handleBirthDateChange}
            popperPlacement="bottom-start"
            dateFormat="yyyy-MM-dd"
            label="Birth date"
            placeholder="Birth date"
          />
          {/* GENDER */}
          <div>
            <Text variant="sm" className={style.labelSelect}>
              Gender
            </Text>
            <CarePlanAutocomplete
              options={genderTypeList}
              value={personFormData.genderType || null} // Ensure value is not undefined
              getOptionLabel={(genderType) => normalizeEnumName(genderType)}
              onChange={handleGenderTypeChange}
              PaperComponent={CarePlanAutocompletePaper}
              PopperComponent={CarePlanAutocompletePopper}
              renderInput={(params) => <CarePlanTextField {...params} placeholder="Select" />}
              renderOption={(props, option, state) => (
                <li {...props} key={option as GenderType}>
                  <div>{normalizeEnumName(option)}</div>
                </li>
              )}
            />
          </div>
        </div>
        <Text variant="xl">Care</Text>
        <div>
          {/* CARE PLAN */}
          <Text variant="sm" className={style.labelSelect}>
            Assign care plan
          </Text>
          <div>
            <CarePlanAutocomplete
              options={Object.values(carePlanById)}
              value={selectedCarePlan || null} // Ensure value is not undefined
              onChange={handleCarePlanChange}
              getOptionLabel={(option) => (option as CarePlan).name || ''}
              isOptionEqualToValue={(option, value) => (option as CarePlan).id === (value as CarePlan).id}
              PaperComponent={CarePlanAutocompletePaper}
              PopperComponent={CarePlanAutocompletePopper}
              renderInput={(params) => <CarePlanTextField name="careplan" {...params} placeholder="Select" />}
              renderOption={(props, option, state) => (
                <li {...props} key={(option as CarePlan).id}>
                  <div>{(option as CarePlan).name?.toUpperCase()}</div>
                </li>
              )}
            />
          </div>
        </div>
        <Button type="submit" variant="contained" disabled={isSubmitBtnDisabled} className={style.button}>
          Add new patient
        </Button>
        {!!apiError && (
          <Text variant="sm" className={style.apiError}>
            {apiError}
          </Text>
        )}
        {!!successMessage && (
          <Text variant="sm" className={style.successMessage}>
            {successMessage}
          </Text>
        )}
      </form>
    </NavSideBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    breadCrumbs: getBreadcrumbsSelector(state),
    carePlanById: getCarePlanByIdSelector(state),
    isLoadingPostNewPatient: isLoadingPostNewPatientSelector(state),
    hasPatientCreatePermission: hasPatientCreatePermissionSelector(state)
  }),
  {
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }),
    fetchCarePlans: fetchCarePlansThunk,
    postNewPatient: postNewPatientThunk
  }
);

export default compose(connectRedux)(NewPatient);
