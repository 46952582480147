import { PersonSleepProgram, SleepSchedule } from '../../../../redux/person/personTypes';
import { DailySleepLog, SleepQualityType } from '../../../../redux/activity/activityTypes';
import React from 'react';
import moment, { Moment } from 'moment-timezone';
import { findInRange, isLessonNoSurveys } from '../../../../utils/content';
import { FeedItem, ProgramType, SurveyScoreResponse } from '../../../../redux/content/contentTypes';
import { adjustToWithinDayOfAnchor, asNightDateMoment, textToMoment, tsAsTimeSince } from '../../../../utils/time';
import { CardHeader, Grid } from '@material-ui/core';
import style from './UserFlags.scss';
import FlagIcon from '@material-ui/icons/Flag';
import ErrorIcon from '@material-ui/icons/Error';
import { createScheduleLogMapping } from '../../../../utils/sleepSchedule';
import { TypographyProps } from '@material-ui/core/Typography';

const createFlags = (flags?: Nullable<string[]>): JSX.Element => (
  <React.Fragment>
    {flags?.map((flag, index) => (
      <Grid key={index} item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <CardHeader avatar={<FlagIcon style={{ color: 'red' }} />} className={style.cardHeader} titleTypographyProps={cardHeaderTypographyProps} title={flag} />
          </Grid>
        </Grid>
      </Grid>
    ))}
  </React.Fragment>
);

const createWarningLabels = (warnings?: Nullable<string[]>, header?: Nullable<string>): JSX.Element => (
  <React.Fragment>
    {!!warnings?.length && !!header && createFlags([header])}
    {!!warnings?.length &&
      warnings.map((label, index) => (
        <Grid key={index} item xs={12}>
          <Grid container>
            {!!header && <Grid item xs={1} />}
            <Grid item xs={header ? 11 : 12}>
              <CardHeader avatar={<ErrorIcon style={{ color: '#F4BC2B' }} />} className={style.cardHeader} titleTypographyProps={cardHeaderTypographyProps} title={label} />
            </Grid>
          </Grid>
        </Grid>
      ))}
  </React.Fragment>
);

const poorSleepQualities: SleepQualityType[] = [SleepQualityType.VERY_POOR, SleepQualityType.POOR];

interface Warnings {
  assignSchedule?: Nullable<string[]>;
  other?: Nullable<string[]>;
  flags?: Nullable<string[]>;
}

interface Props {
  latestTz?: Nullable<string>;
  historicSleepLogs?: Nullable<DailySleepLog[]>;
  historicSurveyResponses?: Nullable<SurveyScoreResponse[]>;
  feedItems?: Nullable<FeedItem[]>;
  historicSleepSchedules?: Nullable<SleepSchedule[]>;
  personProgram?: Nullable<PersonSleepProgram>;
}

const cardHeaderTypographyProps: TypographyProps = {
  align: 'left',
  style: {
    fontSize: '15px'
  }
};

export const UserFlags: React.FC<Props> = React.memo(({ latestTz, historicSleepLogs, historicSurveyResponses, feedItems, historicSleepSchedules, personProgram }) => {
  const isPoorQualitySleep = (sleepQuality?: Nullable<SleepQualityType>): boolean => !!sleepQuality && poorSleepQualities.includes(sleepQuality);

  const warningLabels = (): Warnings => {
    const warnings: Warnings = {
      assignSchedule: [],
      other: [],
      flags: []
    };
    const currentDate: Nullable<Moment> = latestTz ? moment().tz(latestTz) : undefined;
    const lastWeek: Nullable<Moment> = currentDate?.clone().startOf('day').subtract(7, 'days');
    const threeDaysAgo: Nullable<Moment> = lastWeek?.clone().add(4, 'days');
    const thisWeeksLogs: DailySleepLog[] =
      lastWeek && currentDate
        ? findInRange(
            lastWeek,
            currentDate,
            (x) => x?.bedTime,
            (x) => x?.timeZone,
            historicSleepLogs
          )
        : [];
    const lastThreeDaysSleepLogs: DailySleepLog[] =
      threeDaysAgo && currentDate
        ? findInRange(
            threeDaysAgo,
            currentDate,
            (x) => x?.bedTime,
            (x) => x?.timeZone,
            thisWeeksLogs
          )
        : [];
    const thisWeeksSleepNeedsSurveys: SurveyScoreResponse[] =
      lastWeek && currentDate && latestTz
        ? findInRange(
            lastWeek,
            currentDate,
            (x) => x?.surveyDate,
            () => latestTz,
            historicSurveyResponses,
            'YYYY-MM-DD'
          )
        : [];
    const lastThreeDaysLessons: FeedItem[] =
      threeDaysAgo && currentDate && latestTz
        ? findInRange(
            threeDaysAgo,
            currentDate,
            (x) => x?.completedDate,
            () => latestTz,
            feedItems?.filter((feedItem) => isLessonNoSurveys(feedItem))
          )
        : [];
    const firstSleepScheduleAssignment: boolean = !historicSleepSchedules || !historicSleepSchedules.length;

    const onboardingDateTs: Nullable<string> = personProgram?.onboardingDateTs;
    const programTrack: Nullable<ProgramType> = personProgram?.programType;
    const onboardingDate: Nullable<Moment> = textToMoment(onboardingDateTs, latestTz);
    if (programTrack === ProgramType.CBTI_NATIVE && onboardingDate) {
      warnings?.flags?.push(`Onboarding complete ${tsAsTimeSince(onboardingDate)}/on ${onboardingDate.format('dddd, MMMM Do, h:mm:ss A z')}`);
    }

    const daysSinceStart: number = personProgram?.daysSinceOnboarding || 0;
    if (daysSinceStart >= 7) {
      const week: number = Math.floor(daysSinceStart / 7);
      warnings?.flags?.push(`${moment.localeData().ordinal(week)} WEEK COMPLETE`);
    } else if (daysSinceStart >= 6) {
      warnings?.flags?.push('DAY 6 COMPLETE');
    }

    const scheduleStartDateTs: Nullable<string> = historicSleepSchedules?.[0]?.startDate;
    const scheduleStartDate: Nullable<Moment> = textToMoment(scheduleStartDateTs, latestTz);

    const startDate: Nullable<Moment> = firstSleepScheduleAssignment ? onboardingDate : scheduleStartDate;
    const startOfStartDate: Nullable<Moment> = startDate?.clone()?.startOf('day');
    if (startOfStartDate && currentDate && currentDate?.diff(startOfStartDate, 'd') >= 7) {
      if (!thisWeeksSleepNeedsSurveys?.length) {
        warnings?.assignSchedule?.push(`SLEEP NEEDS SURVEY RESPONSE OVERDUE (${currentDate.diff(startOfStartDate, 'days') - 7} DAYS OVERDUE)`);
      }
      if (thisWeeksLogs?.length < 4) {
        warnings?.assignSchedule?.push('TOO FEW SLEEP LOGS SUBMITTED FOR WEEK (FEWER THAN 4)');
      }
    }

    if (daysSinceStart > 3) {
      if (!lastThreeDaysSleepLogs.length) {
        warnings?.other?.push('>3 DAYS SINCE LAST COMPLETED SLEEP LOG');
      }
      if (!lastThreeDaysLessons.length) {
        warnings?.other?.push('>3 DAYS SINCE LAST COMPLETED LESSON');
      }
    }

    if (historicSleepLogs && historicSleepLogs.length >= 3) {
      if (isPoorQualitySleep(historicSleepLogs[0].sleepQuality) && isPoorQualitySleep(historicSleepLogs[1].sleepQuality) && isPoorQualitySleep(historicSleepLogs[2].sleepQuality)) {
        warnings?.other?.push('3 DAYS IN A ROW OF POOR/VERY POOR QUALITY SLEEP');
      }
      if (historicSleepSchedules) {
        let anyOfLastThreeBedTimesInRange: boolean = false;
        let anyOfLastThreeWakeTimesInRange: boolean = false;
        let count: number = 0;
        const scheduleLogMapping: Record<string, DailySleepLog[]> = createScheduleLogMapping(historicSleepLogs, latestTz, historicSleepSchedules);
        for (let i = 0; i < historicSleepSchedules.length && count < 3; i++) {
          const sleepSchedule: SleepSchedule = historicSleepSchedules[i];
          const sleepLogs: DailySleepLog[] = !sleepSchedule?.id ? [] : scheduleLogMapping[sleepSchedule.id];
          for (let j = 0; j < sleepLogs.length && count < 3; j++) {
            const sleepLog: DailySleepLog = sleepLogs[j];
            const unadjustedBedTime: Nullable<Moment> = textToMoment(sleepLog.bedTime, sleepLog.timeZone);
            const bedTime: Nullable<Moment> = asNightDateMoment(unadjustedBedTime?.clone())?.startOf('minute');
            const unadjustedWakeTime: Nullable<Moment> = textToMoment(sleepLog.wakeTime, sleepLog.timeZone);
            let wakeTime: Nullable<Moment> = unadjustedWakeTime?.clone()?.startOf('minute');
            if (wakeTime?.isBefore(bedTime)) {
              wakeTime = wakeTime?.add(1, 'days');
            }

            const scheduledSleepTime: Nullable<Moment> = textToMoment(sleepSchedule.sleepTime, sleepLog.timeZone, 'HH:mm:ss');
            const adjustedScheduledSleepTime: Nullable<Moment> = adjustToWithinDayOfAnchor(scheduledSleepTime, bedTime)?.startOf('minute');

            const scheduledWakeTime: Nullable<Moment> = textToMoment(sleepSchedule.wakeTime, sleepLog.timeZone, 'HH:mm:ss');
            let adjustedScheduledWakeTimeDate: Nullable<Moment> = adjustToWithinDayOfAnchor(scheduledWakeTime, wakeTime)?.startOf('minute');
            if (adjustedScheduledWakeTimeDate?.isBefore(adjustedScheduledSleepTime)) {
              adjustedScheduledWakeTimeDate = adjustedScheduledWakeTimeDate.add(1, 'days');
            }

            // Verify times in range with 20-minute grace period
            anyOfLastThreeBedTimesInRange =
              anyOfLastThreeBedTimesInRange || (!!adjustedScheduledSleepTime && !!bedTime && bedTime.add(20, 'minutes').isSameOrAfter(adjustedScheduledSleepTime));
            anyOfLastThreeWakeTimesInRange =
              anyOfLastThreeWakeTimesInRange || (!!adjustedScheduledWakeTimeDate && !!wakeTime && wakeTime.subtract(20, 'minutes').isSameOrBefore(adjustedScheduledWakeTimeDate));
            count++;
          }
        }
        if (count >= 3) {
          if (!anyOfLastThreeBedTimesInRange) {
            warnings?.other?.push('BED TIME OUTSIDE OF (BEFORE) SLEEP SCHEDULE > 2 DAYS IN A ROW');
          }
          if (!anyOfLastThreeWakeTimesInRange) {
            warnings?.other?.push('WAKE TIME OUTSIDE OF (AFTER) SLEEP SCHEDULE > 2 DAYS IN A ROW');
          }
        }
      }
    }
    return warnings;
  };

  const warnings: Warnings = warningLabels();

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {createFlags(warnings.flags)}
      {createWarningLabels(
        warnings.assignSchedule,
        `ASSIGN ${moment.localeData().ordinal((historicSleepSchedules?.filter((x) => !x.createdByUser)?.length || 0) + 1)} SLEEP SCHEDULE`
      )}
      {createWarningLabels(warnings.other)}
    </Grid>
  );
});
