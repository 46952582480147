import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ReduxState } from '../../redux/types';
import style from './CareGroup.scss';
import { BreadCrumbData } from '../../redux/common/types';
import NavSideBarContentWrapper from '../../components/NavSideBarContentWrapper/NavSideBarContentWrapper';

interface Props {
  breadCrumbs: BreadCrumbData[];
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
  startLogout: () => void;
}

const CareGroup: React.FC<Props> = ({ appendBreadCrumbs }) => {
  return (
    <NavSideBarContentWrapper headerText="Care Group">
      <div className={style.mainContainer}></div>
    </NavSideBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({}),
  (dispatch: Function) => ({})
);

export default compose(connectRedux)(CareGroup);
