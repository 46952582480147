import React, { BaseSyntheticEvent, Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ReduxState } from '../../redux/types';
import {
  AccountStatus,
  DeviceGroup,
  FlagTaskStatus,
  Person,
  PersonOrder,
  PersonOverview,
  PersonOverviewFilters,
  PersonSetting,
  PersonSleepProgram,
  SensorPerson
} from '../../redux/person/personTypes';
import style from './UserOverview.scss';
import {
  DEFAULT_ROWS_PER_PAGE,
  getCreateAdHocBatchContentThunk,
  getPersonOverviewCountThunk,
  getPersonOverviewThunk,
  sendPushNotificationsThunk,
  START_PAGE
} from '../../redux/person/personThunks';
import {
  getAdHocErrorSelector,
  getBatchLoadingSelector,
  getBatchSuccessSelector,
  getClientDeviceById,
  getClientDevicePushInfoByIdSelector,
  getDeviceShipmentByIdSelector,
  getPageLoadingSelector,
  getPersonByIdSelector,
  getPersonErrorStateSelector,
  getPersonLoadingStateSelector,
  getPersonOrderByIdSelector,
  getPersonOverviewByIdSelector,
  getPersonOverviewCountSelector,
  getPersonOverviewListSelector,
  getPersonSettingByIdSelector,
  getPersonSleepProgramByIdSelector,
  getPersonSuccessStateSelector,
  getSensorPersonByIdSelector,
  getSnsErrorSelector
} from '../../redux/person/personSelector';
import {
  accountStatusList,
  AdHocContentRequest,
  DeviceContent,
  DeviceContentType,
  deviceGroupList,
  operatingSystemTypesList,
  PersonWorkflow,
  ProgramSubType,
  subTypeList,
  WorkflowTaskTemplate,
  WorkflowTemplate
} from '../../redux/content/contentTypes';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircleIndicator } from '../../components/CircleIndicator/CircleIndicator';
import { formatTimestamp, textToMoment, tsAsTimeSince } from '../../utils/time';
import { getDeviceStateOverviewThunk, postUnlinkDeviceThunk } from '../../redux/device/deviceThunks';
import {
  getDeviceErrorSelector,
  getDeviceIsLoadingSelector,
  getDeviceSuccessSelector,
  selectDeviceByIdSelector,
  selectDeviceStateByIdSelector,
  selectLocationByIdSelector
} from '../../redux/device/deviceSelectors';
import { Device, DeviceShipment, DeviceStateEntry, Location } from '../../redux/device/deviceTypes';
import { getAllFlagContentThunk, getDeviceContentThunk, getFlagContentThunk, saveFlagContentThunk } from '../../redux/content/contentThunks';
import {
  getContentErrorSelector,
  getContentIsLoadingSelector,
  getContentSuccessSelector,
  getDeviceContentByTypeSelector,
  getFlagByIdSelector,
  getFlagsByPersonIdSelector,
  getSaveSuccessfulSelector
} from '../../redux/content/contentSelectors';
import { contentDisplayName, normalizeEnumName, sortedMutableFlagTasks } from '../../utils/content';
import { ContentSubmitter } from '../ContentSubmitter/ContentSubmitter';
import { getQueryParamObject, goTo } from '../../utils/util';
import { ClientDevice, ClientDevicePushInfo, OperatingSystemType } from '../../redux/clientDevice/clientDeviceTypes';
import { UnlinkButton } from '../../components/UnlinkButton/UnlinkButton';
import { getActiveRecord, hasActiveRecord } from '../../utils/createFrontEndError';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';
import { BreadCrumbData, BreadCrumbType, Error, SelectOption } from '../../redux/common/types';
import { getClearDeviceResponseStatus } from '../../redux/device/deviceActions';
import {
  DataGrid,
  GridColDef,
  GridColType,
  GridFilterItem,
  GridFilterModel,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
  GridSelectionModel,
  GridSortDirection,
  GridSortModel,
  GridValueFormatterParams,
  GridValueGetterParams,
  MuiEvent
} from '@mui/x-data-grid';
import { Box, Button, Chip, CircularProgress, Container, Dialog, IconButton, Link, MenuItem, MuiThemeProvider, TextField } from '@material-ui/core';
import { GridToolbar } from '../../components/GridToolbar/GridToolbar';
import { debounce, isEqual } from 'lodash';
import { Header } from '../../components/Header/Header';
import { defaultTheme } from '../../utils/styles';
import { Moment } from 'moment-timezone';
import { ContentTile, Subtitle } from '../../components/ContentTile/ContentTile';
import { FlagDetails } from '../FlagDetails/FlagDetails';
import { getClearResponseStatusAction } from '../../redux/content/contentActions';
import { defaultAllUsersBreadCrumb, defaultAllUsersBreadCrumbs, defaultDialogTransition, getIconForWorkflow } from '../../utils/breadcrumbs';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '../../redux/oauth/oauthSelectors';
import ClearIcon from '@material-ui/icons/Clear';
import { clearPersonResponseStatusAction } from '../../redux/person/personActions';

const getPathPrefix = (location: any): string => {
  const path: string = location?.pathname;
  const paramIndex: number = path?.indexOf('?');
  return paramIndex !== -1 ? path?.substring(0, paramIndex) : path;
};

const isFlagPath = (location: any): boolean => getPathPrefix(location) !== '/allUsers';

const invalidFilterItem = (filter: GridFilterItem) => !filter?.value && filter?.operatorValue !== 'isEmpty' && filter?.operatorValue !== 'isNotEmpty';

const getColumn = (gridColDefs: GridColDef[], columnField: string): Nullable<GridColDef> => {
  return gridColDefs?.find((col) => col.field === columnField);
};

const getStandardFilterColumn = (gridColDefs: GridColDef[], columnField: string): Nullable<GridColDef> => {
  if (columnField !== 'activeFlags' && columnField !== 'taskStatus') {
    return getColumn(gridColDefs, columnField);
  }
};

const getTextFilterCols = (gridColDefs: GridColDef[], includeUnfilterable?: Nullable<boolean>): GridColDef[] =>
  getFilterCols(gridColDefs, (x) => !x || x === 'string', includeUnfilterable);
const getNonTextFilterCols = (gridColDefs: GridColDef[], includeUnfilterable?: Nullable<boolean>): GridColDef[] =>
  getFilterCols(gridColDefs, (x) => !!x && x !== 'string', includeUnfilterable);
const getFilterCols = (gridColDefs: GridColDef[], colFunc: (x: GridColType) => boolean, includeUnfilterable?: Nullable<boolean>): GridColDef[] =>
  gridColDefs?.filter((col) => (includeUnfilterable || col.filterable) && (!col.type || colFunc(col?.type)));

const getActiveTextFilters = (gridColDefs: GridColDef[], gridFilterMode: GridFilterModel): GridFilterItem[] =>
  getActiveFilters(gridColDefs, gridFilterMode, (x) => !x || x === 'string');
const getActiveNonTextFilters = (gridColDefs: GridColDef[], gridFilterMode: GridFilterModel): GridFilterItem[] =>
  getActiveFilters(gridColDefs, gridFilterMode, (x) => !!x && x !== 'string');

const getActiveFilters = (gridColDefs: GridColDef[], gridFilterMode: GridFilterModel, colFunc: (x: Nullable<GridColType>) => boolean): GridFilterItem[] => {
  return gridFilterMode?.items?.filter((filter) => {
    if (invalidFilterItem(filter)) {
      return false;
    }
    if (filter.columnField) {
      const column: Nullable<GridColDef> = getStandardFilterColumn(gridColDefs, filter.columnField);
      return !!column && colFunc(column?.type);
    }
  });
};

const handleFilterModelUpdate = (
  newFilterMode: GridFilterModel,
  setLocalFilter: Dispatch<SetStateAction<GridFilterModel>>,
  setInternalFilter: Dispatch<SetStateAction<GridFilterModel>>,
  location: any
): void => {
  setLocalFilter(() => newFilterMode);
  setInternalFilter(() => {
    if (isFlagPath(location)) {
      const taskStatusFilter: Nullable<GridFilterItem> = newFilterMode?.items?.find((filter) => filter.columnField === 'taskStatus');
      return { items: taskStatusFilter ? [taskStatusFilter] : [] };
    } else {
      const flagIdFilter: Nullable<GridFilterItem> = newFilterMode?.items?.find((filter) => filter.columnField === 'activeFlags');
      if (flagIdFilter) {
        return { items: [flagIdFilter] };
      } else {
        const standardFilters: GridFilterItem[] = newFilterMode?.items?.filter((item) => item.columnField !== 'search' && !!item.value);
        return { items: standardFilters?.[0] ? [standardFilters?.[0]] : [] };
      }
    }
  });
};

const debounced: Function = debounce(
  (
    searchValue: string,
    gridColDefs: GridColDef[],
    currentFilterModel: GridFilterModel,
    setLocalFilter: Dispatch<SetStateAction<GridFilterModel>>,
    setInternalFilter: Dispatch<SetStateAction<GridFilterModel>>,
    location: any
  ) => {
    const filterModelFromUrl: GridFilterModel = { items: [] };
    getActiveTextFilters(gridColDefs, currentFilterModel)?.forEach((nonTextFilter) => {
      filterModelFromUrl.items.push({ columnField: nonTextFilter.columnField, value: nonTextFilter.value, operatorValue: nonTextFilter.operatorValue });
    });
    getTextFilterCols(gridColDefs)?.forEach((col) => {
      filterModelFromUrl.items.push({ columnField: col.field, value: searchValue, operatorValue: 'contains' });
    });
    filterModelFromUrl.items.push({ columnField: 'search', value: searchValue, operatorValue: 'contains' });
    handleFilterModelUpdate(filterModelFromUrl, setLocalFilter, setInternalFilter, location);
  },
  500
);

const handleQuickSearch = (
  searchValue: string,
  setQuickSearch: Dispatch<SetStateAction<string>>,
  gridColDefs: GridColDef[],
  currentFilterModel: GridFilterModel,
  setLocalFilter: Dispatch<SetStateAction<GridFilterModel>>,
  setInternalFilter: Dispatch<SetStateAction<GridFilterModel>>,
  location: any
) => {
  setQuickSearch(() => searchValue);
  debounced(searchValue, gridColDefs, currentFilterModel, setLocalFilter, setInternalFilter, location);
};

const assembleTasks = (tasks: WorkflowTaskTemplate[]): Subtitle[] =>
  tasks
    ?.map((task) => {
      return { position: task.rank, text: task.title };
    })
    .sort((t1, t2) => t1.position - t2.position);

const quickSelectOptionMapping: Map<string, SelectOption> = new Map<string, SelectOption>([
  [JSON.stringify({ columnField: 'accountStatus', value: AccountStatus.ACTIVE, operatorValue: 'is' }), { value: 1, label: 'Account Status = "Active"' }],
  [JSON.stringify({ columnField: 'accountStatus', value: AccountStatus.ACTIVE_PROGRAM, operatorValue: 'is' }), { value: 2, label: 'Account Status = "Active Program"' }],
  [JSON.stringify({ columnField: 'accountStatus', value: AccountStatus.ACTIVE_EXTENSION, operatorValue: 'is' }), { value: 3, label: 'Account Status = "Active Extension"' }],
  [JSON.stringify({ columnField: 'accountStatus', value: AccountStatus.ACTIVE_14_DAY_TRIAL, operatorValue: 'is' }), { value: 4, label: 'Account Status = "Active 14 Day Trial"' }],
  [
    JSON.stringify({ columnField: 'accountStatus', value: AccountStatus.ACTIVE_14_DAY_TRIAL_PAID, operatorValue: 'is' }),
    { value: 5, label: 'Account Status = "Active 14 Day Trial Paid"' }
  ],
  [JSON.stringify({ columnField: 'accountStatus', value: AccountStatus.ACTIVE_RELAPSE_HIGH, operatorValue: 'is' }), { value: 6, label: 'Account Status = "Active Relapse High"' }],
  [JSON.stringify({ columnField: 'accountStatus', value: AccountStatus.PAUSED, operatorValue: 'is' }), { value: 7, label: 'Account Status = "Paused"' }],
  [JSON.stringify({ columnField: 'accountStatus', value: AccountStatus.INACTIVE, operatorValue: 'is' }), { value: 8, label: 'Account Status = "Inactive"' }],
  [JSON.stringify({ columnField: 'accountStatus', value: AccountStatus.ACTIVE_RELAPSE_LOW, operatorValue: 'is' }), { value: 9, label: 'Account Status = "Active Relapse Low"' }],
  [JSON.stringify({ columnField: 'deviceGroup', value: DeviceGroup.CLINICAL_TRIAL, operatorValue: 'is' }), { value: 14, label: 'Device Group = "Clinical Trial"' }],
  [JSON.stringify({ columnField: 'position', value: '35', operatorValue: '>=' }), { value: 10, label: 'Content Day >= 35' }],
  [JSON.stringify({ columnField: 'daysSinceOnboarding', value: '42', operatorValue: '=' }), { value: 9, label: 'Days Since Onboarding = 42' }],
  [JSON.stringify({ columnField: 'daysSinceOnboarding', value: '42', operatorValue: '>=' }), { value: 10, label: 'Days Since Onboarding >= 42' }],
  [JSON.stringify({ columnField: 'daysSinceOnboarding', value: '53', operatorValue: '=' }), { value: 11, label: 'Days Since Onboarding = 53' }],
  [JSON.stringify({ columnField: 'daysSinceOnboarding', value: '56', operatorValue: '=' }), { value: 12, label: 'Days Since Onboarding = 56' }]
]);

interface UserRow {
  id: string;
  esId?: Nullable<string>;
  deviceNumber?: Nullable<number>;
  email?: Nullable<string>;
  name?: Nullable<string>;
  accountStatus?: Nullable<AccountStatus>;
  contentTrack?: Nullable<ProgramSubType>;
  position?: Nullable<number>;
  sleepProgram?: Nullable<boolean>;
  connectivityStatus?: Nullable<string>;
  connectivityStatusTimestamp?: Nullable<string>;
  healthStatus?: Nullable<string>;
  healthStatusTimestamp?: Nullable<string>;
  deviceId?: Nullable<string>;
  deviceGroup?: Nullable<DeviceGroup>;
  pushEnabled?: Nullable<string>;
  daysSincePurchased?: Nullable<number>;
  daysSinceShipped?: Nullable<number>;
  daysSinceReceived?: Nullable<number>;
  sensorId?: Nullable<string>;
  mobileAppVersion?: Nullable<string>;
  mobileAppBuild?: Nullable<string>;
  osType?: Nullable<OperatingSystemType>;
  osVersion?: Nullable<string>;
  daysSinceOnboarding?: Nullable<number>;
}

interface Props {
  getAllFlagContent: () => void;
  getDeviceStateOverview: () => void;
  getDeviceContent: () => void;
  getPersonOverview: (personOverviewFilters: PersonOverviewFilters) => void;
  getPersonOverviewCount: (personOverviewFilters: PersonOverviewFilters) => void;
  sendPushNotifications: (personIds: string[], title: string, body: string) => void;
  sendAdHocContent: (adHocContentRequest: AdHocContentRequest, proceedToPushNotification?: Nullable<boolean>) => void;
  postUnlinkDevice: (deviceId: string, personId: string, sensorId: string) => void;
  getFlag: (flagId: string) => void;
  saveFlag: (flag: WorkflowTemplate) => void;
  clearPersonSubmissionResponse: () => void;
  clearDeviceSubmissionResponse: () => void;
  clearContentSubmissionResponse: () => void;
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
  personById: Record<string, Person>;
  personSettingById: Record<string, PersonSetting>;
  personOrderById: Record<string, PersonOrder>;
  personSleepProgramById: Record<string, PersonSleepProgram>;
  sensorPersonById: Record<string, SensorPerson>;
  clientDevicePushInfoById: Record<string, ClientDevicePushInfo>;
  personOverviewById: Record<string, PersonOverview>;
  personOverviewList: Array<string>;
  personOverviewCount: number;
  deviceStateById: Record<string, DeviceStateEntry>;
  deviceById: Record<string, Device>;
  locationById: Record<string, Location>;
  deviceShipmentById: Record<string, DeviceShipment>;
  clientDeviceById: Record<string, ClientDevice>;
  flagById: Record<string, WorkflowTemplate>;
  flagsByPersonId: Record<string, Set<string>>;
  isLoading: Record<string, boolean>;
  isLoadingDevice: Record<string, boolean>;
  isLoadingContent: Record<string, boolean>;
  error: Record<string, Error>;
  errorDevice: Record<string, Error>;
  errorContent: Record<string, Error>;
  success: Record<string, boolean>;
  successDevice: Record<string, boolean>;
  successContent: Record<string, boolean>;
  saveSuccessful?: Nullable<boolean>;
  snsError?: Nullable<string>;
  adHocError?: Nullable<string>;
  batchLoading: boolean;
  batchSuccess?: Nullable<boolean>;
  pageLoading: boolean;
  deviceContentByType: Record<DeviceContentType, DeviceContent[]>;
  breadCrumbs: BreadCrumbData[];
}

export const UserOverview: React.FC<Props> = ({
  getAllFlagContent,
  getDeviceStateOverview,
  getDeviceContent,
  getPersonOverview,
  getPersonOverviewCount,
  sendPushNotifications,
  sendAdHocContent,
  postUnlinkDevice,
  getFlag,
  saveFlag,
  clearPersonSubmissionResponse,
  clearDeviceSubmissionResponse,
  clearContentSubmissionResponse,
  appendBreadCrumbs,
  personById,
  personSettingById,
  personOrderById,
  personSleepProgramById,
  sensorPersonById,
  clientDevicePushInfoById,
  personOverviewById,
  personOverviewList,
  personOverviewCount,
  deviceStateById,
  deviceById,
  locationById,
  deviceShipmentById,
  clientDeviceById,
  flagById,
  flagsByPersonId,
  isLoading,
  isLoadingDevice,
  isLoadingContent,
  error,
  errorDevice,
  errorContent,
  success,
  successDevice,
  successContent,
  saveSuccessful,
  snsError,
  adHocError,
  batchLoading,
  batchSuccess,
  pageLoading,
  deviceContentByType,
  breadCrumbs
}) => {
  const location: any = useLocation();

  const [quickSelectSelected, setQuickSelectSelected] = useState<any>('');
  const [quickSearch, setQuickSearch] = useState<string>('');
  const [page, setPage] = useState<number>(START_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [displaySns, setDisplaySns] = useState<boolean>(false);
  const [displayAdHoc, setDisplayAdHoc] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  // Local copy of filter model containing all active filters for reference since free grid version only allows one active filter to be used internally
  const [localFilterModel, setLocalFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [personOverviewLiveCount, setPersonOverviewLiveCount] = useState<number>(0);
  const [url, setUrl] = useState<string>(location?.pathname);
  const [flagOptions, setFlagOptions] = useState<SelectOption[]>([]);
  const [flagOptionIndex, setFlagOptionIndex] = useState<number>(-1);
  const [initialized, setInitialized] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    getAllFlagContent();
    getDeviceStateOverview();
    getDeviceContent();
  }, []);

  useEffect(() => {
    if (initialized) {
      setOpenDialog(() => false);
      fetchPersonOverview(true);
    }
    const flagOptions: SelectOption[] = Object.values(flagById)
      ?.sort((f1, f2) => f1.name.localeCompare(f2.name))
      ?.map((flag) => {
        const selectOption: SelectOption = {
          value: url.replace(/activeFlags=([^&]+)/, `activeFlags=${flag.id}`),
          label: flag.name
        };
        return selectOption;
      });
    setFlagOptions(() => flagOptions);
  }, [flagById]);

  useEffect(() => {
    if (initialized) {
      navigate(url, { replace: true });
    }
  }, [url]);

  useEffect(() => {
    setFlagOptionIndex(() => flagOptions.findIndex((flag) => flag.label === flagById?.[getFlagIdSearched()]?.name));
  }, [flagOptions, url]);

  useEffect(() => {
    const urlParams: URLSearchParams = new URLSearchParams(location?.search);
    const filterModelFromUrl: GridFilterModel = { items: [] };
    getNonTextFilterCols(columns, true)?.forEach((col) => {
      const filterValue: Nullable<string> = urlParams.get(col.field);
      if (filterValue) {
        const filterOp: string = urlParams.get(`${col.field}Op`) || '';
        filterModelFromUrl.items.push({ columnField: col.field, value: filterValue, operatorValue: filterOp });
      }
    });
    const search: string = urlParams.get('search') || '';
    if (search) {
      filterModelFromUrl.items.push({ columnField: 'search', value: search, operatorValue: 'contains' });
      getTextFilterCols(columns)?.forEach((col) => {
        filterModelFromUrl.items.push({ columnField: col.field, value: search, operatorValue: 'contains' });
      });
    } else {
      getTextFilterCols(columns)?.forEach((col) => {
        const filterValue: string = urlParams.get(col.field) || '';
        if (filterValue) {
          const filterOp: string = urlParams.get(`${col.field}Op`) || '';
          filterModelFromUrl.items.push({ columnField: col.field, value: filterValue, operatorValue: filterOp });
        }
      });
    }

    const sortField: string = urlParams.get('sortField') || '';
    const newSortModel: GridSortModel = [];
    if (sortField) {
      newSortModel.push({ field: sortField, sort: (urlParams.get('sort') || 'asc') as GridSortDirection });
    }
    if (!initialized || !isEqual(sortModel, newSortModel)) {
      setSortModel(() => newSortModel);
    }
    if (!initialized || !isEqual(localFilterModel, filterModelFromUrl)) {
      handleFilterModelUpdate(filterModelFromUrl, setLocalFilterModel, setFilterModel, location);
    }
  }, [location?.search]);

  useEffect(() => {
    if (initialized) {
      fetchPersonOverview(true);
      let matchedQuickSelect: Nullable<SelectOption> = undefined;
      for (let i = 0; i < localFilterModel?.items?.length; i++) {
        const filterItem: GridFilterItem = localFilterModel.items[i];
        const filterItemWithoutMetaData: GridFilterItem = {
          columnField: filterItem.columnField,
          value: filterItem.value,
          operatorValue: filterItem.operatorValue
        };
        const jsonString: string = JSON.stringify(filterItemWithoutMetaData);
        matchedQuickSelect = quickSelectOptionMapping.get(jsonString);
        if (matchedQuickSelect) {
          break;
        }
      }
      setQuickSelectSelected(() => (matchedQuickSelect ? matchedQuickSelect.value : ''));
    } else {
      setInitialized(() => true);
    }
  }, [localFilterModel, sortModel]);

  useEffect(() => {
    const expectedPage: number = Math.ceil(personOverviewList.length / rowsPerPage) - 1;
    if (page > expectedPage) {
      setPage(() => START_PAGE);
    }
  }, [personOverviewList, rowsPerPage]);

  useEffect(() => {
    setPersonOverviewLiveCount(() => personOverviewCount);
  }, [personOverviewCount]);

  useEffect(() => {
    const expectedLength: number = (page + 1) * rowsPerPage;
    if (expectedLength > personOverviewList.length && personOverviewList.length < personOverviewLiveCount) {
      fetchPersonOverview(false);
    }
  }, [page, rowsPerPage]);

  const makeQueryUrl = (): string => {
    let url: string = getPathPrefix(location);
    const flagIdFilter: Nullable<GridFilterItem> = localFilterModel?.items?.find((filter) => filter.value && filter.columnField === 'activeFlags');
    const flagTaskStatusFilter: Nullable<GridFilterItem> = flagIdFilter
      ? localFilterModel?.items?.find((filter) => filter.value && filter.columnField === 'taskStatus')
      : undefined;
    const nonTextFilters: GridFilterItem[] = getActiveNonTextFilters(columns, localFilterModel) || [];
    let textFilters: GridFilterItem[] = [];
    const searchFilter: Nullable<GridFilterItem> = localFilterModel?.items?.find((filter) => filter.columnField === 'search');
    if (!searchFilter) {
      textFilters = getActiveTextFilters(columns, localFilterModel) || [];
    }
    if (flagIdFilter || searchFilter || nonTextFilters.length || textFilters.length || sortModel?.[0]) {
      url += '?';
      if (flagIdFilter) {
        url += `${flagIdFilter.columnField}=${flagIdFilter.value}&${flagIdFilter.columnField}Op=${flagIdFilter?.operatorValue}`;
        if (flagTaskStatusFilter) {
          url += `&${flagTaskStatusFilter.columnField}=${flagTaskStatusFilter.value}&${flagTaskStatusFilter.columnField}Op=${flagTaskStatusFilter?.operatorValue}`;
        }
      }
      if (searchFilter) {
        url += flagIdFilter || searchFilter ? '&' : '';
        url += `${searchFilter.columnField}=${searchFilter.value}&${searchFilter.columnField}Op=${searchFilter?.operatorValue}`;
      }
      nonTextFilters?.forEach((filter, index) => {
        url += index > 0 || flagIdFilter || searchFilter ? '&' : '';
        url += `${filter.columnField}=${filter.value}&${filter.columnField}Op=${filter?.operatorValue}`;
      });
      textFilters?.forEach((filter, index) => {
        url += index > 0 || flagIdFilter || searchFilter || nonTextFilters.length ? '&' : '';
        url += `${filter.columnField}=${filter.value}&${filter.columnField}Op=${filter?.operatorValue}`;
      });
      if (sortModel?.[0]) {
        url += `${flagIdFilter || searchFilter || nonTextFilters.length || textFilters.length ? '&' : ''}sortField=${sortModel?.[0].field}&sort=${sortModel?.[0].sort}`;
      }
    }
    return url;
  };

  const getTitle = (includeIcon: boolean): string | JSX.Element => {
    const urlParams: URLSearchParams = new URLSearchParams(location?.search);
    const flagId: string = urlParams.get('activeFlags') || '';
    if (!isFlagPath(location)) {
      return 'All Users';
    }
    if (!flagId) {
      return 'Flagged Users';
    } else {
      const flag: WorkflowTemplate = flagById?.[flagId];
      const name: string = flag?.name;
      if (includeIcon) {
        return (
          <Box display={'flex'}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
              {getIconForWorkflow(flag?.workflowType, false)}
            </Box>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} ml={1}>
              <Box>{name}</Box>
            </Box>
          </Box>
        );
      } else {
        return name;
      }
    }
  };

  const fetchPersonOverview = (isInitial: boolean): void => {
    const flagIdFilter: Nullable<GridFilterItem> = localFilterModel?.items?.find((filter) => filter.value && filter.columnField === 'activeFlags');
    const flagTaskStatusFilter: Nullable<GridFilterItem> = flagIdFilter
      ? localFilterModel?.items?.find((filter) => filter.value && filter.columnField === 'taskStatus')
      : undefined;
    const nonTextFilters: GridFilterItem[] = getActiveNonTextFilters(columns, localFilterModel) || [];
    const textFilters: GridFilterItem[] = getActiveTextFilters(columns, localFilterModel) || [];
    const searchFilter: Nullable<GridFilterItem> = localFilterModel?.items?.find((filter) => filter.columnField === 'search');
    const matchAnyText: boolean = !!searchFilter;
    if (matchAnyText) {
      textFilters.forEach((textFilter) => {
        textFilter.operatorValue = 'contains';
        textFilter.value = searchFilter?.value;
      });
    }
    const personOverviewFilters: PersonOverviewFilters = {
      nonTextFilters: nonTextFilters || [],
      textFilters: textFilters || [],
      flagId: flagIdFilter || undefined,
      flagTaskStatus: flagTaskStatusFilter || undefined,
      matchAnyText: matchAnyText,
      gridSortItem: sortModel?.[0],
      limit: rowsPerPage,
      offset: isInitial ? 0 : page * rowsPerPage
    };
    if (isInitial) {
      setQuickSearch(() => searchFilter?.value || '');
      setUrl(() => makeQueryUrl());
      getPersonOverviewCount(personOverviewFilters);
    }
    getPersonOverview(personOverviewFilters);
  };

  const toggleDisplay = (showAdHoc?: Nullable<boolean>, showSns?: Nullable<boolean>): void => {
    setDisplayAdHoc(() => showAdHoc || false);
    setDisplaySns(() => showSns || false);
  };

  const updateFailed = (): boolean => !batchLoading && !batchSuccess && (!!adHocError || !!snsError);
  const updateSuccess = (): boolean => !batchLoading && !!batchSuccess && !adHocError && !snsError;

  const expandedSnsMenu = (
    adHocContentId: string,
    pushNotificationTitleState: [string, Dispatch<SetStateAction<string>>],
    pushNotificationBodyState: [string, Dispatch<SetStateAction<string>>],
    adHocPushNotificationTitleState: [string, Dispatch<SetStateAction<string>>],
    adHocPushNotificationBodyState: [string, Dispatch<SetStateAction<string>>],
    onSelectContent: (deviceContent: Nullable<DeviceContent>) => void,
    onCreateAdHocContent: () => Promise<any>,
    onSendPushNotification: () => Promise<any>
  ): JSX.Element => {
    return (
      <React.Fragment>
        {displaySns && (
          <div className={style.pane}>
            <div className={style.paneArea}>
              <div>Title</div>
              <input
                className={style.paneInput}
                type="text"
                maxLength={65}
                placeholder="Push Notification Title (Required, max: 65 chars)"
                value={pushNotificationTitleState[0]}
                onChange={(e) => pushNotificationTitleState[1](e.target.value)}
              />
            </div>
            <div className={style.paneArea}>
              <div>Body</div>
              <textarea
                className={style.paneInput}
                maxLength={240}
                placeholder="Push Notification Body (max: 240 chars)"
                value={pushNotificationBodyState[0]}
                onChange={(e) => pushNotificationBodyState[1](e.target.value)}
              />
            </div>
            <button className={style.buttonPane} onClick={() => onSendPushNotification()} disabled={!pushNotificationTitleState[0]}>
              Send Push Notification
            </button>
          </div>
        )}
        {displayAdHoc && (
          <div className={style.pane}>
            <div className={style.paneArea}>
              <div>Content</div>
              <select
                className={style.paneInput}
                defaultValue="Content (Required)"
                onChange={(e) => {
                  onSelectContent(deviceContentByType?.[DeviceContentType.AD_HOC_NATIVE]?.[e.target.selectedIndex - 1]);
                }}
              >
                <option value="Content (Required)" disabled>
                  Content (Required)
                </option>
                {deviceContentByType?.[DeviceContentType.AD_HOC_NATIVE]?.map((deviceContent) => (
                  <option key={deviceContent.id} value={deviceContent.id || ''}>
                    {contentDisplayName(deviceContent)}
                  </option>
                ))}
              </select>
              <div className={style.paneArea}>
                <div>Push Notification Title</div>
                <input
                  className={style.paneInput}
                  type="text"
                  maxLength={65}
                  placeholder="Push Notification Title (Optional)"
                  value={adHocPushNotificationTitleState[0]}
                  onChange={(e) => adHocPushNotificationTitleState[1](e.target.value)}
                />
              </div>
              <div className={style.paneArea}>
                <div>Push Notification Body</div>
                <textarea
                  className={style.paneInput}
                  maxLength={240}
                  placeholder="Push Notification Body (Optional)"
                  value={adHocPushNotificationBodyState[0]}
                  onChange={(e) => adHocPushNotificationBodyState[1](e.target.value)}
                />
              </div>
            </div>
            <button className={style.buttonPane} onClick={() => onCreateAdHocContent()} disabled={!adHocContentId}>
              Create Ad-Hoc Content
            </button>
          </div>
        )}
        {batchLoading && (
          <div className={style.pane}>
            <CircularProgress />
          </div>
        )}
      </React.Fragment>
    );
  };

  const getFlagIdSearched = (): string => {
    const flagIdSearched: Nullable<GridFilterItem> = localFilterModel?.items?.find((filterItem) => filterItem.columnField === 'activeFlags');
    return flagIdSearched?.value;
  };

  const rowClick = (url: string, state: any, event: BaseSyntheticEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    goTo(url, navigate, state).call(this);
  };

  const clearResponseStatuses = async () => {
    await clearPersonSubmissionResponse();
    await clearDeviceSubmissionResponse();
    await clearContentSubmissionResponse();
  };

  const buildRows = (): UserRow[] => {
    return personOverviewList.map((personId: string) => {
      const personOverview: PersonOverview = personOverviewById[personId];
      const person: Person = personById[personId];
      const deviceId: Nullable<string> = personOverview?.deviceId;
      const device: Nullable<Device> = deviceId ? deviceById?.[deviceId] : undefined;
      const deviceState: Nullable<DeviceStateEntry> = deviceId ? deviceStateById?.[deviceId] : undefined;
      const personProgram: Nullable<PersonSleepProgram> = personOverview?.personSleepProgramId ? personSleepProgramById?.[personOverview?.personSleepProgramId] : undefined;
      const personSetting: PersonSetting = personSettingById?.[personId];
      const clientDevicePushInfo: Nullable<ClientDevicePushInfo> = personOverview?.clientDevicePushInfoId
        ? clientDevicePushInfoById?.[personOverview?.clientDevicePushInfoId]
        : undefined;
      const personOrder: Nullable<PersonOrder> = personOverview?.latestPersonOrderId ? personOrderById?.[personOverview?.latestPersonOrderId] : undefined;
      const deviceShipment: Nullable<DeviceShipment> = personOverview?.latestDeviceShipmentId ? deviceShipmentById?.[personOverview?.latestDeviceShipmentId] : undefined;
      const sensorPerson: Nullable<SensorPerson> = personOverview?.sensorPersonId ? sensorPersonById?.[personOverview?.sensorPersonId] : undefined;
      const clientDevice: Nullable<ClientDevice> = personOverview?.latestClientDeviceId ? clientDeviceById?.[personOverview?.latestClientDeviceId] : undefined;
      const sensorId: Nullable<string> = sensorPerson?.sensorId;
      const userRow: UserRow = {
        id: personId,
        esId: person?.esId,
        deviceNumber: device?.deviceNumber,
        email: person?.email,
        name: `${person?.givenNames} ${person?.familyName}`,
        accountStatus: person?.accountStatus,
        contentTrack: personProgram?.programSubType,
        position: personProgram?.programStep,
        sleepProgram: !!person?.currentSleepSchedule,
        connectivityStatus: deviceState?.connectivityStatus,
        connectivityStatusTimestamp: formatTimestamp(deviceState?.connectivityStatusTimestamp),
        healthStatus: deviceState?.healthStatus,
        healthStatusTimestamp: formatTimestamp(deviceState?.healthStatusTimestamp),
        deviceId: personOverview?.deviceId,
        deviceGroup: personSetting?.deviceGroup,
        pushEnabled: clientDevicePushInfo ? 'YES' : 'NO',
        daysSincePurchased: personOrder?.daysSincePurchased,
        daysSinceShipped: deviceShipment?.daysSinceShipped,
        daysSinceReceived: deviceShipment?.daysSinceReceived,
        sensorId: sensorId,
        mobileAppVersion: clientDevice?.appVersion,
        mobileAppBuild: clientDevice?.appBuild,
        osType: clientDevice?.osType,
        osVersion: clientDevice?.osVersion,
        daysSinceOnboarding: personProgram?.daysSinceOnboarding
      };
      return userRow;
    });
  };

  const isUncompletedFlagDisplay = (): boolean => isFlagPath(location) && !!getFlagIdSearched() && getQueryParamObject(location?.search)?.taskStatus === FlagTaskStatus.UNCOMPLETED;

  const enumValueFormatter = (value: any): Nullable<string> => (value ? normalizeEnumName(value) : undefined);
  const enumFilterSelections = (enumVals: any[]) =>
    enumVals.map((val) => {
      return { value: val, label: normalizeEnumName(val) };
    });

  const columns: GridColDef[] = [
    { field: 'esId', headerName: 'ES ID', width: 150, filterable: !isFlagPath(location) },
    {
      field: 'deviceNumber',
      headerName: 'Device Number',
      width: 180,
      filterable: !isFlagPath(location),
      valueGetter: (params: GridValueGetterParams) => (params.value ? 'D' + params.value : undefined)
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 380,
      filterable: !isFlagPath(location) /* @ts-expect-error: TODO add descritption */,
      renderCell: (params: GridRenderCellParams) => <Link href={`/user/${params.id}`}>{params.value}</Link>
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 350,
      filterable: !isFlagPath(location) /* @ts-expect-error: TODO add descritption */,
      renderCell: (params: GridRenderCellParams) => <Link href={`/user/${params.id}`}>{params.value}</Link>
    },
    {
      field: 'flagAge',
      headerName: 'Flag Age',
      type: 'dateTime',
      width: 350,
      filterable: false,
      hide: !isUncompletedFlagDisplay(),
      valueGetter: (params: GridValueGetterParams) => {
        if (getFlagIdSearched()) {
          const personOverview: PersonOverview = personOverviewById[params.id];
          const flagIds: Set<string> = flagsByPersonId?.[personOverview?.personId];
          if (flagIds?.has(getFlagIdSearched())) {
            const flag: WorkflowTemplate = flagById?.[getFlagIdSearched()];
            const personFlag: Nullable<PersonWorkflow> = flag?.personWorkflows?.find((personFlag) => personFlag.personId === personOverview?.personId);
            if (personFlag) {
              const location: Nullable<Location> = personOverview?.locationId ? locationById?.[personOverview?.locationId] : undefined;
              return textToMoment(personFlag.created, location?.timeZoneId || 'America/Los_Angeles');
            }
          }
        }
        return undefined;
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        if (getFlagIdSearched()) {
          const momentValue: Moment = params?.value as Moment;
          return `${tsAsTimeSince(momentValue)} (${momentValue?.format('YYYY-MM-DD hh:mm:ss A z')})`;
        }
        return undefined;
      }
    },
    {
      field: 'taskStatus',
      headerName: 'Task Status',
      sortable: false,
      filterable: isFlagPath(location),
      hide: true,
      type: 'singleSelect',
      valueOptions: Object.values(FlagTaskStatus)
    },
    {
      field: 'accountStatus',
      headerName: 'Account Status',
      width: 200,
      sortable: false,
      filterable: !isFlagPath(location),
      type: 'singleSelect',
      valueOptions: enumFilterSelections(accountStatusList),
      valueFormatter: (params: GridValueFormatterParams) => enumValueFormatter(params.value)
    },
    {
      field: 'contentTrack',
      headerName: 'Content Track',
      width: 200,
      sortable: false,
      filterable: !isFlagPath(location),
      type: 'singleSelect',
      valueOptions: enumFilterSelections(subTypeList),
      valueFormatter: (params: GridValueFormatterParams) => enumValueFormatter(params.value)
    },
    { field: 'position', headerName: 'Content Day', type: 'number', width: 160, filterable: !isFlagPath(location) },
    {
      field: 'sleepProgram',
      headerName: 'Sleep Program',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const rowData: GridRowModel = params.row;
        const email: string = rowData?.['email'];
        const sleepProgram: boolean = params.value as boolean;
        return (
          <Button variant="contained" disabled={!sleepProgram} onClick={(e) => rowClick(`/program/${email}`, {}, e)}>
            Program
          </Button>
        );
      }
    },
    {
      field: 'connectivityStatus',
      headerName: 'Device Status',
      width: 700,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const rowData: GridRowModel = params.row;
        const connectivityStatus: string = params.value as string;
        const connectivityStatusTimestamp: string = rowData?.['connectivityStatusTimestamp'];
        const healthStatus: string = rowData?.['healthStatus'];
        const healthStatusTimestamp: string = rowData?.['healthStatusTimestamp'];
        return (
          <React.Fragment>
            <CircleIndicator status={connectivityStatus} />
            {`${connectivityStatus} (${connectivityStatusTimestamp})`}
            &nbsp;&nbsp;&nbsp;
            <CircleIndicator status={healthStatus} />
            {`${healthStatus} (${healthStatusTimestamp})`}
          </React.Fragment>
        );
      }
    },
    {
      field: 'activeFlags',
      headerName: 'Active Flags',
      width: 700,
      sortable: false,
      filterable: !isFlagPath(location),
      type: 'singleSelect',
      valueOptions: Object.values(flagById).map((flag) => {
        return { value: flag.id, label: flag.name };
      }),
      renderCell: (params: GridRenderCellParams) => {
        const flagsIds: Set<string> = flagsByPersonId[params.id] || new Set<string>();
        const pills: JSX.Element[] = [];
        flagsIds?.forEach((flagId) => pills.push(<Chip key={flagId} className={style.activeTaskChip} label={flagById?.[flagId]?.name} />));
        return <React.Fragment>{pills}</React.Fragment>;
      }
    },
    {
      field: 'deviceId',
      headerName: 'Device Details',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Button variant="contained" disabled={!params.value} onClick={(e) => rowClick(`/devices/${params.value}`, {}, e)}>
          Device
        </Button>
      )
    },
    {
      field: 'deviceGroup',
      headerName: 'Device Group',
      width: 300,
      sortable: false,
      filterable: !isFlagPath(location),
      type: 'singleSelect',
      valueOptions: enumFilterSelections(deviceGroupList),
      valueFormatter: (params: GridValueFormatterParams) => enumValueFormatter(params.value)
    },
    {
      field: 'sensorId',
      headerName: 'Unlink',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const rowData: GridRowModel = params.row;
        const deviceId: string = rowData?.['deviceId'];
        const personId: string = rowData?.['id'];
        const sensorId: string = params.value as string;
        const name: string = rowData?.['name'];
        const deviceNumber: string = rowData?.['deviceNumber'];
        return (
          <UnlinkButton
            unlinkDevice={() => postUnlinkDevice(deviceId, personId, sensorId)}
            personName={name}
            deviceNumber={deviceNumber}
            disabled={!sensorId || !deviceId || !personId}
          />
        );
      }
    },
    { field: 'pushEnabled', headerName: 'Push Enabled', width: 150, sortable: false, filterable: false },
    {
      field: 'daysSincePurchased',
      headerName: 'Days Since Purchased',
      type: 'number',
      width: 300,
      filterable: !isFlagPath(location),
      valueFormatter: (params: GridValueFormatterParams) => {
        const personOverview: PersonOverview = personOverviewById[params.id];
        const personOrder: Nullable<PersonOrder> = personOverview?.latestPersonOrderId ? personOrderById?.[personOverview?.latestPersonOrderId] : undefined;
        const location: Nullable<Location> = personOverview?.locationId ? locationById?.[personOverview.locationId] : undefined;
        return personOrder?.purchaseTs
          ? `${personOrder?.daysSincePurchased} (${textToMoment(personOrder?.purchaseTs, location?.timeZoneId || 'America/Los_Angeles')?.format('YYYY-MM-DD hh:mm:ss A z')})`
          : undefined;
      }
    },
    {
      field: 'daysSinceShipped',
      headerName: 'Days Since Shipped',
      type: 'number',
      width: 300,
      filterable: !isFlagPath(location),
      valueFormatter: (params: GridValueFormatterParams) => {
        const personOverview: PersonOverview = personOverviewById[params.id];
        const deviceShipment: Nullable<DeviceShipment> = personOverview.latestDeviceShipmentId ? deviceShipmentById?.[personOverview.latestDeviceShipmentId] : undefined;
        const location: Nullable<Location> = personOverview.locationId ? locationById?.[personOverview.locationId] : undefined;
        return deviceShipment?.deviceShippedTs
          ? `${deviceShipment?.daysSinceShipped} (${textToMoment(deviceShipment?.deviceShippedTs, location?.timeZoneId || 'America/Los_Angeles')?.format('YYYY-MM-DD hh:mm:ss A z')})`
          : undefined;
      }
    },
    {
      field: 'daysSinceReceived',
      headerName: 'Days Since Received',
      type: 'number',
      width: 300,
      filterable: !isFlagPath(location),
      valueFormatter: (params: GridValueFormatterParams) => {
        const personOverview: PersonOverview = personOverviewById[params.id];
        const deviceShipment: Nullable<DeviceShipment> = personOverview.latestDeviceShipmentId ? deviceShipmentById?.[personOverview.latestDeviceShipmentId] : undefined;
        const location: Nullable<Location> = personOverview.locationId ? locationById?.[personOverview.locationId] : undefined;
        return deviceShipment?.deviceReceivedTs
          ? `${deviceShipment?.daysSinceReceived} (${textToMoment(deviceShipment?.deviceReceivedTs, location?.timeZoneId || 'America/Los_Angeles')?.format('YYYY-MM-DD hh:mm:ss A z')})`
          : undefined;
      }
    },
    {
      field: 'daysSinceOnboarding',
      headerName: 'Days Since Onboarding',
      type: 'number',
      width: 300,
      filterable: !isFlagPath(location),
      valueFormatter: (params: GridValueFormatterParams) => {
        const personOverview: PersonOverview = personOverviewById[params.id];
        const personProgram: Nullable<PersonSleepProgram> = personOverview.personSleepProgramId ? personSleepProgramById?.[personOverview?.personSleepProgramId] : undefined;
        const location: Nullable<Location> = personOverview.locationId ? locationById?.[personOverview.locationId] : undefined;
        return personProgram?.onboardingDateTs
          ? `${personProgram?.daysSinceOnboarding} (${textToMoment(personProgram?.onboardingDateTs, location?.timeZoneId || 'America/Los_Angeles')?.format('YYYY-MM-DD hh:mm:ss A z')})`
          : undefined;
      }
    },
    { field: 'mobileAppVersion', headerName: 'Mobile App Version', width: 210, filterable: !isFlagPath(location) },
    { field: 'mobileAppBuild', headerName: 'Mobile App Build', width: 200, filterable: !isFlagPath(location) },
    {
      field: 'osType',
      headerName: 'Operating System Type',
      width: 210,
      sortable: false,
      filterable: !isFlagPath(location),
      type: 'singleSelect',
      valueOptions: enumFilterSelections(operatingSystemTypesList)
    },
    { field: 'osVersion', headerName: 'Operating System Version', width: 250, filterable: false }
  ];

  const commonFilterClick = (gridFilterItem: Nullable<GridFilterItem>) => {
    const filterModelFromUrl: GridFilterModel = { items: [] };
    if (gridFilterItem) {
      filterModelFromUrl.items.push(gridFilterItem);
    }
    handleFilterModelUpdate(filterModelFromUrl, setLocalFilterModel, setFilterModel, location);
    handleInternalLocalFilterModalChange(filterModelFromUrl); // Simulate the data-grid pass-thru of the internal filter
  };

  const handleInternalLocalFilterModalChange = (newFilterModel: GridFilterModel) => {
    if (isFlagPath(location)) {
      const gridFilters: GridFilterItem[] = [{ columnField: 'activeFlags', value: getFlagIdSearched(), operatorValue: 'is' }];
      const taskFilter: Nullable<GridFilterItem> = newFilterModel?.items?.find((filter) => filter.columnField === 'taskStatus');
      if (taskFilter) {
        gridFilters.push(taskFilter);
      }
      setLocalFilterModel(() => {
        return {
          items: gridFilters
        };
      });
    } else {
      if (newFilterModel?.items?.[0]?.columnField === 'activeFlags') {
        const gridFilters: GridFilterItem[] = [newFilterModel?.items?.[0]];
        gridFilters.push({ columnField: 'taskStatus', value: FlagTaskStatus.UNCOMPLETED, operatorValue: 'is' });
        setLocalFilterModel(() => {
          return {
            items: gridFilters
          };
        });
      } else {
        setLocalFilterModel(() => newFilterModel);
      }
    }
  };

  const selectChange = (value: any): void => {
    const entry: Nullable<[string, SelectOption]> = Array.from(quickSelectOptionMapping).find((entry) => entry[1].value === value);
    if (entry) {
      commonFilterClick(JSON.parse(entry[0]));
    } else {
      commonFilterClick(undefined);
    }
  };

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth={false}>
          <Header
            breadCrumbs={breadCrumbs}
            title={getTitle(true)}
            appendBreadCrumbs={appendBreadCrumbs}
            disableTitleGutters
            defaultCurrentCrumb={defaultAllUsersBreadCrumb}
            defaultCrumbHistory={defaultAllUsersBreadCrumbs}
            overrideUrl={url}
            overrideTitle={getTitle(false) as string}
            overrideType={isFlagPath(location) ? BreadCrumbType.SELECT : BreadCrumbType.SIMPLE}
            overrideValueOptions={isFlagPath(location) ? flagOptions : undefined}
            overrideSelectedIndex={isFlagPath(location) ? flagOptionIndex : undefined}
          />
          {!isFlagPath(location) && (
            <Box display="flex" justifyContent="space-between" className={style.filterContainer}>
              <Box>
                <TextField
                  select
                  label="Select common filter"
                  className={style.selectItem}
                  value={quickSelectSelected}
                  onChange={(e) => selectChange(e.target.value)}
                  inputProps={{ IconComponent: () => null }}
                  InputProps={{
                    endAdornment: quickSelectSelected ? (
                      <IconButton size={'small'} onClick={() => selectChange('')}>
                        <ClearIcon />
                      </IconButton>
                    ) : undefined
                  }}
                  variant="outlined"
                  size="small"
                >
                  {Array.from(quickSelectOptionMapping).map(([key, value]) => (
                    <MenuItem key={value.value} value={value.value}>
                      {value.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <Button variant="contained" color="primary" className={style.buttonItem} disabled={selectionModel.length === 0} onClick={() => toggleDisplay(false, !displaySns)}>
                  {displaySns ? 'Cancel' : 'Send'} Push Notification
                </Button>
                <Button variant="contained" color="primary" className={style.buttonItem} disabled={selectionModel.length === 0} onClick={() => toggleDisplay(!displayAdHoc)}>
                  {displayAdHoc ? 'Cancel' : 'Send'} Ad Hoc
                </Button>
              </Box>
            </Box>
          )}
          <Box className={style.filterContainer} hidden={!isFlagPath(location)}>
            <ContentTile title="Flag Task(s)" subTitles={assembleTasks(sortedMutableFlagTasks(flagById?.[getFlagIdSearched()]))} onClick={() => setOpenDialog(() => !openDialog)} />
            <Dialog fullScreen className={style.sliderDialog} open={openDialog} onClose={() => setOpenDialog(false)} TransitionComponent={defaultDialogTransition}>
              <FlagDetails
                flagId={getFlagIdSearched()}
                flagById={flagById}
                isLoading={isLoading}
                error={errorContent}
                success={successContent}
                saveSuccessful={saveSuccessful}
                getFlag={getFlag}
                saveFlag={saveFlag}
                clearResponseStatus={clearResponseStatuses}
              />
            </Dialog>
          </Box>
          <ContentSubmitter
            personIdsSet={new Set<string>(selectionModel.map((x) => x as string))}
            sendPushNotifications={sendPushNotifications}
            sendAdHocContent={sendAdHocContent}
            clearSubmissionResponse={clearPersonSubmissionResponse}
            toggleDisplay={toggleDisplay}
            submitSuccessful={updateSuccess()}
            submitFailed={updateFailed()}
            createJsx={expandedSnsMenu}
          />
          <div className={isFlagPath(location) ? style.dataGridFlag : style.dataGridUser}>
            <DataGrid
              rows={buildRows()}
              columns={columns}
              pagination
              pageSize={rowsPerPage}
              rowCount={personOverviewCount}
              page={page}
              checkboxSelection={!isFlagPath(location)}
              selectionModel={selectionModel}
              disableSelectionOnClick
              disableVirtualization
              onPageSizeChange={(newPageSize) => setRowsPerPage(() => newPageSize)}
              onPageChange={(newPage) => setPage(() => newPage)}
              onSelectionModelChange={(newSelectionModel) => setSelectionModel(() => newSelectionModel)}
              filterMode="server"
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => {
                setQuickSearch(() => '');
                if (!isEqual(filterModel, newFilterModel)) {
                  setFilterModel(() => newFilterModel);
                  const newF: GridFilterItem = newFilterModel?.items?.[0];
                  const existingF: Nullable<GridFilterItem> = localFilterModel?.items?.find((filter) => filter.columnField === newF?.columnField);
                  if (
                    (existingF && (existingF?.value !== newF?.value || existingF?.operatorValue !== newF?.operatorValue)) ||
                    (!existingF && !invalidFilterItem(newF)) ||
                    (!newF && localFilterModel?.items?.length)
                  ) {
                    handleInternalLocalFilterModalChange(newFilterModel);
                  }
                }
              }}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                if (!isEqual(sortModel, newSortModel)) {
                  setSortModel(() => newSortModel);
                }
              }}
              onRowClick={(params: GridRowParams, event: MuiEvent<SyntheticEvent>) => rowClick(`/user/${params.id}`, {}, event)}
              loading={pageLoading}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  hideExportButton: true,
                  serverQuickSearchProps: isFlagPath(location)
                    ? undefined
                    : {
                        customFilter: (searchValue) => handleQuickSearch(searchValue, setQuickSearch, columns, localFilterModel, setLocalFilterModel, setFilterModel, location),
                        searchText: quickSearch
                      }
                }
              }}
            />
          </div>
          <StatusMessage
            saveSuccessful={
              !hasActiveRecord(isLoading) &&
              !hasActiveRecord(error) &&
              !hasActiveRecord(isLoadingDevice) &&
              !hasActiveRecord(errorDevice) &&
              !hasActiveRecord(isLoadingContent) &&
              !hasActiveRecord(errorContent) &&
              (hasActiveRecord(success) || hasActiveRecord(successDevice) || hasActiveRecord(successContent))
            }
            saveUnsuccessful={
              !hasActiveRecord(isLoading) &&
              !hasActiveRecord(isLoadingDevice) &&
              !hasActiveRecord(isLoadingContent) &&
              (hasActiveRecord(error) || hasActiveRecord(errorDevice) || hasActiveRecord(errorContent))
            }
            clearResponseStatus={clearResponseStatuses}
            errorMessage={getActiveRecord(error)?.message || getActiveRecord(errorDevice)?.message || getActiveRecord(errorContent)?.message}
          />
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      personById: getPersonByIdSelector(state),
      personSettingById: getPersonSettingByIdSelector(state),
      personOrderById: getPersonOrderByIdSelector(state),
      personSleepProgramById: getPersonSleepProgramByIdSelector(state),
      sensorPersonById: getSensorPersonByIdSelector(state),
      clientDevicePushInfoById: getClientDevicePushInfoByIdSelector(state),
      personOverviewById: getPersonOverviewByIdSelector(state),
      personOverviewList: getPersonOverviewListSelector(state),
      personOverviewCount: getPersonOverviewCountSelector(state),
      deviceStateById: selectDeviceStateByIdSelector(state),
      deviceById: selectDeviceByIdSelector(state),
      locationById: selectLocationByIdSelector(state),
      deviceShipmentById: getDeviceShipmentByIdSelector(state),
      clientDeviceById: getClientDeviceById(state),
      flagById: getFlagByIdSelector(state),
      flagsByPersonId: getFlagsByPersonIdSelector(state),
      snsError: getSnsErrorSelector(state),
      adHocError: getAdHocErrorSelector(state),
      batchLoading: getBatchLoadingSelector(state),
      batchSuccess: getBatchSuccessSelector(state),
      pageLoading: getPageLoadingSelector(state),
      deviceContentByType: getDeviceContentByTypeSelector(state),
      isLoading: getPersonLoadingStateSelector(state),
      isLoadingDevice: getDeviceIsLoadingSelector(state),
      isLoadingContent: getContentIsLoadingSelector(state),
      error: getPersonErrorStateSelector(state),
      errorDevice: getDeviceErrorSelector(state),
      errorContent: getContentErrorSelector(state),
      success: getPersonSuccessStateSelector(state),
      successDevice: getDeviceSuccessSelector(state),
      successContent: getContentSuccessSelector(state),
      saveSuccessful: getSaveSuccessfulSelector(state),
      breadCrumbs: getBreadcrumbsSelector(state)
    };
  },
  (dispatch: Function) => ({
    getAllFlagContent: () => {
      dispatch(getAllFlagContentThunk());
    },
    getDeviceStateOverview: () => {
      dispatch(getDeviceStateOverviewThunk());
    },
    getDeviceContent: () => {
      dispatch(getDeviceContentThunk([DeviceContentType.AD_HOC_NATIVE]));
    },
    getPersonOverview: (personOverviewFilters: PersonOverviewFilters) => {
      dispatch(getPersonOverviewThunk(personOverviewFilters));
    },
    getPersonOverviewCount: (personOverviewFilters: PersonOverviewFilters) => {
      dispatch(getPersonOverviewCountThunk(personOverviewFilters));
    },
    sendPushNotifications: (personIds: string[], title: string, body: string) => {
      dispatch(sendPushNotificationsThunk(personIds, title, body));
    },
    sendAdHocContent: (adHocContentRequest: AdHocContentRequest, proceedToPushNotification?: Nullable<boolean>) => {
      dispatch(getCreateAdHocBatchContentThunk(adHocContentRequest, proceedToPushNotification));
    },
    postUnlinkDevice: (deviceId: string, personId: string, sensorId: string) => {
      dispatch(postUnlinkDeviceThunk(deviceId, personId, sensorId));
    },
    getFlag: (flagId: string): void => {
      dispatch(getFlagContentThunk(flagId));
    },
    saveFlag: (flag: WorkflowTemplate): void => {
      dispatch(saveFlagContentThunk(flag));
    },
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    clearPersonSubmissionResponse: () => {
      dispatch(clearPersonResponseStatusAction());
    },
    clearDeviceSubmissionResponse: () => {
      dispatch(getClearDeviceResponseStatus());
    },
    clearContentSubmissionResponse: (): void => {
      dispatch(getClearResponseStatusAction());
    }
  })
);

export default compose(connectRedux)(UserOverview) as React.ComponentType;
