import React from 'react';
import style from './AccountMobileHeader.scss';
import { Box, Hidden } from '@material-ui/core';
import fullSleepMobile from '../../../../assets/images/fullsleepmobile.png';

export const AccountMobileHeader: React.FC = () => (
  /* @ts-expect-error: TODO add description */
  <Hidden mdUp>
    <Box className={style.mobileHeader}>
      <img className={style.titleBox} src={fullSleepMobile} alt="Full Sleep" />
    </Box>
  </Hidden>
);
