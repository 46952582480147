import { Typography } from '@material-ui/core';
import React from 'react';
import cairnsLogo from '../../../../../../assets/images/Noback_multicolor.png';
import style from './DevicePane.scss';

export const DevicePane: React.FC = () => (
  <React.Fragment>
    <img className={style.imageBox} src={cairnsLogo} alt="Cairns AI" />
    <Typography className={style.helpFooterLeft}>Questions? Text us at 844-753-3771.</Typography>
  </React.Fragment>
);
