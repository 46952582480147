import React from "react";
import classNames from "classnames";
import style from './Display.scss';
import { PolymorphicElement } from "types/PolymorphicElement";

export type DisplayElement = "div" | "span";
export type DisplayVariant = "2xl" | "xl" | "lg" | "md" | "sm" | "xs"
 "xs";
export type DisplayWeight = 400 | 500 | 600 | 700;

export type DisplayProps<T extends DisplayElement> = PolymorphicElement<T> & {
  className?: string;
  variant?: DisplayVariant;
  weight?: DisplayWeight;
}

export const Display = <T extends DisplayElement = "span">(props: DisplayProps<T>) => {
  const { as, className, children, ...rest } = props;
  const variant = props.variant ?? "1";
  const weight = props.weight ?? 400;

  const Component = as ?? "span";

  return (
    <Component
    className={classNames(
      style.display,
      variant === "2xl" && style.display2xl,
      variant === "xl" && style.displayXl,
      variant === "lg" && style.displayLg,
      variant === "md" && style.displayMd,
      variant === "sm" && style.displaySm,
      variant === "xs" && style.displayXs,
      weight === 400 && style.weight400,
      weight === 500 && style.weight500,
      weight === 600 && style.weight600,
      weight === 700 && style.weight700,
      className
    )}
    {...rest}
    >
    {children}
    </Component>
  )
}
