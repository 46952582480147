import { Box, List, MenuItem, TextField as MuiTextField } from '@mui/material';
import React, { useState } from 'react';
import { TextField } from '../../components/TextField';
import { Text } from '../../components/Typography';
import { DeviceContent, SurveyOptionType } from '../../redux/content/contentTypes';
import { getQuestionIconType } from '../../utils/carePlanUtils';
import { normalizeEnumName } from '../../utils/content';
import { ActivityDrawerTabs } from './ActivityDrawerTabs';
import style from './AssessmentsLibraryDrawer.scss';
import QuestionIcon from '../../components/QuestionIcon/QuestionIcon';

interface Props {
  assessments: DeviceContent[];
  onCancel: Function;
  onSelectAssessment: (deviceContentId: string) => void;
}

export const AssessmentsLibraryDrawer: React.FC<Props> = ({ assessments, onCancel, onSelectAssessment }) => {
  const [searchText, setSearchText] = useState<string>('');
  const [categoryFilter, setCategoryFilter] = useState<string>('');
  const [tabValue, setTabValue] = useState<number>(0);

  const categories = new Set<string>();

  const allQuestions: DeviceContent[] = [];
  const fixedValueQuestions: DeviceContent[] = [];
  const numberQuestions: DeviceContent[] = [];
  const freeFormQuestions: DeviceContent[] = [];
  const booleanQuestions: DeviceContent[] = [];

  assessments.map((deviceContent) => {
    categories.add(normalizeEnumName(deviceContent.deviceContentSubType));
    if (deviceContent.deviceContentPages?.length) {
      const surveyQuestion = deviceContent?.deviceContentPages[0].surveyQuestion;
      if (surveyQuestion?.surveyOptions?.length) {
        let surveyOption = surveyQuestion.surveyOptions[0];
        if (surveyOption) {
          if (surveyOption.optionType === SurveyOptionType.SKIP && surveyQuestion.surveyOptions.length > 1) {
            surveyOption = surveyQuestion.surveyOptions[1];
          }
          if (searchText && !surveyQuestion.questionText.toLowerCase().includes(searchText.toLowerCase())) {
            return;
          }
          if (categoryFilter && normalizeEnumName(deviceContent.deviceContentSubType) !== categoryFilter) {
            return;
          }
        }
        allQuestions.push(deviceContent);
        if (surveyOption.optionType === SurveyOptionType.FIXED_VALUE) {
          fixedValueQuestions.push(deviceContent);
        } else if (surveyOption.optionType === SurveyOptionType.INTEGER || surveyOption.optionType === SurveyOptionType.FLOAT) {
          numberQuestions.push(deviceContent);
        } else if (surveyOption.optionType === SurveyOptionType.STRING) {
          freeFormQuestions.push(deviceContent);
        } else if (surveyOption.optionType === SurveyOptionType.BOOLEAN) {
          booleanQuestions.push(deviceContent);
        }
      }
    }
  });

  let assessmentsFiltered = allQuestions;
  switch (tabValue) {
    case 0:
      assessmentsFiltered = allQuestions;
      break;
    case 1:
      assessmentsFiltered = fixedValueQuestions;
      break;
    case 2:
      assessmentsFiltered = numberQuestions;
      break;
    case 3:
      assessmentsFiltered = freeFormQuestions;
      break;
    case 4:
      assessmentsFiltered = booleanQuestions;
      break;
  }

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className={style.container}>
      <Text as="div" variant="xxl" className={style.title}>
        Assessment Library
      </Text>
      <div className={style.filterRow}>
        <TextField placeholder="Search" className={style.searchField} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <MuiTextField
          value={categoryFilter}
          onChange={(event) => setCategoryFilter(event.target.value as string)}
          select
          label="Filter by category"
          className={style.categoryFilter}
          InputProps={{ sx: { borderRadius: '7px', height: '44px' } }}
          InputLabelProps={{ style: { top: '-5px', fontSize: '16px' } }}
          inputProps={{ style: { fontSize: '16px' } }}
        >
          <MenuItem value={''}>No Filter</MenuItem>
          {Array.from(categories).map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </MuiTextField>
      </div>
      <ActivityDrawerTabs
        setTabValue={setTabValue}
        tabValue={tabValue}
        allQuestionsLength={allQuestions.length}
        fixedValueQuestionsLength={fixedValueQuestions.length}
        numberQuestionsLength={numberQuestions.length}
        freeFormQuestionsLength={freeFormQuestions.length}
        booleanQuestionsLength={booleanQuestions.length}
      />
      <List className={style.itemList}>
        {Object.values(assessmentsFiltered).map((deviceContent) => {
          const id = deviceContent.id;
          const text = deviceContent.title ?? deviceContent.contentText;
          if (text) {
            const firstDeviceContentPage = deviceContent?.deviceContentPages?.length ? deviceContent.deviceContentPages[0] : null;
            return (
              <div key={id} className={style.rowItem} onClick={() => onSelectAssessment(id)}>
                <Box className={style.boxLabelBox}>
                  <Text variant="md" className={style.boxLabel}>
                    {text}
                  </Text>
                  <Text as="div" variant="sm" className={style.boxLabelDescription}>
                    {normalizeEnumName(deviceContent.deviceContentSubType)}
                  </Text>
                </Box>
                <QuestionIcon type={getQuestionIconType(firstDeviceContentPage, deviceContent?.deviceContentType)} className={style.contentIcon} />
              </div>
            );
          }
        })}
      </List>
      {/* <div className={style.drawerButtonsContainer}>
        <Button variant="outlined" onClick={() => onCancel()} className={style.headerButton}>
          Cancel
        </Button>
        {/* <Button variant="contained" onClick={onSubmitQuestions} className={style.headerButton}>
          Submit
        </Button>
      </div> */}
    </div>
  );
};
