import { Moment } from 'moment-timezone';
import { textToMoment } from './time';
import { SleepSchedule } from '../redux/person/personTypes';
import { DailySleepLog } from '../redux/activity/activityTypes';
import { SurveyScoreResponse } from '../redux/content/contentTypes';

export const UNMAPPED: string = 'UNMAPPED';

const createScheduleMapping = <T>(
  mapItems: Nullable<T[]>,
  tsCallback: (t: T) => Nullable<string>,
  latestTimeZone: Nullable<string>,
  tzCallback?: Nullable<(t: T) => Nullable<string>>,
  historicSleepSchedules?: Nullable<SleepSchedule[]>,
  format?: Nullable<string>
): Record<string, T[]> => {
  const scheduleMapping: Record<string, T[]> = {};
  let indexStart: number = 0;
  const length: number = !mapItems ? 0 : mapItems.length;
  historicSleepSchedules?.map((schedule) => {
    if (schedule.id) {
      const mapping: T[] = [];
      if (schedule.startDate && latestTimeZone) {
        const startTime: Nullable<Moment> = textToMoment(schedule.startDate, latestTimeZone);
        if (startTime) {
          for (indexStart; indexStart < length; indexStart++) {
            const mapItem: Nullable<T> = mapItems?.[indexStart];
            if (mapItem) {
              const ts: Nullable<string> = tsCallback(mapItem);
              if (ts) {
                const tz: Nullable<string> = !tzCallback ? undefined : tzCallback(mapItem);
                const timeStamp: Nullable<Moment> = textToMoment(ts, tz, format);
                if (timeStamp) {
                  if (timeStamp.isBefore(startTime)) {
                    break;
                  }
                  mapping.push(mapItem);
                }
              }
            }
          }
        }
      }
      scheduleMapping[schedule.id] = mapping;
    }
  });
  const unmappedMapping: T[] = [];
  for (indexStart; indexStart < length; indexStart++) {
    const unmappedItem: T | undefined = mapItems?.[indexStart];
    if (unmappedItem) {
      unmappedMapping.push(unmappedItem);
    }
  }
  scheduleMapping[UNMAPPED] = unmappedMapping;
  return scheduleMapping;
};

export const createScheduleLogMapping = (
  historicSleepLogs: Nullable<DailySleepLog[]>,
  latestTz: Nullable<string>,
  historicSleepSchedules?: Nullable<SleepSchedule[]>
): Record<string, DailySleepLog[]> =>
  createScheduleMapping<DailySleepLog>(
    historicSleepLogs,
    (x) => x?.bedTime,
    latestTz,
    (x) => x?.timeZone,
    historicSleepSchedules
  );

export const createScheduleSurveyMapping = (
  historicSurveyResponses: SurveyScoreResponse[],
  latestTz: Nullable<string>,
  historicSleepSchedules?: Nullable<SleepSchedule[]>
): Record<string, SurveyScoreResponse[]> =>
  createScheduleMapping<SurveyScoreResponse>(
    historicSurveyResponses,
    (x) => x?.surveyDate,
    latestTz,
    () => latestTz,
    historicSleepSchedules,
    'YYYY-MM-DD'
  );
