import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Hidden, Link, Typography } from '@material-ui/core';
import style from './SignupPane.scss';
import clsx from 'clsx';
import { emailAlertMessage, isEmailValid, isPasswordValid, passwordAlertMessage } from '../../../../../../utils/util';
import { HelpLink } from '../../../HelpLink/HelpLink';
import { RouteNames, getEmailParam } from '../../../../../../utils/navigationUtils';
import { useLocation } from 'react-router-dom';
import { RegisterPayload } from '../../../../../../redux/oauth/oauthTypes';
import { AccountInput } from '../../../AccountInput/AccountInput';
import { AccountButton } from '../../../AccountButton/AccountButton';
import { AccountMobileHeader } from '../../../AccountMobileHeader/AccountMobileHeader';

interface Props {
  register: Function;
  handleError: (err: any) => void;
  loadingState: [boolean, Dispatch<SetStateAction<boolean>>];
  handleExternalRouting: (url: string) => void;
}

export const SignupPane: React.FC<Props> = ({ register, handleError, loadingState, handleExternalRouting }) => {
  const providedEmail: string = getEmailParam(useLocation());

  const emailState: [string, Dispatch<SetStateAction<string>>] = useState<string>(providedEmail);
  const givenNamesState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');
  const familyNameState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');
  const passwordState: [string, Dispatch<SetStateAction<string>>] = useState<string>('');
  const maskPasswordState: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const [taCAgreed, setTaCAgreed] = useState<boolean>(false);

  useEffect(() => {
    loadingState[1](false);
  }, []);

  const location = useLocation();

  const submitSignup = () => {
    loadingState[1](true);
    const taCAgreedTs: Nullable<string> = taCAgreed ? new Date().toISOString() : undefined;
    const email: string = emailState[0];
    const password: string = passwordState[0];
    const givenNames: string = givenNamesState[0];
    const familyName: string = familyNameState[0];
    const registerPayload: RegisterPayload = {
      email,
      password,
      givenNames,
      familyName,
      taCAgreedTs,
      doNotAuthorizeSession: true
    };
    register(registerPayload)
      .then(() => {
        // const forwardTo: string = getAccountCreatedUrl(emailState[0], getPriceParam(location));
        const forwardToMobileAppLink = RouteNames.LINK_TO_MOBILE;
        handleExternalRouting(forwardToMobileAppLink);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  return (
    <React.Fragment>
      <Box className={style.inputBox}>
        <AccountMobileHeader />
        <Typography className={style.mainHeader}>Welcome! Create an account for Full Sleep</Typography>
        <Typography className={style.instructionHeader}>For your privacy, please set up a password to register for Full Sleep.</Typography>
        <AccountInput inputState={emailState} placeholder="Email" disabled={!!providedEmail} cssClass={style.marginTop} />
        <AccountInput inputState={givenNamesState} placeholder="First Name" />
        <AccountInput inputState={familyNameState} placeholder="Last Name" />
        <AccountInput
          inputState={passwordState}
          maskInputState={maskPasswordState}
          placeholder="Password"
          includeValidation={true}
          validationMessage={emailAlertMessage(emailState[0]) || passwordAlertMessage(passwordState[0])}
        />
        <Box className={clsx(style.marginTop, style.marginBottom)}>
          <FormControlLabel
            control={<Checkbox checked={taCAgreed} onChange={() => setTaCAgreed(!taCAgreed)} color="primary" />}
            label={
              <React.Fragment>
                I have read and agreed to Full Sleep's{' '}
                <Link href="https://www.fullsleep.com/mobile-privacy/terms-conditions" target="_blank">
                  Terms & Conditions
                </Link>
              </React.Fragment>
            }
          />
        </Box>
        <AccountButton
          label="CREATE ACCOUNT"
          onClick={submitSignup}
          loading={loadingState[0]}
          disabled={!isEmailValid(emailState[0]) || !givenNamesState[0] || !familyNameState[0] || !isPasswordValid(passwordState[0]) || !taCAgreed}
        />
        {/* @ts-expect-error: TODO add description */}
        <Hidden mdUp>
          <Typography className={style.helpFooterRight}>
            Questions? Text us at <HelpLink text={'844-753-3771'} />.
          </Typography>
        </Hidden>
      </Box>
    </React.Fragment>
  );
};
